import PainelBase from "./PainelBase";

export default class PainelCanadianPoli405W extends PainelBase {
  getCode = () => 19911;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 19911 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    select
                        prefix,
                        (select count(*) from sspedido s where s.codprodmp = 19911 and  substr(s.numerosseriecontrolados, 1, 5) = prefix) q_insumos,
                        (select count(distinct s.numped) from sspedido s where s.codprodmp = 19911 and  substr(s.numerosseriecontrolados, 1, 5) = prefix) q_pedidos
                    from (
                        select
                            distinct substr(numerosseriecontrolados, 1, 5) as prefix
                        from SSPEDIDO s
                        where s.codprodmp = 19911
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |PREFIX|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |1200  |6329     |417      |
                    |1201  |1257     |70       |
                    |0718  |37       |8        |
                    |2007  |32       |11       |
                    |0071  |22       |10       |
                    |7181  |6        |2        |
                    |1210  |3        |1        |
                    |1205  |2        |2        |
                    |2052  |1        |1        |
                    |0719  |1        |1        |
                    |8300  |1        |1        |
                    |1018  |1        |1        |
                    |0180  |1        |1        |
                    |7190  |1        |1        |
                    |3608  |1        |1        |
                    |1411  |1        |1        |
                    |0120  |1        |1        |
                    |U624  |1        |1        |
                    |3015  |1        |1        |
                    |8307  |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+
                Interpretação:
                    Essa consulta devolve todos os prefixos de 5 dígitos do insumo em questão, mostrando
                    9 resultados. Perceba que apenas as iniciais 1200 e 1201 possuem resultados consideráveis. Os
                    outros muito provavelmente são erros de bipagem.
            Tipagem:
                Foi verificado que todos os seriais desse painel são numéricos. Consulta:
                    select
                        s.numerosseriecontrolados
                    from sspedido s
                    where
                        s.codprodmp = 19911
                        and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
                Essa consulta só devolve um único serial que é composto por letras e números. Para o tamanho
                da amostra, isso é irrelevante. Provavelmente, erro de bipagem.

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 19911 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 19911 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 19911
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |14    |7533     |471      |
                    |13    |32       |12       |
                    |17    |29       |10       |
                    |12    |24       |10       |
                    |11    |22       |9        |
                    |15    |22       |8        |
                    |16    |17       |10       |
                    |18    |6        |3        |
                    |10    |6        |3        |
                    |22    |3        |3        |
                    |6     |3        |3        |
                    |7     |2        |2        |
                    |21    |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com 14 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1200 ou 1201 mais 10 dígitos numéricos, totalizando 14 dígitos.
         */
    return !!this.serial.match(/^120[01]\d{10}$/);
  }

  static build() {
    return new PainelCanadianPoli405W();
  }
}

import { HttpRestService } from "../../../services/http/HttpRestService";

export class DashboardRepository {
  static getMetaDia() {
    return HttpRestService.get(`/api/dashboard/meta-dia`);
  }

  static getMetaHora() {
    return HttpRestService.get(`/api/dashboard/meta-hora`);
  }

  static getConcluido() {
    return HttpRestService.get(`/api/dashboard/concluido`);
  }

  static getFaturados() {
    return HttpRestService.get(`/api/dashboard/faturados`);
  }

  static getAtrasados() {
    return HttpRestService.get(`/api/dashboard/atrasados`);
  }

  static getPaineis() {
    return HttpRestService.get(`/api/dashboard/paineis`);
  }

  static getCabos() {
    return HttpRestService.get(`/api/dashboard/cabo`);
  }

  static getPerfis() {
    return HttpRestService.get(`/api/dashboard/perfil`);
  }

  static getDiversos() {
    return HttpRestService.get(`/api/dashboard/diversos`);
  }

  static getConferenciaAc() {
    return HttpRestService.get(`/api/dashboard/conferencia-ac`);
  }

  static getConferenciaFinal() {
    return HttpRestService.get(`/api/dashboard/conferencia-final`);
  }

  static getAguardandoEmbarque() {
    return HttpRestService.get(`/api/v2/dashboard/embarque`);
  }

  static getPloomesAguardandoProducao() {
    return HttpRestService.get("/api/dashboard/ploomes/aguardando-producao");
  }

  static getPloomesEmLinhaDeProducao() {
    return HttpRestService.get("/api/dashboard/ploomes/linha-producao");
  }

  static getPloomesAguardandoEmbarque() {
    return HttpRestService.get("/api/dashboard/ploomes/aguardando-embarque");
  }
  static getInversores() {
    return HttpRestService.get(
      "/api/v2/dashboard/pedidos-com-inversores-pendentes"
    );
  }
}

<template>
  <div>
    <b-modal data-bv
      id="modal-detalhes-volume"
      body-class="rounded"
      size="lg sm"
      hide-footer
      hide-header
      centered
      class="py-2 px-2"
    >
      <div class="mt-2">
        <div class="d-block text-left my-3">
          <h4 class="font-weight-bold">Detalhar Caixas</h4>
        </div>
        <div>
          <h5>
            Quantidade de caixas: <b>{{ quantidadeVolumes }}</b>
          </h5>
        </div>
        <div style="overflow-y: auto; max-height: 600px">
          <div v-for="(volume, j) in volumes" :key="j">
            <div class="mb-3 px-4 py-4">
              <b-card
                header="featured"
                header-tag="div"
                :header-class="'cor-background-azul'"
              >
                <template #header>
                  <h4 class="mb-6 font-weight-bold" style="color: white">
                    Caixa {{ j + 1 }} - {{ volume.codigo }}
                  </h4>
                </template>
                <div class="my-2">
                  <b-button
                    v-if="isAdmin"
                    :disabled="!volume.codigo.length"
                    class="button-etiqueta"
                    @click="btnGerarEtiqueta(volume.codigo)"
                    >Gerar Etiqueta</b-button
                  >
                </div>
                <b-row
                  v-for="(volumeInsumo, k) in volume.insumos"
                  :key="k"
                  :class="
                    'mb-0 px-1 py-1' +
                    ` volume-insumo-${volume.volumeId}${volumeInsumo.insumo.insumoId}`
                  "
                  :draggable="isAdmin"
                  @dragstart="onDragStart($event)"
                  @dragend="onDragEnd($event)"
                  @drop="onDrop($event)"
                  @dragenter.prevent
                  @dragover.prevent
                >
                  <input
                    type="hidden"
                    :class="`input-hidden-volume-${volume.volumeId}${volumeInsumo.insumo.insumoId}`"
                    :value="volume.volumeId"
                  />
                  <input
                    type="hidden"
                    :class="`input-hidden-insumo-${volume.volumeId}${volumeInsumo.insumo.insumoId}`"
                    :value="volumeInsumo.insumo.insumoId"
                  />
                  <b-col>
                    <label
                      style="
                        align-items: center;
                        font-family: 'Poppins';
                        font-size: 15px;
                      "
                      >{{ (volumeInsumo.insumo || {}).descricao || "" }}</label
                    >
                  </b-col>
                  <b-col>
                    <label>{{ volumeInsumo.quantidade }} unidades</label>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <div>
      <modal-etiqueta :codigoCaixa="codigoCaixa" />
    </div>
  </div>
</template>

<script>
import { VolumeRepository } from "../../../../../repositories/v2/VolumeRepository";
import { UsuarioLogado } from "../../../../../services/login/UsuarioLogado";
import ModalEtiqueta from "../Etiqueta/ModalEtiqueta.vue";

export default {
  name: "ModalDetalhesDeCaixasLinhaCabos",
  components: {
    ModalEtiqueta,
  },
  data: () => ({
    targetDraggableCodigo: "",
    srcDraggableCodigo: "",
    codigoCaixa: "",
    repositories: {
      volumeRepository: VolumeRepository.build(),
    },
  }),
  props: {
    volumes: {
      type: Array,
      required: true,
    },
    quantidadeVolumes: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isAdmin() {
      return UsuarioLogado.getPerfil().includes("administrador");
    },
  },
  methods: {
    btnGerarEtiqueta(codigoCaixa) {
      this.codigoCaixa = codigoCaixa;
      this.$bvModal.show("modal-etiqueta");
    },

    turnCardMovingEffect(target) {
      target.style.opacity = "40%";
      target.style.border = "3px dotted #666";
    },
    stopCardMovingEffect(target) {
      target.style.opacity = "100%";
      target.style.border = "none";
    },
    onDragStart(event) {
      this.srcDraggableCodigo = Array.from(event.target.classList)
        .reverse()[0]
        .replace(/\D/g, "");
      event.dataTransfer.setData("text", this.srcDraggableCodigo);
      event.dataTransfer.dropEffect = "copy";
      this.turnCardMovingEffect(event.currentTarget);
    },
    onDragEnd(event) {
      this.stopCardMovingEffect(event.target);
    },
    async onDrop(event) {
      let draggableDiv = event.target;
      while (!draggableDiv.draggable) draggableDiv = draggableDiv.parentElement;
      const getCodigo = (el) =>
        Array.from(el.classList).reverse()[0].replace(/\D/g, "");
      this.targetDraggableCodigo = getCodigo(draggableDiv);
      const srcCaixa = {
        volumeId: +document.querySelector(
          `.input-hidden-volume-${this.srcDraggableCodigo}`
        ).value,
        insumoId: +document.querySelector(
          `.input-hidden-insumo-${this.srcDraggableCodigo}`
        ).value,
      };
      const targetCaixa = {
        volumeId: +document.querySelector(
          `.input-hidden-volume-${this.targetDraggableCodigo}`
        ).value,
        insumoId: +document.querySelector(
          `.input-hidden-insumo-${this.targetDraggableCodigo}`
        ).value,
      };
      this.srcDraggableCodigo = "";
      this.targetDraggableCodigo = "";
      this.stopCardMovingEffect(
        document.querySelector(
          `.volume-insumo-${srcCaixa.volumeId}${srcCaixa.insumoId}`
        )
      );
      if (!confirm("Confirma mover insumo?")) {
        return;
      }
      const response =
        await this.repositories.volumeRepository.moverInsumoDeVolumeLinhaDiversos(
          {
            numeroPedido: this.$route.params.numped,
            insumoId: srcCaixa.insumoId,
            volumeIdOrigem: srcCaixa.volumeId,
            volumeIdDestino: targetCaixa.volumeId,
          }
        );
      if (response.status !== 200) {
        alert(
          response.data.message ||
            "Ocorreu um erro no ato de mover insumo. Entrar em contato com suporte."
        );
        return;
      }
      const volumeSrc = this.volumes.find(
        ({ volumeId }) => volumeId === srcCaixa.volumeId
      );
      const insumosSrcVolume = volumeSrc.insumos;
      let insumoEmTransporte = {};
      // Retira um insumo da caixa fonte, decrementando sua quantidade naquela caixa. Se a quantidade se tornar 0, ele deve ser removido.
      for (let i = 0; i < insumosSrcVolume.length; i++) {
        if (insumosSrcVolume[i].insumo.insumoId === srcCaixa.insumoId) {
          insumoEmTransporte = { ...insumosSrcVolume[i] };
          insumosSrcVolume[i].quantidade--;
          if (insumosSrcVolume[i].quantidade === 0) {
            if (insumosSrcVolume.length === 1) {
              alert(
                "Não é possível mover esse insumo, pois a caixa não pode ficar vazia."
              );
              insumosSrcVolume[i].quantidade++;
              break;
            }
            insumosSrcVolume.splice(i, 1);
          }
        }
      }
      if (insumosSrcVolume.length === 0) {
        for (let i = 0; i < this.volumes.length; i++) {
          if (this.volumes[i].volumeId === srcCaixa.volumeId) {
            this.volumes.splice(i, 1);
          }
        }
      }
      const insumosTargetVolume = this.volumes.find(
        ({ volumeId }) => volumeId === targetCaixa.volumeId
      ).insumos;
      // Para o caso de já existir um insumo na caixa alvo, apenas incrementa a quantidade
      for (let i = 0; i < insumosTargetVolume.length; i++) {
        if (insumosTargetVolume[i].insumo.insumoId === srcCaixa.insumoId) {
          insumosTargetVolume[i].quantidade++;
          return;
        }
      }
      // Se o loop foi percorrido e o insumo não foi encontrado, então deve-se inserí-lo
      insumosTargetVolume.push({ ...insumoEmTransporte, quantidade: 1 });
    },
  },
};
</script>

<style scoped>
.cor-background-azul {
  background: #002233;
}
.button-etiqueta {
  background: #ff7d27;
  border: none;
  color: #002233;
  font-weight: bold;
}
.button-etiqueta:hover {
  color: white;
}
</style>

<template>
  <div>
    <div
      v-for="(v, index) in volumes.filter((v) => v.volumeId === 0)"
      :key="index"
    >
      <table :class="loading ? 'caixa-carregando' : ''">
        <thead class="text-white">
          <tr>
            <th style="background-color: #002233 !important">
              Caixa {{ v.index }}
            </th>
          </tr>
        </thead>
      </table>
      <listagem-insumos-no-volume-linha-paineis
        :insumos="insumos.filter((i) => i.volumeId === v.volumeId)"
        :volumes="volumes.filter((vol) => vol.volumeId === v.volumeId)"
        :indexVol="index"
        :total-insumos="insumos.length"
        :loading="loading"
        v-on="$listeners"
      />
    </div>
  </div>
</template>
<script>
import ListagemInsumosNoVolumeLinhaPaineis from "./ListagemInsumosNoVolumeLinhaPaineis.vue";
export default {
  name: "ListagemVolumesLinhaPaineis",
  components: { ListagemInsumosNoVolumeLinhaPaineis },
  data: () => ({
    numVol: 1,
  }),
  props: {
    insumos: { type: Array, required: true },
    volumes: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },
};
</script>
<style scoped>
.caixa-carregando {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>

import { HttpRestService } from "@/services/http/HttpRestService";

export class EtiquetaRepository {
  static build() {
    return new EtiquetaRepository();
  }

  getImpressoras() {
    return HttpRestService.get(`/api/v2/usuario/listagem/impressoras`);
  }

  sendEtiqueta(codigoCaixa, impressoraId) {
    return HttpRestService.get(
      `/api/v2/etiqueta/${codigoCaixa}/${impressoraId}`
    );
  }

  resendEtiqueta(numped) {
    return HttpRestService.get(`/api/v2/carga/etiqueta/${numped}`);
  }
}

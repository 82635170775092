import { HttpRestService } from "@/services/http/HttpRestService";

export default class Repository {
  static getVolumeDetails(volumeCode) {
    return HttpRestService.get(
      `/api/v2/volume/detalhes/conferencia-painel/${volumeCode}`
    );
  }
  static confirmOrder(orderToBeConfirmed) {
    return HttpRestService.post(
      `/api/v2/volume/confirmar/conferencia-painel`,
      orderToBeConfirmed
    );
  }
  static blockOrder(orderToBeBlocked) {
    return HttpRestService.post(
      `/api/v2/pedido/bloquear`,
      orderToBeBlocked
    );
  }
}

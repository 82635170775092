<template>
  <div>
    <div class="content">
      <div class="box-content">
        <div class="pedido-det" v-if="linhasProducao.length">
          <div class="topo-det">
            <div class="det-info">
              <div class="item">
                RESUMO PEDIDO: {{ this.detalhesDoPedido.numeroPedido }} - NFE
                {{ this.detalhesDoPedido.chaveNfe }} - Transportadora:
                {{ this.detalhesDoPedido.transportadoraNome }}
              </div>

              <div class="item">
                <button type="submit" class="btn btn-danger" @click="report">
                  Relatório
                </button>
              </div>

              <div class="item">
                <button
                  v-if="isAdmin"
                  type="submit"
                  class="btn btn-danger"
                  @click="cancelarDespacho"
                >
                  Cancelar Despacho
                </button>
              </div>

              <div class="item">
                <button
                  v-if="isAdmin"
                  type="submit"
                  class="btn btn-danger"
                  @click="actionZerar(this.detalhesDoPedido.numeroPedido)"
                >
                  Zerar Bipagem
                </button>
              </div>
            </div>
          </div>
          <div class="det-info">
            <div class="item">
              <span>CLIENTE:</span> {{ this.detalhesDoPedido.clienteNome }}
            </div>
            <div class="item">
              <span>DATA DE PRODUÇÃO:</span>
              {{ formatDate(this.detalhesDoPedido.dataProducao) }}
            </div>
          </div>
          <div class="det-info">
            <div class="item">
              <span>KIT:</span> {{ this.detalhesDoPedido.kitDescricao }}
            </div>
            <div class="item">
              <span>LOCALIZAÇÃO DO ESTOQUE: </span
              >{{ this.detalhesDoPedido.localizacaoEstoque }}
            </div>
          </div>
          <div class="counter">
            <h3>Total Itens: {{ this.detalhesDoPedido.totalInsumos }}</h3>
          </div>

          <div class="prods">
            <table class="table table-bordered" v-if="this.linha1.length">
              <thead>
                <tr>
                  <th colspan="4">Linha 1</th>
                  <th width="120">
                    Volume: {{ this.detalhesDoPedido.volumesLinha1 }}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Funcionário Bipagem</th>
                  <th>Dt Incial Sep.</th>
                  <th>Dt Final Sep.</th>
                  <th>Cód. Barras</th>
                  <!-- <th>Cód. Barras2</th> -->
                </tr>
              </thead>

              <tbody>
                <tr v-for="(pedido, index) in linha1" :key="index">
                  <td>
                    {{ pedido.codigoProdutoMateriaPrima }} -
                    {{ pedido.insumoDescricao }} -
                    <span class="qt-table"
                      >QUANTIDADE: {{ pedido.insumosQuantidade }}</span
                    >
                  </td>
                  <td>
                    {{ pedido.bipadorCodigo ? pedido.bipadorNome : "" }}
                  </td>
                  <td>{{ formatDate(pedido.dataInicio) }}</td>
                  <td>{{ formatDate(pedido.dataFinal) }}</td>
                  <td>{{ pedido.NUMEROSSERIECONTROLADOS }}</td>
                </tr>
              </tbody>
            </table>

            <table class="table table-bordered" v-if="this.linha2.length">
              <thead>
                <tr>
                  <th colspan="4">Linha 2</th>
                  <th width="120">
                    Volume:
                    {{ this.detalhesDoPedido.volumesLinha2 }}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Funcionário Bipagem</th>
                  <th>Dt Incial Sep.</th>
                  <th>Dt Final Sep.</th>
                  <th>Cód. Barras</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in linha2" :key="index">
                  <td>
                    {{ pedido.codigoProdutoMateriaPrima }} -
                    {{ pedido.insumoDescricao }} -
                    <span class="qt-table"
                      >QUANTIDADE: {{ pedido.insumosQuantidade }}</span
                    >
                  </td>
                  <td>
                    {{ pedido.bipadorCodigo ? pedido.bipadorNome : "" }}
                  </td>
                  <td>{{ formatDate(pedido.dataInicio) }}</td>
                  <td>{{ formatDate(pedido.dataFinal) }}</td>
                  <td>{{ pedido.NUMEROSSERIECONTROLADOS }}</td>
                </tr>
              </tbody>
            </table>

            <table class="table table-bordered" v-if="this.linha3.length">
              <thead>
                <tr>
                  <th colspan="5">Linha 3</th>
                  <th width="120">
                    Volume:
                    {{ this.detalhesDoPedido.volumesLinha3 }}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Funcionário Bipagem</th>
                  <th>Dt Incial Sep.</th>
                  <th>Dt Final Sep.</th>
                  <th>Cód. Barras</th>
                  <th>Cód. Barras2</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in linha3" :key="index">
                  <td>
                    {{ pedido.codigoProdutoMateriaPrima }} -
                    {{ pedido.insumoDescricao }}
                    <span class="qt-table"
                      >QUANTIDADE: {{ pedido.insumosQuantidade }}</span
                    >
                  </td>
                  <td>
                    {{ pedido.bipadorCodigo ? pedido.bipadorNome : "" }}
                  </td>
                  <td>{{ formatDate(pedido.dataInicio) }}</td>
                  <td>{{ formatDate(pedido.dataFinal) }}</td>
                  <td>{{ pedido.NUMEROSSERIECONTROLADOS }}</td>
                  <td>{{ pedido.NUMEROSSERIECONTROLADOS2 }}</td>
                </tr>
              </tbody>
            </table>

            <!--RODRIGO-->
            <!--          <table class="table table-bordered" v-if="this.linha2.length">-->
            <table class="table table-bordered" v-if="this.linha4.length">
              <thead>
                <tr>
                  <th colspan="4">Linha 4</th>
                  <th width="120">
                    Volume:
                    {{ this.detalhesDoPedido.volumesLinha4 }}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Funcionário Bipagem</th>
                  <th>Dt Incial Sep.</th>
                  <th>Dt Final Sep.</th>
                  <th>Cód. Barras</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in linha4" :key="index">
                  <td>
                    {{ pedido.codigoProdutoMateriaPrima }} -
                    {{ pedido.insumoDescricao }}
                    <span class="qt-table"
                      >QUANTIDADE: {{ pedido.insumosQuantidade }}</span
                    >
                  </td>
                  <td>
                    {{ pedido.bipadorCodigo ? pedido.bipadorNome : "" }}
                  </td>
                  <td>{{ formatDate(pedido.dataInicio) }}</td>
                  <td>{{ formatDate(pedido.dataFinal) }}</td>
                  <td>{{ pedido.NUMEROSSERIECONTROLADOS }}</td>
                </tr>
              </tbody>
            </table>

            <br />
            <h3>Histórico</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Data</th>
                  <th>Funcionário</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in historicoDoPedido" :key="index">
                  <td>{{ item.status_nome }}</td>
                  <td>{{ formatDate(item.dataCriacao) }}</td>
                  <td>{{ item.despachante_nome }}</td>
                </tr>
              </tbody>
            </table>

            <br />
            <h3>Log Modificações com autorização</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Antigo Status</th>
                  <th>Status</th>
                  <th>Data</th>
                  <th>Funcionário</th>
                  <th>Obs</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in list.logs" :key="index">
                  <td>{{ status(item.STATUS) }}</td>
                  <td>{{ status(item.NEW_STATUS) }}</td>
                  <td>{{ formatDate(item.CREATED_AT) }}</td>
                  <td>{{ item.usuario ? item.usuario.NOME : "" }}</td>
                  <td>{{ item.OBS }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "../../../../mixins/routesValidationMixin";
import { UsuarioLogado } from "@/services/login/UsuarioLogado";
import { PedidoResumoService } from "../../../../services/producao_pedido/expedicao_pedido/PedidoResumoService";
import UsersRepository from "../../../../repositories/v1/UsersRepository";
import PedidoWebRepository from "../../../../repositories/v2/PedidoWebRepository";
import Swal from "sweetalert2";
import router from "../../../../router";

export default {
  components: {},
  data() {
    return {
      pedidos: [],
      list: [],
      historicoDoPedido: [],
      detalhesDoPedido: [],
      linhasProducao: [],
      linha1: [],
      linha2: [],
      linha3: [],
      linha4: [],
      save_ped: {},
      total_items: 0,
      volumes: 0,
      usersRepository: UsersRepository.build(),
      pedidoWebRepository: PedidoWebRepository.build(),
      pedidoResumoService: PedidoResumoService.build(),
    };
  },
  mixins: [routesValidationMixin],
  computed: {
    isAdmin() {
      return UsuarioLogado.getPerfil().includes("administrador");
    },
  },
  async created() {
    this.pedidoResumoService = PedidoResumoService.build().setVm(this);

    this.usersRepository
      .getFuncoesByUser(`${UsuarioLogado.getUserId()}`)
      .then((response) => {
        if (response.data.roles.includes("ADMIN")) {
          this.type = "ADMIN";
        } else {
          window.location.href = "/dashboard";
        }

        this.pedidoWebRepository
          .pedidoResumoEmbarque(`${this.$route.params.id}`)
          .then((response) => {
            // this.list = response.data;
            // this.pedidos = response.data.pedidos;
            // this.volumes = response.data.total;

            //Novo response
            if (!response.data)
              Swal.fire(
                "Pedido sem dados. Favor entrar em contato com suporte.",
                "",
                "error"
              ).then((result) => {
                if (result.isConfirmed) {
                  router.replace({ path: "/pedidos-ss/despachados" });
                }
              });
            this.historicoDoPedido = response.data.data.historico;
            this.detalhesDoPedido = response.data.data.pedido;
            this.linhasProducao = response.data.data.linhasProducao;
            this.linha1 = this.linhasProducao
              .filter((i) => i.linha === 1)
              .map((um) => ({
                insumoDescricao: um.insumoDescricao,
                codigoProdutoMateriaPrima: um.codigoProdutoMateriaPrima,
                insumosQuantidade: um.insumosQuantidade,
                linha: um.linha,
                bipadorCodigo: um.bipadorCodigo,
                bipadorNome: um.bipadorNome,
                dataInicio: um.dataInicio,
                dataFinal: um.dataFinal,
              }));
            this.linha2 = this.linhasProducao
              .filter((i) => i.linha === 2)
              .map((dois) => ({
                insumoDescricao: dois.insumoDescricao,
                codigoProdutoMateriaPrima: dois.codigoProdutoMateriaPrima,
                insumosQuantidade: dois.insumosQuantidade,
                linha: dois.linha,
                bipadorCodigo: dois.bipadorCodigo,
                bipadorNome: dois.bipadorNome,
                dataInicio: dois.dataInicio,
                dataFinal: dois.dataFinal,
              }));
            this.linha3 = this.linhasProducao
              .filter((i) => i.linha === 3)
              .map((tres) => ({
                insumoDescricao: tres.insumoDescricao,
                codigoProdutoMateriaPrima: tres.codigoProdutoMateriaPrima,
                insumosQuantidade: tres.insumosQuantidade,
                linha: tres.linha,
                bipadorCodigo: tres.bipadorCodigo,
                bipadorNome: tres.bipadorNome,
                dataInicio: tres.dataInicio,
                dataFinal: tres.dataFinal,
              }));

            this.linha4 = this.linhasProducao
              .filter((i) => i.linha === 4)
              .map((quatro) => ({
                insumoDescricao: quatro.insumoDescricao,
                codigoProdutoMateriaPrima: quatro.codigoProdutoMateriaPrima,
                insumosQuantidade: quatro.insumosQuantidade,
                linha: quatro.linha,
                bipadorCodigo: quatro.bipadorCodigo,
                bipadorNome: quatro.bipadorNome,
                dataInicio: quatro.dataInicio,
                dataFinal: quatro.dataFinal,
              }));

            // if (this.detalhesDoPedido.length) {
            //   this.item_save = this.this.detalhesDoPedido.NUMSEQ;
            //   this.total_items = this.pedidos.length;
            // }
          })
          .catch(function (error) {
            console.error(error);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  },
  methods: {
    formatDate(data) {
      return this.pedidoResumoService.formatDate(data);
    },
    report() {
      return this.pedidoResumoService.report();
    },
    cancelarDespacho() {
      this.pedidoResumoService.cancelarDespacho();
    },
    actionZerar(id) {
      this.pedidoResumoService.actionZerar(id);
    },
    status(label) {
      return this.pedidoResumoService.status(label);
    },
  },
};
</script>

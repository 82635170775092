import PainelBase from "./PainelBase";

export default class PainelDahMono540W extends PainelBase {
  getCode = () => 15892;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 15892 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 15892)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 15892 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 15892 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 4) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 15892
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +--------+---------+---------+-------+
                    |PREFIX  |Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +--------+---------+---------+-------+
                    |18582104|11034    |386      |74%    |
                    |85821041|438      |58       |3%     |
                    |82104100|302      |42       |2%     |
                    |58210410|241      |38       |2%     |
                    |85821040|157      |25       |1%     |
                    |82104020|137      |23       |1%     |
                    |21041004|122      |29       |1%     |
                    |58210402|94       |20       |1%     |
                    |18582105|87       |5        |1%     |
                    |82104120|64       |14       |0%     |
                    |58210412|40       |13       |0%     |
                    |10410040|32       |13       |0%     |
                    |21040202|30       |10       |0%     |
                    |18582106|17       |5        |0%     |
                    |18582103|17       |3        |0%     |
                    |04100400|14       |9        |0%     |
                    |82104030|14       |8        |0%     |
                    |18582102|11       |2        |0%     |
                    |18582101|9        |3        |0%     |
                    |10402021|8        |4        |0%     |
                    |12101600|6        |1        |0%     |
                    |21041202|6        |4        |0%     |
                    |58210403|5        |3        |0%     |
                    |04100401|5        |3        |0%     |
                    |41004011|4        |2        |0%     |
                    |04020210|3        |2        |0%     |
                    |21040303|3        |2        |0%     |
                    |00400678|2        |2        |0%     |
                    |40202102|2        |2        |0%     |
                    |02021003|2        |2        |0%     |
                    |18582107|2        |1        |0%     |
                    |41004090|2        |2        |0%     |
                    |10412020|2        |2        |0%     |
                    |40202100|2        |2        |0%     |
                    |85821010|2        |1        |0%     |
                    |40202101|2        |2        |0%     |
                    |00400674|2        |2        |0%     |
                    |10040111|2        |1        |0%     |
                    |04011129|1        |1        |0%     |
                    |04002632|1        |1        |0%     |
                    |18581880|1        |1        |0%     |
                    |04004205|1        |1        |0%     |
                    |02021020|1        |1        |0%     |
                    |02101561|1        |1        |0%     |
                    |41004007|1        |1        |0%     |
                    |41004003|1        |1        |0%     |
                    |10028818|1        |1        |0%     |
                    |04009112|1        |1        |0%     |
                    |00400566|1        |1        |0%     |
                    |12107180|1        |1        |0%     |
                    |10040094|1        |1        |0%     |
                    |04018185|1        |1        |0%     |
                    |58018581|1        |1        |0%     |
                    |41004009|1        |1        |0%     |
                    |10040900|1        |1        |0%     |
                    |00400951|1        |1        |0%     |
                    |41004050|1        |1        |0%     |
                    |4007059 |1        |1        |0%     |
                    |04009618|1        |1        |0%     |
                    |0042618 |1        |1        |0%     |
                    |58210411|1        |1        |0%     |
                    |85821018|1        |1        |0%     |
                    |00400672|1        |1        |0%     |
                    |85821011|1        |1        |0%     |
                    |02101155|1        |1        |0%     |
                    |41004001|1        |1        |0%     |
                    |04004873|1        |1        |0%     |
                    |00405006|1        |1        |0%     |
                    |40106471|1        |1        |0%     |
                    |04011014|1        |1        |0%     |
                    |18518511|1        |1        |0%     |
                    |3014775 |1        |1        |0%     |
                    |12104601|1        |1        |0%     |
                    |04006777|1        |1        |0%     |
                    |05341858|1        |1        |0%     |
                    |81858101|1        |1        |0%     |
                    |07121185|1        |1        |0%     |
                    |82104185|1        |1        |0%     |
                    |10040068|1        |1        |0%     |
                    |10040089|1        |1        |0%     |
                    |0876018 |1        |1        |0%     |
                    |10040071|1        |1        |0%     |
                    |21026861|1        |1        |0%     |
                    |04008338|1        |1        |0%     |
                    |1033991 |1        |1        |0%     |
                    |20210253|1        |1        |0%     |
                    |4005533 |1        |1        |0%     |
                    |85801811|1        |1        |0%     |
                    |04003954|1        |1        |0%     |
                    |00400060|1        |1        |0%     |
                    |007226  |1        |1        |0%     |
                    |4000403 |1        |1        |0%     |
                    |10403223|1        |1        |0%     |
                    |00400703|1        |1        |0%     |
                    |0724018 |1        |1        |0%     |
                    |84007111|1        |1        |0%     |
                    |72318582|1        |1        |0%     |
                    |82104118|1        |1        |0%     |
                    |00895018|1        |1        |0%     |
                    |8005463 |1        |1        |0%     |
                    |02103074|1        |1        |0%     |
                    |00401142|1        |1        |0%     |
                    |0425018 |1        |1        |0%     |
                    |15282101|1        |1        |0%     |
                    |04100405|1        |1        |0%     |
                    |41004010|1        |1        |0%     |
                    |04010651|1        |1        |0%     |
                    |21601010|1        |1        |0%     |
                    |00400018|1        |1        |0%     |
                    |10040004|1        |1        |0%     |
                    |41121185|1        |1        |0%     |
                    |00400734|1        |1        |0%     |
                    |02021019|1        |1        |0%     |
                    |00400202|1        |1        |0%     |
                    |71858210|1        |1        |0%     |
                    |40051561|1        |1        |0%     |
                    |04050025|1        |1        |0%     |
                    |40322300|1        |1        |0%     |
                    |41004006|1        |1        |0%     |
                    |10040073|1        |1        |0%     |
                    |28118018|1        |1        |0%     |
                    |20210057|1        |1        |0%     |
                    |4005088 |1        |1        |0%     |
                    |02104224|1        |1        |0%     |
                    |18581858|1        |1        |0%     |
                    |11191858|1        |1        |0%     |
                    |00409006|1        |1        |0%     |
                    |02102590|1        |1        |0%     |
                    |0548185 |1        |1        |0%     |
                    |00401147|1        |1        |0%     |
                    |40202103|1        |1        |0%     |
                    |00400523|1        |1        |0%     |
                    |00400835|1        |1        |0%     |
                    |40303700|1        |1        |0%     |
                    |0981858 |1        |1        |0%     |
                    |4000248 |1        |1        |0%     |
                    |82104032|1        |1        |0%     |
                    |04100409|1        |1        |0%     |
                    |04007338|1        |1        |0%     |
                    |20210235|1        |1        |0%     |
                    |00400206|1        |1        |0%     |
                    |21036531|1        |1        |0%     |
                    |10040113|1        |1        |0%     |
                    |0042611 |1        |1        |0%     |
                    |2104117 |1        |1        |0%     |
                    |41004008|1        |1        |0%     |
                    |NULL    |0        |0        |0%     |
                    +--------+---------+---------+-------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 8 dígitos do insumo em questão, mostrando
                    147 resultados. Perceba que a inicial 18582104 é a mais relevante, com 74%. Portanto,
                    vamos considerar que o serial inicie com 18582104.
            Tipagem:
                Foi verificado que os seriais desse painel são compostos apenas por números.
                Consulta:
                    select
                        count(*)
                    from sspedido s
                    where
                          s.codprodmp = 15892
                          and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 15892 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 15892 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 15892
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |18    |10739    |386      |
                    |19    |417      |68       |
                    |17    |417      |71       |
                    |16    |274      |62       |
                    |20    |272      |61       |
                    |21    |247      |66       |
                    |15    |206      |65       |
                    |22    |70       |27       |
                    |14    |70       |34       |
                    |23    |26       |17       |
                    |9     |22       |17       |
                    |25    |21       |18       |
                    |12    |21       |14       |
                    |10    |20       |14       |
                    |13    |20       |12       |
                    |24    |19       |13       |
                    |27    |18       |14       |
                    |11    |17       |11       |
                    |8     |17       |12       |
                    |7     |16       |11       |
                    |28    |12       |10       |
                    |36    |12       |5        |
                    |26    |11       |9        |
                    |33    |8        |6        |
                    |30    |7        |5        |
                    |32    |7        |4        |
                    |35    |6        |4        |
                    |34    |5        |3        |
                    |29    |5        |5        |
                    |38    |3        |1        |
                    |48    |2        |2        |
                    |51    |2        |2        |
                    |39    |2        |1        |
                    |47    |2        |2        |
                    |31    |2        |2        |
                    |40    |2        |1        |
                    |41    |2        |2        |
                    |44    |1        |1        |
                    |113   |1        |1        |
                    |185   |1        |1        |
                    |105   |1        |1        |
                    |6     |1        |1        |
                    |70    |1        |1        |
                    |60    |1        |1        |
                    |88    |1        |1        |
                    |54    |1        |1        |
                    |46    |1        |1        |
                    |55    |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com 18 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pela inicial 18582104 mais 10 dígitos numéricos, totalizando 18 dígitos.
         */
    return !!this.serial.match(/^18582104\d{10}$/);
  }

  static build() {
    return new PainelDahMono540W();
  }
}

import PainelBase from "./PainelBase";

export default class PainelLongiMonoHalfCell440W extends PainelBase {
  getCode = () => 7329;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 7329 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 7329 and numerosseriecontrolados is not null)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') percent from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 7329 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 7329 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 7) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 7329
                               and numerosseriecontrolados is not null
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +-------+---------+---------+-------+
                    |PREFIX |Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +-------+---------+---------+-------+
                    |LRP6040|13134    |590      |90%    |
                    |RP60408|606      |71       |4%     |
                    |P604088|355      |48       |2%     |
                    |P604089|210      |18       |1%     |
                    |6040882|35       |13       |0%     |
                    |3824210|28       |1        |0%     |
                    |6040892|21       |8        |0%     |
                    |U624MM2|20       |4        |0%     |
                    |12     |18       |2        |0%     |
                    |2404202|14       |1        |0%     |
                    |24MM21A|11       |1        |0%     |
                    |1      |10       |2        |0%     |
                    |8611383|8        |3        |0%     |
                    |[NULL] |8        |1        |0%     |
                    |0408920|7        |5        |0%     |
                    |624MM21|6        |1        |0%     |
                    |0408820|6        |3        |0%     |
                    |2012012|6        |4        |0%     |
                    |0882012|5        |4        |0%     |
                    |2020202|5        |1        |0%     |
                    |0892011|5        |4        |0%     |
                    |1858210|4        |1        |0%     |
                    |8210410|4        |1        |0%     |
                    |8820120|4        |3        |0%     |
                    |4MM21A5|4        |1        |0%     |
                    |8201201|3        |3        |0%     |
                    |4089201|3        |2        |0%     |
                    |4088201|3        |2        |0%     |
                    |8582104|3        |1        |0%     |
                    |SUX0233|3        |1        |0%     |
                    |9201103|2        |1        |0%     |
                    |L)!@)!@|2        |2        |0%     |
                    |LRA6040|2        |2        |0%     |
                    |5821041|2        |1        |0%     |
                    |1020304|2        |1        |0%     |
                    |3213123|2        |1        |0%     |
                    |2312312|1        |1        |0%     |
                    |1208215|1        |1        |0%     |
                    |05303  |1        |1        |0%     |
                    |10621LR|1        |1        |0%     |
                    |3304087|1        |1        |0%     |
                    |2011033|1        |1        |0%     |
                    |520864 |1        |1        |0%     |
                    |13     |1        |1        |0%     |
                    |gfdg433|1        |1        |0%     |
                    |202020 |1        |1        |0%     |
                    |10945L |1        |1        |0%     |
                    |5260LR |1        |1        |0%     |
                    |0330415|1        |1        |0%     |
                    |201349 |1        |1        |0%     |
                    |0110052|1        |1        |0%     |
                    |2012019|1        |1        |0%     |
                    |1201202|1        |1        |0%     |
                    |5598L  |1        |1        |0%     |
                    |1000648|1        |1        |0%     |
                    |0330512|1        |1        |0%     |
                    |122    |1        |1        |0%     |
                    |0120971|1        |1        |0%     |
                    |1103305|1        |1        |0%     |
                    |040882L|1        |1        |0%     |
                    |1005226|1        |1        |0%     |
                    |1231231|1        |1        |0%     |
                    |1210233|1        |1        |0%     |
                    |2012103|1        |1        |0%     |
                    |RLRLRP6|1        |1        |0%     |
                    |0520275|1        |1        |0%     |
                    |212    |1        |1        |0%     |
                    |25     |1        |1        |0%     |
                    |0330412|1        |1        |0%     |
                    |1101704|1        |1        |0%     |
                    |22659LR|1        |1        |0%     |
                    |1232132|1        |1        |0%     |
                    |14     |1        |1        |0%     |
                    |gf54354|1        |1        |0%     |
                    |2020   |1        |1        |0%     |
                    |4354353|1        |1        |0%     |
                    |0330523|1        |1        |0%     |
                    |305244 |1        |1        |0%     |
                    |0110330|1        |1        |0%     |
                    |1103304|1        |1        |0%     |
                    |0522603|1        |1        |0%     |
                    |2312321|1        |1        |0%     |
                    |0120587|1        |1        |0%     |
                    |1201204|1        |1        |0%     |
                    |2132131|1        |1        |0%     |
                    |2321321|1        |1        |0%     |
                    |2011005|1        |1        |0%     |
                    |0120121|1        |1        |0%     |
                    |0232LRP|1        |1        |0%     |
                    |1210204|1        |1        |0%     |
                    |22680LR|1        |1        |0%     |
                    |8920110|1        |1        |0%     |
                    |0120205|1        |1        |0%     |
                    |2131231|1        |1        |0%     |
                    |2012106|1        |1        |0%     |
                    |1210240|1        |1        |0%     |
                    |22677L |1        |1        |0%     |
                    |1208583|1        |1        |0%     |
                    |0330514|1        |1        |0%     |
                    +-------+---------+---------+-------+
                Interpretação:
                    Essa consulta devolve todos os prefixos de 7 dígitos do insumo em questão, mostrando
                    99 resultados. Perceba que a inicial com relevância é LRP6040.
                    Portanto, vamos considerar que esse painel sempre comece com essa sequência.
            Tipagem:
                Consulta:
                    select
                        s.numerosseriecontrolados,
                        s.NUMPED
                    from sspedido s
                    where
                          s.codprodmp = 7329
                      and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
                Essa consulta mostra que os seriais são formados por números e letras.
            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 7329 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 7329 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 7329
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |NULL  |0        |0        |
                    |63    |1        |1        |
                    |59    |1        |1        |
                    |51    |1        |1        |
                    |49    |1        |1        |
                    |48    |1        |1        |
                    |47    |1        |1        |
                    |46    |4        |1        |
                    |45    |2        |1        |
                    |44    |2        |1        |
                    |43    |1        |1        |
                    |42    |6        |1        |
                    |41    |10       |3        |
                    |40    |10       |3        |
                    |39    |3        |2        |
                    |38    |4        |2        |
                    |37    |3        |2        |
                    |36    |6        |3        |
                    |35    |8        |3        |
                    |34    |4        |4        |
                    |33    |7        |2        |
                    |32    |5        |5        |
                    |31    |3        |2        |
                    |30    |5        |4        |
                    |29    |5        |3        |
                    |28    |2        |2        |
                    |27    |7        |5        |
                    |26    |8        |4        |
                    |25    |9        |6        |
                    |24    |21       |15       |
                    |23    |213      |57       |
                    |22    |441      |67       |
                    |21    |12926    |590      |
                    |20    |435      |70       |
                    |19    |209      |62       |
                    |18    |66       |22       |
                    |17    |20       |10       |
                    |16    |35       |14       |
                    |15    |14       |10       |
                    |14    |13       |6        |
                    |13    |5        |5        |
                    |12    |10       |8        |
                    |11    |11       |5        |
                    |10    |10       |10       |
                    |9     |15       |5        |
                    |8     |5        |4        |
                    |7     |4        |4        |
                    |6     |15       |5        |
                    |5     |2        |2        |
                    |4     |1        |1        |
                    |3     |2        |2        |
                    |2     |21       |3        |
                    |1     |10       |2        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com maior relevância está entre 19 e 23 caracteres. Aparentemente,
                    não existe regularidade de lenght.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais LRP6040 mais 12-16 dígitos numéricos, totalizando entre 19 e 23 caracteres.
         */
    return !!this.serial.match(/^LRP6040(\d|\w){12,16}$/);
  }
  static build() {
    return new PainelLongiMonoHalfCell440W();
  }
}

export default {
  setRoles(state, payload) {
    state.roles = payload;
  },
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },

  setPagination(state, payload) {
    state.pagination = payload;
  },
  setUrlParams(state, payload) {
    state.urlParams = payload;
  },
  resetUrlParams(state, payload) {
    state.urlParams = payload;
  },
};

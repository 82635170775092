import { HttpRestService } from "../http/HttpRestService";

export default class VisualizacaoDetalhesPedido {
  numeroPedido = 0;
  vm;
  loading = false;
  static build() {
    return new VisualizacaoDetalhesPedido();
  }

  setNumeroPedido(numeroPedido) {
    this.numeroPedido = numeroPedido;
    return this;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async abrirDetalhesPedido() {
    // if (this.vm.relacaoTiposInsumoQuantidade.length === 0)  {
    await this.getRelacaoInsumosQuantidade();
    // }
    const { pedido, relacaoTiposInsumoQuantidade } = this.vm;
    this.vm.$emit("open-details", { pedido, relacaoTiposInsumoQuantidade });
    this.vm.$parent.$bvModal.show("modal-detalhes");
  }

  async getRelacaoInsumosQuantidade() {
    try {
      this.loading = true;
      const url = `/api/v2/insumos/pedido/${this.numeroPedido}/relacao-insumos-quantidade`;
      const response = await HttpRestService.get(url);
      this.loading = false;
      const { status, data } = response;
      if (status === 200) {
        this.vm.relacaoTiposInsumoQuantidade = data.data;
      }
    } catch (e) {
      this.loading = false;
    }
  }
}

export default {
  setOrders(state, payload) {
    state.orders = payload;
  },
  setUrlParams(state, payload) {
    state.urlParams = payload;
  },
  setIsLoadingOrders(state, payload) {
    state.isLoadingOrders = payload;
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },
  setIsLoadingOrderDetails(state, payload) {
    state.isLoadingOrderDetails = payload;
  },
  setOrderDetails(state, payload) {
    state.orderDetails = payload;
  },
};

export default {
  getListingUsuarios(state) {
    return state.usuarios;
  },
  getUsuariosPagination(state) {
    return state.pagination;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getImpressoras(state) {
    return state.impressoras;
  },
  getRoles(state) {
    return state.roles;
  },
  getPermissions(state) {
    return state.permissions;
  },
  getEmployes(state) {
    return state.employes;
  },
};

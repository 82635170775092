export class PedidoDetalhesModel {
  static build() {
    return new PedidoDetalhesModel();
  }

  numeroPedido = 0;
  numeroPedidoWeb = 0;
  numeroLote = 0;
  clienteCodigo = 0;
  clienteNome = "";
  kitDescricao = "";
  statusCodigo = "";
  statusDescricao = "";
  chaveNfe = "";
  nfe = "";
  situacaoNfe = 0;
  numeroViasMapaSeparacao = 0;
  posicao = "";
  localizacao = "";
  enderecoApanhaRua = "";
  enderecoApanhaModulo = "";
  enderecoApanhaApartamento = "";
  enderecoApanhaNumero = "";
  numeroTransent = 0;
  dataProducao = "";
  dataFinalDespacho = "";
  transportadoraCodigo = 0;
  transportadoraNome = "";
  origemPedido = "";
  foiFaturado = false;
  temTransportadora = false;
  expedir = false;

  linhaPaineisVolumes = 0;
  linhaCabosVolumes = 0;
  linhaDiversosVolumes = 0;
  linhaPerfisVolumes = 0;

  linhaPaineisInsumos = 0;
  linhaCabosInsumos = 0;
  linhaDiversosInsumos = 0;
  linhaPerfisInsumos = 0;
}

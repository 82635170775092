import PainelBase from "./PainelBase";

export default class PainelGcl405WMonoHalfCell extends PainelBase {
  getCode = () => 8464;

  isValid() {
    /*  ESTUDO DE PADRÃO DE PAINEL 8464 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Existem 278 insumos desse tipo no sistema. Porém, apenas 21 não são nulos. São esses números:
            +-----------------------+
            |NUMEROSSERIECONTROLADOS|
            +-----------------------+
            |1212                   |
            |121212121              |
            |2                      |
            |12                     |
            |121                    |
            |212                    |
            |1212                   |
            |861138304125121623     |
            |861138304225122003     |
            |861138304225122275     |
            |12                     |
            |12                     |
            |1212                   |
            |1212                   |
            |1212                   |
            |12                     |
            |12                     |
            |12                     |
            |12                     |
            |12                     |
            |861138304325120578     |
            +-----------------------+
            Como pode-se perceber, os dados são despadronizados e a amostragem é muito pobre para estabelecer padrões.
            Por isso, esse insumo, se detectado, não será validado.
         */
    return true;
  }

  static build() {
    return new PainelGcl405WMonoHalfCell();
  }
}

import PainelBase from "./PainelBase";

export default class PainelCanadianMono440W extends PainelBase {
  getCode = () => 19914;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 19914 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    select
                        prefix,
                        (select count(*) from sspedido s where s.codprodmp = 19914 and  substr(s.numerosseriecontrolados, 1, 4) = prefix) quantidade_insumos,
                        (select count(distinct s.numped) from sspedido s where s.codprodmp = 19914 and  substr(s.numerosseriecontrolados, 1, 4) = prefix) quantidade_pedidos
                    from (
                        select
                            distinct substr(numerosseriecontrolados, 1, 4) as prefix
                        from SSPEDIDO s
                        where s.codprodmp = 19914
                    ) order by quantidade_insumos desc
                Resultado:
                    +------+------------------+------------------+
                    |PREFIX|QUANTIDADE_INSUMOS|QUANTIDADE_PEDIDOS|
                    +------+------------------+------------------+
                    |1210  |9703              |480               |
                    |2102  |65                |15                |
                    |1021  |41                |12                |
                    |0215  |35                |12                |
                    |2150  |11                |6                 |
                    |3824  |8                 |1                 |
                    |1500  |3                 |2                 |
                    |2107  |2                 |2                 |
                    |2160  |2                 |1                 |
                    |3014  |2                 |1                 |
                    |5001  |1                 |1                 |
                    |0040  |1                 |1                 |
                    |1001  |1                 |1                 |
                    |1010  |1                 |1                 |
                    +------+------------------+------------------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 4 dígitos do insumo em questão, mostrando
                    14 resultados. Perceba que a moda dessa amostra é o número 1210, com 98% de presença.
                    Para todos os outros resultados, foi verificado que, quando estes apareciam nas amostras,
                    a bipagem dos painéis era confusa e sem regularidade, como o seguinte caso do pedido 3014840:
                    +-------------------------+---------+
                    |NUMEROSSERIECONTROLADOS  |CODPRODMP|
                    +-------------------------+---------+
                    |12102150010145           |19914    |
                    |12102150010582           |19914    |
                    |12102150010588           |19914    |
                    |12102150010577           |19914    |
                    |12102150010664           |19914    |
                    |12102150020173           |19914    |
                    |12102150020313           |19914    |
                    |1210215002044612102150020|19914    |
                    |12102150020314           |19914    |
                    |12102150020131           |19914    |
                    |12102150020144121        |19914    |
                    |021500206451             |19914    |
                    |2102150020342            |19914    |
                    |12102150020538           |19914    |
                    |12102150020338           |19914    |
                    |1210215002041012         |19914    |
                    |102150020433             |19914    |
                    |12102150020348           |19914    |
                    |121021500205351          |19914    |
                    |2102150020142            |19914    |
                    |12102150020254           |19914    |
                    |12102150020135121        |19914    |
                    |02150020132              |19914    |
                    |12102150020344121        |19914    |
                    |02150020323              |19914    |
                    |121021500203211          |19914    |
                    |21021500201751           |19914    |
                    |21021500203281           |19914    |
                    |210215002054212          |19914    |
                    |102150020136             |19914    |
                    |12102150020390           |19914    |
                    |1210215002035512         |19914    |
                    |1021500203871            |19914    |
                    |2102150020416            |19914    |
                    |12102150011388           |19914    |
                    |12102150010264           |19914    |
                    |12102150011304           |19914    |
                    |12102150010549           |19914    |
                    |12102150010090           |19914    |
                    |12102150010132           |19914    |
                    |12102150010396           |19914    |
                    |12102150010662           |19914    |
                    |12102150010709           |19914    |
                    |12102150010210           |19914    |
                    |12102150010087           |19914    |
                    |12102150011387           |19914    |
                    |12102150010558           |19914    |
                    |12102150011289           |19914    |
                    |12102150010527           |19914    |
                    |12102150010554           |19914    |
                    |12102150010685           |19914    |
                    |12102150010254           |19914    |
                    |12102150010251           |19914    |
                    |12102150010399           |19914    |
                    |12102150010131           |19914    |
                    |12102150010663           |19914    |
                    |12102150010256           |19914    |
                    |12102150010513           |19914    |
                    |12102150010556           |19914    |
                    |12102150010079           |19914    |
                    |12102150010683           |19914    |
                    |12102150011391           |19914    |
                    |12102150010485           |19914    |
                    |12102150010464           |19914    |
                    |12102150020485           |19914    |
                    |12102150020378           |19914    |
                    |12102150020068           |19914    |
                    |12102150020108           |19914    |
                    |12102150020397           |19914    |
                    |1210215002039312         |19914    |
                    |102150020097             |19914    |
                    |12102150020003           |19914    |
                    |121021500200981          |19914    |
                    |2102150020109            |19914    |
                    |12102150020214           |19914    |
                    |12102150020206           |19914    |
                    |12102150021238           |19914    |
                    |12102150020018           |19914    |
                    |12102150020213           |19914    |
                    |121021500212411          |19914    |
                    |210215002011212102       |19914    |
                    |150020490                |19914    |
                    |12102150020396           |19914    |
                    |12102150021239           |19914    |
                    |12102150021226           |19914    |
                    |12102150020011           |19914    |
                    |12102150020201           |19914    |
                    |12102150020014           |19914    |
                    |12102150020486           |19914    |
                    |12102150021242           |19914    |
                    |12102150020666           |19914    |
                    |12102150020215           |19914    |
                    |12102150020399           |19914    |
                    |12102150020407           |19914    |
                    |12102150010551           |19914    |
                    |1210215001018512         |19914    |
                    |102150010190             |19914    |
                    |12102150010187121        |19914    |
                    |021500101441             |19914    |
                    |2102150010113121         |19914    |
                    |02150010155              |19914    |
                    |12102150010571121021500  |19914    |
                    |101061                   |19914    |
                    |2102150010526            |19914    |
                    |12102150010573           |19914    |
                    |12102150010503           |19914    |
                    |12102150010501           |19914    |
                    |121021500105721          |19914    |
                    |2102150010122            |19914    |
                    |1210215001057612         |19914    |
                    |102150010574             |19914    |
                    |1210215001015712         |19914    |
                    |102150010019             |19914    |
                    |121021500100951          |19914    |
                    |2102150010121            |19914    |
                    |12102150010094           |19914    |
                    |1210215001018612         |19914    |
                    |10215001012312102        |19914    |
                    |150010583                |19914    |
                    |12102150010160           |19914    |
                    |121021500100211          |19914    |
                    |2102150010153            |19914    |
                    |12102150010030           |19914    |
                    |12102150010111           |19914    |
                    |12102150010542           |19914    |
                    |12102150010363           |19914    |
                    |12102150011308           |19914    |
                    |121021500112901210       |19914    |
                    |2150010088               |19914    |
                    |12102150010065           |19914    |
                    |12102150010457           |19914    |
                    |12102150010544           |19914    |
                    |12102150011295           |19914    |
                    |12102150010273           |19914    |
                    |12102150010539           |19914    |
                    |12102150010212           |19914    |
                    |121021500104901          |19914    |
                    |2102150010710            |19914    |
                    |121021500112721          |19914    |
                    |21021500102391           |19914    |
                    |21021500105411210        |19914    |
                    |2150010213               |19914    |
                    |121021500105381          |19914    |
                    |210215001068112          |19914    |
                    |102150011286121          |19914    |
                    |02150010257              |19914    |
                    |12102150010382           |19914    |
                    |12102150011294           |19914    |
                    |121021500112961          |19914    |
                    |210215001129712          |19914    |
                    |1021500112981210         |19914    |
                    |2150010489               |19914    |
                    |12102150010456           |19914    |
                    |12102150010143           |19914    |
                    |12102150011290           |19914    |
                    +-------------------------+---------+
                    Parece que o correto nesse caso é iniciar com 121021500, mas o bipador erra e deixa passar outras séries
                    incorretas.
                    Concluímos que o serial deve começar com a inicial 1210.
            Tipagem:
                Foi verificado que todos os seriais desse painel são numéricos. Consulta:
                    select
                        s.numerosseriecontrolados
                    from sspedido s
                    where
                        s.codprodmp = 19914
                        and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 19914 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 19914 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 19914
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |14    |9618     |480      |
                    |15    |51       |13       |
                    |13    |49       |16       |
                    |12    |35       |13       |
                    |16    |34       |10       |
                    |17    |28       |14       |
                    |11    |23       |10       |
                    |18    |14       |5        |
                    |10    |9        |6        |
                    |6     |3        |3        |
                    |7     |2        |1        |
                    |9     |2        |1        |
                    |8     |2        |2        |
                    |24    |1        |1        |
                    |20    |1        |1        |
                    |22    |1        |1        |
                    |28    |1        |1        |
                    |25    |1        |1        |
                    |23    |1        |1        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com 14 dígitos corresponde a 97% do total. Para todos os outros
                    lengths, muito provavelmente ocorreu algum erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1210 mais 10 dígitos numéricos, totalizando 14 dígitos.
         */
    return !!this.serial.match(/^1210\d{10}$/);
  }

  static build() {
    return new PainelCanadianMono440W();
  }
}

import router from "../../../router";

export default class SelecaoLinhasService {
  numped = "";
  static build() {
    return new SelecaoLinhasService();
  }

  setNumped(numped) {
    this.numped = numped;
    return this;
  }

  async goToLinhaPaineis() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaPaineis",
      params: { numped },
    });
  }

  async goToLinhaCabos() {
    const { numped } = this;
    await router.replace({ name: "pedidoLinhaCabos", params: { numped } });
  }

  async goToLinhaDiversos() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaDiversos",
      params: { numped },
    });
  }

  async goToLinhaPerfis() {
    const { numped } = this;
    await router.replace({ name: "pedidoLinhaPerfis", params: { numped } });
  }

  async goToLinhaInversores() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaInversores",
      params: { numped },
    });
  }

  async goToLinhaPaineisExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaPaineisExpress",
      params: { numped },
    });
  }

  async goToLinhaCabosExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaCabosExpress",
      params: { numped },
    });
  }

  async goToLinhaDiversosExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaDiversosExpress",
      params: { numped },
    });
  }

  async goToLinhaPerfisExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaPerfisExpress",
      params: { numped },
    });
  }

  async goToLinhaInversoresExpress() {
    const { numped } = this;
    await router.replace({
      name: "pedidoLinhaInversoresExpress",
      params: { numped },
    });
  }
}

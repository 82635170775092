<template>
  <b-modal data-bv id="modal-upload" ref="modal" title="Upload de Pedido.">
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Insira o arquivo."
        label-for="fileXlsx"
        invalid-feedback="Arquivo obrigatório."
        :state="nameState"
      >
        <b-form-file
          v-model="fileXlsx"
          :state="nameState"
          placeholder="Selecione um arquivo..."
          drop-placeholder="Selecione um arquivo..."
        ></b-form-file>
      </b-form-group>
    </form>

    <template #modal-footer="{}">
      <b-button size="sm" variant="success" @click="handleOk">
        Enviar Arquivo
      </b-button>
      <b-button size="sm" variant="danger" @click="resetModal">
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import UploadPedido from "@/services/listagem_pedidos/UploadPedido";
export default {
  data() {
    return {
      fileXlsx: [],
      volume: "",
      nameState: null,
      array: [],
      uploadPedido: UploadPedido.build(),
    };
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.fileXlsx = "";
      this.nameState = null;
      this.$bvModal.hide("modal-upload");
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.uploadPedido.setArquivoPedido(this.fileXlsx);
      this.uploadPedido.enviarArquivo();
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
};
</script>

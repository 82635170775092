import UsuariosRepository from "@/repositories/v2/UsuariosRepository";
import UsersRepository from "@/repositories/v1/UsersRepository";
import RolesRepository from "@/repositories/v1/RolesRepository";

export class EditarUsuarioService {
  vm;
  usuariosRepository = UsuariosRepository.build();
  usersRepository = UsersRepository.build();
  rolesRepository = RolesRepository.build();

  constructor(usuariosRepository, usersRepository, rolesRepository) {
    this.usuariosRepository = usuariosRepository;
    this.usersRepository = usersRepository;
    this.rolesRepository = rolesRepository;
  }

  static build() {
    const usuariosRepository = UsuariosRepository.build();
    const usersRepository = UsersRepository.build();
    const rolesRepository = RolesRepository.build();
    return new EditarUsuarioService(
      usuariosRepository,
      usersRepository,
      rolesRepository
    );
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async updateUser() {
    if (!this.validaDados()) return;

    const body = this.getBody();

    await this.usuariosRepository.editUser(body).then((response) => {
      if (response.status === 200) {
        this.vm.modalAlert = {
          message: "Usuário editado com sucesso.",
          variant: "success",
        };
        this.vm.$bvModal.show("modal-usuarios");
      } else {
        const message = response.data.message;
        this.vm.modalAlert = {
          message: message,
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-usuarios");
      }
    });
  }

  async deleteUser() {
    const body = this.getBody();
    const id = body.id;

    await this.usersRepository.deleteUser(id).then((response) => {
      if (response.status === 200) {
        this.vm.modalAlert = {
          message: "Usuário deletado com sucesso.",
          variant: "success",
        };
        this.vm.$bvModal.show("modal-usuarios");
      } else {
        const message = response.data.message;
        this.vm.modalAlert = {
          message: message,
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-usuarios");
      }
    });
  }

  async getEditarUsuarioData(usuarioId) {
    this.vm.isLoading = true;
    try {
      await Promise.all([
        this.getUserById(usuarioId),
        this.getEmployes(),
        this.getRoles(),
        this.getPermissions(),
        this.getImpressoras(),
      ]);
    } catch (e) {
      console.error(e);
    }
    this.vm.isLoading = false;
  }

  async getUserById(usuarioId) {
    await this.usuariosRepository.getUser(usuarioId).then((response) => {
      if (response.status === 200) {
        this.vm.user.id = response.data.data[0].usuarioId;
        this.vm.user.pcemprId = response.data.data[0].pcemprId;
        this.vm.user.login = response.data.data[0].email;
        this.vm.user.roles = response.data.data[0].roleID;
        this.vm.user.impressoraId = response.data.data[0].impressoraId;
        this.vm.user.permissions = response.data.data[0].permissionId;
      } else {
        this.vm.isLoading = false;

        this.vm.modalAlert = {
          message: "Não foi possível carregar usuário",
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-usuarios");
      }
    });
  }

  async getRoles() {
    await this.rolesRepository.getRoles().then((response) => {
      if (response.status === 200) {
        this.vm.roles = response.data.data;
      } else {
        this.vm.isLoading = false;

        this.vm.modalAlert = {
          message: "Não foi possível carregar as rotas dos usuários",
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-usuarios");
      }
    });
  }

  async getPermissions() {
    await this.rolesRepository.getPermissions().then((response) => {
      if (response.status === 200) {
        this.vm.permissions = response.data.data;
      } else {
        this.vm.isLoading = false;

        this.vm.modalAlert = {
          message: "Não foi possível carregar as funções dos usuários",
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-usuarios");
      }
    });
  }

  async getEmployes() {
    await this.usersRepository.getFuncoes().then((response) => {
      if (response.status === 200) {
        this.vm.employes = response.data;
      } else {
        this.vm.isLoading = false;

        this.vm.modalAlert = {
          message: "Não foi possível carregar a listagem de funcionários",
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-usuarios");
      }
    });
  }

  async getImpressoras() {
    await this.usuariosRepository.getImpressoras().then((response) => {
      if (response.status === 200) {
        this.vm.impressoras = response.data.data;
      } else {
        this.vm.isLoading = false;
        this.vm.modalAlert = {
          message: "Não foi possível carregar a listagem de impressoras",
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-usuarios");
      }
    });
  }

  validaDados() {
    const data = this.vm.user;

    if (
      data.pcemprId === null ||
      data.login === "" ||
      data.password === "" ||
      data.impressoraId === null ||
      data.roles === null ||
      data.permissions === null
    ) {
      this.vm.modalAlert = {
        message: "Preencha os dados corretamente.",
        variant: "danger",
      };
      this.vm.$bvModal.show("modal-usuarios");
      return false;
    }
    return true;
  }

  getBody() {
    const data = this.vm.user;
    return {
      id: data.id,
      pcemprId: data.pcemprId,
      login: data.login,
      password: data.password,
      permissions: data.permissions,
      impressoraId: data.impressoraId,
      roles: typeof data.roles === "number" ? [data.roles] : data.roles,
    };
  }
}

<template>
  <v-dialog max-width="330" v-model="dialogVisible">
    <v-card>
      <v-card-title class="justify-center">
        <h3 class="mb-6 navy--text">Atenção</h3>
      </v-card-title>
      <v-card-text>
        <v-card-text class="font-weight-bold text-center">
          Confirmar a zeragem da Linha
          <span class="secondary--text"> {{ linha == -1 ? "ac" : linha }}</span
          >?
        </v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogVisible = false">
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="zerarLinha"
          :loading="getIsLoadingZerarLinha"
        >
          Zerar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ZerarLinha",
  data() {
    return {
      loading: false,
      dialogVisible: this.$props.enable,
    };
  },
  methods: {
    async zerarLinha() {
      this.$emit("zerar-linha");
    },
  },
  props: {
    enable: {
      type: Boolean,
      required: true,
    },
    linha: {
      required: true,
    },
  },
  computed: {
    ...mapGetters("gerenciarPedido", ["getIsLoadingZerarLinha"]),
  },
  watch: {
    enable(enable) {
      this.dialogVisible = enable;
    },
  },
};
</script>

<style></style>

import PainelBase from "./PainelBase";

export default class PainelUlicaMonoHalfCut440W extends PainelBase {
  getCode = () => 10950;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 10950 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 10950 and numerosseriecontrolados is not null)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') percent from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 10950 and substr(s.numerosseriecontrolados, 1, 6) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 10950 and substr(s.numerosseriecontrolados, 1, 6) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 6) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 10950
                               and numerosseriecontrolados is not null
                           )
                      order by q_insumos desc
                    ) r;
                Resultado:
                    +------+---------+---------+-------+
                    |PREFIX|Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +------+---------+---------+-------+
                    |U624MM|20612    |1180     |93%    |
                    |624MM2|460      |87       |2%     |
                    |24MM21|388      |77       |2%     |
                    |4MM21A|153      |28       |1%     |
                    |4MM21C|69       |22       |0%     |
                    |MM21A5|59       |17       |0%     |
                    |382421|45       |3        |0%     |
                    |LRP604|39       |10       |0%     |
                    |4MM20L|39       |8        |0%     |
                    |24MM20|36       |8        |0%     |
                    |MM20L5|22       |5        |0%     |
                    |SN    |15       |1        |0%     |
                    |MM21C5|14       |11       |0%     |
                    |M21A5D|7        |4        |0%     |
                    |M21C5B|4        |2        |0%     |
                    |M20L5R|4        |1        |0%     |
                    |21A5D4|3        |1        |0%     |
                    |123456|3        |1        |0%     |
                    |21A5A8|3        |2        |0%     |
                    |BRSOE0|2        |1        |0%     |
                    |M21A5E|2        |2        |0%     |
                    |M21A5C|2        |1        |0%     |
                    |6244U6|1        |1        |0%     |
                    |25UU62|1        |1        |0%     |
                    |640001|1        |1        |0%     |
                    |380007|1        |1        |0%     |
                    |5B2625|1        |1        |0%     |
                    |3322  |1        |1        |0%     |
                    |M21A5G|1        |1        |0%     |
                    |U%R517|1        |1        |0%     |
                    |1A5D44|1        |1        |0%     |
                    |D2540B|1        |1        |0%     |
                    |112821|1        |1        |0%     |
                    |230025|1        |1        |0%     |
                    |21C5B2|1        |1        |0%     |
                    |U624M |1        |1        |0%     |
                    |332222|1        |1        |0%     |
                    |32212 |1        |1        |0%     |
                    |UB2876|1        |1        |0%     |
                    |R7545B|1        |1        |0%     |
                    |ˆ624M |1        |1        |0%     |
                    |5R5562|1        |1        |0%     |
                    |R1867B|1        |1        |0%     |
                    |A5D129|1        |1        |0%     |
                    |R0580B|1        |1        |0%     |
                    |U%D337|1        |1        |0%     |
                    |5A8621|1        |1        |0%     |
                    |A5A848|1        |1        |0%     |
                    |650003|1        |1        |0%     |
                    |20L5M3|1        |1        |0%     |
                    |M20L5M|1        |1        |0%     |
                    |5R7235|1        |1        |0%     |
                    |A5E394|1        |1        |0%     |
                    |M21A5A|1        |1        |0%     |
                    |5A8622|1        |1        |0%     |
                    |1C5B22|1        |1        |0%     |
                    |20L5R7|1        |1        |0%     |
                    |4808BU|1        |1        |0%     |
                    |4377BU|1        |1        |0%     |
                    |4MM212|1        |1        |0%     |
                    |5B2166|1        |1        |0%     |
                    |M21C5D|1        |1        |0%     |
                    |212121|1        |1        |0%     |
                    |21A5C7|1        |1        |0%     |
                    |UB3543|1        |1        |0%     |
                    |R4268B|1        |1        |0%     |
                    |M21U62|1        |1        |0%     |
                    |2154BU|1        |1        |0%     |
                    |185821|1        |1        |0%     |
                    |L5R545|1        |1        |0%     |
                    |MUU624|1        |1        |0%     |
                    |1A5A84|1        |1        |0%     |
                    |24UU62|1        |1        |0%     |
                    |260029|1        |1        |0%     |
                    |1C5B63|1        |1        |0%     |
                    |ˆ624MM|1        |1        |0%     |
                    |R7473B|1        |1        |0%     |
                    |1A4421|1        |1        |0%     |
                    |5R5509|1        |1        |0%     |
                    |624MU6|1        |1        |0%     |
                    |U2USDA|1        |1        |0%     |
                    |A5C770|1        |1        |0%     |
                    |21A5E3|1        |1        |0%     |
                    |5B4073|1        |1        |0%     |
                    |4U6242|1        |1        |0%     |
                    |U624M6|1        |1        |0%     |
                    |U%B482|1        |1        |0%     |
                    |U63574|1        |1        |0%     |
                    |L5R728|1        |1        |0%     |
                    |21A5D3|1        |1        |0%     |
                    |R6899B|1        |1        |0%     |
                    +------+---------+---------+-------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 6 dígitos do insumo em questão, mostrando
                    99 resultados. Perceba que a inicial com relevância é U624MM.
                    Portanto, vamos considerar que esse painel sempre comece com essa sequência.
            Tipagem:
                Como a própria inicial já é composta por números e letras, a tipagem é alfanumérica.
            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 10950 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 10950 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 10950
                    ) order by q_insumos desc
                Resultado:
                     +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |16    |20252    |1178     |
                    |15    |395      |86       |
                    |17    |372      |89       |
                    |18    |315      |86       |
                    |14    |248      |81       |
                    |19    |130      |48       |
                    |13    |107      |44       |
                    |21    |51       |20       |
                    |12    |37       |27       |
                    |20    |23       |14       |
                    |8     |16       |14       |
                    |2     |15       |1        |
                    |7     |14       |10       |
                    |10    |12       |8        |
                    |11    |12       |9        |
                    |23    |8        |3        |
                    |6     |7        |7        |
                    |25    |5        |5        |
                    |22    |5        |4        |
                    |42    |3        |1        |
                    |26    |3        |3        |
                    |5     |3        |3        |
                    |31    |2        |2        |
                    |29    |2        |1        |
                    |9     |2        |2        |
                    |27    |2        |2        |
                    |48    |1        |1        |
                    |61    |1        |1        |
                    |98    |1        |1        |
                    |46    |1        |1        |
                    |56    |1        |1        |
                    |4     |1        |1        |
                    |32    |1        |1        |
                    |24    |1        |1        |
                    |33    |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+


                Interpretação:
                    Perceba que a quantidade de seriais com maior relevância é 16 caracteres.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais U624MM mais 10 dígitos alfanuméricos, totalizando 16 caracteres.
         */
    return !!this.serial.match(/^U624MM(\d|\w){10}$/);
  }

  static build() {
    return new PainelUlicaMonoHalfCut440W();
  }
}

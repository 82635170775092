import { HttpRestService } from "@/services/http/HttpRestService";
export default class AuthRepository {
  static build() {
    return new AuthRepository();
  }

  login() {
    return HttpRestService.post(`/api/v2/login`);
  }

  logout() {
    return HttpRestService.post(`/api/v2/logout`);
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cordefundo"},[_c('div',{staticClass:"bv-example-row px-3"},[_c('div',{staticStyle:{"font-size":"30px"}},[_c('b-row',{staticClass:"mb-7"},[_c('b-col',{staticClass:"p-3 mt-4",attrs:{"cols":"4"}},[_c('span',[_vm._v(" Linha 5 ")])])],1)],1),_c('b-row',{staticClass:"py-3 top"},[_c('b-col',{attrs:{"sm":"auto"}},[_c('span',[_vm._v("Pedido: ")]),_vm._v(_vm._s(_vm.numeroPedido))]),_c('b-col',{staticClass:"text-auto py-2",attrs:{"sm":"auto"}},[_c('span',[_vm._v("Data:")]),_vm._v(" "+_vm._s(_vm.dataProducao))])],1),_c('b-row',{staticClass:"py-3 top"},[_c('b-col',{attrs:{"sm":"auto"}},[_c('span',[_vm._v("Cliente: ")]),_vm._v(_vm._s(_vm.nomeCliente))])],1),_c('b-row',{staticClass:"py-3 top"},[_c('b-col',{attrs:{"sm":"auto"}},[_c('span',[_vm._v(" Transportadora:")]),_vm._v(" "+_vm._s(_vm.transportadora))]),_c('b-col',{staticClass:"text-auto py-2",attrs:{"sm":"auto"}},[_c('span',[_vm._v("Status:")]),_vm._v(" "+_vm._s(_vm.linhaStatus))])],1),_c('b-row',{staticClass:"py-3 top"},[_c('b-col',{staticClass:"text-right py-2",attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('span',[_vm._v(" Quantidade de caixas: "),_c('span',{staticStyle:{"color":"#ff7d27"}},[_vm._v(" "+_vm._s(_vm.quantidadeCaixas)+" ")])])])],1)],1)]),_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"cordefundo bv-example-row py-2"},_vm._l((_vm.insumos),function(insumo,i){return _c('b-row',{key:i,staticClass:"top mx-4 my-2 mx-sm-1 my-sm-1 mx-md-1 my-md-1 mx-lg-1 my-lg-1 mx-xl-1 my-xl-1 justify-content-start",attrs:{"align-h":"start"}},[_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"5","md":"7","lg":"8","xl":"8","align-self":"center"}},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(insumo.descricao)+":")])]),_c('b-col',{staticClass:"insumoQuantidade d-flex justify-content-center",style:(`background: ${
            insumo.quantidadeRestante > 0
              ? insumo.quantidade === insumo.quantidadeRestante
                ? 'lightcoral'
                : '#FFD700'
              : '#38BB3E'
          }`),attrs:{"cols":"6","sm":"3","md":"3","lg":"2","xl":"2","align-self":"center"}},[_c('span',{staticClass:"insumoQuantidade",style:(`background: ${
              insumo.quantidadeRestante > 0
                ? insumo.quantidade === insumo.quantidadeRestante
                  ? 'lightcoral'
                  : '#FFD700'
                : '#38BB3E'
            }`)},[_vm._v(" "+_vm._s(insumo.quantidadeRestante)+"/"+_vm._s(insumo.quantidade)+" restantes ")])]),_c('b-col',{staticClass:"justify-content-end text-end",attrs:{"cols":"6","sm":"4","md":"2","lg":"2","xl":"2","align-self":"center"}},[(insumo.descricao.match(_vm.descricaoMesa))?_c('b-button',{staticClass:"default-button",staticStyle:{"padding":"0.1rem !important"},attrs:{"block":"","disabled":insumo.bipado || _vm.isLoading},on:{"click":function($event){return _vm.separaMesa(insumo.insumoId)}}},[(!_vm.isLoading)?_c('span',[_vm._v("Separar Mesa")]):_vm._e(),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1):_vm._e()],1)],1)}),1)]),_c('modal-mesas',{attrs:{"modalAlert":_vm.modalAlert}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
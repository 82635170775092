import { HttpRestService } from "@/services/http/HttpRestService";

export default class PedidoRepository {
  static build() {
    return new PedidoRepository();
  }

  indexOracle2(body) {
    return HttpRestService.post(`/api/pedidos-filter,`, body);
  }

  listagemPedidosManutencao(page, total, pedido, dt1, dt2, transp) {
    return HttpRestService.get(
      `/api/pedidos/manutencao?pagina=${page}&por_pagina=${total}&numero_pedido=${pedido}&data_inicio=${dt1}&data_fim=${dt2}&transportadora=${transp}`
    );
  }

  indexList(body) {
    return HttpRestService.post(`/api/pedidos-list`, body);
  }

  indexAc(offset, status, id, date, dt1, dt2) {
    return HttpRestService.get(
      `/api/pedidos-ac/${offset}/${status}/${id}/${date}/${dt1}/${dt2}`
    );
  }

  indexLinha(offset, status, id, date, dt1, dt2) {
    return HttpRestService.get(
      `/api/pedidos-linha/${offset}/${status}/${id}/${date}/${dt1}/${dt2}`
    );
  }
  indexAtrasado(offset) {
    return HttpRestService.get(`/api/pedidos-atrasado/${offset}`);
  }

  pedido(id, type) {
    return HttpRestService.get(`/api/pedido/${id}/${type}`);
  }

  pedidoConfere(id) {
    return HttpRestService.get(
      `/api/v2/conferencia-final/pedido-volumes/${id}`
    );
  }

  pedidoConfereAc(id) {
    return HttpRestService.get(`/api/pedido-confere-ac/${id}`);
  }

  pedidoConfereDet(id) {
    return HttpRestService.get(`/api/pedido-confere-det/${id}`);
  }

  pedidoResumo(id) {
    return HttpRestService.get(`/api/pedido-resumo/${id}`);
  }

  pedidoDetalhe(body) {
    return HttpRestService.post(`/api/pedido-detalhe`, body);
  }

  pedidoIniciar(id) {
    return HttpRestService.post(`/api/pedido-iniciar/${id}`);
  }

  confereIniciar(id) {
    return HttpRestService.post(`/api/confere-iniciar/${id}`);
  }

  confere(body) {
    return HttpRestService.post(`/api/pedido/confere`, body);
  }

  pedidoRetirarConfere(body) {
    return HttpRestService.post(`/api/pedido/retirar-confere`, body);
  }

  pedidoRetirarConfereAc(body) {
    return HttpRestService.post(`/api/pedido/retirar-confere-ac`, body);
  }

  confereTodos(body) {
    return HttpRestService.post(`/api/pedido/confere-todos`, body);
  }

  confereAc(body) {
    return HttpRestService.post(`/api/v2/pedido/confere-ac`, body);
  }

  confereComProblema(body) {
    return HttpRestService.post(`/api/pedido/confere-com-problema`, body);
  }

  confereComProblemaSeq(data) {
    return HttpRestService.post(`/api/pedido/confere-com-problema-seq/`, data);
  }

  cancelar(body) {
    return HttpRestService.post(`/api/pedido/cancelar`, body);
  }

  zerar(body) {
    return HttpRestService.post(`/api/pedido/zerar`, body);
  }

  notaFiscal(body) {
    return HttpRestService.post(`/api/pedido/nota-fiscal`, body);
  }

  saveLinha1(body) {
    return HttpRestService.post(`/api/pedido/save-linha1`, body);
  }

  save(body) {
    return HttpRestService.post(`/api/pedido/save`, body);
  }

  saveVol(body) {
    return HttpRestService.post(`/api/pedido/save-vol`, body);
  }

  saveLocal(body) {
    return HttpRestService.post(`/api/pedido/save-local`, body);
  }

  alteraVolume(body) {
    return HttpRestService.post(`/api/pedido/altera-volume`, body);
  }

  pedidoLimpaLinha(body) {
    return HttpRestService.post(`/api/pedido/altera-linha`, body);
  }

  zeraConfere(body) {
    return HttpRestService.post(`/api/pedido/zera-confere`, body);
  }

  PedidoFunBegin(body) {
    return HttpRestService.post(`/api/pedido/begin-func`, body);
  }

  changeSms(body) {
    return HttpRestService.post(`/api/pedido/change-sms`, body);
  }

  // uploadSerialPainel(body){
  //     return HttpRestService.post(`r/api/egister-serie-upload`, body)
  // }

  cadastroAgendamentoSS(body) {
    return HttpRestService.post(`/api/agendamento/cadastro/ss`, body);
  }

  checkSms() {
    return HttpRestService.get(`/api/pedido/check-sms`);
  }
}

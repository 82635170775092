import { ErrorNotifier } from "../../../../services/messages/ErrorNotifier";
import { UsuariosRepository } from "@/repositories/v2/UsuariosRepository";
import { UsuariosViewRepository } from "./../../repository/UsuariosViewRepository";

export default {
  async usuarios({ commit, dispatch, state }) {
    commit("setIsLoading", true);
    commit("setListingUsuarios", []);
    commit("setUsuariosPagination", {});
    try {
      const response = await UsuariosRepository.getUsers(state.urlParams);
      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        const { pagination, list } = data;
        commit("setIsLoading", false);
        commit("setListingUsuarios", list);
        commit("setUsuariosPagination", pagination);
        return;
      } else {
        commit("setIsLoading", false);
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async getMetaHora({ dispatch, commit }) {
    try {
      const response = await true;
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setMetaHora",
          response.data.total_pedidos[0].METAHORA.length ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async createUser({ commit, dispatch }, body) {
    try {
      const response = await UsuariosRepository.postUser(body);
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("setIsLoading", false);
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });

        return response;
      } else {
        throw response;
      }
    } catch (error) {
      dispatch("errorMessage", error);
    }
  },

  async editUser({ commit, dispatch }, body) {
    try {
      const response = await UsuariosRepository.editUser(body);
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("setIsLoading", false);
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });

        return response;
      } else {
        throw response;
      }
    } catch (error) {
      dispatch("errorMessage", error);
    }
  },

  async listImpressoras({ dispatch, commit }) {
    try {
      const response = await UsuariosRepository.getImpressoras();
      if (response.status >= 200 && response.status < 300) {
        commit("setImpressoras", response.data.data);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async listRoles({ dispatch, commit }) {
    try {
      const response = await UsuariosViewRepository.getRoles();
      if (response.status >= 200 && response.status < 300) {
        commit("setRoles", response.data.data);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async listPermissions({ dispatch, commit }) {
    try {
      const response = await UsuariosViewRepository.getPermissions();
      if (response.status >= 200 && response.status < 300) {
        commit("setPermissions", response.data.data);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async listEmployes({ dispatch, commit }) {
    try {
      const response = await UsuariosViewRepository.getFuncoes();
      if (response.status >= 200 && response.status < 300) {
        commit("setEmployes", response.data);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },
  setListingInsumosNaoConfiguradosFilter({ commit }, value) {
    commit("setListingInsumosNaoConfiguradosFilter", value);
  },

  errorMessage({ commit, dispatch }, value) {
    console.log(value);
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },
};

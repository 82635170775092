<template>
  <b-modal data-bv
    id="modal-detalhes-volume"
    body-class="rounded"
    size="lg sm"
    hide-footer
    hide-header
    centered
    class="py-2 px-2"
  >
    <div class="mt-2">
      <div class="d-block text-left my-3">
        <h4 class="font-weight-bold">Detalhar Caixas</h4>
      </div>
      <div>
        <h5>
          Quantidade de caixas: <b>{{ quantidadeVolumes }}</b>
        </h5>
      </div>
      <div style="overflow-y: auto; max-height: 600px">
        <div v-for="(volume, j) in volumes" :key="j">
          <div class="mb-3 px-4 py-4">
            <b-card
              header="featured"
              header-tag="div"
              :header-class="'cor-background-azul'"
            >
              <template #header>
                <h4 class="mb-6 font-weight-bold" style="color: white">
                  Caixa {{ j + 1 }} - {{ volume.codigo }}
                </h4>
              </template>
              <b-row
                v-for="(volumeInsumo, k) in volume.insumos"
                :key="k"
                class="mb-0 px-1 py-1"
              >
                <b-col>
                  <label
                    :style="
                      `align-items: center; font-family: 'Poppins'; font-size: 15px` +
                      (volumeInsumo.erroMotivo
                        ? volumeInsumo.erroCorrigido
                          ? ';color:orange'
                          : ';color:red'
                        : '')
                    "
                  >
                    {{ (volumeInsumo.insumo || {}).descricao || "" }}
                  </label>
                </b-col>
                <b-col>
                  <label
                    :style="
                      volumeInsumo.erroMotivo
                        ? volumeInsumo.erroCorrigido
                          ? ';color:orange'
                          : ';color:red'
                        : ''
                    "
                    >{{ volumeInsumo.quantidade }} unidades -
                    {{ volumeInsumo.erroMotivo }}</label
                  >
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalDetalhesDeCaixasConferenciaAc",
  props: {
    volumes: {
      type: Array,
      required: true,
    },
    quantidadeVolumes: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.cor-background-azul {
  background: #002233;
}
</style>

import { PedidoDetalhesModel } from "../../models/PedidoDetalhesModel";

export const mapPedidosDetalhesData = (data) => {
  // console.log({ data });
  const pedidoDetalhes = PedidoDetalhesModel.build();
  pedidoDetalhes.numeroPedido = data.numeroPedido;
  pedidoDetalhes.numeroPedidoWeb = data.numeroPedidoWeb;
  pedidoDetalhes.numeroLote = data.numeroLote;
  pedidoDetalhes.clienteCodigo = data.clienteCodigo;
  pedidoDetalhes.clienteNome = data.clienteNome;
  pedidoDetalhes.kitDescricao = data.kitDescricao;
  pedidoDetalhes.statusCodigo = data.status;
  pedidoDetalhes.statusDescricao = data.statusDescricao;
  pedidoDetalhes.chaveNfe = data.chaveNfe;
  pedidoDetalhes.nfe = data.nfe;
  pedidoDetalhes.situacaoNfe = data.situacaoNfe;
  pedidoDetalhes.numeroViasMapaSeparacao = data.numeroViasMapaSeparacao;
  pedidoDetalhes.posicao = data.posicao;
  pedidoDetalhes.localizacao = data.localizacaoPedido;
  pedidoDetalhes.enderecoApanhaRua = data.enderecoApanhaRua;
  pedidoDetalhes.enderecoApanhaModulo = data.enderecoApanhaModulo;
  pedidoDetalhes.enderecoApanhaApartamento = data.enderecoApanhaApartamento;
  pedidoDetalhes.enderecoApanhaNumero = data.enderecoApanhaNumero;
  pedidoDetalhes.numeroTransent = data.numeroTransent;
  pedidoDetalhes.dataProducao = data.dataProducao;
  pedidoDetalhes.dataFinalDespacho = data.dataFinalDespacho;
  pedidoDetalhes.transportadoraCodigo = data.transportadoraCodigo;
  pedidoDetalhes.transportadoraNome = data.transportadoraNome;
  pedidoDetalhes.origemPedido = data.origemPedido;
  pedidoDetalhes.foiFaturado = data.foiFaturado;
  pedidoDetalhes.temTransportadora = data.temTransportadora;
  pedidoDetalhes.expedir = data.expedir;
  pedidoDetalhes.linhaPaineisVolumes = data.linhaPaineisVolumes;
  pedidoDetalhes.linhaCabosVolumes = data.linhaCabosVolumes;
  pedidoDetalhes.linhaDiversosVolumes = data.linhaDiversosVolumes;
  pedidoDetalhes.linhaPerfisVolumes = data.linhaPerfisVolumes;
  pedidoDetalhes.linhaPaineisInsumos = data.linhaPaineisInsumos;
  pedidoDetalhes.linhaCabosInsumos = data.linhaCabosInsumos;
  pedidoDetalhes.linhaDiversosInsumos = data.linhaDiversosInsumos;
  pedidoDetalhes.linhaPerfisInsumos = data.linhaPerfisInsumos;
  // console.log({pedidoDetalhes})
  return pedidoDetalhes;
};

import { HttpRestService } from "@/services/http/HttpRestService";

export default class EditarLinhasRepository {
  static build() {
    return new EditarLinhasRepository();
  }

  alterarSerial(body) {
    return HttpRestService.put(`/api/v2/insumos/alterar/serial`, body);
  }
}

<template>
  <b-spinner style="width: 4rem; height: 4rem; color: #e6792e" />
</template>

<script>
export default {
  name: "DefaultSpinner",
};
</script>

<style scoped></style>

import PainelBase from "./PainelBase";

export default class PainelUlicaMonoHalfCut505W extends PainelBase {
  getCode = () => 21632;
  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 21632 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 21632 and numerosseriecontrolados is not null)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') percent from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 21632 and substr(s.numerosseriecontrolados, 1, 6) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 21632 and substr(s.numerosseriecontrolados, 1, 6) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 6) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 21632
                               and numerosseriecontrolados is not null
                           )
                      order by q_insumos desc
                    ) r;
                Resultado:
                    +------+---------+---------+-------+
                    |PREFIX|Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +------+---------+---------+-------+
                    |U622MM|4504     |278      |97%    |
                    |u622mm|134      |1        |3%     |
                    |U624MM|1        |1        |0%     |
                    |121080|1        |1        |0%     |
                    +------+---------+---------+-------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 6 dígitos do insumo em questão, mostrando
                    99 resultados. Perceba que a inicial com relevância é U622MM.
                    Portanto, vamos considerar que esse painel sempre comece com essa sequência.
            Tipagem:
                Como a própria inicial já é composta por números e letras, a tipagem é alfanumérica.
            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 21632 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 21632 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 21632
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |16    |4638     |278      |
                    |14    |1        |1        |
                    |18    |1        |1        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com maior relevância é 16 caracteres.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais U622MM mais 10 dígitos alfanuméricos, totalizando 16 caracteres.
         */
    return !!this.serial.match(/^U622MM(\d|\w){10}$/);
  }

  static build() {
    return new PainelUlicaMonoHalfCut505W();
  }
}

export default {
  setListingUsuarios(state, value) {
    state.usuarios = value;
  },

  setUsuariosPagination(state, value) {
    state.pagination = value;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },

  setUrlParams(state, value) {
    state.urlParams = value;
  },

  setImpressoras(state, value) {
    state.impressoras = value;
  },

  setRoles(state, value) {
    state.roles = value;
  },

  setPermissions(state, value) {
    state.permissions = value;
  },

  setEmployes(state, value) {
    state.employes = value;
  },
};

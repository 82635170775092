import { HttpRestService } from "@/services/http/HttpRestService";

export default class ConfereACRepository {
  static build() {
    return new ConfereACRepository();
  }

  listaVolumesAC(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/pedido/${numeroPedido}/confere-ac/volumes`
    );
  }

  listaInsumosAC(volumeId) {
    return HttpRestService.get(`/api/v2/pedido/confere-ac/${volumeId}/insumos`);
  }

  listaVolumesConferenciaAc({ numeroPedido }) {
    return HttpRestService.get(`/api/v2/pedido/${numeroPedido}/conferencia-ac`);
  }

  biparInsumoConfereAc({ insumoId, volumeId, serial }) {
    return HttpRestService.post(`/api/v2/pedido/confere-ac`, {
      insumoId,
      volumeId,
      serial,
    });
  }

  reportarProblema({ motivo, volumeId, insumoId }) {
    return HttpRestService.post("/api/v2/pedido/confere-com-problema-seq", {
      motivo,
      volumeId,
      insumoId,
    });
  }

  reportarCorrecao({ volumeId, insumoId }) {
    return HttpRestService.post(
      `/api/v2/pedido/confere-com-problema-correcao/volume/${volumeId}/insumo/${insumoId}`,
      {}
    );
  }

  zerarConferencia(numeroPedido) {
    return HttpRestService.post(
      `/api/v2/pedido/confere-ac/${numeroPedido}/zerar`
    );
  }
}

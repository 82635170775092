export default {
  getDrawer(state) {
    return state.drawer;
  },
  getDarkMode(state) {
    return state.darkMode;
  },
  getListingInsumos(state) {
    return state.insumos;
  },
  getInsumosPagination(state) {
    return state.pagination;
  },
  getInsumosNaoConfigurados(state) {
    return state.insumosNaoConfigurados;
  },
  getInsumosNaoConfiguradosFilter(state) {
    return state.insumosNaoConfiguradosFilter;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
};

import { HttpRestService } from "@/services/http/HttpRestService";
export default class LocalizacaoEstoqueRepository {
  static build() {
    return new LocalizacaoEstoqueRepository();
  }

  getLocalizacoesEmEstoque() {
    return HttpRestService.get(`/api/v2/localizacao-estoque`);
  }

  getLocalizacaoEmEstoque(id) {
    return HttpRestService.get(`/api/v2/localizacao-estoque/${id}`);
  }

  postLocalizacaoEmEstoque(body) {
    return HttpRestService.post(`/api/v2/localizacao-estoque`, body);
  }

  editLocalizacaoEmEstoque(id, body) {
    return HttpRestService.patch(`/api/v2/localizacao-estoque/${id}`, body);
  }

  deleteLocalizacaoEmEstoque(id) {
    return HttpRestService.delete(`/api/v2/localizacao-estoque/${id}`);
  }
}

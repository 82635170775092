<template>
  <b-form>
    <b-form-group
      id="input-group-3"
      label="Localização estoque"
      label-for="input-3"
    >
      <b-form-select
        id="input-3"
        v-model="form.localizacaoEstoque"
        :options="localizacoesEstoque"
        required
      ></b-form-select>
    </b-form-group>

    <b-form-group id="input-group-3" label="Separador 1" label-for="input-3">
      <b-form-select
        id="input-3"
        v-model="form.separadorUm"
        :options="orderedSeparadores"
        required
      ></b-form-select>
    </b-form-group>

    <b-form-group id="input-group-3" label="Separador 2" label-for="input-3">
      <b-form-select
        id="input-3"
        v-model="form.separadorDois"
        :options="orderedSeparadores"
        required
      ></b-form-select>
    </b-form-group>

    <div class="row">
      <b-button
        style="background: #120947"
        class="mx-auto btn-iniciar"
        @click="iniciarProducao()"
      >
        Iniciar produção
      </b-button>
      <b-button @click="closeModal()" class="mx-auto" variant="danger">
        Cancelar
      </b-button>
    </div>
  </b-form>
</template>

<script>
export default {
  components: {},
  name: "ModalIniciarProducao",
  data: () => ({
    form: {
      separadorDois: null,
      separadorUm: null,
      localizacaoEstoque: "",
      volume: 1,
    },
  }),
  created() {},
  props: {
    localizacoesEstoque: { Type: Array, required: true, default: [] },
    separadores: { Type: Array, required: true, default: [] },
  },
  computed: {
    orderedSeparadores() {
      const separadores = JSON.parse(JSON.stringify(this.separadores));
      separadores.sort((a, b) => {
        if (a.text > b.text) return 1;
        if (a.text < b.text) return -1;
        return 0;
      });
      return separadores;
    },
  },
  methods: {
    iniciarProducao() {
      this.$emit("iniciarProducao", this.form);
    },
    closeModal() {
      this.form = {
        separadorDois: null,
        separadorUm: null,
        localizacaoEstoque: null,
        volume: 1,
      };
      this.$emit("closeModal");
    },
    increasesVolume() {
      this.form.volume++;
      if (this.form.volume <= 0) this.form.volume = 1;
    },
    decreaseVolume() {
      this.form.volume--;
      if (this.form.volume <= 0) this.form.volume = 1;
    },
  },
};
</script>

<style scoped>
.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
</style>

<template>
  <b-modal data-bv
    id="modal-total-etiquetas"
    ref="modal"
    title="Gerar Todas as Etiquetas"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Informe o numero do pedido."
        label-for="numped"
        invalid-feedback="Nr. pedido obrigatório."
        :state="nameState"
      >
        <b-form-input
          id="name-input"
          v-model="numped"
          :state="nameState"
          required
        ></b-form-input>
      </b-form-group>
    </form>

    <template #modal-footer="{}">
      <b-button size="sm" variant="success" @click="handleOk">
        Gerar Etiqueta
      </b-button>
      <b-button size="sm" variant="danger" @click="resetModal">
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import GeracaoEtiquetas from "@/services/listagem_pedidos/GeracaoEtiquetas";
export default {
  data() {
    return {
      numped: "",
      volume: "",
      nameState: null,
      array: [],
      gerarEtiqueta: GeracaoEtiquetas.build(),
    };
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.numped = "";
      this.nameState = null;
      this.$bvModal.hide("modal-total-etiquetas");
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.gerarEtiqueta.setNumeroPedido(this.numped);
      this.gerarEtiqueta.abrirTotalEtiqueta();
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
};
</script>

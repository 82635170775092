<template>
  <b-button
    :disabled="disabled"
    pill
    variant="secondary"
    :class="'default-button text-truncate' + (' default-button-' + color)"
    @click="$emit('click')"
    >{{ name }}</b-button
  >
  <!--  <button-->
  <!--    :class="[-->
  <!--      'default-button',-->
  <!--      `default-button-${color}`,-->
  <!--      {-->
  <!--        'default-button-disabled': disabled,-->
  <!--      },-->
  <!--    ]"-->
  <!--    v-bind="$attrs"-->
  <!--    v-on="listeners"-->
  <!--  >-->
  <!--    <span class="default-button-content">-->
  <!--      <slot> {{ name }} </slot>-->
  <!--    </span>-->
  <!--  </button>-->
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: [String, Number],
      default: "",
    },
    color: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        click: this.onClick,
      };
    },
  },

  methods: {
    onClick(evt) {
      if (this.disabled) {
        return;
      }
      this.$emit("click", evt);
    },
  },
};
</script>

<style scoped>
.default-button {
  text-align: center;
  padding: 0.625rem;
  outline: none;
  border-radius: 1rem;
  font-weight: bold !important;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
  border: none;
  transition: background-color, padding 0.15s;
}

.default-button-disabled {
  cursor: context-menu;
  pointer-events: none;
  background-color: #e9e9e9 !important;
  color: #252525 !important;
}

/* colors */

.default-button-orange-color {
  background-color: #cc5d02;
  color: white;
}

.default-button-orange-color:hover {
  background-color: #e6781e;
  color: white;
  transition: 300ms all ease-in-out;
}

.default-button-red-color {
  color: white;
  background-color: #721212;
}

.default-button-red-color:hover {
  background-color: rgb(199, 37, 37);
  color: white;
  transition: 300ms all ease-in-out;
}

.default-button-green-color {
  color: white;
  background-color: #2eb03b;
}

.default-button-green-color:hover {
  background-color: rgb(97, 228, 110);
  color: white;
  transition: 300ms all ease-in-out;
}

.default-button-blue-color {
  color: white;
  background-color: #3699ff;
}

.default-button-blue-color:hover {
  background-color: #70abe6;
  color: white;
  transition: 300ms all ease-in-out;
}

.default-button-yellow-color {
  color: white;
  background-color: #dd9402;
}

.default-button-yellow-color:hover {
  background-color: #eeb033;
  color: white;
  transition: 300ms all ease-in-out;
}

.default-button-gray-color {
  color: white;
  background-color: #252525;
}

.default-button-gray-color:hover {
  background-color: #3f3f3f;
  color: white;
  transition: 300ms all ease-in-out;
}

/* media query */

@media only screen and (max-width: 576px) {
  .default-button {
    width: 50%;
  }
}

@media only screen and (max-width: 768px) {
  .default-button {
    width: 60%;
  }
}

@media only screen and (max-width: 992px) {
  .default-button {
    width: 70%;
  }
}

@media only screen and (max-width: 1200px) {
  .default-button {
    width: 80%;
  }
}

@media only screen and (max-width: 1400px) {
  .default-button {
    width: 100%;
  }
}

@media only screen and (min-width: 1400px) {
  .default-button {
    width: 100%;
  }
}
</style>

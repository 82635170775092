import Repository from "../repository";

export default {
  async volumeDetails({ commit }, volumeCode) {
    commit("setIsLoadingVolumeDetails", true);
    commit("setVolumeDetails", {});
    try {
      const response = await Repository.getVolumeDetails(volumeCode);
      if (response.status === 200) {
        const volumeDetails = response.data.data;
        commit("setVolumeDetails", volumeDetails);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingVolumeDetails", false);
    }
  },
  async confirmOrder({ commit, dispatch }, orderToBeConfirmed) {
    try {
      const response = await Repository.confirmOrder(orderToBeConfirmed);
      if (response.status === 200) {
        const isInspectionCompleted = response.data.data.pedidoFinalizado;
        if (isInspectionCompleted) {
          commit("setIsInspectionCompletedModalOpen", true);
          return;
        }
        commit(
          "notifications/setSuccessNotification",
          { successStatus: true, successMessage: response.data.message },
          { root: true }
        );
        dispatch("reRenderView");
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    }
  },
  async blockOrder({ commit, dispatch }, orderToBeBlocked) {
    try {
      const response = await Repository.blockOrder(orderToBeBlocked);
      if (response.status === 200) {
        commit("setIsBlockOrderModalOpen", false);
        dispatch("reRenderView");
        commit(
          "notifications/setSuccessNotification",
          { successStatus: true, successMessage: response.data.message },
          { root: true }
        );
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    }
  },
  resetVolumeDetails({ commit }) {
    commit("setVolumeDetails", {});
  },
  setIsBlockOrderModalOpen({ commit }, payload) {
    commit("setIsBlockOrderModalOpen", payload);
  },
  setIsInspectionCompletedModalOpen({ commit }, payload) {
    commit("setIsInspectionCompletedModalOpen", payload);
  },
  reRenderView({ commit, state }) {
    commit("setViewKey", state.viewKey + 1);
  },
};

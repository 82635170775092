import ConferenciaFinalRepository from "@/repositories/v2/ConferenciaFinalRepository";
import PedidoRepository from "@/repositories/v1/PedidoRepository";

export class ConferenciaFinalService {
  vm;
  conferenciaFinalRepository = ConferenciaFinalRepository.build();
  pedidoRepository = PedidoRepository.build();

  constructor(conferenciaFinalRepository, pedidoRepository) {
    this.conferenciaFinalRepository = conferenciaFinalRepository;
    this.pedidoRepository = pedidoRepository;
  }

  static build() {
    const conferenciaFinalRepository = ConferenciaFinalRepository.build();
    const pedidoRepository = PedidoRepository.build();
    return new ConferenciaFinalService(
      conferenciaFinalRepository,
      pedidoRepository
    );
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async listarConferencia(numeroPedido) {
    try {
      await this.conferenciaFinalRepository
        .listarConferencia(numeroPedido)
        .then((response) => {
          this.vm.pedidosVolume = response.data.data;
          this.vm.pedidosVolumeListaveis = response.data.data.filter(
            (i) => i.carga_id == 0
          );
          this.vm.pedidosVolumeDetalhes = response.data.data.filter(
            (i) => i.carga_id != 0
          );
          // this.vm.pedidosLinhaPaineis = response.data.data.filter(
          //   (i) => i.linha_volume == "paineis"
          // );
          // this.vm.pedidosLinhaCabos = response.data.data.filter(
          //   (i) => i.linha_volume == "cabos"
          // );
          // this.vm.pedidosLinhaDiversos = response.data.data.filter(
          //   (i) => i.linha_volume == "diversos"
          // );
          // this.vm.pedidosLinhaPerfis = response.data.data.filter(
          //   (i) => i.linha_volume == "perfis"
          // );
          this.vm.pedidoId = response.data.data[0].pedido_id;
        });
    } catch (e) {
      console.error(e);
    }
  }

  async fecharCarga() {
    const body = this.getBody();

    this.vm.btnFecharCargaLoading = true;

    try {
      await this.conferenciaFinalRepository
        .fecharCarga(body)
        .then((response) => {
          if (response.status === 200) {
            this.vm.volumesFecharCarga = [];
            this.vm.habilitaBtnFecharCarga = false;
            this.listarConferencia(this.vm.$route.params.id);
          } else {
            const message = response.data.message;
            this.vm.modalAlert = {
              message: message,
              variant: "danger",
            };
            this.vm.$bvModal.show("modal-alert-conferencia-final");
          }
        });
    } catch (e) {
      console.error(e);
    }
    this.vm.btnFecharCargaLoading = false;
  }

  async finalizarConferencia() {
    this.vm.btnFinalizarConferenciaLoading = true;
    this.vm.save_ped = {
      numped: this.vm.pedidos.numeroPedido,
    };
    try {
      await this.pedidoRepository.confereTodos(this.vm.save_ped).then((res) => {
        if (res.data == "ok") {
          this.vm.modalAlert = {
            message: "Pedido Conferido!",
            variant: "success",
          };
          this.vm.$bvModal.show("modal-alert-conferencia-final");
        }
      });
    } catch (e) {
      console.error(e);
    }
    this.vm.btnFinalizarConferenciaLoading = false;
  }

  getBody() {
    const data = this.vm;
    return {
      pedidoId: data.pedidoId,
      volumes: data.volumesFecharCarga,
    };
  }
}

<template>
  <v-btn-toggle
    dense
    tile
    color="primary"
    mandatory
    bordeless
    v-model="getEnvironment"
    v-if="!isProductionBuild"
    class="elevation-1"
    active-class="font-weight-bold"
  >
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          value="development"
          small
          v-bind="attrs"
          v-on="on"
          >DEV</v-btn
        >
      </template>
      <span>{{ baseUrls.developmentBaseUrl }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          value="homologation"
          small
          v-bind="attrs"
          v-on="on"
          >HOM</v-btn
        >
      </template>
      <span>{{ baseUrls.homologationBaseUrl }}</span>
    </v-tooltip>
  </v-btn-toggle>
</template>

<script>
import { mapActions } from "vuex";
import { developmentBaseUrl, homologationBaseUrl } from "@/env";

export default {
  name: "EnvironmentToggler",
  data() {
    return {};
  },
  methods: {
    ...mapActions("menu", ["setEnvironment"]),
  },
  computed: {
    getEnvironment: {
      get() {
        return this.$store.getters["menu/getEnvironment"];
      },
      set(newEnvironment) {
        this.setEnvironment(newEnvironment);
        window.location.reload();
      },
    },
    isProductionBuild() {
      return process.env.NODE_ENV === "production";
    },
    baseUrls() {
      return { developmentBaseUrl, homologationBaseUrl };
    },
  },
};
</script>

<style scoped></style>

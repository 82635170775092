import inversores from "./inversores";

export class ValidaSerial {
  insumo;
  inversor;
  valorBipado;

  constructor() {}

  setInsumo(insumo) {
    this.insumo = insumo;
  }

  setValorBipado(_valorBipado) {
    const valorBipado = new String(_valorBipado);
    this.valorBipado = valorBipado.replace(
      // eslint-disable-next-line no-useless-escape
      /[-=&\/\\#,+() $~%.'":*?<>{}]/g,
      ""
    );
  }

  static build() {
    return new ValidaSerial();
  }

  validaSerialBipado() {
    if (
      this.buscaInversor() &&
      this.validaQuantidadeCaracteres() &&
      this.validaInicioSerial() &&
      this.validaFinalSerial() &&
      this.validaSerialPossuiLetras()
    ) {
      return true;
    } else {
      return false;
    }
  }

  validaQuantidadeCaracteres() {
    let validaCaracteresSerialBipado = false;

    this.inversor.totalCaracteres.forEach((value) => {
      if (this.valorBipado.length === value) {
        validaCaracteresSerialBipado = true;
      }
    });

    if (!validaCaracteresSerialBipado) return false;

    console.log("Passou 2");

    return true;
  }

  validaSerialPossuiLetras() {
    console.log(isNaN(this.valorBipado));
    if (this.inversor.possuiLetras) {
      console.log("possui letras");
      if (/^[0-9]+$/.test(this.valorBipado)) return false;
    } else {
      console.log("possui não letras");
      if (!/^[0-9]+$/.test(this.valorBipado)) return false;
    }

    console.log("Passou 5");

    return true;
  }

  validaFinalSerial() {
    let validaFinalSerialBipado = false;

    if (this.inversor.finalPadrao.length) {
      this.inversor.finalPadrao.forEach((value) => {
        if (
          this.valorBipado.substr(
            this.valorBipado.length - value.length,
            value.length
          ) === value
        ) {
          validaFinalSerialBipado = true;
        }
      });
    } else {
      validaFinalSerialBipado = true;
    }

    if (!validaFinalSerialBipado) return false;

    console.log("Passou 4");

    return true;
  }

  validaInicioSerial() {
    let validaInicioSerialBipado = false;

    this.inversor.inicioPadrao.forEach((value) => {
      console.log("/");
      console.log(value);
      console.log(this.valorBipado.substr(0, value.length));
      if (this.valorBipado.substr(0, value.length) === value) {
        validaInicioSerialBipado = true;
      }
    });
    if (!validaInicioSerialBipado) return false;

    console.log("Passou 3");

    return true;
  }

  buscaInversor() {
    this.inversor = inversores.filter((value) => {
      return value.codigoProducao === this.insumo.codigoProdutoMateriaPrima
        ? value
        : null;
    })[0];

    if (this.inversor === undefined) {
      alert("Inversor não encontrado, entre em contato com o suporte.");
      return false;
    }
    console.log(this.inversor);

    console.log("Passou 1");

    return true;
  }
}

export const statusPedido = {
  todosPedidos: "",
  aguardandoProducao: "A",
  conferenciaComProblema: "T",
  cancelado: "C",
  aguardandoConferenciaAposSepararUltimoProduto: "N",
  finalizadoSpt: "Z",
  emLinhaDeProducao: "L",
  aguardandoEmbarque: "E",
  aoConferenteSelecionarPedido: "E",
  despachados: "D",
  conferenciaAc: "ac",
  aguardandoConferencia: "N",
  emConferencia: "F",
  paraManutencao: "M",
  emLinhaDeProducaoDiversos: "O",
  naoExpedir: "NE",
};

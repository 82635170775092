import PainelBase from "./PainelBase";

export default class PainelCanadianPoli420W extends PainelBase {
  getCode = () => 20307;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 20307 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 20307)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 20307 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 20307 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 4) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 20307
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +------+---------+---------+-------+
                    |PREFIX|Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +------+---------+---------+-------+
                    |1210  |4547     |279      |77%    |
                    |1201  |1171     |82       |20%    |
                    |2101  |60       |23       |1%     |
                    |0111  |48       |18       |1%     |
                    |1011  |40       |15       |1%     |
                    |1110  |11       |4        |0%     |
                    |1211  |5        |1        |0%     |
                    |2012  |2        |2        |0%     |
                    |1001  |2        |1        |0%     |
                    |2110  |2        |1        |0%     |
                    |1200  |2        |2        |0%     |
                    |1109  |2        |1        |0%     |
                    |1107  |2        |2        |0%     |
                    |2111  |2        |1        |0%     |
                    |1111  |1        |1        |0%     |
                    |3500  |1        |1        |0%     |
                    |1079  |1        |1        |0%     |
                    |2011  |1        |1        |0%     |
                    |1102  |1        |1        |0%     |
                    |0072  |1        |1        |0%     |
                    |1401  |1        |1        |0%     |
                    |7909  |1        |1        |0%     |
                    |1100  |1        |1        |0%     |
                    |4010  |1        |1        |0%     |
                    |1081  |1        |1        |0%     |
                    +------+---------+---------+-------+


                Interpretação:
                    Essa consulta devolve todos os prefixos de 4 dígitos do insumo em questão, mostrando
                    25 resultados. Perceba que apenas as iniciais 1210 e 1201 possuem resultados consideráveis. Os
                    outros muito provavelmente são erros de bipagem.
            Tipagem:
                Foi verificado que todos os seriais desse painel são numéricos. Consulta:
                    select
                        s.numerosseriecontrolados
                    from sspedido s
                    where
                        s.codprodmp = 20307
                        and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
                Essa consulta só devolve um único serial que é composto por letras e números. Para o tamanho
                da amostra, isso é irrelevante. Provavelmente, erro de bipagem.

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 20307 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 20307 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 20307
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |14    |5622     |334      |
                    |13    |52       |22       |
                    |15    |45       |20       |
                    |17    |42       |19       |
                    |11    |40       |19       |
                    |16    |31       |14       |
                    |12    |31       |15       |
                    |10    |10       |5        |
                    |18    |9        |3        |
                    |9     |6        |4        |
                    |19    |5        |4        |
                    |8     |5        |4        |
                    |28    |3        |1        |
                    |7     |2        |2        |
                    |22    |2        |2        |
                    |20    |1        |1        |
                    |6     |1        |1        |
                    +------+---------+---------+


                Interpretação:
                    Perceba que a quantidade de seriais com 14 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1210 ou 1201 mais 10 dígitos numéricos, totalizando 14 dígitos.
         */
    return !!this.serial.match(/^12(10|01)\d{10}$/);
  }

  static build() {
    return new PainelCanadianPoli420W();
  }
}

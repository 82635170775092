import PainelBase from "./PainelBase";

export default class PainelDahMono440W extends PainelBase {
  getCode = () => 15028;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 15028 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 15028)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 15028 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 15028 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 4) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 15028
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +-----------+---------+---------+-------+
                    |PREFIX     |Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +-----------+---------+---------+-------+
                    |6022108D010|19476    |1225     |29%    |
                    |6102107D010|11974    |585      |18%    |
                    |38242103010|11687    |490      |17%    |
                    |99282106010|10595    |611      |16%    |
                    |74202105010|5541     |264      |8%     |
                    |6022108d079|1722     |140      |3%     |
                    |6022108D079|1637     |126      |2%     |
                    |92821060102|309      |83       |0%     |
                    |42021050102|159      |36       |0%     |
                    |6102107d010|150      |7        |0%     |
                    |82106010270|122      |33       |0%     |
                    |02105010250|117      |22       |0%     |
                    |28210601027|104      |24       |0%     |
                    |20210501025|85       |22       |0%     |
                    |82421030102|84       |28       |0%     |
                    |82106010280|55       |16       |0%     |
                    |42103010220|46       |16       |0%     |
                    |82106010290|37       |15       |0%     |
                    |42103010210|36       |12       |0%     |
                    |21060102700|34       |5        |0%     |
                    |02105010240|34       |12       |0%     |
                    |28210601029|32       |16       |0%     |
                    |28210601028|32       |16       |0%     |
                    |28210601026|31       |15       |0%     |
                    |82106010260|30       |11       |0%     |
                    |20210501024|26       |11       |0%     |
                    |15282101081|23       |2        |0%     |
                    |102107D0103|22       |9        |0%     |
                    |24210301022|22       |16       |0%     |
                    |42103010200|22       |6        |0%     |
                    |21030102200|19       |4        |0%     |
                    |24210301021|17       |7        |0%     |
                    |21050102501|15       |8        |0%     |
                    |2107D010330|14       |3        |0%     |
                    |21030102102|12       |4        |0%     |
                    |21060102701|11       |5        |0%     |
                    |21050102500|11       |7        |0%     |
                    |21060102601|10       |8        |0%     |
                    |21060102702|10       |6        |0%     |
                    |15282101100|9        |1        |0%     |
                    |20210501026|9        |4        |0%     |
                    |02105010260|9        |2        |0%     |
                    |U624MM21A5E|8        |1        |0%     |
                    |21030102100|8        |5        |0%     |
                    |12102150020|8        |1        |0%     |
                    |21030102001|8        |1        |0%     |
                    |02107D01032|7        |4        |0%     |
                    |02107D01033|7        |4        |0%     |
                    |21030102202|6        |3        |0%     |
                    |21030102201|6        |6        |0%     |
                    |21030102000|6        |1        |0%     |
                    |U624MM20L5R|6        |1        |0%     |
                    |12107030750|6        |1        |0%     |
                    |21050102503|6        |1        |0%     |
                    |21030102101|6        |1        |0%     |
                    |21050102401|5        |2        |0%     |
                    |21050102502|5        |3        |0%     |
                    |21060102900|4        |4        |0%     |
                    |LRP60408920|4        |2        |0%     |
                    |21060102801|4        |3        |0%     |
                    |24210301024|4        |3        |0%     |
                    |12102150021|4        |1        |0%     |
                    |21060102600|4        |4        |0%     |
                    |U624MM21A5A|4        |1        |0%     |
                    |LRP60408820|3        |2        |0%     |
                    |2107D010320|3        |3        |0%     |
                    |18582105100|3        |2        |0%     |
                    |42103010240|3        |1        |0%     |
                    |24210301020|3        |2        |0%     |
                    |24210301023|3        |2        |0%     |
                    |21060102602|3        |2        |0%     |
                    |12106010670|3        |1        |0%     |
                    |U624MM21A5C|3        |2        |0%     |
                    |07D01033000|2        |2        |0%     |
                    |107D0103300|2        |1        |0%     |
                    |28210108132|2        |1        |0%     |
                    |10601027001|2        |1        |0%     |
                    |21030102002|2        |2        |0%     |
                    |99280601029|2        |2        |0%     |
                    |82101081320|2        |1        |0%     |
                    |10601027003|2        |1        |0%     |
                    |10301021033|2        |1        |0%     |
                    |2108D010370|1        |1        |0%     |
                    |63901355   |1        |1        |0%     |
                    |16006012110|1        |1        |0%     |
                    |10301021019|1        |1        |0%     |
                    |10301021011|1        |1        |0%     |
                    |10301022012|1        |1        |0%     |
                    |01298      |1        |1        |0%     |
                    |10501025000|1        |1        |0%     |
                    |50179274   |1        |1        |0%     |
                    |61000239   |1        |1        |0%     |
                    |01025023897|1        |1        |0%     |
                    |70162999   |1        |1        |0%     |
                    |99286021010|1        |1        |0%     |
                    |60102601902|1        |1        |0%     |
                    |1026015969 |1        |1        |0%     |
                    |12007181302|1        |1        |0%     |
                    |2108D010380|1        |1        |0%     |
                    |03010210290|1        |1        |0%     |
                    |21030102103|1        |1        |0%     |
                    |2302480    |1        |1        |0%     |
                    |10240027138|1        |1        |0%     |
                    |62000934   |1        |1        |0%     |
                    |05010250058|1        |1        |0%     |
                    |10501024017|1        |1        |0%     |
                    |102401933  |1        |1        |0%     |
                    |05010250238|1        |1        |0%     |
                    |06010270210|1        |1        |0%     |
                    |06010270036|1        |1        |0%     |
                    |99010290119|1        |1        |0%     |
                    |01028029599|1        |1        |0%     |
                    |10601028021|1        |1        |0%     |
                    |06010290068|1        |1        |0%     |
                    |01026003779|1        |1        |0%     |
                    |99282102901|1        |1        |0%     |
                    |01026012839|1        |1        |0%     |
                    |03203386   |1        |1        |0%     |
                    |12107030740|1        |1        |0%     |
                    |10301022003|1        |1        |0%     |
                    |201599     |1        |1        |0%     |
                    |38000694   |1        |1        |0%     |
                    |01025016317|1        |1        |0%     |
                    |21050102400|1        |1        |0%     |
                    |102400103  |1        |1        |0%     |
                    |35001517   |1        |1        |0%     |
                    |102501174  |1        |1        |0%     |
                    |102702043  |1        |1        |0%     |
                    |10601027026|1        |1        |0%     |
                    |10601027028|1        |1        |0%     |
                    |0049899    |1        |1        |0%     |
                    |60102700662|1        |1        |0%     |
                    |02600024   |1        |1        |0%     |
                    |15852106050|1        |1        |0%     |
                    |99282106090|1        |1        |0%     |
                    |99210601029|1        |1        |0%     |
                    |600991     |1        |1        |0%     |
                    |10601026008|1        |1        |0%     |
                    |601374     |1        |1        |0%     |
                    |01026013229|1        |1        |0%     |
                    |7D010330000|1        |1        |0%     |
                    |107D0103101|1        |1        |0%     |
                    |10301020019|1        |1        |0%     |
                    |21030102003|1        |1        |0%     |
                    |2101955    |1        |1        |0%     |
                    |03010210194|1        |1        |0%     |
                    |002493     |1        |1        |0%     |
                    |5001917    |1        |1        |0%     |
                    |50144474   |1        |1        |0%     |
                    |05010250170|1        |1        |0%     |
                    |10501025016|1        |1        |0%     |
                    |10501025018|1        |1        |0%     |
                    |50102401829|1        |1        |0%     |
                    |501051     |1        |1        |0%     |
                    |700770     |1        |1        |0%     |
                    |05010250280|1        |1        |0%     |
                    |01028021479|1        |1        |0%     |
                    |60102802436|1        |1        |0%     |
                    |10601028013|1        |1        |0%     |
                    |99280102902|1        |1        |0%     |
                    |99282029011|1        |1        |0%     |
                    |10601026006|1        |1        |0%     |
                    |107D0103202|1        |1        |0%     |
                    |06010260178|1        |1        |0%     |
                    |6022108D018|1        |1        |0%     |
                    |10301021014|1        |1        |0%     |
                    |10301021027|1        |1        |0%     |
                    |18282101100|1        |1        |0%     |
                    |30102103312|1        |1        |0%     |
                    |21030102300|1        |1        |0%     |
                    |64000240   |1        |1        |0%     |
                    |05010250019|1        |1        |0%     |
                    |62001024   |1        |1        |0%     |
                    |02501533   |1        |1        |0%     |
                    |50102500248|1        |1        |0%     |
                    |62000942   |1        |1        |0%     |
                    |0102401385 |1        |1        |0%     |
                    |82102100405|1        |1        |0%     |
                    |70192599   |1        |1        |0%     |
                    |06010270054|1        |1        |0%     |
                    |06010270005|1        |1        |0%     |
                    |10601027007|1        |1        |0%     |
                    |60102701791|1        |1        |0%     |
                    |21050102600|1        |1        |0%     |
                    |06010290066|1        |1        |0%     |
                    |10601029013|1        |1        |0%     |
                    |9900538    |1        |1        |0%     |
                    |21060102802|1        |1        |0%     |
                    |99282101026|1        |1        |0%     |
                    |99282601900|1        |1        |0%     |
                    |01026020839|1        |1        |0%     |
                    |06010260008|1        |1        |0%     |
                    |99010290132|1        |1        |0%     |
                    |10601026025|1        |1        |0%     |
                    |107D0103203|1        |1        |0%     |
                    |8012107D050|1        |1        |0%     |
                    |2108D010340|1        |1        |0%     |
                    |2108D010360|1        |1        |0%     |
                    |10301024002|1        |1        |0%     |
                    |02400171382|1        |1        |0%     |
                    |0102202079 |1        |1        |0%     |
                    |21021500003|1        |1        |0%     |
                    |05010240053|1        |1        |0%     |
                    |5019587    |1        |1        |0%     |
                    |58210210040|1        |1        |0%     |
                    |82103100405|1        |1        |0%     |
                    |06010270040|1        |1        |0%     |
                    |10601027019|1        |1        |0%     |
                    |502019     |1        |1        |0%     |
                    |60102900786|1        |1        |0%     |
                    |60102601262|1        |1        |0%     |
                    |0260119899 |1        |1        |0%     |
                    |06010260208|1        |1        |0%     |
                    |06010260144|1        |1        |0%     |
                    |12107180010|1        |1        |0%     |
                    |02107D01031|1        |1        |0%     |
                    |00183      |1        |1        |0%     |
                    |52821010813|1        |1        |0%     |
                    |15282010080|1        |1        |0%     |
                    |21030102400|1        |1        |0%     |
                    |30102202288|1        |1        |0%     |
                    |10301022004|1        |1        |0%     |
                    |0102501631 |1        |1        |0%     |
                    |10501025015|1        |1        |0%     |
                    |10501025024|1        |1        |0%     |
                    |85821051004|1        |1        |0%     |
                    |10601027025|1        |1        |0%     |
                    |60102702422|1        |1        |0%     |
                    |02702172   |1        |1        |0%     |
                    |10501025031|1        |1        |0%     |
                    |01491      |1        |1        |0%     |
                    |0102600913 |1        |1        |0%     |
                    |18582106100|1        |1        |0%     |
                    |12107030751|1        |1        |0%     |
                    |22108d07997|1        |1        |0%     |
                    |10301021002|1        |1        |0%     |
                    |10301021031|1        |1        |0%     |
                    |4210301    |1        |1        |0%     |
                    |10301022001|1        |1        |0%     |
                    |2302479    |1        |1        |0%     |
                    |201813     |1        |1        |0%     |
                    |42103010230|1        |1        |0%     |
                    |102500406  |1        |1        |0%     |
                    |10501024002|1        |1        |0%     |
                    |293999282  |1        |1        |0%     |
                    |60102700772|1        |1        |0%     |
                    |0102702058 |1        |1        |0%     |
                    |10601027002|1        |1        |0%     |
                    |99282100099|1        |1        |0%     |
                    |60102901014|1        |1        |0%     |
                    |99282011002|1        |1        |0%     |
                    |102802799  |1        |1        |0%     |
                    |102601597  |1        |1        |0%     |
                    |9600493    |1        |1        |0%     |
                    |0102600520 |1        |1        |0%     |
                    |NULL       |0        |0        |0%     |
                    +-----------+---------+---------+-------+
                Interpretação:
                    Essa consulta devolve todos os prefixos de 11 dígitos do insumo em questão, mostrando
                    256 resultados. Perceba que as iniciais 6022108D010, 6102107D010, 38242103010, 99282106010,
                    74202105010, 6022108d079 e 6022108D079 possuem porcentagem por insumo acima de 0. Portanto,
                    vamos considerar que todas essas iniciais são possíveis nesse painel.
            Tipagem:
                Foi verificado que os seriais desse painel são compostos por letras e/ou números.

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 15028 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 15028 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 15028
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |18    |62237    |3285     |
                    |19    |493      |160      |
                    |17    |441      |151      |
                    |16    |291      |131      |
                    |21    |275      |133      |
                    |20    |274      |132      |
                    |15    |265      |136      |
                    |14    |137      |69       |
                    |22    |106      |58       |
                    |13    |28       |27       |
                    |24    |21       |16       |
                    |23    |20       |17       |
                    |8     |16       |10       |
                    |26    |14       |11       |
                    |12    |13       |13       |
                    |11    |13       |13       |
                    |30    |12       |8        |
                    |27    |9        |8        |
                Interpretação:
                    Perceba que a quantidade de seriais com 18 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 6022108D010, 6102107D010, 38242103010, 99282106010,
                    74202105010, 6022108d079 ou 6022108D079 mais 7 dígitos numéricos, totalizando 18 dígitos.
         */
    return [
      /^6022108D010(\d|\w){7}$/,
      /^6102107D010(\d|\w){7}$/,
      /^38242103010(\d|\w){7}$/,
      /^99282106010(\d|\w){7}$/,
      /^74202105010(\d|\w){7}$/,
      /^6022108d079(\d|\w){7}$/,
      /^6022108D079(\d|\w){7}$/,
    ].some((regex) => !!this.serial.match(regex));
  }

  static build() {
    return new PainelDahMono440W();
  }
}

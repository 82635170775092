import PainelBase from "./PainelBase";

export default class PainelDahPoliHalfCell360W extends PainelBase {
  getCode = () => 3171;
  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 3171 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 3171 and numerosseriecontrolados is not null)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') percent from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 3171 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 3171 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 7) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 3171
                               and numerosseriecontrolados is not null
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +-------+---------+---------+-------+
                    |PREFIX |Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +-------+---------+---------+-------+
                    |1828210|2225     |124      |85%    |
                    |8282101|81       |11       |3%     |
                    |8210110|62       |9        |2%     |
                    |2821011|61       |8        |2%     |
                    |2101100|58       |7        |2%     |
                    |LRP6040|26       |2        |1%     |
                    |1011000|24       |4        |1%     |
                    |1528210|16       |1        |1%     |
                    |1288200|12       |2        |0%     |
                    |0110006|11       |2        |0%     |
                    |1100060|6        |2        |0%     |
                    |1000604|4        |2        |0%     |
                    |0110005|3        |3        |0%     |
                    |0006041|1        |1        |0%     |
                    |3014975|1        |1        |0%     |
                    |0604211|1        |1        |0%     |
                    |03805  |1        |1        |0%     |
                    |03270  |1        |1        |0%     |
                    |0005012|1        |1        |0%     |
                    |0060446|1        |1        |0%     |
                    |8218282|1        |1        |0%     |
                    |0603341|1        |1        |0%     |
                    |2005412|1        |1        |0%     |
                    |6041291|1        |1        |0%     |
                    |0604331|1        |1        |0%     |
                    |6053591|1        |1        |0%     |
                    |0604165|1        |1        |0%     |
                    |0604548|1        |1        |0%     |
                    |1000605|1        |1        |0%     |
                    |8611382|1        |1        |0%     |
                    |2012050|1        |1        |0%     |
                    |3618282|1        |1        |0%     |
                    |0006040|1        |1        |0%     |
                    |5182821|1        |1        |0%     |
                    |1428200|1        |1        |0%     |
                    |0501216|1        |1        |0%     |
                    |3018282|1        |1        |0%     |
                    |LRA6040|1        |1        |0%     |
                    |4718282|1        |1        |0%     |
                    |3014937|1        |1        |0%     |
                    |0060496|1        |1        |0%     |
                    |3014957|1        |1        |0%     |
                    |41501  |1        |1        |0%     |
                    +-------+---------+---------+-------+
                Interpretação:
                    Essa consulta devolve todos os prefixos de 7 dígitos do insumo em questão, mostrando
                    43 resultados. Perceba que a inicial com alguma relevância é 1828210.
                    Portanto, vamos considerar que esse painel sempre comece com essa sequência.
            Tipagem:
                Consulta:
                    select
                        s.numerosseriecontrolados,
                        s.NUMPED
                    from sspedido s
                    where
                          s.codprodmp = 3171
                      and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
                Essa consulta chega a retornar ainda 27 linhas, porém eles são distribuídos em apenas 2 pedidso.
                Portanto, nõo é uma amostra considerável para afirmar que esse painel possui letras. Portanto, essa
                amostra foi descartada e foi verificado que esse serial é composto apenas por números.

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 3171 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 3171 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 3171
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |18    |2157     |127      |
                    |17    |60       |11       |
                    |21    |58       |10       |
                    |19    |57       |8        |
                    |16    |52       |8        |
                    |20    |50       |8        |
                    |14    |28       |7        |
                    |22    |25       |5        |
                    |15    |20       |6        |
                    |13    |14       |4        |
                    |23    |12       |3        |
                    |12    |9        |3        |
                    |7     |8        |4        |
                    |24    |7        |3        |
                    |10    |6        |3        |
                    |11    |5        |3        |
                    |33    |5        |1        |
                    |36    |5        |1        |
                    |26    |4        |3        |
                    |35    |3        |1        |
                    |32    |3        |1        |
                    |5     |3        |2        |
                    |29    |3        |3        |
                    |34    |3        |1        |
                    |9     |3        |2        |
                    |42    |3        |2        |
                    |27    |3        |2        |
                    |30    |2        |1        |
                    |25    |2        |2        |
                    |63    |2        |1        |
                    |31    |2        |2        |
                    |38    |2        |1        |
                    |44    |1        |1        |
                    |84    |1        |1        |
                    |28    |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+
                Interpretação:
                    Perceba que a quantidade de seriais com 18 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1828210 mais 11 dígitos numéricos, totalizando 18 dígitos.
         */
    return !!this.serial.match(/^1828210\d{11}$/);
  }

  static build() {
    return new PainelDahPoliHalfCell360W();
  }
}

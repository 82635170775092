import PainelBase from "./PainelBase";

export default class PainelDahPoliHalfCell400W extends PainelBase {
  getCode = () => 6146;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 6146 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 6146 and numerosseriecontrolados is not null)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') percent from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 6146 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 6146 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 7) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 6146
                               and numerosseriecontrolados is not null
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +-------+---------+---------+-------+
                    |PREFIX |Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +-------+---------+---------+-------+
                    |1528210|818      |75       |58%    |
                    |1128210|499      |45       |36%    |
                    |LRP6040|22       |1        |2%     |
                    |1528201|21       |4        |1%     |
                    |6022108|13       |1        |1%     |
                    |3824210|10       |1        |1%     |
                    |U624MM2|6        |2        |0%     |
                    |8611383|2        |1        |0%     |
                    |1010101|2        |1        |0%     |
                    |6666   |1        |1        |0%     |
                    |4040   |1        |1        |0%     |
                    |66666  |1        |1        |0%     |
                    |6060   |1        |1        |0%     |
                    |3030   |1        |1        |0%     |
                    |1010   |1        |1        |0%     |
                    |8210108|1        |1        |0%     |
                    |5050   |1        |1        |0%     |
                    |1010100|1        |1        |0%     |
                    +-------+---------+---------+-------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 7 dígitos do insumo em questão, mostrando
                    18 resultados. Perceba que as iniciais com alguma relevância são 1528210 e 1128210.
                    Portanto, vamos considerar que esse painel sempre comece com essas sequências.
            Tipagem:
                Consulta:
                    select
                        s.numerosseriecontrolados,
                        s.NUMPED
                    from sspedido s
                    where
                          s.codprodmp = 6146
                      and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
                Essa consulta chega a retornar ainda 41 linhas, porém eles são distribuídos em apenas 4 pedidos.
                Portanto, nõo é uma amostra considerável para afirmar que esse painel possui letras. Portanto, essa
                amostra foi descartada e foi verificado que esse serial é composto apenas por números.

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 6146 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 6146 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 6146
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |18    |1363     |113      |
                    |21    |22       |1        |
                    |16    |6        |2        |
                    |4     |6        |1        |
                    |12    |1        |1        |
                    |10    |1        |1        |
                    |15    |1        |1        |
                    |5     |1        |1        |
                    |8     |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com 18 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1528210 ou 1128210 mais 11 dígitos numéricos, totalizando 18 dígitos.
         */
    return !!this.serial.match(/^(1828210|1128210)\d{11}$/);
  }

  static build() {
    return new PainelDahPoliHalfCell400W();
  }
}

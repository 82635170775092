import PainelBase from "./PainelBase";

export default class PainelGcl335W extends PainelBase {
  getCode = () => 60;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 60 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Segundo a consulta "select numerosseriecontrolados from sspedido where codprodmp = 60",
            só existem dois painéis desse tipo no sistema. Portanto, essa amostra será desoconsiderada.
            e quando houver alguma bipagem desse painel novamente, o sistema deixará passar sem validação.
         */
    return true;
  }

  static build() {
    return new PainelGcl335W();
  }
}

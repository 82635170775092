<template>
  <span>
    <div v-for="(volume, i) in volumes" :key="i">
      <div class="mb-3 px-1 py-4" v-show="!volume.codigo">
        <b-card
          class="shadow"
          header="featured"
          header-tag="div"
          :header-class="
            'cor-background-azul ' +
            (btnFecharCaixaLoading ? ' caixa-carregando' : '')
          "
        >
          <template #header>
            <h4 class="mb-6 font-weight-bold" style="color: white">
              Caixa {{ i + 1 }} - {{ volume.codigo }}
            </h4>
          </template>
          <div
            v-for="(volumeInsumo, j) in volume.insumosNaoBipaveis"
            :key="j"
            class="mb-0 px-1 py-1"
          >
            <b-card v-if="!volumeInsumo.insumo.descricao.match(descricaoMesa)">
              <b-row
                no-gutters
                style="justify-content: space-between !important"
              >
                <!--            <span v-if="!volumeInsumo.insumo.precisaSerBipado">-->
                <b-col cols="3" sm="8" md="8" lg="8" xl="8" align="start">
                  <label
                    style="
                      align-items: center;
                      font-family: 'Poppins';
                      font-size: 20px;
                    "
                    >{{ (volumeInsumo.insumo || {}).descricao || "" }}</label
                  >
                </b-col>
                <b-col cols="2" sm="1" md="1" lg="1" xl="1" align="center">
                  <b-button
                    class="number-modifier"
                    @click="volumeInsumo.quantidade--"
                    style="border-radius: 8px 0px 0px 8px !important"
                    pill
                    :disabled="btnFecharCaixaLoading"
                  >
                    -
                  </b-button>
                </b-col>
                <b-col cols="4" sm="2" md="2" lg="2" xl="2" align="center">
                  <input
                    class="py-0 px-0; my-0 mx-0 input-metragem text-center"
                    v-model="volumeInsumo.quantidade"
                    type="number"
                    :disabled="btnFecharCaixaLoading"
                  />
                </b-col>
                <b-col cols="2" sm="1" md="1" lg="1" xl="1" align="center">
                  <b-button
                    class="number-modifier"
                    @click="volumeInsumo.quantidade++"
                    style="border-radius: 0px 8px 8px 0px !important"
                    pill
                    :disabled="btnFecharCaixaLoading"
                  >
                    +
                  </b-button>
                </b-col>
                <!--            </span>-->
                <!---->
                <!--            <div v-else>-->
                <!--            </div>-->
              </b-row>
            </b-card>
          </div>

          <span
            v-for="(volumeInsumo, k) in volume.insumosBipaveis"
            :key="k"
            class="mb-0 px-1 py-1"
          >
            <linha-producao-input-bipavel
              :insumo="volumeInsumo.insumo"
              :total-insumos="volume.insumos.length"
              @click="biparInsumo($event)"
              :bipagem-only="true"
            />
          </span>
        </b-card>
      </div>
    </div>
  </span>
</template>

<script>
import LinhaProducaoInputBipavel from "../../components/LinhaProducaoInputBipavel";
export default {
  name: "ListagemCaixasLinhaDiversos",
  components: { LinhaProducaoInputBipavel },
  data() {
    return {
      descricaoMesa: "MESA",
    };
  },
  props: {
    volumes: {
      type: Array,
      required: true,
    },
    btnFecharCaixaLoading: {
      type: Boolean,
      required: true,
    },
  },
  // computed: {
  //   volumesMapped() {
  //     return this.volumes.map(v => ({
  //        ...v,
  //        insumosBipaveis: v.insumos.filter(i => i.insumo.precisaSerBipado),
  //        insumosNaoBipaveis: v.insumos.filter(i => !i.insumo.precisaSerBipado)
  //      }))
  //   }
  // },
  methods: {
    async biparInsumo({ event, insumo }) {
      this.$emit("bipar", { event, insumo });
    },
  },
};
</script>

<style scoped>
.cor-background-azul {
  background: #002233;
}
.number-modifier {
  border-style: none;
  background: #ff7d27;
}

.input-metragem {
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  width: 80%;
  border-radius: 0px 0px 0px 0px;
}

.caixa-carregando {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>

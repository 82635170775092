<template>
  <v-tooltip
    :left="left"
    :top="top"
    :bottom="bottom"
    :disabled="disableTooltip"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :color="color"
        v-on="{ ...on, ...$listeners }"
        v-bind="{ ...attrs, ...$attrs }"
        >{{ icon }}</v-icon
      >
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DefaultIcon",
  props: {
    top: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      required: true,
    },
    left: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    tooltipText: {
      type: String,
      required: false,
    },
    disableTooltip: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>

export default {
  getOrders(state) {
    return state.orders;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getIsLoadingOrders(state) {
    return state.isLoadingOrders;
  },
  getPagination(state) {
    return state.pagination;
  },
  getIsLoadingOrderDetails(state) {
    return state.isLoadingOrderDetails;
  },
  getOrderDetails(state) {
    return state.orderDetails;
  },
};

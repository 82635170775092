import PainelBase from "./PainelBase";

export default class PainelGcl330W extends PainelBase {
  getCode = () => 62;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 62 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Segundo a consulta "select numerosseriecontrolados from sspedido where codprodmp = 62",
            todos os 4 seriais desse painel estão nulos no sistema. Portanto, essa amostra será desoconsiderada.
            e quando houver alguma bipagem desse painel novamente, o sistema deixará passar sem validação.
         */
    return true;
  }

  static build() {
    return new PainelGcl330W();
  }
}

export default {
  sortingBays: [],
  isLoading: false,
  isRegisterBayButtonDisabled: true,
  isEditBayModalOpen: false,
  isRegisterBayModalOpen: false,
  maxNumberOfBays: 5,
  pagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  urlParams: {
    page: 1,
    perPage: 20,
  },
};

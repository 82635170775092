import env from "../../env";

export default class VisualizacaoRelatorioAguardandoProducao {
  static build() {
    return new VisualizacaoRelatorioAguardandoProducao();
  }

  abrirRelatorio() {
    const url = env.baseUrl + "/pedido/rel-ag-prod";
    window.open(url);
  }
}

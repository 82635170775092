export default {
  linha1: [],
  linha2: [],
  linha3: [],
  linha3Conf: [],
  linha4: [],
  linha5: [],
  metaDia: [],
  metaHora: [],
  pedRealizados: [],
  pedFaturados: [],
  pedAtrasados: [],
  confFinal: [],
  embarque: [],
  ploomesAguardandoProducao: [],
  ploomesEmLinhaProducao: [],
  ploomesAguardandoEmbarque: [],
};

import router from "../../router";

export default class EditarLinhasRotasService {
  numped = "";
  static build() {
    return new EditarLinhasRotasService();
  }

  setNumped(numped) {
    this.numped = numped;
    return this;
  }

  async goToEditarLinhaPaineis() {
    const { numped } = this;
    await router.replace({
      name: "editarPedidoLinhaPaineis",
      params: { numped },
    });
  }

  async goToEditarLinhaDiversos() {
    const { numped } = this;
    await router.replace({
      name: "editarPedidoLinhaDiversos",
      params: { numped },
    });
  }
}

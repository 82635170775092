import { HttpRestService } from "@/services/http/HttpRestService";

export default class UsuarioIniciadorRepository {
  static build() {
    return new UsuarioIniciadorRepository();
  }

  getUsuarioIniciadorLinhaPaineis(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/pedido-web/${numeroPedido}/usuario-iniciador/paineis`
    );
  }

  getUsuarioIniciadorLinhaCabos(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/pedido-web/${numeroPedido}/usuario-iniciador/cabos`
    );
  }

  getUsuarioIniciadorLinhaDiversos(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/pedido-web/${numeroPedido}/usuario-iniciador/diversos`
    );
  }

  getUsuarioIniciadorLinhaPerfis(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/pedido-web/${numeroPedido}/usuario-iniciador/perfis`
    );
  }

  getUsuarioIniciadorLinhaInversores(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/pedido-web/${numeroPedido}/usuario-iniciador/inversores`
    );
  }
}

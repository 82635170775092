import { HttpRestService } from "@/services/http/HttpRestService";

export class GerenciarPedidoRepository {
  static getPedidoResumo(numeroPedido) {
    return HttpRestService.get(`/api/v2/pedido/pedido-resumo/${numeroPedido}`);
  }

  static pedidoResumoEmbarque(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/pedido/resumo-embarque/${numeroPedido}`
    );
  }

  static zerarLinhas(numeroPedido, linha) {
    const body = {
      numped: numeroPedido,
      linha: linha,
    };
    if (linha > 0) {
      return HttpRestService.post(`/api/pedido/altera-linha`, body);
    } else {
      return HttpRestService.post(
        `/api/v2/pedido/confere-ac/${numeroPedido}/zerar`
      );
    }
  }

  static zerarTotalBipagem(numeroPedido) {
    const body = {
      numped: numeroPedido,
    };
    return HttpRestService.post(`/api/pedido/zerar`, body);
  }
}

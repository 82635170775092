<template>
  <div>
    <div class="content">
      <div class="box-content">
        <h3 class="text-center">Funções - Editar Registro</h3>

        <form @submit.prevent="updateReg">
          <div class="form-group">
            <label>Nome</label>
            <input type="text" class="form-control" v-model="role.NAME" />
          </div>
          <div class="form-group">
            <label>Obs</label>
            <input type="text" class="form-control" v-model="role.OBS" />
          </div>
          <button type="submit" class="btn btn-primary">Salvar >></button>
        </form>

        <button class="btn btn-danger" @click="deleteReg()">Deletar</button>
      </div>
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "../../mixins/routesValidationMixin";
import RolesRepository from "@/repositories/v1/RolesRepository";

export default {
  components: {},
  data() {
    return {
      role: {},
      rolesRepository: RolesRepository.build(),
    };
  },
  mixins: [routesValidationMixin],
  created() {
    this.rolesRepository = RolesRepository.build();
    this.rolesRepository
      .getRole(this.$route.params.id)
      .then((response) => {
        this.role = response.data;
      })
      .catch(function (error) {
        window.location.href = "/dashboard";
        console.error(error);
      });
  },
  methods: {
    updateReg() {
      this.rolesRepository
        .editRole(this.$route.params.id, this.role)
        .then(() => {
          this.$router.push({ name: "roles", query: { res: "edit" } });
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    deleteReg() {
      if (confirm("Confirma Exclusão?")) {
        this.rolesRepository
          .deleteRole(this.$route.params.id)
          .then(() => {
            this.$router.push({ name: "roles", query: { res: "remove" } });
          })
          .catch(function (error) {
            console.error(error);
          });
      }
    },
  },
};
</script>

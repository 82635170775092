<template>
  <v-container fluid>
    <div>
      <div class="" v-if="pedidos.length">
        <div class="">
          <div class="base_dark rounded-lg pa-4 mb-4" style="">
            <v-row
              justify="center"
              class="mx-1"
              style="font-weight: 600; font-size: 24px; line-height: 36px"
            >
              <v-col cols="6" align-self="center">
                <v-row>
                  <v-chip
                    :color="chipColorStatus(pedidos[0].STATUS)"
                    class="navy--text"
                  >
                    {{ status(pedidos[0].STATUS) }}
                  </v-chip>
                  <span
                    class="d-block text-wrap font-weight-bold secondary--text mb-n2 mx-2"
                  >
                    #{{ pedidos[0].NUMPED }}</span
                  >
                </v-row>
              </v-col>
              <v-col cols="6">
                <div class="font-weight-light text-truncate">
                  Transportadora:
                  {{ pedidos[0].TRANSPORTADORA }}
                </div>
              </v-col>
            </v-row>

            <v-row align="center" class="justify-space-between">
              <v-col cols="6" class="text-start"></v-col>
              <v-col cols="6" class="text-end">
                <b> NFE: {{ pedidos[0].CHAVENFE ?? "N/A" }} </b>
              </v-col>
            </v-row>

            <v-row align="center" class="mt-2 justify-space-between">
              <v-col cols="6" class="text-start"
                ><b>CLIENTE:</b> {{ pedidos[0].CLIENTE }}</v-col
              >
              <v-col cols="6" class="text-end">
                <b>DATA DE PRODUÇÃO:</b>
                {{ formatDate(pedidos[0].DATASEPROD) }}
              </v-col>
            </v-row>
            <v-row align="center" class="mt-2 justify-space-between">
              <v-col cols="6" class="text-start"
                ><b>KIT:</b> {{ pedidos[0].DESC_KIT }}</v-col
              >
              <v-col cols="6" class="text-end">
                <b>LOCALIZAÇÃO DO ESTOQUE: </b
                >{{ pedidos[0].LOCALIZACAOPEDIDO }}
              </v-col>
            </v-row>

            <v-row align="center" class="justify-space-between">
              <v-col cols="3">
                <div v-if="pedidos[0].STATUS !== 'D'">
                  <default-button
                    type="submit"
                    class="btn btn-danger"
                    @click="openDialogZerarTotal()"
                  >
                    Zerar Bipagem Total
                  </default-button>
                </div>
              </v-col>
              <v-col cols="3">
                <template>
                  <v-select
                    v-if="!isPedidoFixador && pedidos[0].STATUS !== 'D'"
                    id="linhaz"
                    :items="[1, 2, 3, 4, 5, { text: 'ac', value: -1 }]"
                    v-model="linha"
                    label="Linha"
                    hide-details
                    clearable
                    persistent-placeholder
                    color="primary"
                    background-color="base"
                    outlined
                    dense
                  ></v-select>
                </template>
              </v-col>
              <v-col cols="3">
                <div v-if="!isPedidoFixador && pedidos[0].STATUS !== 'D'">
                  <default-button
                    type="submit"
                    class="btn btn-danger"
                    @click="openDialogZerarLinha"
                  >
                    Zerar Linha
                  </default-button>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>

        <div class="counter">
          <h3>Total Itens: {{ total_items }}</h3>
        </div>
      </div>
      <v-skeleton-loader v-else type="card" class="mt-3" />

      <v-row v-if="pedidos.length" justify="center" class="my-3">
        <v-col cols="6" align-self="center">
          <div align="center">
            <default-button
              type="submit"
              @click="downloadReport()"
              class="btn btn-danger"
            >
              Relatório <br />
              (volumes e insumos)
            </default-button>
          </div>
        </v-col>
      </v-row>

      <div class="prods" v-if="pedidos.length">
        <table class="table table-bordered" v-if="this.linha1.length">
          <thead>
            <tr>
              <th colspan="4">Linha 1</th>
              <th width="120">
                Volume: {{ this.detalhesDoPedido.volumesLinha1 }}
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Produto</th>
              <th>Funcionário Bipagem</th>
              <th>Dt Incial Sep.</th>
              <th>Dt Final Sep.</th>
              <th>Cód. Barras</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(pedido, index) in linha1" :key="index">
              <td>
                {{ pedido.codigoProdutoMateriaPrima }} -
                {{ pedido.insumoDescricao }} -
                <span class="qt-table"
                  >QUANTIDADE: {{ pedido.insumosQuantidade }}</span
                >
              </td>
              <td>
                {{ pedido.bipadorCodigo ? pedido.bipadorNome : "" }}
              </td>
              <td>{{ formatDate(pedido.dataInicio) }}</td>
              <td>{{ formatDate(pedido.dataFinal) }}</td>
              <td>{{ pedido.NUMEROSSERIECONTROLADOS }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-bordered" v-if="this.linha2.length">
          <thead>
            <tr>
              <th colspan="4">Linha 2</th>
              <th width="120">
                Volume:
                {{ this.detalhesDoPedido.volumesLinha2 }}
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Produto</th>
              <th>Funcionário Bipagem</th>
              <th>Dt Incial Sep.</th>
              <th>Dt Final Sep.</th>
              <th>Cód. Barras</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pedido, index) in linha2" :key="index">
              <td>
                {{ pedido.codigoProdutoMateriaPrima }} -
                {{ pedido.insumoDescricao }} -
                <span class="qt-table"
                  >QUANTIDADE: {{ pedido.insumosQuantidade }}</span
                >
              </td>
              <td>
                {{ pedido.bipadorCodigo ? pedido.bipadorNome : "" }}
              </td>
              <td>{{ formatDate(pedido.dataInicio) }}</td>
              <td>{{ formatDate(pedido.dataFinal) }}</td>
              <td>{{ pedido.NUMEROSSERIECONTROLADOS }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-bordered" v-if="this.linha3.length">
          <thead>
            <tr>
              <th colspan="5">Linha 3</th>
              <th width="120">
                Volume:
                {{ this.detalhesDoPedido.volumesLinha3 }}
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Produto</th>
              <th>Funcionário Bipagem</th>
              <th>Dt Incial Sep.</th>
              <th>Dt Final Sep.</th>
              <th>Cód. Barras</th>
              <th>Cód. Barras2</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pedido, index) in linha3" :key="index">
              <td>
                {{ pedido.codigoProdutoMateriaPrima }} -
                {{ pedido.insumoDescricao }}
                <span class="qt-table"
                  >QUANTIDADE: {{ pedido.insumosQuantidade }}</span
                >
              </td>
              <td>
                {{ pedido.bipadorCodigo ? pedido.bipadorNome : "" }}
              </td>
              <td>{{ formatDate(pedido.dataInicio) }}</td>
              <td>{{ formatDate(pedido.dataFinal) }}</td>
              <td>{{ pedido.NUMEROSSERIECONTROLADOS }}</td>
              <td>{{ pedido.NUMEROSSERIECONTROLADOS2 }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-bordered" v-if="this.linha4.length">
          <thead>
            <tr>
              <th colspan="4">Linha 4</th>
              <th width="120">
                Volume:
                {{ this.detalhesDoPedido.volumesLinha4 }}
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Produto</th>
              <th>Funcionário Bipagem</th>
              <th>Dt Incial Sep.</th>
              <th>Dt Final Sep.</th>
              <th>Cód. Barras</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pedido, index) in linha4" :key="index">
              <td>
                {{ pedido.codigoProdutoMateriaPrima }} -
                {{ pedido.insumoDescricao }}
                <span class="qt-table"
                  >QUANTIDADE: {{ pedido.insumosQuantidade }}</span
                >
              </td>
              <td>
                {{ pedido.bipadorCodigo ? pedido.bipadorNome : "" }}
              </td>
              <td>{{ formatDate(pedido.dataInicio) }}</td>
              <td>{{ formatDate(pedido.dataFinal) }}</td>
              <td>{{ pedido.NUMEROSSERIECONTROLADOS }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-bordered" v-if="this.linha5.length">
          <thead>
            <tr>
              <th colspan="4">Linha 5</th>
              <th width="120">
                Volume:
                {{ this.detalhesDoPedido.volumesLinha4 }}
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th>Produto</th>
              <th>Funcionário Bipagem</th>
              <th>Dt Incial Sep.</th>
              <th>Dt Final Sep.</th>
              <th>Cód. Barras</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pedido, index) in linha5" :key="index">
              <td>
                {{ pedido.codigoProdutoMateriaPrima }} -
                {{ pedido.insumoDescricao }}
                <span class="qt-table"
                  >QUANTIDADE: {{ pedido.insumosQuantidade }}</span
                >
              </td>
              <td>
                {{ pedido.bipadorCodigo ? pedido.bipadorNome : "" }}
              </td>
              <td>{{ formatDate(pedido.dataInicio) }}</td>
              <td>{{ formatDate(pedido.dataFinal) }}</td>
              <td>{{ pedido.NUMEROSSERIECONTROLADOS }}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <h3>Histórico</h3>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Status</th>
              <th>Data</th>
              <th>Funcionário</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in historicoDoPedido" :key="index">
              <td>{{ item.status_nome }}</td>
              <td>{{ formatDate(item.dataCriacao) }}</td>
              <td>{{ item.despachante_nome }}</td>
            </tr>
          </tbody>
        </table>

        <br />
        <h3>Log Modificações com autorização</h3>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Antigo Status</th>
              <th>Status</th>
              <th>Data</th>
              <th>Funcionário</th>
              <th>Obs</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list.logs" :key="index">
              <td>{{ status(item.STATUS) }}</td>
              <td>{{ status(item.NEW_STATUS) }}</td>
              <td>{{ formatDate(item.CREATED_AT) }}</td>
              <td>{{ item.usuario ? item.usuario.NOME : "" }}</td>
              <td>{{ item.OBS }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <v-skeleton-loader type="table" />
      </div>
    </div>

    <zerar-linha
      @close="dialogZerarLinha = false"
      :enable="dialogZerarLinha"
      :key="zerarLinhaKey"
      :linha="linha"
      @zerar-linha="postZerarLinha"
    >
    </zerar-linha>
    <zerar-total
      @close="dialogZerarTotal = false"
      :enableTotal="dialogZerarTotal"
      :key="zerarTotalKey"
      @zerar-total="postZerarTotal"
    >
    </zerar-total>
  </v-container>
</template>

<script>
import routesValidationMixin from "@/mixins/routesValidationMixin";
import UsersRepository from "@/repositories/v1/UsersRepository";
import PedidoRepository from "@/repositories/v1/PedidoRepository";
import PedidoWebRepository from "@/repositories/v2/PedidoWebRepository";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";

import env from "@/env.js";
// import Swal from "sweetalert2";
// import router from "@/router";

import { mapActions, mapState } from "vuex";
import ZerarLinha from "../components/ZerarLinha.vue";
import ZerarTotal from "../components/ZerarTotal.vue";

export default {
  name: "GerenciarPedido",
  components: {
    DefaultButton,
    ZerarLinha,
    ZerarTotal,
  },
  data() {
    return {
      zerarLinhaKey: 0,
      dialogZerarLinha: false,
      dialogZerarTotal: false,
      zerarTotalKey: 0,
      linha: "",
      usersRepository: UsersRepository.build(),
      pedidoWebRepository: PedidoWebRepository.build(),
      pedidoRepository: PedidoRepository.build(),
    };
  },
  mounted() {
    this.getDetails(this.$route.params.id);
  },
  mixins: [routesValidationMixin],
  // created() {
  //   this.zeragemBipagemTotalService =
  //     ZeragemBipagemTotalService.build().setVm(this);
  //   this.usersRepository = UsersRepository.build();
  //   this.pedidoRepository = PedidoRepository.build();
  //   this.pedidoWebRepository = PedidoWebRepository.build();

  //   this.usersRepository
  //     .getFuncoesByUser(`${UsuarioLogado.getUserId()}`)
  //     .then((response) => {
  //       if (response.data.roles.includes("ADMIN")) {
  //         this.type = "ADMIN";
  //       } else {
  //         window.location.href = "/dashboard";
  //       }

  //       this.pedidoWebRepository
  //         .getPedidoResumo(this.$route.params.id)
  //         .then((response) => {
  //           this.pedidos = response.data.data.pedidos;
  //           this.volumes = response.data.total;
  //           console.log(this.pedidos);

  //           if (this.pedidos.length) {
  //             this.item_save = this.pedidos[0].NUMSEQ;
  //             this.total_items = this.pedidos.length;
  //           }
  //         })
  //         .catch(function (error) {
  //           console.error(error);
  //         });
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });

  //   this.usersRepository
  //     .getFuncoesByUser(`${UsuarioLogado.getUserId()}`)
  //     .then((response) => {
  //       if (response.data.roles.includes("ADMIN")) {
  //         this.type = "ADMIN";
  //       } else {
  //         window.location.href = "/dashboard";
  //       }

  //       this.pedidoWebRepository
  //         .pedidoResumoEmbarque(`${this.$route.params.id}`)
  //         .then((response) => {
  //           // this.list = response.data;
  //           // this.pedidos = response.data.pedidos;
  //           // this.volumes = response.data.total;

  //           //Novo response
  //           if (!response.data)
  //             Swal.fire(
  //               "Pedido sem dados. Favor entrar em contato com suporte.",
  //               "",
  //               "error"
  //             ).then((result) => {
  //               if (result.isConfirmed) {
  //                 router.replace({ path: "/pedidos-ss/despachados" });
  //               }
  //             });
  //           // this.historicoDoPedido = response.data.data.historico;
  //           // this.detalhesDoPedido = response.data.data.pedido;
  //           // this.linhasProducao = response.data.data.linhasProducao;
  //           // this.linha1 = this.linhasProducao
  //           //   .filter((i) => i.linha === 1)
  //           //   .map((um) => ({
  //           //     insumoDescricao: um.insumoDescricao,
  //           //     codigoProdutoMateriaPrima: um.codigoProdutoMateriaPrima,
  //           //     insumosQuantidade: um.insumosQuantidade,
  //           //     linha: um.linha,
  //           //     bipadorCodigo: um.bipadorCodigo,
  //           //     bipadorNome: um.bipadorNome,
  //           //     dataInicio: um.dataInicio,
  //           //     dataFinal: um.dataFinal,
  //           //   }));
  //           // this.linha2 = this.linhasProducao
  //           //   .filter((i) => i.linha === 2)
  //           //   .map((dois) => ({
  //           //     insumoDescricao: dois.insumoDescricao,
  //           //     codigoProdutoMateriaPrima: dois.codigoProdutoMateriaPrima,
  //           //     insumosQuantidade: dois.insumosQuantidade,
  //           //     linha: dois.linha,
  //           //     bipadorCodigo: dois.bipadorCodigo,
  //           //     bipadorNome: dois.bipadorNome,
  //           //     dataInicio: dois.dataInicio,
  //           //     dataFinal: dois.dataFinal,
  //           //   }));
  //           // this.linha3 = this.linhasProducao
  //           //   .filter((i) => i.linha === 3)
  //           //   .map((tres) => ({
  //           //     insumoDescricao: tres.insumoDescricao,
  //           //     codigoProdutoMateriaPrima: tres.codigoProdutoMateriaPrima,
  //           //     insumosQuantidade: tres.insumosQuantidade,
  //           //     linha: tres.linha,
  //           //     bipadorCodigo: tres.bipadorCodigo,
  //           //     bipadorNome: tres.bipadorNome,
  //           //     dataInicio: tres.dataInicio,
  //           //     dataFinal: tres.dataFinal,
  //           //   }));

  //           // this.linha4 = this.linhasProducao
  //           //   .filter((i) => i.linha === 4)
  //           //   .map((quatro) => ({
  //           //     insumoDescricao: quatro.insumoDescricao,
  //           //     codigoProdutoMateriaPrima: quatro.codigoProdutoMateriaPrima,
  //           //     insumosQuantidade: quatro.insumosQuantidade,
  //           //     linha: quatro.linha,
  //           //     bipadorCodigo: quatro.bipadorCodigo,
  //           //     bipadorNome: quatro.bipadorNome,
  //           //     dataInicio: quatro.dataInicio,
  //           //     dataFinal: quatro.dataFinal,
  //           //   }));

  //           // if (this.detalhesDoPedido.length) {
  //           //   this.item_save = this.this.detalhesDoPedido.NUMSEQ;
  //           //   this.total_items = this.pedidos.length;
  //           // }
  //         })
  //         .catch(function (error) {
  //           console.error(error);
  //         });
  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // },
  computed: {
    ...mapState("gerenciarPedido", [
      "pedidos",
      "list",
      "linha1",
      "linha2",
      "linha3",
      "linha4",
      "linha5",
      "historicoDoPedido",
      "detalhesDoPedido",
      "linhasProducao",
      "total_items",
      "volumes",
    ]),

    isPedidoFixador() {
      return this.$route.query.tipoPedido === "lote-fixador";
    },
  },
  methods: {
    ...mapActions("gerenciarPedido", [
      "getPedidoDetails",
      "getPedidoResumoEmbarque",
      "zerarLinhas",
      "zerarTotalBipagem",
    ]),

    getDetails(id) {
      Promise.all([
        this.getPedidoDetails(id),
        this.getPedidoResumoEmbarque(id),
      ]);
    },

    openDialogZerarLinha() {
      if (this.linha == "" || this.linha == null) return;
      this.dialogZerarLinha = true;
      this.zerarLinhaKey++;
    },

    openDialogZerarTotal() {
      this.dialogZerarTotal = true;
      this.zerarTotalKey++;
    },

    downloadReport() {
      window.open(env.relatorioUrl + this.pedidos[0].NUMPED);
    },

    async postZerarTotal() {
      await this.zerarTotalBipagem(this.pedidos[0].NUMPED).then(() => {
        console.log("zerou");
        this.dialogZerarTotal = false;
      });
    },

    async postZerarLinha() {
      const body = {
        numped: this.pedidos[0].NUMPED,
        linha: this.linha,
      };
      await this.zerarLinhas(body).then(() => {
        this.dialogZerarLinha = false;
      });
    },

    formatDate(data) {
      let out = "";
      if (data) {
        out = data.substr(0, 10).split("-").reverse().join("/");
        out = out + " " + data.substr(10, 10);
      }
      return out;
    },
    status(label) {
      let out = "";

      switch (label) {
        case "A":
          out = "AGUARDANDO PRODUÇÃO";
          break;
        case "L":
          out = "EM LINHA DE PRODUÇÃO";
          break;
        case "N":
          out = "AGUARDANDO CONFERÊNCIA";
          break;
        case "F":
          out = "EM CONFERÊNCIA";
          break;
        case "T":
          out = "CONFERÊNCIA COM PROBLEMA";
          break;
        case "E":
          out = "AGUARDANDO EMBARQUE";
          break;
        case "D":
          out = "DESPACHADO";
          break;
        case "C":
          out = "CANCELADO";
          break;
        default:
          out = "";
      }

      return out;
    },
    chipColorStatus(label) {
      let out = "";

      switch (label) {
        case "A":
          out = "primary";
          break;
        case "L":
          out = "primary";
          break;
        case "N":
          out = "primary";
          break;
        case "F":
          out = "primary";
          break;
        case "T":
          out = "warning";
          break;
        case "E":
          out = "primary";
          break;
        case "D":
          out = "success";
          break;
        case "C":
          out = "error";
          break;
        default:
          out = "";
      }

      return out;
    },
  },
};
</script>

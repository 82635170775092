import { HttpRestService } from "@/services/http/HttpRestService";

export default class ConferenciaFinalRepository {
  static build() {
    return new ConferenciaFinalRepository();
  }

  listarConferencia(numped) {
    return HttpRestService.get(`/api/v2/pedido/${numped}/volumes/conferencia`);
  }

  fecharCarga(body) {
    return HttpRestService.post(`/api/v2/carga/save`, body);
  }
}

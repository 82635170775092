const setUserLogged = (userId, pcEmprId, roles, perfil) => {
  localStorage.setItem("userId", userId);
  localStorage.setItem("roles", roles);
  localStorage.setItem("pcEmprId", pcEmprId);
  localStorage.setItem("perfil", perfil);
};

const getUserLogged = () => {
  return {
    userId: +localStorage.getItem("userId") || 0,
    roles: localStorage.getItem("roles") || [],
    pcEmprId: localStorage.getItem("pcEmprId") || 0,
    permissoes: localStorage.getItem("permissoes")?.split(",") || [],
    perfil: localStorage.getItem("perfil") || "",
  };
};

export { setUserLogged, getUserLogged };

import PainelBase from "./PainelBase";

export default class PainelCanadianPoli400W extends PainelBase {
  getCode = () => 19913;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 19913 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    select
                        prefix,
                        (select count(*) from sspedido s where s.codprodmp = 19913 and  substr(s.numerosseriecontrolados, 1, 5) = prefix) q_insumos,
                        (select count(distinct s.numped) from sspedido s where s.codprodmp = 19913 and  substr(s.numerosseriecontrolados, 1, 5) = prefix) q_pedidos
                    from (
                        select
                            distinct substr(numerosseriecontrolados, 1, 5) as prefix
                        from SSPEDIDO s
                        where s.codprodmp = 19913
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |PREFIX|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |12012 |1082     |69       |
                    |20121 |11       |5        |
                    |01219 |9        |4        |
                    |12191 |8        |4        |
                    |15282 |2        |2        |
                    |01205 |1        |1        |
                    |21914 |1        |1        |
                    |12051 |1        |1        |
                    |U624M |1        |1        |
                    +------+---------+---------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 5 dígitos do insumo em questão, mostrando
                    9 resultados. Perceba que apenas a inicial 12012 possui resultados consideráveis. Os
                    outros muito provavelmente são erros de bipagem.
            Tipagem:
                Foi verificado que todos os seriais desse painel são numéricos. Consulta:
                    select
                        s.numerosseriecontrolados
                    from sspedido s
                    where
                        s.codprodmp = 19913
                        and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
                Essa consulta só devolve um único serial que é composto por letras e números. Para o tamanho
                da amostra, isso é irrelevante. Provavelmente, erro de bipagem.

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 19913 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 19913 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 19913
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |14    |1067     |68       |
                    |13    |10       |4        |
                    |16    |10       |5        |
                    |15    |9        |4        |
                    |11    |6        |5        |
                    |12    |6        |5        |
                    |17    |4        |3        |
                    |18    |3        |3        |
                    |21    |1        |1        |
                    +------+---------+---------+
                Interpretação:
                    Perceba que a quantidade de seriais com 14 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 12012 mais 9 dígitos numéricos, totalizando 14 dígitos.
         */
    return !!this.serial.match(/^12012\d{9}$/);
  }

  static build() {
    return new PainelCanadianPoli400W();
  }
}

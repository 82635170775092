import PainelBase from "./PainelBase";

export default class PainelGclMono375W extends PainelBase {
  getCode = () => 2468;

  isValid() {
    /*  ESTUDO DE PADRÃO DE PAINEL 2468 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            +-----------------------+-------------------+--------+
            |NUMEROSSERIECONTROLADOS|DATASEPROD         |NUMPED  |
            +-----------------------+-------------------+--------+
            |D02190230705117        |2021-07-16 11:30:00|58000626|
            |D02190230704966        |2021-07-16 11:30:00|58000626|
            |D02190230704962        |2021-07-16 11:30:00|58000626|
            |D02190230704492        |2021-07-16 11:30:00|58000626|
            |D02190230704880        |2021-07-16 11:30:00|58000626|
            |D02190230704951        |2021-07-16 11:30:00|58000626|
            |D02190230705081        |2021-07-16 11:30:00|58000626|
            |D02190230705112        |2021-07-16 11:30:00|58000626|
            |D02190230705105        |2021-07-16 11:30:00|58000626|
            |D02190230705107        |2021-07-16 11:30:00|58000626|
            |D02190230705101        |2021-07-16 11:30:00|58000626|
            |D02190230704945        |2021-07-16 11:30:00|58000626|
            |D02190230704949        |2021-07-16 11:30:00|58000626|
            |D02190230705099        |2021-07-16 11:30:00|58000626|
            |D02190230704948        |2021-07-16 11:30:00|58000626|
            |D02190230705098        |2021-07-16 11:30:00|58000626|
            |D02190230704923        |2021-07-16 11:30:00|58000626|
            |D02190230704921        |2021-07-16 11:30:00|58000626|
            |D02190230705097        |2021-07-16 11:30:00|58000626|
            |D02190230704937        |2021-07-16 11:30:00|58000626|
            |D02190230705096        |2021-07-16 11:30:00|58000626|
            |S69190600300584        |2021-07-16 11:30:00|58000626|
            |S69190600300996        |2021-07-16 11:30:00|58000626|
            |S69190600300389        |2021-07-16 11:30:00|58000626|
            |S69190600300603        |2021-07-16 11:30:00|58000626|
            |S69190600300471        |2021-07-16 11:30:00|58000626|
            |S69190600300236        |2021-07-16 11:30:00|58000626|
            |S69190600300178        |2021-07-16 11:30:00|58000626|
            |S69190600300237        |2021-07-16 11:30:00|58000626|
            |S69190600300238        |2021-07-16 11:30:00|58000626|
            |S69190600300283        |2021-07-16 11:30:00|58000626|
            |S69190600300285        |2021-07-16 11:30:00|58000626|
            |S69190600300179        |2021-07-16 11:30:00|58000626|
            |D02190230705110        |2021-07-16 11:30:00|58000626|
            |D02190230704907        |2021-07-16 11:30:00|58000626|
            |S69190600300604        |2021-07-16 11:30:00|58000626|
            |S69190600300180        |2021-07-16 11:30:00|58000626|
            |D02190230705062        |2021-07-16 11:30:00|58000626|
            |S69190600300240        |2021-07-16 11:30:00|58000626|
            |D02190230704963        |2021-07-16 11:30:00|58000626|
            |D02190230705100        |2021-07-16 11:30:00|58000626|
            |D02190230705077        |2021-07-16 11:30:00|58000626|
            |D02190230704886        |2021-07-16 11:30:00|58000626|
            |S69190600300199        |2021-07-16 11:30:00|58000626|
            |S69190600300239        |2021-07-16 11:30:00|58000626|
            |S69190600300281        |2021-07-16 11:30:00|58000626|
            +-----------------------+-------------------+--------+
            Como pode-se observar dos dados do sistema, só existem 46 insumos em um único pedido
            desse painel. Portanto, o Winthor não possui dados suficientes para escrever uma regra de validação.
            Por isso, quando esse serial for bipado novamente, o sistema deixará passar sem regras.
         */
    return true;
  }

  static build() {
    return new PainelGclMono375W();
  }
}

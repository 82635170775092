export default {
  setDrawer(state, value) {
    state.drawer = value;
  },

  setDarkMode(state, value) {
    state.darkMode = value;
  },

  setListingInsumos(state, value) {
    state.insumos = value;
  },

  setInsumosPagination(state, value) {
    state.pagination = value;
  },

  setListingInsumosNaoConfigurados(state, value) {
    state.insumosNaoConfigurados = value;
  },

  setIsLoading(state, value) {
    state.isLoading = value;
  },

  setListingInsumosNaoConfiguradosFilter(state, value) {
    state.insumosNaoConfiguradosFilter = value;
  },

  setUrlParams(state, value) {
    state.urlParams = value;
  },
};

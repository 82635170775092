import { HttpRestService } from "@/services/http/HttpRestService";

export default class ConferenciaFinalAgilRepository {
  static build() {
    return new ConferenciaFinalAgilRepository();
  }

  requestBiparCarga(body) {
    return HttpRestService.post(`/api/v2/conferencia-final/bipagem/lote`, body);
  }
}

<template>
  <div>
    <div class="listing-card">
      <b-row no-gutters>
        <b-col sm="12" md="12" lg="2" xl="2" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Número do pedido: </b>
            </span>
            {{ pedido.numeroPedido }}
          </li>
        </b-col>
        <b-col sm="12" md="12" lg="2" xl="2" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Data de Produção: </b>
            </span>
            {{ pedido.dataProducao | dateDMY }}
          </li>
        </b-col>
        <b-col sm="12" md="12" lg="2" xl="2" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Nome do Cliente: </b>
            </span>
            <b>
              {{ pedido.clienteNome }}
            </b>
          </li>
        </b-col>
        <b-col sm="12" md="12" lg="2" xl="2" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Transportadora: </b>
            </span>
            {{ pedido.transportadoraNome }}
          </li>
        </b-col>
        <b-col sm="12" md="12" lg="2" xl="2" class="listing-section">
          <li>
            <span class="mb-3 d-xl-none d-lg-none">
              <b> Kit: </b>
            </span>
            {{ pedido.kitDescricao }}
          </li>
          <li class="mt-4 d-flex justify-content-center">
            <b-button
              style="width: 100%"
              class="default-button-style"
              v-if="!isUsuarioLinha3"
              pill
              @click="visualizarDetalhesPedido"
            >
              <b-spinner v-if="visualizacaoDetalhesPedido.loading" />
              <span v-else>Mais detalhes</span>
            </b-button>
          </li>
        </b-col>

        <b-col
          sm="12"
          md="12"
          lg="2"
          xl="2"
          class="listing-section"
          v-if="isContinuarConferenciaAc"
        >
          <b-button
            pill
            style="width: 100%"
            class="default-button-style"
            @click="continuarConferenciaAc"
            >Continuar Conferência</b-button
          >
        </b-col>

        <b-col
          sm="12"
          md="12"
          lg="2"
          xl="2"
          class="listing-section"
          v-if="isVerDetalhesDespachados"
        >
          <li>
            <router-link
              :to="{
                name: 'pedido-resumo',
                params: { id: pedido.numeroPedido },
              }"
            >
              <b-button
                style="width: 100%"
                class="default-button-style"
                pill
                @click="visualizarDetalhesDespachados"
                >Ver resumo do pedido</b-button
              ></router-link
            >
          </li>
        </b-col>

        <b-col
          sm="12"
          md="12"
          lg="2"
          xl="2"
          class="listing-section"
          v-if="isVerDetalhesEmbarque"
        >
          <li>
            <router-link
              :to="{
                name: 'pedido-embarque-agil',
                params: { id: pedido.numeroPedido },
              }"
            >
              <b-button
                style="width: 100%"
                class="default-button-style"
                pill
                @click="visualizarDetalhesEmbarque"
              >
                <b-spinner v-if="isVerDetalhesEmbarque.loading" />
                <span v-else>Iniciar Embarque</span>
              </b-button>
            </router-link>
          </li>
        </b-col>
        <b-col
          sm="12"
          md="12"
          lg="2"
          xl="2"
          class="listing-section"
          v-if="
            isGerenciarAdmin ||
            isAguardandoProducao ||
            isLinhaProducao ||
            isLinhaProducaoDiversos ||
            isIniciarConferencia ||
            isContinuarConferencia ||
            isVerDetalhesComProblema
          "
        >
          <li>
            <b-button
              style="width: 100%"
              class="default-button-style"
              v-if="isGerenciarAdmin"
              pill
              @click="gerenciarAdmin()"
              >Gerenciar</b-button
            >
            <b-button
              style="width: 100%"
              class="default-button-style"
              v-if="isAguardandoProducao"
              pill
              @click="openModalIniciarProducao()"
              >Iniciar Produção</b-button
            >
            <b-button
              style="width: 100%"
              class="default-button-style"
              v-if="isLinhaProducao"
              pill
              @click="continuarProducaoPedido()"
              >Continuar Produção</b-button
            >
            <b-button
              style="width: 100%"
              class="default-button-style"
              v-if="isLinhaProducaoDiversos"
              pill
              @click="continuarProducaoPedidoDiversos()"
              >Continuar Produção</b-button
            >
            <b-button
              style="width: 100%"
              class="default-button-style"
              v-if="isIniciarConferencia"
              pill
              @click="iniciarConferencia()"
              >Iniciar Conferência</b-button
            >
            <b-button
              style="width: 100%"
              class="default-button-style"
              v-if="isContinuarConferencia"
              pill
              @click="continuarConferencia()"
              >Continuar Conferência</b-button
            >
            <b-button
              style="width: 100%"
              class="default-button-style"
              v-if="isVerDetalhesComProblema"
              pill
              @click="verDetalhesComProblema()"
              >Ver Detalhes</b-button
            >
          </li>
          <li class="mt-1">
            <b-button
              style="width: 100%"
              class="default-button-style"
              v-if="isGerenciarAdmin"
              pill
              @click="editarAdmin()"
              >Editar</b-button
            >
          </li>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import VisualizacaoDetalhesPedido from "@/services/listagem_pedidos/VisualizacaoDetalhesPedido";
import ContinuacaoProducaoPedido from "@/services/listagem_pedidos/ContinuacaoProducaoPedido";
import ContinuacaoProducaoPedidoDiversos from "@/services/listagem_pedidos/ContinuacaoProducaoPedidoDiversos";
import PedidoWebRepository from "@/repositories/v2/PedidoWebRepository";
import { getUserLogged } from "./ListingHeader.vue";

export default {
  created() {
    this.visualizacaoDetalhesPedido = VisualizacaoDetalhesPedido.build()
      .setVm(this)
      .setNumeroPedido(this.pedido.numeroPedido);
    this.continuacaoProducaoPedido = ContinuacaoProducaoPedido.build()
      .setVm(this)
      .setNumeroPedido(this.pedido.numeroPedido);

    this.pedidoWebRepository = PedidoWebRepository.build();
  },
  components: {
    // ModalDetalhesPedido,
    // DefaultButton,
  },

  data: () => ({
    relacaoTiposInsumoQuantidade: [],
    visualizacaoDetalhesPedido: VisualizacaoDetalhesPedido.build(),
    continuacaoProducaoPedido: ContinuacaoProducaoPedido.build(),
    continuacaoProducaoPedidoDiversos:
      ContinuacaoProducaoPedidoDiversos.build(),
    pedidoWebRepository: PedidoWebRepository.build(),
  }),

  props: {
    pedido: {
      type: Object,
      required: true,
      validator: (pedido) =>
        [
          "numeroPedido",
          "dataProducao",
          "clienteNome",
          "kitDescricao",
          "transportadoraNome",
        ].every((key) => ![null, undefined].includes(pedido[key])),
    },
    colorBtnDetalhes: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    colorBtnIniciarProducao: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
  },
  methods: {
    visualizarDetalhesPedido() {
      // console.group({ pedido: this.pedido });
      // console.log("visualizarDetalhesPedido");
      // console.groupEnd();
      // this.$bvModal.show('modal-detalhes')
      this.visualizacaoDetalhesPedido.abrirDetalhesPedido();
    },
    fecharDetalhesPedido() {
      this.$bvModal.hide("modal-detalhes");
    },
    gerenciarAdmin() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "gerenciar-pedido",
        params: { id: this.pedido.numeroPedido },
        query: {
          tipoPedido: this.pedido.tipoPedido,
        },
      });
    },
    editarAdmin() {
      this.$router.push({
        name: "editar-pedido",
        params: { numped: this.pedido.numeroPedido },
      });
    },
    continuarConferencia() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedido-confere-agil",
        params: {
          id: this.pedido.numeroPedido,
        },
        query: {
          tipoPedido: this.pedido.tipoPedido,
        },
      });
    },
    verDetalhesComProblema() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedido-confere-det",
        params: { id: this.pedido.numeroPedido },
      });
    },
    continuarConferenciaAc() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedido-confere-agil-ac",
        params: {
          id: this.pedido.numeroPedido,
        },
        query: { tipoPedido: this.pedido.tipoPedido },
      });
    },
    visualizarDetalhesDespachados() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedido-resumo",
        params: { id: this.pedido.numeroPedido },
      });
    },
    visualizarDetalhesEmbarque() {
      console.group({ pedido: this.pedido });
      console.groupEnd();
      this.$router.push({
        name: "pedidoExpressEmbarque",
        params: { id: this.pedido.numeroPedido },
      });
    },
    iniciarConferencia() {
      console.group({ pedido: this.pedido });
      console.groupEnd();

      this.pedidoWebRepository
        .iniciarConferencia(this.pedido.numeroPedido)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.$router.push({
              name: "pedido-confere-agil",
              params: {
                id: this.pedido.numeroPedido,
              },
              query: {
                tipoPedido: this.pedido.tipoPedido,
              },
            });
          } else {
            alert("O pedido não tem itens para ser conferidos");
            window.location.href = "/pedidos-agil/aguardando-conferencia";
          }
        })
        .catch(function (error) {
          console.error(error);
        });

      //this.$router.push({
      //  name: "pedido-confere",
      //  params: { id: this.pedido.numeroPedido },
      //});
    },
    iniciarProducaoPedido() {
      console.group({ pedido: this.pedido });
      console.log("iniciarProducaoPedido");
      console.groupEnd();
    },
    openModalIniciarProducao() {
      this.$emit("openModalIniciarProducao", this.pedido.numeroPedido);
    },
    continuarProducaoPedido() {
      this.continuacaoProducaoPedidoDiversos.setTipoPedido(
        this.pedido.tipoPedido
      );
      this.continuacaoProducaoPedido.continuarProducaoPedidoExpress();
    },
    continuarProducaoPedidoDiversos() {
      this.continuacaoProducaoPedidoDiversos.setNumeroPedido(
        this.pedido.numeroPedido
      );
      this.continuacaoProducaoPedidoDiversos.setTipoPedido(
        this.pedido.tipoPedido
      );

      this.continuacaoProducaoPedidoDiversos.continuarProducaoPedidoDiversos();
    },
  },
  computed: {
    isUsuarioLinha3() {
      return getUserLogged().permissoes().inclues("separacao-da-linha-3");
      // return UsuarioLogado.getRoles().includes("LINHA3");
    },
    status() {
      return this.$parent.pedidosStatus;
    },
    isGerenciarAdmin() {
      return this.status === "";
    },
    isContinuarConferenciaAc() {
      return this.status === "ac";
    },
    isContinuarConferencia() {
      return this.status === "F";
    },
    isVerDetalhesComProblema() {
      return this.status === "T";
    },
    isIniciarConferencia() {
      return this.status === "N";
    },
    isVerDetalhesEmbarque() {
      return this.status === "E";
    },
    isVerDetalhesDespachados() {
      return this.status === "D";
    },
    isAguardandoProducao() {
      return this.status === "A";
    },
    isLinhaProducao() {
      return this.status === "L";
    },
    isLinhaProducaoDiversos() {
      return this.status === "O";
    },
  },
};
</script>

<style scoped>
.default-button-style {
  text-align: center;
  outline: none;
  border-radius: 1rem;
  padding: 1rem;
  font-weight: bold !important;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
  transition: background-color, padding 0.15s;
  background-color: #cc5d02 !important;
  color: white !important;
  border: none;
}

.default-button-style:hover {
  background-color: #e6781e !important;
  color: white !important;
}

.listing-section {
  list-style-type: none;
  border-right: 0.063rem solid rgba(83, 83, 83, 0.14);
  padding: 1.25rem;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
}
.listing-section-last {
  list-style-type: none;
  padding: 1.25rem;
  text-align: center;
}
.listing-card {
  list-style-type: none;
  width: 100%;
  height: 100%;
  background-color: #eef0f8;
  color: #2e2e2e;
  border-radius: 1rem;
}
.listing-card:hover {
  background-color: rgba(215, 219, 223, 0.84);
  border-radius: 1rem;
  transition: 300ms all ease-in-out;
}
</style>

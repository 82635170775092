import { HttpRestService } from "@/services/http/HttpRestService";

export class PedidosRepository {
  static build() {
    return new PedidosRepository();
  }

  getPedidoDetalhes(numeroPedido) {
    //return HttpRestService.get(`/api/v2/pedidos/numped/${numeroPedido}`);
    return HttpRestService.get(`/api/v2/pedido-web/${numeroPedido}`);
  }

  getPedidoLinhasPendentes(numeroPedido) {
    //return HttpRestService.get(`/api/v2/pedidos/numped/${numeroPedido}/linhas-pendentes`);
    return HttpRestService.get(
      `/api/v2/pedido-web/${numeroPedido}/linhas-pendentes`
    );
  }
}

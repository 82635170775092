import PainelBase from "./PainelBase";

export default class PainelCanadianMono445W extends PainelBase {
  getCode = () => 24387;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 24387 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    select
                        prefix,
                        (select count(*) from sspedido s where s.codprodmp = 24387 and  substr(s.numerosseriecontrolados, 1, 5) = prefix) quantidade_insumos,
                        (select count(distinct s.numped) from sspedido s where s.codprodmp = 24387 and  substr(s.numerosseriecontrolados, 1, 5) = prefix) quantidade_pedidos
                    from (
                        select
                            distinct substr(numerosseriecontrolados, 1, 5) as prefix
                        from SSPEDIDO s
                        where s.codprodmp = 24387
                    ) order by quantidade_insumos desc
                Resultado:
                    +------+------------------+------------------+
                    |PREFIX|QUANTIDADE_INSUMOS|QUANTIDADE_PEDIDOS|
                    +------+------------------+------------------+
                    |12107 |3045              |233               |
                    |12108 |42                |3                 |
                    |27619 |2                 |1                 |
                    |12106 |1                 |1                 |
                    |NULL  |0                 |0                 |
                    +------+------------------+------------------+
                Interpretação:
                    Essa consulta devolve todos os prefixos de 5 dígitos do insumo em questão, mostrando
                    5 resultados. Perceba que os 3 maiores totalizadores começam com a sequência 1210. De
                    todos os seriais bipados no sistema, se excetuam apenas 2 em um único pedido que começam
                    de outra forma. Portanto, esse painel sempre inicia com 1210.
            Tipagem:
                Foi verificado que todos os seriais desse painel são numéricos. Consulta:
                    select
                        s.numerosseriecontrolados
                    from sspedido s
                    where
                        s.codprodmp = 19914
                        and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 24387 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 24387 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 24387
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |14    |3088     |236      |
                    |11    |2        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+
                Interpretação:
                    Perceba que a quantidade de seriais com 14 dígitos corresponde a 99% do total. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1210 mais 10 dígitos numéricos, totalizando 14 dígitos.
         */
    return !!this.serial.match(/^1210\d{10}$/);
  }

  static build() {
    return new PainelCanadianMono445W();
  }
}

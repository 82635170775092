<template>
  <default-fab
    :tooltip-text="status.tooltip"
    :color="status.color"
    ><span class="white--text font-weight-bolder h3">{{
      status.letter
    }}</span></default-fab
  >
</template>

<script>
import DefaultFab from "./DefaultFab.vue";
export default {
  name: "BlockStatus",
  components: {
    DefaultFab,
  },
  props: {
    blockStatus: {},
  },
  computed: {
    status() {
      const statuses = {
        BLOQUEADO: {
          color: "pink",
          letter: "B",
          tooltip: "Pedido Bloqueado",
        },
        DESBLOQUEADO: {
          color: "blue",
          letter: "D",
          tooltip: "Pedido Desbloqueado",
        },
        LIBERADO: {
          color: "green",
          letter: "L",
          tooltip: "Pedido Liberado",
        },
      };
      return statuses[this.blockStatus];
    },
  },
};
</script>

<style scoped></style>

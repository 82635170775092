import router from "../../../router";
import { dateTimeInFormatYmdhis } from "../../dateService";
import { LocalStorageService } from "../../LocalStorageService";
import { UsuarioLogado } from "../../login/UsuarioLogado";
import LinhasEnum from "../../enums/LinhasEnum";
import { getLinhaDoUsuario } from "../../perfilFunc";
import { FirstLineRepository } from "../../../repositories/v1/FirstLineRepository";
import PedidoWebRepository from "../../../repositories/v2/PedidoWebRepository";
import Swal from "sweetalert2";
import store from "../../../store/store";

export class LinhaPaineisRenderService {
  localStorageService;
  FirstLineRepository;
  PedidoWebRepository;

  constructor(localStorageService, firstLineRepository, pedidoWebRepository) {
    this.localStorageService = localStorageService;
    this.firstLineRepository = firstLineRepository;
    this.pedidoWebRepository = pedidoWebRepository;
  }

  static build() {
    const pedidoWebRepository = PedidoWebRepository.build();
    const localStorageService = LocalStorageService.build();
    const firstLineRepository = FirstLineRepository.build();
    return new LinhaPaineisRenderService(
      localStorageService,
      firstLineRepository,
      pedidoWebRepository
    );
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async run() {
    const linhaUsuarioAtual = getLinhaDoUsuario() || LinhasEnum.paineis;

    if (linhaUsuarioAtual !== LinhasEnum.paineis) {
      let errorMessage = {
        errorStatus: true,
        errorMessage: "Você não tem permissão para acessar essa linha.",
      };
      store.commit("notifications/setErrorNotification", errorMessage, {
        root: true,
      });
      await router.replace({ name: "pedidosWebLinhaProducao" });
    }
    await this.requestUserFuncoes();
  }

  async requestUserSepLinhaPaineis() {
    this.vm.firstLineRepository.getUser.then(async (response) => {
      this.vm.usuariosSeparadoresLinhaPaineisLista = response.data;
      await this.requestUserFuncoes();
    });
  }

  async requestUserFuncoes() {
    this.vm.perfilUsuarioAtual = "";
    if (UsuarioLogado.getPerfil().includes("administrador")) {
      this.vm.perfilUsuarioAtual = "ADMIN";
    } else if (UsuarioLogado.getRoles().includes("LINHA")) {
      this.vm.perfilUsuarioAtual = UsuarioLogado.getRoles()
        .replace(/[^0-9.]+/g, "")
        .split("")
        .join("-");
    } else {
      await router.replace({ path: "/dashboard" });
    }
    await this.requestPedidosLinhaPaineisList();
  }

  async requestPedidosLinhaPaineisList() {
    const numeroPedido = this.vm.$route.params.numped;
    const response = await this.firstLineRepository.getPedidos(numeroPedido);
    if (response.status === 200) {
      this.verificarInsumosVazios(response);
    } else {
      await Swal.fire({
        icon: "error",
        text: response.data.message + " Entre em contato com o Suporte.",
      });
    }
  }

  verificarInsumosVazios(response) {
    if (!response.data.data.insumos) {
      Swal.fire({
        icon: "error",
        text: " Não há insumos para serem bipados.",
      }).then((result) => {
        if (result.isConfirmed) {
          router.replace({ path: "/pedidos-ss/em-linha-de-producao" });
        }
      });
    } else {
      this.adicaoDeDadosResponse(response);
    }
  }

  adicaoDeDadosResponse(response) {
    // Mess code
    this.vm.insumosLinhaPaineis = response.data.data.insumos;
    this.vm.detalhesDoPedido = response.data.data;
    this.vm.volumesLinhaPaineis = response.data.data.volumes;
    this.vm.horaInicialBipagem = dateTimeInFormatYmdhis();
    //

    // Correct code
    const { data } = response.data;
    let index = 0;
    this.vm.insumos = data.insumos.map((i) => ({
      insumoId: i.insumoId,
      winthorCodigoProduto: i.winthorCodigoProduto,
      insumoDescricao: i.insumoDescricao,
      bipado: i.bipado,
      serial: i.serial,
      quantidade: i.quantidade,
      sspedidoNumSeq: i.sspedidoNumSeq,
      volumeId: i.volumeId,
      index: ++index,
      tipoInsumoId: i.tipoInsumoId,
    }));
    index = 0;
    this.vm.volumes = data.volumes.map((v) => ({
      volumeId: v.volumeId,
      codigo: v.codigo,
      insumos: this.vm.insumos.filter((i) => i.volumeId === v.volumeId),
      index: ++index,
    }));
    this.vm.pedido = {
      numeroPedido: data.numeroPedido,
      clienteNome: data.clienteNome,
      transportadoraNome: data.transportadoraNome,
      transportadoraCodigo: data.transportadoraCodigo,
      kitDescricao: data.kitDescricao,
      dataProducao: data.dataProducao,
      status: data.linhaProducao1Status.toUpperCase(),
      bloqueioStatus: data.bloqueioStatus,
      pedidoId: data.pedidoId,
    };
    // Check tipoInsumoId has Pallet
    this.hasPallet(this.vm.insumos);
  }

  hasPallet(insumos) {
    insumos.forEach(async (insumo) => {
      if (insumo.tipoInsumoId != this.vm.tipoInsumoId) {
        this.vm.tipoInsumoId = insumo.tipoInsumoId;
        this.vm.painelNameList = [
          ...this.vm.painelNameList,
          insumo.insumoDescricao,
        ];
        let response = await this.pedidoWebRepository.checkTipoInsumoId(
          this.vm.tipoInsumoId
        );
        console.log(response);
        //Não remover "!"
        this.vm.modalAlertPainel = !response.data.data;
      }
    });
  }

  async enviarInsumoBipadoLinhaPainel(body) {
    const response = await this.pedidoWebRepository.biparInsumoPaineis(body);
    this.vm.insumosBipados.length = 0;
    return response;
  }

  async requestMoverInsumoPedido(body) {
    const numeroPedido = this.vm.$route.params.numped;
    const response = await this.pedidoWebRepository.moverInsumoPaineis(
      numeroPedido,
      body
    );
    if (response.status === 200) {
      await Swal.fire({
        icon: "success",
        text: "Insumo movido com sucesso",
      });
      await this.vm.$router.go();
    } else {
      await Swal.fire({
        icon: "error",
        text: response.data.message,
      });
    }
  }

  async requestCriarVolumePedido() {
    const numeroPedido = this.vm.$route.params.numped;
    let body = {
      sspedidoNumSeqList: this.vm.numSeqBipados,
      linha: 1,
    };
    const response = await this.pedidoWebRepository.criarVolumePaineis(
      numeroPedido,
      body
    );
    console.log(response);
    if (response.status === 200) {
      await Swal.fire({
        icon: "success",
        text: "Caixa fechada com sucesso",
      });
      await window.location.reload();
    }
  }
}

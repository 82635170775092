import { HttpRequest } from "./HttpRequest";
import { HttpFileRequestService } from "./HttpFileRequestService";
import { getJwtToken } from "@/services/jwtToken";
import swal from "sweetalert2";

export class HttpRestService {
  static get(url, queryStrings = {}) {
    Object.keys(queryStrings)
      .filter((key) => ![null, ""].includes(queryStrings[key]))
      .forEach((key, i) => {
        url += ["?", "&"][+!!i] + `${key}=${encodeURI(queryStrings[key])}`;
      });
    return HttpRequest.build()
      .setMethod("GET")
      .setUrl(encodeURI(url))
      .request();
  }

  static post(url, data) {
    return HttpRequest.build()
      .setMethod("POST")
      .setUrl(url)
      .setBody(data)
      .request();
  }

  static postGetFile(url, data) {
    return HttpFileRequestService.build()
      .setMethod("POST")
      .setUrl(url)
      .setBody(data)
      .request();
  }

  static patch(url, data) {
    return HttpRequest.build()
      .setMethod("PATCH")
      .setUrl(url)
      .setBody(data)
      .request();
  }

  static put(url, data) {
    return HttpRequest.build()
      .setMethod("PUT")
      .setUrl(url)
      .setBody(data)
      .request();
  }

  static delete(url) {
    return HttpRequest.build().setMethod("DELETE").setUrl(url).request();
  }

  static async postFile(url, data) {
    const headers = new Headers();

    headers.append("Authorization", "Bearer " + getJwtToken());

    const requestOptions = {
      method: "POST",
      body: data,
      headers: headers,
    };

    return await fetch(HttpRequest.build().baseUrl.concat(url), requestOptions)
      .then(async (response) => {
        return response.json().then((value) => {
          console.log(value);
          return value;
        });
      })
      .catch(function (error) {
        swal.fire("Não foi possível cadastrar os dados!", "", "error");
        console.error(error);
      });
  }
}

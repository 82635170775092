import { HttpRestService } from "@/services/http/HttpRestService";

export default class BipagemInsumosRepository {
  static build() {
    return new BipagemInsumosRepository();
  }

  painel(body) {
    return HttpRestService.post(`/api/v2/bipagem-insumo/painel`, body);
  }

  diversos(body) {
    return HttpRestService.post(`/api/v2/bipagem-insumo/diversos`, body);
  }

  cabo(body) {
    return HttpRestService.post(`/api/v2/bipagem-insumo/cabo`, body);
  }

  perfil(body) {
    return HttpRestService.post(`/api/v2/bipagem-insumo/perfil`, body);
  }

  deleteBipagem({ insumoId }) {
    return HttpRestService.delete(`/api/v2/bipagem-insumo/${insumoId}`);
  }

  inversores(body) {
    return HttpRestService.post(`/api/v2/bipagem-insumo/inversores`, body);
  }
}

<template>
  <div>
    <div class="content">
      <div class="box-content">
        <h3 class="text-center">Funções - Novo Registro</h3>

        <form @submit.prevent="newReg">
          <div class="form-group">
            <label>Nome</label>
            <input type="text" class="form-control" v-model="role.NAME" />
          </div>
          <div class="form-group">
            <label>Obs</label>
            <input type="text" class="form-control" v-model="role.OBS" />
          </div>
          <button type="submit" class="btn btn-primary">Salvar >></button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "../../mixins/routesValidationMixin";
import RolesRepository from "@/repositories/v1/RolesRepository";

export default {
  components: {},
  data() {
    return {
      role: {},
      rolesRepository: RolesRepository.build(),
    };
  },
  mixins: [routesValidationMixin],
  methods: {
    newReg() {
      this.rolesRepository = RolesRepository.build();
      this.rolesRepository
        .postRole(this.role)
        .then(() => {
          this.$router.push({ name: "roles", query: { res: "add" } });
        })
        .catch(function (error) {
          window.location.href = "/dashboard";
          console.error(error);
        });
    },
  },
};
</script>

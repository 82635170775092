import PainelBase from "./PainelBase";

export default class PainelOsdaSolarMono460WHalfCells extends PainelBase {
  getCode = () => 25985;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 25985 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 25985 and numerosseriecontrolados is not null)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') percent from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 25985 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 25985 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 7) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 25985
                               and numerosseriecontrolados is not null
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +------+---------+---------+-------+
                    |PREFIX|Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +------+---------+---------+-------+
                    |66854 |7025     |378      |99%    |
                    |16006 |74       |3        |1%     |
                    |18486 |1        |1        |0%     |
                    |31854 |1        |1        |0%     |
                    |60060 |1        |1        |0%     |
                    |68549 |1        |1        |0%     |
                    +------+---------+---------+-------+
                Interpretação:
                    Essa consulta devolve todos os prefixos de 5 dígitos do insumo em questão, mostrando
                    6 resultados. Perceba que a inicial com relevância é 66854.
                    Portanto, vamos considerar que esse painel sempre comece com essa sequência.
            Tipagem:
                Consulta:
                    select
                        s.numerosseriecontrolados,
                        s.NUMPED
                    from sspedido s
                    where
                          s.codprodmp = 25985
                      and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
                Essa consulta mostra que os seriais são formados por números e letras.
            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 25985 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 25985 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 25985
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |NULL  |0        |0        |
                    |23    |1        |1        |
                    |22    |1        |1        |
                    |19    |1        |1        |
                    |18    |6808     |373      |
                    |17    |291      |28       |
                    |16    |1        |1        |
                    +------+---------+---------
                Interpretação:
                    Perceba que a quantidade de seriais com maior relevância está entre 17 e 18 caracteres. Aparentemente,
                    não existe regularidade de lenght fora desse range.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 66854 mais 12-13 dígitos alfanuméricos, totalizando entre 17 e 18 caracteres.
         */
    return !!this.serial.match(/^66854(\d|\w){12,13}$/);
  }

  static build() {
    return new PainelOsdaSolarMono460WHalfCells();
  }
}

<template>
  <div>
    <div class="cordefundo">
      <div class="bv-example-row px-3">
        <div style="font-size: 30px">
          <b-row class="mb-7"
            ><b-col cols="4" class="p-3 mt-4">
              <span> Linha 5 </span>
            </b-col>
          </b-row>
        </div>
        <!-- <div class="top"> -->
        <b-row class="py-3 top">
          <b-col sm="auto"><span>Pedido: </span>{{ numeroPedido }}</b-col>
          <b-col sm="auto" class="text-auto py-2"
            ><span>Data:</span> {{ dataProducao }}</b-col
          >
        </b-row>

        <b-row class="py-3 top">
          <b-col sm="auto"><span>Cliente: </span>{{ nomeCliente }}</b-col>
        </b-row>

        <b-row class="py-3 top">
          <b-col sm="auto">
            <span> Transportadora:</span>
            {{ transportadora }}</b-col
          >
          <b-col sm="auto" class="text-auto py-2"
            ><span>Status:</span> {{ linhaStatus }}</b-col
          >
        </b-row>
        <b-row class="py-3 top">
          <b-col sm="12" md="12" lg="12" xl="12" class="text-right py-2">
            <span>
              Quantidade de caixas:
              <span style="color: #ff7d27">
                {{ quantidadeCaixas }}
              </span>
            </span>
          </b-col>
        </b-row>
        <!-- </div> -->
      </div>
    </div>
    <div class="py-3">
      <div class="cordefundo bv-example-row py-2">
        <b-row
          v-for="(insumo, i) in insumos"
          :key="i"
          align-h="start"
          class="top mx-4 my-2 mx-sm-1 my-sm-1 mx-md-1 my-md-1 mx-lg-1 my-lg-1 mx-xl-1 my-xl-1 justify-content-start"
        >
          <b-col
            cols="12"
            sm="5"
            md="7"
            lg="8"
            xl="8"
            align-self="center"
            class="d-flex"
          >
            <h5 class="font-weight-bold">{{ insumo.descricao }}:</h5>
          </b-col>
          <b-col
            cols="6"
            sm="3"
            md="3"
            lg="2"
            xl="2"
            align-self="center"
            class="insumoQuantidade d-flex justify-content-center"
            :style="`background: ${
              insumo.quantidadeRestante > 0
                ? insumo.quantidade === insumo.quantidadeRestante
                  ? 'lightcoral'
                  : '#FFD700'
                : '#38BB3E'
            }`"
          >
            <span
              class="insumoQuantidade"
              :style="`background: ${
                insumo.quantidadeRestante > 0
                  ? insumo.quantidade === insumo.quantidadeRestante
                    ? 'lightcoral'
                    : '#FFD700'
                  : '#38BB3E'
              }`"
            >
              {{ insumo.quantidadeRestante }}/{{ insumo.quantidade }}
              restantes
            </span>
          </b-col>
          <b-col
            cols="6"
            sm="4"
            md="2"
            lg="2"
            xl="2"
            align-self="center"
            class="justify-content-end text-end"
          >
            <b-button
              block
              class="default-button"
              style="padding: 0.1rem !important"
              v-if="insumo.descricao.match(descricaoMesa)"
              :disabled="insumo.bipado || isLoading"
              @click="separaMesa(insumo.insumoId)"
            >
              <span v-if="!isLoading">Separar Mesa</span>
              <b-spinner v-if="isLoading" small></b-spinner>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <modal-mesas :modalAlert="modalAlert" />
  </div>
</template>

<script>
import { MesasService } from "@/services/pedido_web_producao/MesasService";
import ModalMesas from "./ModalMesas";

export default {
  name: "CabecalhoLinhaInversores",
  components: { ModalMesas },
  data() {
    return {
      isLoading: false,
      descricaoMesa: "MESA",
      pedidoId: 0,
      insumoId: 0,
      modalAlert: {},
      mesasService: MesasService.build(),
    };
  },
  mounted() {
    this.mesasService = MesasService.build().setVm(this);
  },
  props: {
    numeroPedido: {
      type: Number,
      required: true,
    },
    nomeCliente: {
      type: String,
      required: true,
    },
    transportadora: {
      type: String,
      required: true,
    },
    dataProducao: {
      type: String,
      required: true,
    },
    linhaStatus: {
      type: String,
      required: true,
    },
    quantidadeCaixas: {
      type: Number,
      required: true,
    },
    insumos: {
      type: Array,
      required: true,
    },
  },
  // computed: {
  //   listeners() {
  //     return {
  //       ...this.$listeners,
  //       click: this.onClick,
  //     };
  //   },
  // },
  // methods: {
  //   onClick(evt) {
  //     this.$emit("click", evt);
  //   },
  //   // tratarString(str) {
  //   //   let substituirStr = str.toLocaleLowerCase();
  //   //   let novaStr = substituirStr[0].toUpperCase() + substituirStr.substr(1);
  //   //   return novaStr;
  //   // },
  // },
  methods: {
    separaMesa(insumoId) {
      this.pedidoId = this.numeroPedido;
      this.insumoId = insumoId;
      this.mesasService.separarMesa();
    },
  },
};
</script>

<style scoped>
.default-button {
  text-align: center;
  padding: 0.625rem;
  outline: none;
  border-radius: 1rem;
  font-weight: bold !important;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
  border: none;
  transition: background-color, padding 0.15s;
  background-color: #cc5d02;
  color: white;
}

.default-button-disabled {
  cursor: context-menu;
  pointer-events: none;
  background-color: #e9e9e9 !important;
  color: #252525 !important;
}

.default-button:hover {
  background-color: #e6781e;
  color: white;
  transition: 300ms all ease-in-out;
}

.insumoQuantidade {
  color: #002233;
  padding: 3px;
  border-radius: 10px;
}

.cordefundo {
  background: #f2f4f5;
  color: #002233;
}
.linha1 {
  font-family: sans-serif;
  font-size: 30px;
}
.top {
  font-family: sans-serif;
  font-size: 17px;
  line-height: 10px;
}
.qLinha {
  font-size: 20px;
}
</style>

import { getJwtToken } from "../jwtToken";
import router from "../../router";
import env from "../../env";

export class HttpFileRequestService {
  url = "";
  token = "";
  method = "";
  body = {};
  baseUrl = "";

  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  static getBaseUrl() {
    return env.baseUrl;
  }

  static build() {
    return new HttpFileRequestService(env.baseUrl);
  }

  setMethod(method) {
    this.method = method;
    return this;
  }

  setUrl(url) {
    this.url = url;
    return this;
  }

  setBody(body) {
    this.body = body;
    return this;
  }

  async request(fileFormat, fileName) {
    // console.group(`Requisição ${this.method} para ${this.url}`);
    this.setToken();
    await this.evaluateToken();
    const { baseUrl, url } = this;
    const requestUrl = baseUrl + url;
    const options = this.getOptions();
    return fetch(requestUrl, options)
      .then(this.treatResponseFunction(fileFormat, fileName))
      .catch((e) => console.error(e))
      .finally(() => console.groupEnd);
  }

  setToken() {
    this.token = getJwtToken();
  }

  async evaluateToken() {
    if (!this.token && router.app.$route.name !== "Login") {
      // await router.replace("/");
    }
  }

  getOptions() {
    const { method, body } = this;
    const redirect = "follow";
    const headers = this.getHeaders();
    const options = { method, headers, redirect };
    const jsonBody = JSON.stringify(body);
    if (jsonBody !== "{}") {
      options.body = jsonBody;
    }
    return options;
  }

  getHeaders() {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${this.token}`);
    headers.append("Content-Type", "application/json");
    return headers;
  }

  treatResponseFunction(fileFormat = "pdf", fileName = "arquivo") {
    return async (response) => {

      if (response.status === 200) {
      const requestBlob = await response.blob();
      const newBlob = new Blob([requestBlob]);

      const blobUrl = window.URL.createObjectURL(newBlob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${fileName}.${fileFormat}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(blobUrl);
      return response;
      }
      return await response.json();
    };
  }
}

import PainelBase from "./PainelBase";

export default class PainelResunMono545W extends PainelBase {
  getCode = () => 22938;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 22938 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 22938 and numerosseriecontrolados is not null)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') percent from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 22938 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 22938 and substr(s.numerosseriecontrolados, 1, 7) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 7) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 22938
                               and numerosseriecontrolados is not null
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +--------+---------+---------+-------+
                    |PREFIX  |Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +--------+---------+---------+-------+
                    |RS8I545M|25110    |869      |97%    |
                    |RS81545M|323      |7        |1%     |
                    |rs8i545m|299      |7        |1%     |
                    |S8I545M-|34       |10       |0%     |
                    |12107180|19       |3        |0%     |
                    |6102107D|15       |4        |0%     |
                    |8I545M-2|13       |5        |0%     |
                    |20210702|4        |1        |0%     |
                    |I545M-20|4        |3        |0%     |
                    |35002551|2        |1        |0%     |
                    |5M-20210|2        |2        |0%     |
                    |RS8=545M|2        |2        |0%     |
                    |18582104|2        |1        |0%     |
                    |1RS8I545|2        |2        |0%     |
                    |10300006|2        |1        |0%     |
                    |35001914|1        |1        |0%     |
                    |20217020|1        |1        |0%     |
                    |RS8I545&|1        |1        |0%     |
                    |RS8IWV5M|1        |1        |0%     |
                    |RS8H545M|1        |1        |0%     |
                    |60000323|1        |1        |0%     |
                    |45M-2021|1        |1        |0%     |
                    |+RS8I545|1        |1        |0%     |
                    |RS8i545M|1        |1        |0%     |
                    |RS8154  |1        |1        |0%     |
                    |18582101|1        |1        |0%     |
                    |9RS8I545|1        |1        |0%     |
                    |12107191|1        |1        |0%     |
                    |M-202107|1        |1        |0%     |
                    |RS8I1U5M|1        |1        |0%     |
                    |RSK545M-|1        |1        |0%     |
                    |RS8IO45M|1        |1        |0%     |
                    |18582103|1        |1        |0%     |
                    |RS8Is8I5|1        |1        |0%     |
                    |RR8I545M|1        |1        |0%     |
                    |RS81645M|1        |1        |0%     |
                    |21210702|1        |1        |0%     |
                    |RS8I5452|1        |1        |0%     |
                    |ES8I545M|1        |1        |0%     |
                    |60000263|1        |1        |0%     |
                    |62001442|1        |1        |0%     |
                    |12108050|1        |1        |0%     |
                    |RS8B545M|1        |1        |0%     |
                    |18582102|1        |1        |0%     |
                    |RS8I8V5M|1        |1        |0%     |
                    |RS)I545M|1        |1        |0%     |
                    |02132009|1        |1        |0%     |
                    |RSHI545M|1        |1        |0%     |
                    |18582105|1        |1        |0%     |
                    |8012107D|1        |1        |0%     |
                    |545M-202|1        |1        |0%     |
                    |RS8I5N5M|1        |1        |0%     |
                    |RS8I54M2|1        |1        |0%     |
                    |12108020|1        |1        |0%     |
                    |RS807020|1        |1        |0%     |
                    +--------+---------+---------+-------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 8 dígitos do insumo em questão, mostrando
                    55 resultados. Perceba que a inicial com relevância é RS8I545M.
                    Portanto, vamos considerar que esse painel sempre comece com essa sequência.
            Tipagem:
                Como a própria inicial já contém números e letras, entende-se que a tipagem já é alfanumérica.
            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 22938 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 22938 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 22938
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |22    |25615    |874      |
                    |23    |67       |26       |
                    |21    |53       |25       |
                    |24    |31       |12       |
                    |14    |24       |8        |
                    |18    |22       |5        |
                    |20    |19       |10       |
                    |8     |6        |3        |
                    |13    |6        |2        |
                    |19    |5        |4        |
                    |25    |4        |3        |
                    |26    |4        |3        |
                    |16    |2        |2        |
                    |12    |2        |2        |
                    |44    |2        |2        |
                    |61    |1        |1        |
                    |17    |1        |1        |
                    |27    |1        |1        |
                    |39    |1        |1        |
                    |9     |1        |1        |
                    |10    |1        |1        |
                    |28    |1        |1        |
                    |29    |1        |1        |
                    |15    |1        |1        |
                    |6     |1        |1        |
                    |11    |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com maior relevância está em 22 caracteres. Aparentemente,
                    não existe regularidade de lenght fora desse range.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais RS8I545M mais 14 dígitos alfanuméricos, totalizando 22 caracteres.
         */
    return !!this.serial.match(/^RS8I545M(\d|\w|-){14}$/);
  }

  static build() {
    return new PainelResunMono545W();
  }
}

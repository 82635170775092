export default {
  getRoles(state) {
    return state.roles;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getPagination(state) {
    return state.pagination;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
};

import PainelBase from "./PainelBase";

export default class PainelCanadianMono450W extends PainelBase {
  getCode = () => 19916;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 19916 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    select
                        prefix,
                        (select count(*) from sspedido s where s.codprodmp = 19916 and  substr(s.numerosseriecontrolados, 1, 4) = prefix) quantidade_insumos,
                        (select count(distinct s.numped) from sspedido s where s.codprodmp = 19916 and  substr(s.numerosseriecontrolados, 1, 4) = prefix) quantidade_pedidos
                    from (
                        select
                            distinct substr(numerosseriecontrolados, 1, 4) as prefix
                        from SSPEDIDO s
                        where s.codprodmp = 19916
                    ) order by quantidade_insumos desc
                Resultado:
                    +------+------------------+------------------+
                    |PREFIX|QUANTIDADE_INSUMOS|QUANTIDADE_PEDIDOS|
                    +------+------------------+------------------+
                    |1210  |11519             |651               |
                    |2101  |7                 |3                 |
                    |1016  |6                 |2                 |
                    |0160  |5                 |2                 |
                    |LRP6  |4                 |1                 |
                    |2102  |4                 |1                 |
                    |1600  |3                 |2                 |
                    |U624  |2                 |1                 |
                    |0215  |2                 |1                 |
                    |182P  |1                 |1                 |
                    |1858  |1                 |1                 |
                    |RP60  |1                 |1                 |
                    |2104  |1                 |1                 |
                    |1001  |1                 |1                 |
                    |2160  |1                 |1                 |
                    |LRA6  |1                 |1                 |
                    |0931  |1                 |1                 |
                    |1021  |1                 |1                 |
                    |9210  |1                 |1                 |
                    |2108  |1                 |1                 |
                    |BRSO  |1                 |1                 |
                    |3824  |1                 |1                 |
                    |NULL  |0                 |0                 |
                    +------+------------------+------------------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 4 dígitos do insumo em questão, mostrando
                    23 resultados. Perceba que apenas a inicial 1210 possui resultados consideráveis. Os
                    outros muito provavelmente são erros de bipagem.
            Tipagem:
                Foi verificado que todos os seriais desse painel são numéricos. Consulta:
                    select
                        s.numerosseriecontrolados
                    from sspedido s
                    where
                        s.codprodmp = 19916
                        and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
                Apenas de essa consulta retornar números de série com caracteres alfanuméricos, são tão poucos
                que podemos considerar como erro de bipagem.

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 19916 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 19916 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 19916
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |14    |11508    |651      |
                    |13    |9        |5        |
                    |12    |8        |4        |
                    |15    |8        |5        |
                    |16    |6        |4        |
                    |11    |5        |5        |
                    |17    |5        |3        |
                    |21    |4        |2        |
                    |18    |4        |3        |
                    |22    |2        |2        |
                    |10    |1        |1        |
                    |19    |1        |1        |
                    |8     |1        |1        |
                    |6     |1        |1        |
                    |20    |1        |1        |
                    |7     |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com 14 dígitos corresponde a 99% do total. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1210 mais 10 dígitos numéricos, totalizando 14 dígitos.
         */
    return !!this.serial.match(/^1210\d{10}$/);
  }

  static build() {
    return new PainelCanadianMono450W();
  }
}

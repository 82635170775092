import router from "../../router";
import { HttpRestService } from "../http/HttpRestService";

export default class ContinuacaoProducaoPedidoDiversos {
  numeroPedido = 0;
  vm;
  static build() {
    return new ContinuacaoProducaoPedidoDiversos();
  }

  setNumeroPedido(numeroPedido) {
    this.numeroPedido = numeroPedido;
    return this;
  }

  setTipoPedido(tipoPedido) {
    this.tipoPedido = tipoPedido;
    return this;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async continuarProducaoPedidoDiversos() {
    try {
      //const url = `/api/v2/insumos/pedido/${this.numeroPedido}/relacao-insumos-quantidade`;
      console.log(this.numeroPedido);
      const url = `/api/v2/pedido-diversos/${this.numeroPedido}`;
      const response = await HttpRestService.post(url);
      console.log(response);
      const { status } = response;
      if (status === 200) {
        await router.replace({
          name: "pedidoWebProducaoSelecaoPaineis",
          params: { numped: this.numeroPedido },
          query: {
            tipoPedido: this.tipoPedido,
          },
        });
      }
    } catch (e) {
      alert("Erro ao iniciar o pedido!");
    }
  }

  async continuarProducaoPedidoDiversosExpress() {
    try {
      //const url = `/api/v2/insumos/pedido/${this.numeroPedido}/relacao-insumos-quantidade`;
      console.log(this.numeroPedido);
      const url = `/api/v2/pedido-diversos/${this.numeroPedido}`;
      const response = await HttpRestService.post(url);
      console.log(response);
      const { status } = response;
      if (status === 200) {
        await router.replace({
          name: "PedidoWebProducaoSelecaoLinhasExpress",
          params: { numped: this.numeroPedido },
          query: {
            tipoPedido: this.tipoPedido,
          },
        });
      }
    } catch (e) {
      alert("Erro ao iniciar o pedido!");
    }
  }
}

import { HttpRestService } from "@/services/http/HttpRestService";

export class VolumeRepository {
  static build() {
    return new VolumeRepository();
  }
  getBtnGerarEtiqueta(params) {
    return HttpRestService.get(`/api/v2/etiqueta/${params}`);
  }
  volume() {
    return HttpRestService.post(`/api/v2/volume/atualizar`);
  }
  moverInsumoDeVolumeLinhaPaineis({
    numeroPedido,
    insumoId,
    volumeIdOrigem,
    volumeIdDestino,
  }) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/mover-insumo/linha/paineis`,
      {
        insumoId,
        volumeIdOrigem,
        volumeIdDestino,
      }
    );
  }

  moverInsumoDeVolumeLinhaCabos({
    numeroPedido,
    insumoId,
    volumeIdOrigem,
    volumeIdDestino,
  }) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/mover-insumo/linha/cabos`,
      {
        insumoId,
        volumeIdOrigem,
        volumeIdDestino,
      }
    );
  }

  moverInsumoDeVolumeLinhaDiversos({
    numeroPedido,
    insumoId,
    volumeIdOrigem,
    volumeIdDestino,
  }) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/mover-insumo/linha/diversos`,
      {
        insumoId,
        volumeIdOrigem,
        volumeIdDestino,
      }
    );
  }
  moverInsumoDeVolumeLinhaInversores({
    numeroPedido,
    insumoId,
    volumeIdOrigem,
    volumeIdDestino,
  }) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/mover-insumo/linha/inversor`,
      {
        insumoId,
        volumeIdOrigem,
        volumeIdDestino,
      }
    );
  }

  moverInsumoDeVolumeLinhaPerfis({
    numeroPedido,
    insumoId,
    volumeIdOrigem,
    volumeIdDestino,
  }) {
    return HttpRestService.post(
      `/api/v2/pedido-web/${numeroPedido}/mover-insumo/linha/perfis`,
      {
        insumoId,
        volumeIdOrigem,
        volumeIdDestino,
      }
    );
  }
}

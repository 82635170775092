import { HttpRestService } from "@/services/http/HttpRestService";

export default class MesasRepository {
  static build() {
    return new MesasRepository();
  }

  separarMesa(body) {
    return HttpRestService.post(`/api/v2/volume/mesa`, body);
  }
}

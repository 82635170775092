<template>
  <div>
    <table>
      <tbody v-for="(insumo, x) in insumos" :key="x">
        <tr class="itens">
          <th>
            <div class="mt-2" style="font-size: 20px">
              {{ insumo.insumoDescricao }} -
              {{ `${insumo.index}/${totalInsumos}` }}
            </div>
            <div class="mt-2">
              <input
                type="text"
                :class="
                  'prod-cod item-bipagem insumo-' +
                  insumo.insumoId +
                  (insumo.bipado ? ' insumoBipado' : '') +
                  (insumo.loading ? ' input-loading' : '')
                "
                @keyup.enter="validarBipagem($event, x, insumo)"
                :value="insumo.serial"
                :disabled="insumo.bipado || insumo.loading || loading"
              />
            </div>
          </th>
          <th>
            <div v-if="!insumo.bipado">
              <b-button
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
              >
                <b-icon icon="check-circle" scale="2"></b-icon>
              </b-button>
            </div>
            <div v-else>
              <b-button
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
              >
                <b-icon
                  scale="2"
                  icon="check-circle-fill"
                  variant="success"
                ></b-icon>
              </b-button>
            </div>
          </th>
          <th>
            <b-button
              v-if="insumo.bipado && !detalhes"
              aria-pressed="false"
              size="lg"
              variant="transparent"
              class="mt-4 rounded-circle p-2"
              @click="limparValorDaBipagem($event, insumo.insumoId, x)"
            >
              <b-icon icon="x-circle" scale="2" variant="danger"></b-icon>
            </b-button>
          </th>
          <th>
            <div v-if="insumo.bipado && insumo.serial !== ''">
              <b-button
                v-if="false"
                :disabled="
                  !permiteBotaoMoverInsumo || insumo.bipado || insumo.loading
                "
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
                aria-pressed="false"
                @click="modalMoverInsumo(insumo.insumoId, insumo.volumeId)"
              >
                <b-iconstack variant="transparent" font-scale="1.3">
                  <b-icon
                    stacked
                    scale="2"
                    icon="arrows-move"
                    variant="warning"
                  ></b-icon>
                  <b-icon
                    stacked
                    scale="0.3"
                    icon="circle-fill"
                    variant="warning"
                  ></b-icon>
                </b-iconstack>
              </b-button>
            </div>
            <!--            <div v-else>-->
            <!--              <b-button-->
            <!--                size="lg"-->
            <!--                variant="transparent"-->
            <!--                class="mt-4 rounded-circle p-2"-->
            <!--                aria-pressed="false"-->
            <!--                @click="modalMoverInsumo(insumo.insumoId, insumo.volumeId)"-->
            <!--              >-->
            <!--                <b-iconstack variant="transparent" font-scale="1.3">-->
            <!--                  <b-icon-->
            <!--                    stacked-->
            <!--                    scale="2"-->
            <!--                    icon="arrows-move"-->
            <!--                    variant="warning"-->
            <!--                  ></b-icon>-->
            <!--                  <b-icon-->
            <!--                    stacked-->
            <!--                    scale="0.3"-->
            <!--                    icon="circle-fill"-->
            <!--                    variant="warning"-->
            <!--                  ></b-icon>-->
            <!--                </b-iconstack>-->
            <!--              </b-button>-->
            <!--            </div>-->
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { BipagemService } from "../../../../../services/pedido_web_producao/linha_paineis/BipagemService";
import { UsuarioLogado } from "../../../../../services/login/UsuarioLogado";
import BipagemInsumosRepository from "../../../../../repositories/v2/BipagemInsumoRepository";
export default {
  components: {},
  name: "InsumoCard",
  data: () => ({
    bodyMoverInsumo: {},
    bipagemService: BipagemService.build(),
    bipagemRepository: BipagemInsumosRepository.build(),
  }),
  async created() {
    this.bipagemService = BipagemService.build().setVm(this);
  },
  props: {
    volumes: { type: Array, required: true },
    insumos: { type: Array, required: true },
    indexVol: { type: Number, required: true },
    totalInsumos: { type: Number, required: true },
    loading: { type: Boolean, required: true },
    detalhes: { type: Boolean, default: false },
  },
  computed: {
    permiteBotaoMoverInsumo() {
      return UsuarioLogado.getPerfil().includes("administrador");
    },
  },
  methods: {
    modalMoverInsumo(insumoId, volumeId) {
      let bodyMoverInsumo = {
        insumoIdNumber: insumoId,
        indexVol: this.indexVol,
        volumeId: volumeId,
      };
      this.$emit("abrirModalMoverInsumo", bodyMoverInsumo);
      this.$bvModal.show("modal-mover-insumo");
    },

    async limparValorDaBipagem(event, insumoId) {
      const { target } = event;
      const serial = target.value;
      target.value = "";
      for (let i = 0; i < this.insumos.length; i++) {
        const insumo = this.insumos[i];
        if (insumo.insumoId === insumoId) {
          insumo.bipado = false;
          insumo.serial = "";
        }
      }
      target.focus();
      this.bipagemRepository.deleteBipagem({ insumoId }).then(({ status }) => {
        if (status === 200) return;
        for (let i = 0; i < this.insumos.length; i++) {
          const insumo = this.insumos[i];
          if (insumo.insumoId === insumoId) {
            insumo.bipado = true;
            insumo.serial = serial;
          }
        }
      });
    },

    async validarBipagem(event, index, insumo) {
      this.insumos[index].serial = event.target.value;
      let bodyValidarBipagem = {
        insumo: insumo,
        event: event,
        index: index,
      };
      await this.$emit("validarBipagem", bodyValidarBipagem);
    },
  },
};
</script>
<style scoped>
.insumoBipado {
  border: 2px solid green;
  border-radius: 10px;
}

.input-loading {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
}
</style>

import PainelBase from "./PainelBase";

export default class PainelGclMonoHalfCell440W extends PainelBase {
  getCode = () => 6484;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 6484 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Segundo a consulta "select numerosseriecontrolados from sspedido where codprodmp = 6484",
            só existem quatro painéis desse tipo no sistema. Portanto, essa amostra será desoconsiderada.
            e quando houver alguma bipagem desse painel novamente, o sistema deixará passar sem validação.
         */
    return true;
  }

  static build() {
    return new PainelGclMonoHalfCell440W();
  }
}

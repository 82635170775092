import Repository from "../repository";

export default {
  async groundMounts({ commit }) {
    commit("setIsLoadingGroundMounts", true);
    commit("setGroundMounts", []);
    try {
      const response = await Repository.getGroundMounts();
      if (response.status === 200) {
        const groundMounts = response.data.data;
        commit("setGroundMounts", groundMounts);
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoadingGroundMounts", false);
    }
  },
  async editGroundMount({ commit, dispatch }, groundMount) {
    try {
      const response = await Repository.editGroundMount(groundMount);
      if (response.status === 200) {
        dispatch("groundMounts");
        commit(
          "notifications/setSuccessNotification",
          { successStatus: true, successMessage: response.data.message },
          { root: true }
        );
        return response.status;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response.data.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    }
  },
};

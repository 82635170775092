<template>
  <div class="listing-header">
    <b-row>
      <b-col
        cols="12"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0 listing-title"
      >
        <span v-if="isManutencao">
          <h3 class="font-weight-bold">
            {{ title }}
          </h3>
        </span>
      </b-col>
      <b-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0"
        v-if="etiquetaBtn"
      >
        <default-button
          name="Etiqueta"
          :color="etiquetaColor"
          :disabled="!etiquetaEnabled"
          @click="clickEtiqueta"
          v-if="role == 'ADMIN'"
        ></default-button>
      </b-col>
      <b-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0"
        v-if="uploadBtn"
      >
        <default-button
          name="Upload"
          :color="uploadColor"
          :disabled="!uploadEnabled"
          @click="clickUpload"
        ></default-button>
      </b-col>
      <b-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0"
        v-if="agendarBtn"
      >
        <default-button
          name="Agendar"
          :color="agendarColor"
          :disabled="!agendarEnabled"
          @click="clickAgendar"
        ></default-button>
      </b-col>
      <b-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0"
        v-if="relatorioEnabled"
      >
        <default-button
          name="Relatorio"
          :color="relatorioColor"
          @click="clickRelatorio()"
        ></default-button>
      </b-col>
      <b-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0"
        v-if="selecionarBtn"
      >
        <default-button
          name="Selecionar Todos"
          :color="selecionarColor"
          :disabled="!selecionarEnabled"
          @click="clickSelecionar"
        ></default-button>
      </b-col>
    </b-row>
    <b-row class="mt-3" align-h="center">
      <b-col cols="11" sm="5" md="5" lg="2" xl="2" class="ml-3 mr-3">
        <b-row>
          <label>Número do pedido</label>
        </b-row>
        <b-row>
          <input
            v-model="numeroPedido"
            class="filter-input"
            id="order"
            name="order"
            type="text"
          />
        </b-row>
      </b-col>
      <b-col cols="11" sm="5" md="5" lg="2" xl="2" class="ml-3 mr-3">
        <b-row>
          <label>Transportadora</label>
        </b-row>
        <b-row>
          <input
            v-model="transportadoraNome"
            class="filter-input"
            id="order"
            name="order"
            type="text"
          />
        </b-row>
      </b-col>
      <b-col cols="11" sm="5" md="5" lg="2" xl="2" class="ml-3 mr-3">
        <b-row>
          <label for="dataInicio">Data Inicial</label>
        </b-row>
        <b-row>
          <b-form-datepicker
            reset-button
            locale="pt"
            label-reset-button="Limpar"
            class="datepicker-style"
            size="sm"
            placeholder=""
            id="dataInicio"
            v-model="dataInicio"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          >
          </b-form-datepicker>
        </b-row>
      </b-col>
      <b-col cols="11" sm="5" md="5" lg="2" xl="2" class="ml-3 mr-3">
        <b-row>
          <label for="dataFinal">Data Final</label>
        </b-row>
        <b-row>
          <b-form-datepicker
            reset-button
            locale="pt"
            label-reset-button="Limpar"
            class="datepicker-style"
            size="sm"
            placeholder=""
            id="dataFinal"
            v-model="dataFinal"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          >
          </b-form-datepicker>
        </b-row>
      </b-col>
      <b-col
        cols="11"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="ml-3 mr-3"
        align-self="center"
      >
        <b-row
          class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0 d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start"
        >
          <default-button
            name="Filtrar Todos "
            :color="filtrarColor"
            v-if="filtrarAllEnabled"
            @click="clickFiltrarTodos"
            class="mb-2"
          ></default-button>
          <default-button
            name="Filtrar"
            :color="filtrarColor"
            :disabled="!filtrarEnabled"
            @click="clickFiltrar"
          ></default-button>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export { setUserLogged, getUserLogged } from "../../../services/userLogged";
import DefaultButton from "../../../components/DefaultButton";
import VisualizacaoRelatorioAguardandoProducao from "../../../services/listagem_pedidos/VisualizacaoRelatorioAguardandoProducao";

export default {
  components: {
    DefaultButton,
  },
  created() {
    this.visualizacaoRelatorioAguardandoProducao =
      VisualizacaoRelatorioAguardandoProducao.build();
  },
  beforeMount() {
    this.getRoles();
  },
  data() {
    return {
      role: "",
      transportadoraNome: "",
      numeroPedido: "",
      dataInicio: "",
      dataFinal: "",
      visualizacaoRelatorioAguardandoProducao:
        VisualizacaoRelatorioAguardandoProducao.build(),
    };
  },
  computed: {
    statusPedido() {
      return this.$parent.pedidosStatus;
    },
    statusPedidoDescricao() {
      return (
        {
          A: "Aguard. Prod. Ágil",
          T: "Conferência com problema",
          C: "Cancelados",
          L: "Em linha de produção",
          E: "Aguardando embarque",
          D: "Lotes Finalizados",
          ac: "Em conferência AC",
          N: "Aguardando conferência",
          F: "Em conferência",
          M: "Manutenção",
          O: "Pedidos Diversos",
          "": "Adm. de Pedidos",
        }[this.statusPedido] || ""
      );
    },
    permiteFiltro() {
      const { numeroPedido, dataInicio, dataFinal, transportadoraNome } = this;
      const filtroEmAcao = JSON.stringify(this.$parent.filtro);
      const filtroEmEdicao = JSON.stringify({
        numeroPedido,
        dataInicio,
        dataFinal,
        transportadoraNome,
      });
      return filtroEmEdicao !== filtroEmAcao;
    },
  },
  props: {
    title: { type: String, required: true },
    isManutencao: { type: Boolean, required: false },
    relatorioBtn: { type: Boolean, default: false },
    relatorioColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    relatorioEnabled: { type: Boolean, default: false },

    etiquetaBtn: { type: Boolean, default: false },
    etiquetaColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    etiquetaEnabled: { type: Boolean, default: true },

    uploadBtn: { type: Boolean, default: false },
    uploadColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    uploadEnabled: { type: Boolean, default: true },

    agendarBtn: { type: Boolean, default: false },
    agendarColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    agendarEnabled: { type: Boolean, default: true },

    selecionarBtn: { type: Boolean, default: false },
    selecionarColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    selecionarEnabled: { type: Boolean, default: true },

    filtrarBtn: { type: Boolean, default: false },
    filtrarColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    filtrarAllEnabled: { type: Boolean, default: false },
    filtrarEnabled: { type: Boolean, default: true },
  },
  methods: {
    getRoles() {
      this.role = window.localStorage.getItem("roles");
    },
    clickEtiqueta() {
      this.$bvModal.show("modal-etiquetas");
    },
    clickUpload() {
      this.$bvModal.show("modal-upload");
    },
    clickAgendar() {
      this.$emit("clickAgendar");
    },
    clickRelatorio() {
      this.$bvModal.show("modal-relatorio");
    },
    clickSelecionar() {
      this.$emit("clickSelecionar");
    },
    clickFiltrarTodos() {
      this.$emit("filterAll", {
        numeroPedido: this.numeroPedido,
        dataInicio: this.dataInicio,
        dataFinal: this.dataFinal,
        transportadoraNome: this.transportadoraNome,
      });
    },
    clickFiltrar() {
      this.$emit("filter", {
        numeroPedido: this.numeroPedido,
        dataInicio: this.dataInicio,
        dataFinal: this.dataFinal,
        transportadoraNome: this.transportadoraNome,
      });
    },
    dataInicioEvent() {
      this.$emit("resetDataInicio", this.dataInicio);
    },
    dataFinalEvent() {
      this.$emit("resetDataFinal", this.dataFinal);
    },
    openRelatorioAguardandoProducao() {
      this.visualizacaoRelatorioAguardandoProducao.abrirRelatorio();
    },
  },
};
</script>

<style scoped>
.datepicker-style {
  margin: 8px 0 23px;
  padding: 7px;
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  color: #353535;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 11px;
  outline: 0;
  box-sizing: border-box;
}
.listing-title {
  font-family: inherit;
  font-weight: bold;
  color: #2e2e2e;
}
.filter-input:hover {
  border: none;
  outline: 0.063rem solid #212529;
}
</style>

<template>
  <v-app-bar app color="base_dark" fixed elevation="1">
    <v-toolbar-title class="text-sm-h5 text-md-h4 font-weight-bold">
      <v-app-bar-nav-icon @click="setDrawer(!getDrawer)"
        ><template v-slot:default
          ><default-icon
            icon="mdi-menu"
            :top="false"
            :bottom="true"
            :tooltip-text="
              getDrawer ? 'Fechar Menu' : 'Abrir Menu'
            " /></template
      ></v-app-bar-nav-icon>
      {{ $route.meta.viewName ?? $route.name }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      left
      transition="slide-x-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          depressed
          color="transparent"
          class="d-flex flex-row pr-0 pl-1"
          v-bind="attrs"
          v-on="on"
        >
          <div class="text-end mt-2">
            <span class="d-block text--text text-truncate text-body-1 mb-n3">
              <span v-if="name">{{ name | firstAndLastName }}</span>
              <v-skeleton-loader v-else type="text" width="120" />
            </span>
            <span
              style="font-size: 10px !important"
              class="secondary--text text-wrap text-overline mt-5 font-weight-bold"
            >
              <span v-if="roles">{{ perfil | formatPerfil }}</span>
              <v-skeleton-loader
                v-else
                type="text"
                width="120"
                class="pl-10 mt-3"
              />
            </span>
          </div>
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>
      <v-card max-width="300" min-width="200" class="text-center">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-truncate">
                {{ name }}
              </v-list-item-title>
              <v-list-item-subtitle
                style="font-size: 10px !important"
                class="secondary--text text-wrap text-overline font-weight-bold"
              >
                {{ perfil | formatPerfil }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <!-- botão de modo claro/escuro desabilitado temporariamente -->
          <v-list-item class="mt-2 mb-2 d-flex justify-center">
            <default-fab
              disabled
              @click="setMode(!getDarkMode)"
              :tooltip-text="getDarkMode ? 'Modo Claro' : 'Modo Escuro'"
            >
              <v-icon v-if="!getDarkMode">mdi-weather-night</v-icon>
              <v-icon v-else>mdi-white-balance-sunny</v-icon>
            </default-fab>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="mt-2 d-flex justify-center font-weight-bold">
            <div @click="actionLogout" style="cursor: pointer">
              <v-icon class="mr-1">mdi-logout</v-icon>
              <span>Sair</span>
            </div>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <environment-toggler class="ml-2" />
  </v-app-bar>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { UsuarioLogado } from "@/services/login/UsuarioLogado";
import AuthRepository from "@/repositories/v2/AuthRepository";
import UsersRepository from "@/repositories/v1/UsersRepository";
import router from "../../router";
import DefaultIcon from "@/shared/components/vuetify/DefaultIcon.vue";
import DefaultFab from "@/shared/components/vuetify/DefaultFab.vue";
import EnvironmentToggler from "@/shared/components/vuetify/EnvironmentToggler.vue";
export default {
  name: "TheNavbar",
  components: {
    DefaultFab,
    DefaultIcon,
    EnvironmentToggler,
  },
  data() {
    return {
      name: "",
      roles: "",
      authRepository: AuthRepository.build(),
      usersRepository: UsersRepository.build(),
      isLoading: false,
      isPopoverOpen: false,
      menu: false,
    };
  },
  created() {
    this.authRepository = AuthRepository.build();
    this.usersRepository = UsersRepository.build();
  },
  async mounted() {
    this.setMode(this.getDarkMode);
    this.isLoading = true;
    if (UsuarioLogado.isLogged()) {
      const response = await this.usersRepository.getFuncoesByUser(
        UsuarioLogado.getUserId()
      );
      const { data } = response;
      const { name, roles } = data;
      this.name = name;
      this.roles = roles;
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions("menu", ["setDrawer", "setDarkMode"]),
    setMode(isDarkModeOn) {
      this.$vuetify.theme.dark = isDarkModeOn;
      this.setDarkMode(isDarkModeOn);
      this.menu = false;
    },
    async actionLogout() {
      Object.keys(JSON.parse(JSON.stringify(localStorage))).forEach((l) =>
        localStorage.removeItem(l)
      );
      await router.push({ name: "Login" });
      this.authRepository.logout();
    },
    rolesStringToArray(roles) {
      return roles.split(/[\s,]+/).filter(Boolean);
    },
  },
  computed: {
    ...mapGetters("menu", ["getDrawer", "getDarkMode"]),
    perfil() {
      return UsuarioLogado.getPerfil();
    },
  },
  filters: {
    firstAndLastName(value) {
      if (value.length <= 20) {
        return value;
      } else {
        const words = value.split(" ");
        const firstWord = words[0];
        const lastWord = words[words.length - 1];
        const result =
          words.length === 1 ? firstWord : `${firstWord} ${lastWord}`;
        return result.toUpperCase();
      }
    },
  },
};
</script>

<style scoped></style>

import PainelCanadianMono435W from "./PainelCanadianMono435W";
import PainelCanadianMono440W from "./PainelCanadianMono440W";
import PainelCanadianMono445W from "./PainelCanadianMono445W";
import PainelCanadianMono450W from "./PainelCanadianMono450W";
import PainelCanadianMono455W from "./PainelCanadianMono455W";
import PainelCanadianMono535W from "./PainelCanadianMono535W";
import PainelCanadianPoli400W from "./PainelCanadianPoli400W";
import PainelCanadianPoli405W from "./PainelCanadianPoli405W";
import PainelCanadianPoli420W from "./PainelCanadianPoli420W";
import PainelDahMono440W from "./PainelDahMono440W";
import PainelDahMono540W from "./PainelDahMono540W";
import PainelDahMono545W from "./PainelDahMono545W";
import PainelDahPoliHalfCell360W from "./PainelDahPoliHalfCell360W";
import PainelDahPoliHalfCell400W from "./PainelDahPoliHalfCell400W";
import PainelGcl330W from "./PainelGcl330W";
import PainelGcl335W from "./PainelGcl335W";
import PainelGcl405WMonoHalfCell from "./PainelGcl405WMonoHalfCell";
import PainelGclMono375W from "./PainelGclMono375W";
import PainelGclMonoHalfCell440W from "./PainelGclMonoHalfCell440W";
import PainelJASolar325W from "./PainelJASolar325W";
import PainelJinko280W from "./PainelJinko280W";
import PainelJinko335W from "./PainelJinko335W";
import PainelLeapton182Mono60MH460W from "./PainelLeapton182Mono60MH460W";
import PainelLeapton210Mono66MH665W from "./PainelLeapton210Mono66MH665W";
import PainelLongiMonoHalfCell440W from "./PainelLongiMonoHalfCell440W";
import PainelOsdaSolarMono460WHalfCells from "./PainelOsdaSolarMono460WHalfCells";
import PainelResunMono545W from "./PainelResunMono545W";
import PainelSunlight265W from "./PainelSunlight265W";
import PainelTrina330W from "./PainelTrina330W";
import PainelUlicaMonoHalfCut440W from "./PainelUlicaMonoHalfCut440W";
import PainelUlicaMonoHalfCut450W from "./PainelUlicaMonoHalfCut450W";
import PainelUlicaMonoHalfCut505W from "./PainelUlicaMonoHalfCut505W";
import PainelBase from "./PainelBase";

export default class SelecaoAlgoritmoValidacaoPainel {
  static build() {
    return new SelecaoAlgoritmoValidacaoPainel();
  }

  setCodigoProdutoMateriaPrima(codigoProdutoMateriaPrima) {
    this.codigoProdutoMateriaPrima = codigoProdutoMateriaPrima;
  }

  selectPainelByCodigoProdutoMateriaPrima = () => {
    const paineis = this.getPaineisStrategies();
    for (const painel of paineis) {
      if (!(painel.prototype instanceof PainelBase)) {
        console.error({ painel });
        console.error(
          `The panel ${painel.name} is not instance of ${PainelBase.name}.`
        );
        continue;
      }
      if (!painel.build) {
        console.error({ painel });
        console.error(
          `The panel ${painel.name} does not have the static method build.`
        );
        continue;
      }
      const concretePainel = painel.build();
      if (+concretePainel.getCode() === +this.codigoProdutoMateriaPrima) {
        return concretePainel;
      }
    }
    return null;
  };

  getPaineisStrategies() {
    return [
      PainelCanadianMono435W,
      PainelCanadianMono440W,
      PainelCanadianMono445W,
      PainelCanadianMono450W,
      PainelCanadianMono455W,
      PainelCanadianMono535W,
      PainelCanadianPoli400W,
      PainelCanadianPoli405W,
      PainelCanadianPoli420W,
      PainelDahMono440W,
      PainelDahMono540W,
      PainelDahMono545W,
      PainelDahPoliHalfCell360W,
      PainelDahPoliHalfCell400W,
      PainelGcl330W,
      PainelGcl335W,
      PainelGcl405WMonoHalfCell,
      PainelGclMono375W,
      PainelGclMonoHalfCell440W,
      PainelJASolar325W,
      PainelJinko280W,
      PainelJinko335W,
      PainelLeapton182Mono60MH460W,
      PainelLeapton210Mono66MH665W,
      PainelLongiMonoHalfCell440W,
      PainelOsdaSolarMono460WHalfCells,
      PainelResunMono545W,
      PainelSunlight265W,
      PainelTrina330W,
      PainelUlicaMonoHalfCut440W,
      PainelUlicaMonoHalfCut450W,
      PainelUlicaMonoHalfCut505W,
    ];
  }
}

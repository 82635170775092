export default {
  setLinha1(state, value) {
    state.linha1 = value;
  },

  setLinha2(state, value) {
    state.linha2 = value;
  },

  setLinha3(state, value) {
    state.linha3 = value;
  },

  setLinha3Conf(state, value) {
    state.linha3Conf = value;
  },

  setLinha4(state, value) {
    state.linha4 = value;
  },

  setLinha5(state, value) {
    state.linha5 = value;
  },
  setMetaDia(state, value) {
    state.metaDia = value;
  },

  setMetaHora(state, value) {
    state.metaHora = value;
  },

  setPedRealizados(state, value) {
    state.pedRealizados = value;
  },

  setPedFaturados(state, value) {
    state.pedFaturados = value;
  },

  setConfFinal(state, value) {
    state.confFinal = value;
  },

  setEmbarque(state, value) {
    state.embarque = value;
  },

  setPedAtrasados(state, value) {
    state.pedAtrasados = value;
  },

  setPloomesAguardandoProducao(state, value) {
    state.ploomesAguardandoProducao = value;
  },

  setPloomesEmLinhaProducao(state, value) {
    state.ploomesEmLinhaProducao = value;
  },

  setPloomesAguardandoEmbarque(state, value) {
    state.ploomesAguardandoEmbarque = value;
  },
};

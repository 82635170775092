<template>
  <v-app :style="`background-color: ${backgroundColor}`">
    <template v-if="areNavbarAndSidebarVisible">
      <navbar-component />
      <sidebar-component />
    </template>
    <div :data-vuetify="hasVuetify" :data-bv="!hasVuetify">
      <v-main :style="!hasVuetify ? 'display: block !important;' : ''">
        <router-view :id="hasVuetify ? 'vuetify-view' : ''" />
      </v-main>
    </div>
    <div class="overflow-hidden float-end teste">
      <error-alert />
      <success-alert />
    </div>
  </v-app>
</template>

<script>
import NavbarComponent from "./modules/menu/NavbarComponent.vue";
import SidebarComponent from "./modules/menu/SidebarComponent.vue";
import ErrorAlert from "./modules/messages/ErrorAlert.vue";
import SuccessAlert from "./modules/messages/SuccessAlert.vue";
export default {
  name: "App",
  components: {
    NavbarComponent,
    SidebarComponent,
    ErrorAlert,
    SuccessAlert,
  },
  data() {
    return {};
  },
  mounted() {
    const originalSetItem = localStorage.setItem;
    var vm = this;
    localStorage.setItem = function (key, value) {
      if (key === "token") {
        vm.isLoggedIn = !!value;
        console.log(Object.assign({}, vm));
      }
      originalSetItem.apply(this, arguments);
    };
  },
  computed: {
    hasVuetify() {
      return this.$route.meta.hasVuetify;
    },
    backgroundColor() {
      const isDarkModeOn = this.$vuetify.theme.dark;
      return isDarkModeOn
        ? this.$vuetify.theme.themes.dark.background
        : this.$vuetify.theme.themes.light.background;
    },
    areNavbarAndSidebarVisible() {
      const isLoginView = ["Login"].includes(this.$route.name);
      return !isLoginView;
    },
  },
};
</script>

<style lang="scss">
//@import "bootstrap-vue/src/components/modal/index.scss";
@charset "UTF-8";

html {
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  margin: 0;
  position: relative;
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;
  // scrollbar-color: #e6792e #343a40;
}

body {
  // background: #eef0f8;
  color: #2e2e2e;
  position: relative;
  // height: 100%;
  margin: 0;
  font-weight: bold;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  font-weight: normal;
}

.teste {
  position: absolute;
  right: 0%;
  top: 0%;
  z-index: 2000;
}

.c-sliding-pagination__list-element {
  display: inline;
  text-decoration: none;
  float: left;
  padding: 8px 16px;
  border: 1px solid #ddd;
  color: #000000;
  background-color: #ffffff;
  font-size: 1em;
}

.c-sliding-pagination__list-element--active {
  background-color: #fd7502;
}

.c-sliding-pagination__list-element--disabled {
  background-color: #fd7502;
}

a {
  color: inherit;
  outline: 0;
  text-decoration: none;
}

a.active,
a:active,
a:focus,
a:hover {
  color: inherit;
}

a:focus {
  box-shadow: 0 0 0 0.1rem #ccc;
}

ul li {
  font-size: 1.366em;
  line-height: 1.8rem;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  margin: 5px 0 5px;
}
h1.center,
h2.center,
h3.center,
h4.center {
  text-align: center;
}
h1.normal,
h2.normal,
h3.normal,
h4.normal {
  font-weight: 300;
}
h1.no-margin,
h2.no-margin,
h3.no-margin,
h4.no-margin {
  margin: 0;
}

.h1,
h1,
h1 a {
  font-size: 3rem;
  line-height: 3.5rem;
}

.h2,
h2,
h2 a {
  font-size: 2.3rem;
  line-height: 3rem;
}
@media only screen and (max-width: 600px) {
  .h2,
  h2,
  h2 a {
    font-size: 2rem;
  }
}

.h3,
h3,
h3 a {
  font-size: 1.7rem;
  line-height: 2rem;
}
@media only screen and (max-width: 600px) {
  .h3,
  h3,
  h3 a {
    font-size: 1.3rem;
  }
}

.h4,
h4,
h4 a {
  font-size: 1.6rem;
  line-height: 2rem;
}

.img-full img {
  width: 100%;
}

.img-full-99 img {
  width: 99%;
}

img {
  border-style: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
  outline: none;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="text"]:not([data-vuetify] *),
input[type="tel"],
input[type="email"],
input[type="number"],
input[type="date"],
input[type="password"]:not([data-vuetify] *),
select,
textarea {
  width: 100%;
  margin: 8px 0 23px;
  padding: 11px;
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  color: #353535;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  outline: 0;
  box-sizing: border-box;
  transition: border-color 0.2s ease-in-out;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

.hidden {
  display: none;
}

*,
::after,
::before {
  box-sizing: inherit;
}

a.full {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  text-decoration: none;
}

.grid {
  box-sizing: border-box;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  max-width: 1400px;
  width: 97.1428571429%;
}
@media screen and (max-width: 1020px) {
  .grid {
    width: 96.0784313725%;
  }
}
@media screen and (max-width: 620px) {
  .grid {
    width: 94.8387096774%;
  }
}
.grid-0 {
  box-sizing: border-box;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  max-width: 1400px;
  width: 100%;
}
.grid.full {
  width: 100%;
}
.grid.center {
  justify-content: center;
}

.col-desk-0 {
  box-sizing: border-box;
  width: 0%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-0.no-padding {
  padding: 0;
}
.col-desk-shift-0 {
  margin-left: 0%;
}
.col-desk-1 {
  box-sizing: border-box;
  width: 8.3333333333%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-1.no-padding {
  padding: 0;
}
.col-desk-shift-1 {
  margin-left: 8.3333333333%;
}
.col-desk-2 {
  box-sizing: border-box;
  width: 16.6666666667%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-2.no-padding {
  padding: 0;
}
.col-desk-shift-2 {
  margin-left: 16.6666666667%;
}
.col-desk-3 {
  box-sizing: border-box;
  width: 25%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-3.no-padding {
  padding: 0;
}
.col-desk-shift-3 {
  margin-left: 25%;
}
.col-desk-4 {
  box-sizing: border-box;
  width: 33.3333333333%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-4.no-padding {
  padding: 0;
}
.col-desk-shift-4 {
  margin-left: 33.3333333333%;
}
.col-desk-5 {
  box-sizing: border-box;
  width: 41.6666666667%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-5.no-padding {
  padding: 0;
}
.col-desk-shift-5 {
  margin-left: 41.6666666667%;
}
.col-desk-6 {
  box-sizing: border-box;
  width: 50%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-6.no-padding {
  padding: 0;
}
.col-desk-shift-6 {
  margin-left: 50%;
}
.col-desk-7 {
  box-sizing: border-box;
  width: 58.3333333333%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-7.no-padding {
  padding: 0;
}
.col-desk-shift-7 {
  margin-left: 58.3333333333%;
}
.col-desk-8 {
  box-sizing: border-box;
  width: 66.6666666667%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-8.no-padding {
  padding: 0;
}
.col-desk-shift-8 {
  margin-left: 66.6666666667%;
}
.col-desk-9 {
  box-sizing: border-box;
  width: 75%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-9.no-padding {
  padding: 0;
}
.col-desk-shift-9 {
  margin-left: 75%;
}
.col-desk-10 {
  box-sizing: border-box;
  width: 83.3333333333%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-10.no-padding {
  padding: 0;
}
.col-desk-shift-10 {
  margin-left: 83.3333333333%;
}
.col-desk-11 {
  box-sizing: border-box;
  width: 91.6666666667%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-11.no-padding {
  padding: 0;
}
.col-desk-shift-11 {
  margin-left: 91.6666666667%;
}
.col-desk-12 {
  box-sizing: border-box;
  width: 100%;
  padding-left: 0.7352941176%;
  padding-right: 0.7352941176%;
}
.col-desk-12.no-padding {
  padding: 0;
}
.col-desk-shift-12 {
  margin-left: 100%;
}
@media screen and (max-width: 1020px) {
  .col-tab-0 {
    box-sizing: border-box;
    width: 0%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-0.no-padding {
    padding: 0;
  }
  .col-tab-shift-0 {
    margin-left: 0%;
  }
  .col-tab-1 {
    box-sizing: border-box;
    width: 8.3333333333%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-1.no-padding {
    padding: 0;
  }
  .col-tab-shift-1 {
    margin-left: 8.3333333333%;
  }
  .col-tab-2 {
    box-sizing: border-box;
    width: 16.6666666667%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-2.no-padding {
    padding: 0;
  }
  .col-tab-shift-2 {
    margin-left: 16.6666666667%;
  }
  .col-tab-3 {
    box-sizing: border-box;
    width: 25%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-3.no-padding {
    padding: 0;
  }
  .col-tab-shift-3 {
    margin-left: 25%;
  }
  .col-tab-4 {
    box-sizing: border-box;
    width: 33.3333333333%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-4.no-padding {
    padding: 0;
  }
  .col-tab-shift-4 {
    margin-left: 33.3333333333%;
  }
  .col-tab-5 {
    box-sizing: border-box;
    width: 41.6666666667%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-5.no-padding {
    padding: 0;
  }
  .col-tab-shift-5 {
    margin-left: 41.6666666667%;
  }
  .col-tab-6 {
    box-sizing: border-box;
    width: 50%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-6.no-padding {
    padding: 0;
  }
  .col-tab-shift-6 {
    margin-left: 50%;
  }
  .col-tab-7 {
    box-sizing: border-box;
    width: 58.3333333333%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-7.no-padding {
    padding: 0;
  }
  .col-tab-shift-7 {
    margin-left: 58.3333333333%;
  }
  .col-tab-8 {
    box-sizing: border-box;
    width: 66.6666666667%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-8.no-padding {
    padding: 0;
  }
  .col-tab-shift-8 {
    margin-left: 66.6666666667%;
  }
  .col-tab-9 {
    box-sizing: border-box;
    width: 75%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-9.no-padding {
    padding: 0;
  }
  .col-tab-shift-9 {
    margin-left: 75%;
  }
  .col-tab-10 {
    box-sizing: border-box;
    width: 83.3333333333%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-10.no-padding {
    padding: 0;
  }
  .col-tab-shift-10 {
    margin-left: 83.3333333333%;
  }
  .col-tab-11 {
    box-sizing: border-box;
    width: 91.6666666667%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-11.no-padding {
    padding: 0;
  }
  .col-tab-shift-11 {
    margin-left: 91.6666666667%;
  }
  .col-tab-12 {
    box-sizing: border-box;
    width: 100%;
    padding-left: 1.0714285714%;
    padding-right: 1.0714285714%;
  }
  .col-tab-12.no-padding {
    padding: 0;
  }
  .col-tab-shift-12 {
    margin-left: 100%;
  }
}
@media screen and (max-width: 620px) {
  .col-mob-0 {
    box-sizing: border-box;
    width: 0%;
    padding-left: 0.8503401361%;
    padding-right: 0.8503401361%;
  }
  .col-mob-0.no-padding {
    padding: 0;
  }
  .col-mob-shift-0 {
    margin-left: 0%;
  }
  .col-mob-1 {
    box-sizing: border-box;
    width: 25%;
    padding-left: 0.8503401361%;
    padding-right: 0.8503401361%;
  }
  .col-mob-1.no-padding {
    padding: 0;
  }
  .col-mob-shift-1 {
    margin-left: 25%;
  }
  .col-mob-2 {
    box-sizing: border-box;
    width: 50%;
    padding-left: 0.8503401361%;
    padding-right: 0.8503401361%;
  }
  .col-mob-2.no-padding {
    padding: 0;
  }
  .col-mob-shift-2 {
    margin-left: 50%;
  }
  .col-mob-3 {
    box-sizing: border-box;
    width: 75%;
    padding-left: 0.8503401361%;
    padding-right: 0.8503401361%;
  }
  .col-mob-3.no-padding {
    padding: 0;
  }
  .col-mob-shift-3 {
    margin-left: 75%;
  }
  .col-mob-4 {
    box-sizing: border-box;
    width: 100%;
    padding-left: 0.8503401361%;
    padding-right: 0.8503401361%;
  }
  .col-mob-4.no-padding {
    padding: 0;
  }
  .col-mob-shift-4 {
    margin-left: 100%;
  }
}

@media screen and (max-width: 1020px) {
  .hidden-mob {
    display: none;
  }
}
.no-margin {
  margin: 0 !important;
}

.header {
  padding: 14px;
  position: relative;
  background: #fff;
  margin: 0 0 -4px 0;
  border-bottom: #eeeff3;
}
.header .user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1100px) {
  .header .user {
    justify-content: space-between;
  }
}
.header .user .logo {
  display: none;
}
@media only screen and (max-width: 1100px) {
  .header .user .logo {
    display: block;
    max-width: 25px;
    margin-top: -8px;
    margin-left: 28px;
  }
}
@media only screen and (max-width: 1100px) {
  .header .user h4 {
    display: none;
  }
}
.header .user .func {
  background: rgba(255, 168, 0, 0.67);
  border-radius: 4px;
  padding: 5px 10px;
  color: #333;
  font-size: 17px;
  line-height: 25px;
  margin-left: 20px;
}
.header .user .func span.funcao {
  display: inline;
}
.header .user .func span.name {
  display: none;
}
@media only screen and (max-width: 1100px) {
  .header .user .func span.funcao {
    display: none;
  }
  .header .user .func span.name {
    display: inline;
  }
}
@media only screen and (max-width: 1100px) {
  .header .user .func {
    display: inline;
    font-size: 12px;
    margin-top: -3px;
  }
}
.header .user .func:hover {
  background: #ffa800;
  transition: 300ms all ease-in-out;
}
.header .dropdownmenu {
  display: none;
}
.header .dropdownmenu ul {
  margin: 25px 0 0 0;
  padding: 0;
}
.header .dropdownmenu ul li {
  display: inline;
  margin-left: 15px;
}
.header .dropdownmenu ul li a {
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 27px;
  text-align: center;
  color: #616161;
  position: relative;
}
@media (max-width: 1030px) {
  .header .dropdownmenu {
    display: block;
    background: #fd7502;
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: none;
    width: 100%;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 100%;
    top: 104px;
    transition: all 0.3s;
    z-index: 9999999;
  }
  .header .dropdownmenu ul li {
    display: block;
    margin-bottom: 16px;
    text-align: center;
    text-transform: uppercase;
  }
  .header .dropdownmenu ul li a {
    color: #fff;
    font-size: 1.7rem;
  }
  .header .dropdownmenu ul li ul {
    margin-left: 5px;
  }
}
.header .burger {
  position: absolute;
  width: 25px;
  height: 33px;
  left: 11px;
  display: none;
  cursor: pointer;
  z-index: 6;
  flex-direction: column;
  justify-content: space-around;
}
@media (max-width: 1030px) {
  .header .burger {
    display: block;
    top: 16px;
  }
}
.header .burger div {
  width: 25px;
  height: 2px;
  background: #fd7502;
  transition: all 0.3s linear;
  margin-bottom: 8px;
  position: relative;
  transform-origin: 1px;
}
.header .burger div:first-child {
  transform: rotate(0);
}
.header .burger div:nth-child(2) {
  opacity: 1;
  transform: translateX(0);
}
.header .burger div:nth-child(3) {
  transform: rotate(0);
}

.v-sidebar-menu .vsm--item {
  position: static !important;
  display: block;
  width: 100%;
  white-space: nowrap;
}

.v-sidebar-menu .vsm--link_active {
  background-color: #fd7502 !important;
}

.v-sidebar-menu .vsm--link_open {
  background-color: #fd7502 !important;
  box-shadow: 3px 0 0 0 #fd7502 inset;
  font-weight: bold;
}

.v-sidebar-menu .vsm--title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.4rem;
  padding: 5px 0;
}

.v-sidebar-menu .vsm--arrow_open {
  background-color: #fd7502 !important;
}
@media screen and (min-width: 1100px) {
  .b-sidebar {
    display: flex !important;
    width: 280px !important;
    // position: absolute !important;
  }
}
// .b-sidebar-body::-webkit-scrollbar {
//   width: 12px; /* width of the entire scrollbar */
// }

// .b-sidebar-body::-webkit-scrollbar-track {
//   background: transparent; /* color of the tracking area */
// }

// .b-sidebar-body::-webkit-scrollbar-thumb {
//   background-color: #343a40; /* color of the scroll thumb */
//   border-radius: 20px; /* roundness of the scroll thumb */
//   border: 1px solid #343a40; /* creates padding around scroll thumb */
// }
@media only screen and (max-width: 1100px) {
  .info-list .linha-det,
  .info-list-atrasado .linha-det {
    width: 100%;
    text-align: center;
    margin: 20px 0 20px 0;
  }
  .b-sidebar {
    // display: none;
    width: 280px !important;
    // position: absolute !important;
  }
  .b-sidebar:not(.b-sidebar-right) {
    left: 0;
    right: auto;
    // top: 4rem;
  }
  // .b-sidebar-body::-webkit-scrollbar {
  //   width: 12px; /* width of the entire scrollbar */
  // }

  // .b-sidebar-body::-webkit-scrollbar-track {
  //   background: transparent; /* color of the tracking area */
  // }

  // .b-sidebar-body::-webkit-scrollbar-thumb {
  //   background-color: #343a40; /* color of the scroll thumb */
  //   border-radius: 20px; /* roundness of the scroll thumb */
  //   border: 1px solid #343a40; /* creates padding around scroll thumb */
  // }
}

.sidebar {
  right: auto;
  left: 0;
  bottom: 0;
  z-index: 202;
  height: 100%;
  padding: 10px 15px;
  width: 280px;
  overflow-y: auto;
  transition: all 0.3s;
}
.sidebar .logo {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1100px) {
  .sidebar {
    top: 62px;
    left: 100%;
    width: 100%;
    padding-bottom: 97px;
  }
  .sidebar .logo {
    display: none;
  }
}
.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}
.sidebar ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 10px 0;
}
.sidebar ul li.main {
  background: #4b4a4a;
  color: white;
  margin: 0 -15px;
  padding-left: 14px;
}
.sidebar ul li.main a {
  font-weight: bold;
}
.sidebar ul li.top {
  margin-top: 20px;
}
.sidebar ul li a {
  color: #fff;
}
.sidebar ul li a:hover {
  color: #9e9e9e;
  transition: 300ms all ease-in-out;
}
.sidebar ul li a.space {
  padding-left: 30px;
}
.sidebar ul li:hover {
  transition: 300ms all ease-in-out;
  background: #0e588a;
  cursor: pointer;
}
.sidebar ul li img {
  margin-right: 10px;
}

@media only screen and (max-width: 1100px) {
  body.open {
    overflow: hidden;
  }
}
body.open .burger div {
  background: #2e2e2e;
  width: 30px;
}
body.open .burger div:first-child {
  transform: rotate(45deg);
}
body.open .burger div:nth-child(2) {
  opacity: 0;
  transform: translateX(20px);
}
body.open .burger div:nth-child(3) {
  transform: rotate(-45deg);
}
body.open .sidebar {
  left: 0;
}

section.login {
  // background: url("/img/home.jpg") top center no-repeat;
  background: url("../public/img/MaskGroupHome.png") top center no-repeat;
  width: 100%;

  background-size: cover;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  padding: 30px;
  height: 100vh;
}
@media only screen and (max-width: 1100px) {
  section.login {
    justify-content: center;
  }
}
@media only screen and (max-width: 1100px) {
  section.login {
    padding: 15px;
  }
}
section.login .login-cont {
  background: #ffffff;
  border-radius: 16px;
  // min-height: 367px;
  padding: 15px 30px;

  margin-top: 70px;
  height: 24rem;
  width: 24rem;

  // margin-top, margin-right, margin-bottom, margin-left
}
@media only screen and (max-width: 1100px) {
  section.login .login-cont {
    padding: 54px 20px;
  }
}
section.login .login-cont .alert {
  text-align: center;
  font-size: 17px;
  color: red;
}
section.login .login-cont form {
  margin: 30px 0;
}
section.login .login-cont form label {
  text-align: left;
  color: #8a9099;
}
section.login .login-cont form .row {
  margin-bottom: 10px;
}
section.login .login-cont form a.pass {
  text-align: right;
  display: block;
  margin-top: -19px;
  margin-bottom: 25px;
}
section.login .login-cont form button {
  background: #fd7502;
  color: #fff;
  text-align: center;
  border-radius: 14px;
  padding: 10px 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  width: 100%;
  border: 0;
  cursor: pointer;
}
section.login .login-cont form button:hover {
  transition: 300ms all ease-in-out;
  background: #cc5d02;
}

#app {
  display: block;
  height: 100%;
}

// .content {
//   position: relative;
//   padding: 5px 0px 45px 280px;
//   display: block;
//   height: 100%;
// }
.content.full {
  padding: 10px 0px 45px 10px;
}
@media only screen and (max-width: 1100px) {
  .content {
    padding: 10px;
  }
}
.content .box-content {
  margin: 20px 20px 45px 20px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 5px 7px 7px -3px rgba(0, 0, 0, 0.15);
  display: block;
}
@media only screen and (max-width: 1100px) {
  .content .box-content {
    margin: 20px 0px;
    padding: 20px 10px;
  }
}
.content .box-content form {
  margin: 20px 0;
}
.content .box-content form button {
  background: #cc5d02;
  border-radius: 4px;
  padding: 5px 16px;
  color: #fff;
  font-size: 17px;
  line-height: 25px;
  border: none;
  cursor: pointer;
}
.content .box-content form button:hover {
  background: #e6781e;
  transition: 300ms all ease-in-out;
}
.content .box-content .btn-group {
  float: right;
  background: #e6781e;
  padding: 5px 16px;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  line-height: 25px;
  border-radius: 8px;
}
.content .box-content .func-tag-cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.content .box-content .func-tag-cont .func-tag {
  background: #e6781e;
  padding: 5px 10px;
  color: #fff;
  font-size: 14px;
  margin: 5px;
}
.content .box-content .func-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 20px 20px 0;
}
.content .box-content .func-list .func-list-item {
  padding: 10px 10px 10px 0;
}
.content .box-content .VuePagination__count {
  display: none;
}
.content .box-content .VuePagination ul {
  list-style: none;
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  padding: 0;
}
.content .box-content .VuePagination ul li {
  margin: 3px;
}
.content .box-content .VuePagination ul li button {
  font-size: 14px;
  background: #6f6f6f;
}
.content .box-content .VuePagination ul li.disabled {
  display: none;
}
.content .box-content .VuePagination ul li.active button {
  background: #e6781e;
}
.content .box-content table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin: 20px 0 10px;
}
.content .box-content table td,
.content .box-content table th {
  padding: 3px 10px;
}
.content .box-content table thead th {
  background: -moz-linear-gradient(center top, #8c8c8c 5%, #7d7d7d 100%);
  background-color: #8c8c8c;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  padding: 10px;
  border-left: 1px solid #a3a3a3;
}
.content .box-content table thead th:first-child {
  border: none;
}
.content .box-content table tbody tr {
  border-bottom: 1px solid #dbdbdb;
}
.content .box-content table tbody tr:hover td {
  background: #d4d4d4;
  transition: 300ms all ease-in-out;
}
.content .box-content table tbody td {
  color: #3c3c3c;
  border-left: 1px solid #dbdbdb;
  font-weight: normal;
  padding: 10px;
  line-height: 1.3rem;
  background: #f1f1f1;
}
.content .box-content table tbody .alt td {
  background: #ebebeb;
  color: #7d7d7d;
}
.content .box-content table tbody td:first-child {
  border-left: none;
}
.content .box-content table tbody tr:last-child td {
  border-bottom: none;
}
.content .box-content .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
// body::-webkit-scrollbar {
//   width: 12px; /* width of the entire scrollbar */
// }

// body::-webkit-scrollbar-track {
//   background: transparent; /* color of the tracking area */
// }

// body::-webkit-scrollbar-thumb {
//   background-color: #343a40; /* color of the scroll thumb */
//   border: 1px solid #343a40; /* creates padding around scroll thumb */
// }

// body::-webkit-scrollbar {
//   width: 4px;
// }

// body::-webkit-scrollbar-thumb {
//   border-radius: 20px;
//   background: #e6792e;
// }

// body::-webkit-scrollbar-track {
//   background: #343a40; /* color of the tracking area */
// }

//button.btn,
//.VuePagination button {
//  background: #cc5d02;
//  border-radius: 4px;
//  padding: 5px 16px;
//  color: #fff;
//  font-size: 17px;
//  line-height: 25px;
//  border: none;
//  cursor: pointer;
//}
//button.btn.deletar,
//.VuePagination button.deletar {
//  float: right;
//  margin-top: -51px;
//  background: #6d0606;
//}
//button.btn.deletar:hover,
//.VuePagination button.deletar:hover {
//  background: #b61010;
//  transition: 300ms all ease-in-out;
//}
//button.btn:hover,
//.VuePagination button:hover {
//  background: #e6781e;
//  transition: 300ms all ease-in-out;
//}

.pedido-det span {
  font-weight: bold;
}
.pedido-det .topo-det {
  background: rgba(196, 196, 196, 0.3);
  border-radius: 10px;
  padding: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 20px;
  color: #434349;
}
.pedido-det .det-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.pedido-det .det-info.row-grid {
  width: 100%;
  margin: 20px 0;
}
.pedido-det .det-info.row-grid .item-grid {
  width: 210px;
}
@media only screen and (max-width: 600px) {
  .pedido-det .det-info.row-grid .item-grid {
    width: 100%;
  }
}
.pedido-det .det-info .item {
  margin-bottom: 15px;
}
@media only screen and (max-width: 600px) {
  .pedido-det .det-info .item {
    width: 100%;
  }
  .pedido-det .det-info .item button {
    width: 100%;
  }
  .pedido-det .det-info .item select {
    width: 100%;
  }
}
.pedido-det .prods.min {
  max-height: 400px;
  overflow-y: auto;
}
.pedido-det .prods .linha {
  border-radius: 10px;
  margin: 30px 0;
}
.pedido-det .prods .linha button.send {
  background: #444444;
  color: white;
  padding: 10px 16px;
  margin-top: 19px;
}
.pedido-det .prods .linha button.sendlinha {
  background: #721212;
  padding: 18px 28px;
  margin-top: 3px;
  font-weight: bold;
  font-size: 18px;
  border-radius: 2rem;
  border: 1px solid #721212;
}
.pedido-det .prods .linha button.sendlinha:hover {
  background: rgba(114, 18, 18, 0.568);
  border: 1px solid rgba(114, 18, 18, 0.568);
}
@media only screen and (max-width: 1100px) {
  .pedido-det .prods .linha button.sendlinha {
    margin: 22px auto 0;
    display: block;
    width: 63% !important;
  }
}
.pedido-det .prods .linha button.sendlinha.ok {
  background: #2eb03b;
  border: 1px solid #2eb03b;
}
.pedido-det .prods .linha .infos {
  padding: 10px;
}
.pedido-det .prods .linha .infos h3 {
  margin-bottom: 10px;
}
@media only screen and (max-width: 1100px) {
  .pedido-det .prods .linha .infos h3 {
    margin-bottom: 2px;
  }
}
.pedido-det .prods .linha .infos input,
.pedido-det .prods .linha .infos select {
  margin: 8px 0 0 0;
}
.pedido-det .prods .linha .infos .item-cod {
  width: 84%;
}
.pedido-det .prods .linha .infos .item-local {
  width: 15%;
}
.pedido-det .prods .linha .infos .item-vol {
  width: 10%;
}
@media only screen and (max-width: 1100px) {
  .pedido-det .prods .linha .infos .item-cod {
    width: 100%;
  }
  .pedido-det .prods .linha .infos .item-local {
    width: 48%;
  }
  .pedido-det .prods .linha .infos .item-vol {
    width: 48%;
  }
  .pedido-det .prods .linha .infos .item-send {
    width: 100%;
    margin-top: -15px;
  }
  .pedido-det .prods .linha .infos .item-send button.btn {
    width: 100%;
  }
}
.pedido-det .prods .linha .topo {
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
}
.pedido-det .prods .linha.linha-1 {
  background: #dbdbdb;
}
.pedido-det .prods .linha.linha-1 .topo {
  background: #8c8c8c;
}
.pedido-det .prods .linha.linha-2 {
  background: #dbdbdb;
}
.pedido-det .prods .linha.linha-2 .topo {
  background: #8c8c8c;
}
.pedido-det .prods .linha.linha-3 {
  background: #dbdbdb;
}
.pedido-det .prods .linha.linha-3 .topo {
  background: #8c8c8c;
}
.pedido-det .prods .linha.linha-4 {
  background: #dbdbdb;
}
.pedido-det .prods .linha.linha-4 .topo {
  background: #8c8c8c;
}
.pedido-det .prods .linha.linha-5 {
  background: #dbdbdb;
}
.pedido-det .prods .linha.linha-5 .topo {
  background: #8c8c8c;
}
.pedido-det .prods .linha.linha-6 {
  background: #dbdbdb;
}
.pedido-det .prods .linha.linha-6 .topo {
  background: #8c8c8c;
}
.pedido-det .prods .linha.linha-7 {
  background: #dbdbdb;
}
.pedido-det .prods .linha.linha-7 .topo {
  background: #8c8c8c;
}
.pedido-det .prods .linha.linha-8 {
  background: #dbdbdb;
}
.pedido-det .prods .linha.linha-8 .topo {
  background: #8c8c8c;
}

.filtrar {
  display: none;
}
.filtrar input {
  margin: 10px 0 0 0;
}
.filtrar.open {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 10px;
  margin: 30px 0;
  background: rgba(215, 219, 223, 0.34);
  border-radius: 10px;
}
@media only screen and (max-width: 1100px) {
  .filtrar.open {
    margin: 13px 2px;
    padding: 10px 2px;
  }
}
.filtrar .item-filtro {
  margin: 10px;
}
@media only screen and (max-width: 1100px) {
  .filtrar .item-filtro {
    width: 42%;
  }
}

.list-pedidos.aguardando .top-list {
  background: #3699ff;
}
.list-pedidos.aguardando .list .btn {
  background: #3699ff;
}
.list-pedidos.aguardando .list .btn:hover {
  transition: 300ms all ease-in-out;
  background: #2069b3;
}
.list-pedidos.linha .top-list {
  background: #2eb03b;
}
.list-pedidos.linha .list .btn {
  background: #2eb03b;
}
.list-pedidos.linha .list .btn:hover {
  transition: 300ms all ease-in-out;
  background: rgba(46, 176, 59, 0.9);
}
.list-pedidos.confere .top-list {
  background: #721212;
}
.list-pedidos.confere .list .btn {
  background: #721212;
}
.list-pedidos.confere .list .btn:hover {
  transition: 300ms all ease-in-out;
  background: rgba(114, 18, 18, 0.568);
}
.list-pedidos.embarque .top-list {
  background: #dd9402;
}
.list-pedidos.embarque .list .btn {
  background: #dd9402;
}
.list-pedidos.embarque .list .btn:hover {
  transition: 300ms all ease-in-out;
  background: #a87103;
}
.list-pedidos.despachado .top-list {
  background: #c25904;
}
.list-pedidos.despachado .list .btn {
  background: #c25904;
}
.list-pedidos.despachado .list .btn:hover {
  transition: 300ms all ease-in-out;
  background: #994602;
}
.list-pedidos.cancelado .top-list {
  background: #3f3f3f;
}
.list-pedidos.cancelado .list .btn {
  background: #3f3f3f;
}
.list-pedidos.cancelado .list .btn:hover {
  transition: 300ms all ease-in-out;
  background: #252525;
}
.list-pedidos .top-list {
  border-radius: 11px;
  margin: 10px 0;
  padding: 5px;
}
@media only screen and (max-width: 1100px) {
  .list-pedidos .top-list {
    display: none;
  }
}
.list-pedidos .top-list ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.list-pedidos .top-list ul li {
  color: #fff;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  padding: 10px;
  border-left: 1px solid rgba(196, 196, 196, 0.14);
}
.list-pedidos .top-list ul li:nth-child(1) {
  width: 100px;
  border-left: none;
}
.list-pedidos .top-list ul li:nth-child(2) {
  width: 120px;
}
.list-pedidos .top-list ul li:nth-child(3) {
  width: 280px;
}
.list-pedidos .list {
  width: 100%;
}
.list-pedidos .list .item {
  background: rgba(215, 219, 223, 0.34);
  border-radius: 11px;
  margin: 10px 0;
  padding: 5px;
}
@media only screen and (max-width: 1100px) {
  .list-pedidos .list .item {
    margin: 10px 0 20px;
    padding: 20px 5px;
  }
}
.list-pedidos .list .item:hover {
  transition: 300ms all ease-in-out;
  background: rgba(215, 219, 223, 0.84);
}
.list-pedidos .list .item ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (max-width: 1100px) {
  .list-pedidos .list .item ul {
    flex-wrap: wrap;
  }
}
.list-pedidos .list .item ul li {
  color: #333333;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  padding: 10px;
  border-left: 1px solid rgba(196, 196, 196, 0.14);
}
@media only screen and (max-width: 1100px) {
  .list-pedidos .list .item ul li {
    padding: 7px;
  }
  .list-pedidos .list .item ul li:nth-child(5) {
    width: 100%;
    margin-top: 10px;
  }
}
.list-pedidos .list .item ul li .btn {
  color: #fff;
  text-align: center;
  border-radius: 14px;
  padding: 10px;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  display: block;
  width: 100%;
  border: 0;
  cursor: pointer;
}
.list-pedidos .list .item ul li .btn.list {
  margin-top: 10px;
  width: 120px;
}
@media only screen and (max-width: 1100px) {
  .list-pedidos .list .item ul li .btn.list {
    width: 100%;
  }
}
.list-pedidos .list .item ul li:nth-child(1) {
  width: 100px;
  border-left: none;
}
@media only screen and (max-width: 1100px) {
  .list-pedidos .list .item ul li:nth-child(1) {
    width: 50%;
  }
}
.list-pedidos .list .item ul li:nth-child(2) {
  width: 120px;
}
@media only screen and (max-width: 1100px) {
  .list-pedidos .list .item ul li:nth-child(2) {
    width: 50%;
  }
}
.list-pedidos .list .item ul li:nth-child(3) {
  width: 280px;
}
@media only screen and (max-width: 1100px) {
  .list-pedidos .list .item ul li:nth-child(3) {
    width: 100%;
  }
}
.list-pedidos .list .item ul li:nth-child(4) {
  width: 318px;
}
@media only screen and (max-width: 1100px) {
  .list-pedidos .list .item ul li:nth-child(4) {
    width: 100%;
  }
}
.list-pedidos .list .item ul li span {
  font-weight: bold;
}
@media only screen and (min-width: 1100px) {
  .list-pedidos .list .item ul li span {
    display: none;
  }
}

.nr-nota {
  width: 85%;
}

.qt-table {
  background: #cc5d02;
  border-radius: 4px;
  padding: 7px;
  margin: 6px 2px;
  display: inline-flex;
  color: #fff;
  font-size: 15px;
}
.qt-table.hide {
  display: none;
}
.qt-table.red {
  background: #cc0202;
}

h3.red {
  color: #960e0e;
}

.info-list,
.info-list-atrasado {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 20px;
  margin: 30px 10px 10px 10px;
}
@media only screen and (max-width: 1100px) {
  .info-list,
  .info-list-atrasado {
    margin: 0px 10px 10px 10px;
  }
}
.info-list p,
.info-list-atrasado p {
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  margin: 0;
}
.info-list .qt-table,
.info-list-atrasado .qt-table {
  margin: 0 3px 10px 0;
}
.info-list .linha-det,
.info-list-atrasado .linha-det {
  background: #cc5d02;
  border-radius: 4px;
  padding: 5px;
  margin: 0 10px 4px 0;
  color: #fff;
  font-weight: bold;
}

.print table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
  margin: 20px 0 10px;
}
.print table td,
.print table th {
  padding: 3px 10px;
}
.print table thead th {
  background: -moz-linear-gradient(center top, #8c8c8c 5%, #7d7d7d 100%);
  background-color: #8c8c8c;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  padding: 10px;
  border-left: 1px solid #a3a3a3;
}
.print table thead th:first-child {
  border: none;
}
.print table tbody tr {
  border-bottom: 1px solid #dbdbdb;
}
.print table tbody tr:hover td {
  background: #d4d4d4;
  transition: 300ms all ease-in-out;
}
.print table tbody td {
  color: #3c3c3c;
  border-left: 1px solid #dbdbdb;
  font-weight: normal;
  padding: 10px;
  line-height: 1.3rem;
  background: #f1f1f1;
}
.print table tbody .alt td {
  background: #ebebeb;
  color: #7d7d7d;
}
.print table tbody td:first-child {
  border-left: none;
}
.print table tbody tr:last-child td {
  border-bottom: none;
}

input.checking {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.func-list-item {
  padding: 10px 10px 10px 0;
  font-size: 18px;
  font-weight: bold;
}
.func-list-item input {
  margin: 8px 6px 0 0 !important;
}

.wrapper-btn {
  width: 168px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1100px) {
  .wrapper-btn {
    margin-bottom: 50px;
  }
}
.wrapper-btn .btn {
  border: none;
  width: 42px;
  background-color: #444;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
}
.wrapper-btn button:focus,
.wrapper-btn input:focus {
  outline: 0;
}
.wrapper-btn .volume {
  text-align: center;
  width: 60px;
  font-size: 16px;
  color: #444;
  border: 1px solid #e1e8ee;
}

.imp-vol {
  font-family: sans-serif;
  border: 2px solid black;
  outline: 2px solid black;
  outline-offset: 5px;
}

div.alert {
  color: #ff0000;
  background: #151515;
  padding: 10px;
  border-radius: 10px;
}

//.modal-backdrop {
//  display: none;
//  z-index : -1;
//}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.9rem;
  outline: 0;
  //background-color: #e1e1e1;
  //background-color: #ebebeb;
}
.common-title {
  color: white !important;
  font-weight: bold !important;
  text-transform: uppercase;
}

#vuetify-view {
  // padding: 10px 10px 10px 290px;
  text-align: center !important;
}

// @media screen and (max-width: 1100px) {
//   #vuetify-view {
//     padding: 10px;
//   }
// }

// .v-main__wrap {
//   background-color: #eeeeee;
// }

// .v-application--wrap {
//   min-height: 85vh !important;
// }

// :not([data-bv] *) > .v-application .white {
//   background-color: transparent !important;
// }

:not([data-bv] *) > .theme--dark.v-application {
  color: revert !important;
}
</style>

<template>
  <div>
    <b-modal data-bv
      id="modal-detalhes-conferencia-final"
      body-class="rounded"
      size="lg sm"
      hide-footer
      hide-header
      centered
      class="py-2 px-2"
    >
      <div class="mt-2">
        <div class="d-block text-left my-3">
          <h4 class="font-weight-bold text-center">Detalhar Cargas</h4>
        </div>
        <div>
          <h5>
            Quantidade de cargas: <b>{{ volumesMapped.length }}</b>
          </h5>
        </div>
        <div style="overflow-y: auto; max-height: 600px">
          <div v-for="(carga, j) in volumesMapped" :key="j">
            <div class="mb-3 px-4 py-4">
              <b-card
                header="featured"
                header-tag="div"
                :header-class="'cor-background-azul'"
              >
                <template #header>
                  <h4 class="mb-6 font-weight-bold" style="color: white">
                    Carga {{ j + 1 }}
                  </h4>
                </template>
                <b-row
                  v-for="(volume, k) in carga.volumes"
                  :key="k"
                  :class="
                    'mb-0 px-1 py-1' + ` volume-insumo-${volume.volume_id}`
                  "
                >
                  <input
                    type="hidden"
                    :class="`input-hidden-volume-${volume.volume_id}`"
                    :value="volume.volume_id"
                  />
                  <input
                    type="hidden"
                    :class="`input-hidden-insumo-${volume.volume_codigo}`"
                    :value="volume.volume_codigo"
                  />
                  <b-col>
                    <label
                      style="
                        align-items: center;
                        font-family: 'Poppins';
                        font-size: 15px;
                      "
                      >{{ volume.volume_codigo || "" }}</label
                    >
                  </b-col>
                  <b-col>
                    <label>{{ volume.quantidade_insumos }} unidades</label>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalDetalhesConferenciaFinal",
  components: {},
  data: () => ({
    volumesMapped: [],
  }),
  mounted() {
    this.separeteVolumesPerCarga(this.volumes);
  },
  props: {
    volumes: {
      type: Array,
      required: true,
    },
  },
  watch: {
    volumes: {
      handler: function (volumes) {
        this.separeteVolumesPerCarga(volumes);
      },
      deep: true,
    },
  },
  methods: {
    async separeteVolumesPerCarga(volumes) {
      const cargas = [];
      await volumes.forEach((volume) => {
        const carga = cargas.find((c) => c.cargaId === volume.carga_id);
        if (carga) {
          carga.volumes.push(volume);
        } else {
          cargas.push({
            cargaId: volume.carga_id,
            cargaStatus: volume.carga_status,
            volumes: [volume],
          });
        }
      });
      this.volumesMapped = cargas;
      return;
    },
  },
};
</script>

<style scoped>
.cor-background-azul {
  background: #120947;
}
</style>

<template>
  <div class="py-2">
    <v-progress-circular
      v-bind="$attrs"
      color="primary"
      indeterminate
      :size="size"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "DefaultSpinner",
  props: {
    size: {
      type: String,
      default: "64",
    },
  },
};
</script>

<style scoped></style>

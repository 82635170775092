var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',_vm._l((_vm.volumes),function(volume,i){return _c('div',{key:i},[_c('div',{staticClass:"mb-3 px-1 py-4"},[_c('b-card',{staticClass:"shadow",attrs:{"header":"featured","header-tag":"div","header-class":'cor-background-azul ' +
          (_vm.btnFecharCaixaLoading ? ' caixa-carregando' : '')},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"mb-6 font-weight-bold",staticStyle:{"color":"white"}},[_vm._v(" Caixa "+_vm._s(_vm.indexCaixa)+" - "+_vm._s(volume.codigo)+" ")])]},proxy:true}],null,true)},[_vm._l((volume.insumosNaoBipaveis),function(volumeInsumo,j){return _c('div',{key:j,class:'mb-0 px-1 py-1' +
            (volumeInsumo.conferido || volumeInsumo.erroCorrigido
              ? ' insumo-conferido'
              : '') +
            (volumeInsumo.erroMotivo && !volumeInsumo.erroCorrigido
              ? ' insumo-com-erro'
              : '')},[_c('b-card',[_c('b-row',{staticStyle:{"justify-content":"space-between !important"},attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"3","sm":"6","md":"6","lg":"6","xl":"6","align":"start"}},[_c('label',{staticStyle:{"align-items":"center","font-family":"'Poppins'","font-size":"20px"}},[_vm._v(_vm._s((volumeInsumo.insumo || {}).descricao || ""))])]),_c('b-col',{attrs:{"cols":"3","sm":"2","md":"2","lg":"2","xl":"2","align":"start"}},[_c('label',{staticStyle:{"align-items":"center","font-size":"20px","color":"red"}},[_vm._v(" "+_vm._s(volumeInsumo.erroMotivo.toUpperCase())+" ")])]),_c('b-col',{attrs:{"cols":"3","sm":"4","md":"4","lg":"4","xl":"4","align":"end"}},[_c('label',{staticStyle:{"align-items":"center","font-family":"'Poppins'","font-size":"20px"}},[_vm._v(_vm._s(volumeInsumo.quantidade)+" UNIDADES")])])],1),(!volumeInsumo.conferido)?_c('b-row',[_c('b-col',{attrs:{"cols":"1","sm":"12","md":"12","lg":"12","xl":"12","align":"start"}},[_c('default-button',{staticClass:"text-center my-4",attrs:{"name":'Confirmar'},on:{"click":function($event){return _vm.confirmarInsumo(
                      volumeInsumo.insumo.insumoId,
                      volume.volumeId
                    )}}}),(!volumeInsumo.erroMotivo)?_c('default-button',{staticClass:"text-center mb-4",attrs:{"name":'Reportar problema',"color":"red-color"},on:{"click":function($event){return _vm.reportarProblema(
                      volumeInsumo.insumo.insumoId,
                      volume.volumeId
                    )}}}):_vm._e()],1)],1):_vm._e(),(volumeInsumo.erroMotivo && !volumeInsumo.erroCorrigido)?_c('b-row',[_c('b-col',{attrs:{"cols":"1","sm":"12","md":"12","lg":"12","xl":"12","align":"start"}},[_c('default-button',{staticClass:"text-center mb-4",attrs:{"name":'Informar problema corrigido',"color":"yellow-color"},on:{"click":function($event){return _vm.reportarCorrecaoErro(
                      volume.volumeId,
                      volumeInsumo.insumo.insumoId
                    )}}})],1)],1):_vm._e()],1)],1)}),_vm._l((volume.insumosBipaveis),function(volumeInsumo,k){return _c('span',{key:k,class:'mb-0 px-1 py-1'},[_c('linha-producao-input-bipavel',{attrs:{"insumo":{
              ...volumeInsumo.insumo,
              serial: volumeInsumo.conferido
                ? volumeInsumo.insumo.serial
                : '',
            },"total-insumos":volume.insumos.length,"conferido":volumeInsumo.conferido,"erro-motivo":volumeInsumo.erroMotivo,"erro-corrigido":volumeInsumo.erroCorrigido},on:{"click":function($event){return _vm.biparInsumo($event, volumeInsumo.insumo, volume.volumeId)},"reportar-problema":function($event){return _vm.reportarProblema(volumeInsumo.insumo.insumoId, volume.volumeId)},"reportar-correcao-erro":function($event){return _vm.reportarCorrecaoErro(
                volume.volumeId,
                volumeInsumo.insumo.insumoId
              )}}})],1)})],2)],1)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <table>
      <tbody
        v-for="(insumo, x) in insumos"
        :key="x"
        :draggable="isAdmin"
        @dragstart="onDragStart($event)"
        @dragend="onDragEnd($event)"
        @drop="onDrop($event)"
        @dragenter.prevent
        @dragover.prevent
        :class="`insumo-detalhe-${insumo.insumoId}`"
      >
        <input
          type="hidden"
          :class="`input-hidden-volume-${insumo.insumoId}`"
          :value="insumo.volumeId"
        />
        <tr class="itens">
          <th>
            <div class="mt-2" style="font-size: 20px">
              {{ insumo.insumoDescricao }} -
              {{ `${insumo.index}/${totalInsumos}` }}
            </div>
            <div class="mt-2">
              <input
                type="text"
                :class="'prod-cod item-bipagem insumoBipado'"
                :value="insumo.serial"
                :disabled="true"
              />
            </div>
          </th>
          <th>
            <div v-if="insumo.bipado && insumo.serial !== ''"></div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { BipagemService } from "../../../../../services/pedido_web_producao/linha_paineis/BipagemService";
import { UsuarioLogado } from "../../../../../services/login/UsuarioLogado";
import BipagemInsumosRepository from "../../../../../repositories/v2/BipagemInsumoRepository";
import { VolumeRepository } from "../../../../../repositories/v2/VolumeRepository";
export default {
  components: {},
  name: "ListagemInsumosDetalhesLinhaPaineis",
  data: () => ({
    bodyMoverInsumo: {},
    bipagemService: BipagemService.build(),
    bipagemRepository: BipagemInsumosRepository.build(),
    targetDraggableCodigo: "",
    srcDraggableCodigo: "",
    repositories: {
      volumeRepository: VolumeRepository.build(),
    },
  }),
  async created() {
    this.bipagemService = BipagemService.build().setVm(this);
  },
  props: {
    volumes: { type: Array, required: true },
    insumos: { type: Array, required: true },
    indexVol: { type: Number, required: true },
    totalInsumos: { type: Number, required: true },
    loading: { type: Boolean, required: true },
    detalhes: { type: Boolean, default: false },
  },
  computed: {
    permiteBotaoMoverInsumo() {
      return UsuarioLogado.getRoles() === "ADMIN";
    },
    isAdmin() {
      return UsuarioLogado.isAdmin();
    },
  },
  methods: {
    turnCardMovingEffect(target) {
      target.style.opacity = "40%";
      target.style.border = "3px dotted #666";
    },
    stopCardMovingEffect(target) {
      target.style.opacity = "100%";
      target.style.border = "none";
    },
    onDragStart(event) {
      const insumoId = +Array.from(event.target.classList)
        .reverse()[0]
        .replace(/\D/g, "");
      const volumeId = this.insumos.find(
        (i) => i.insumoId === insumoId
      ).volumeId;
      if (this.insumos.length === 1) {
        event.dataTransfer.setData("text", "");
        alert(
          "Não é possível mover esse insumo, pois a caixa não pode ficar vazia."
        );
        event.stopPropagation();
        return;
      }
      event.dataTransfer.setData(
        "text",
        JSON.stringify({ insumoId, volumeId })
      );
      event.dataTransfer.dropEffect = "copy";
      this.turnCardMovingEffect(event.currentTarget);
    },
    onDragEnd(event) {
      this.stopCardMovingEffect(event.target);
    },
    async onDrop(event) {
      const data = JSON.parse(event.dataTransfer.getData("text"));
      if (!data) return;
      const insumoId = data.insumoId;
      const volumeIdOrigem = data.volumeId;
      let draggableDiv = event.target;
      while (!draggableDiv.draggable) draggableDiv = draggableDiv.parentElement;
      const getCodigo = (el) =>
        +Array.from(el.classList).reverse()[0].replace(/\D/g, "");
      const insumoIdDestino = getCodigo(draggableDiv);
      const volumeIdDestino = this.insumos.find(
        ({ insumoId }) => insumoId === insumoIdDestino
      ).volumeId;
      const response =
        await this.repositories.volumeRepository.moverInsumoDeVolumeLinhaPaineis(
          {
            numeroPedido: this.$route.params.numped,
            insumoId,
            volumeIdOrigem,
            volumeIdDestino,
          }
        );
      if (response.status !== 200) {
        alert(
          response.data.message ||
            "Ocorreu um erro no ato de mover insumo. Entrar em contato com suporte."
        );
        return;
      }
      this.$emit("mover-insumo", { response });
      // window.location.reload()
    },
  },
};
</script>
<style scoped>
.insumoBipado {
  border: 2px solid green;
  border-radius: 10px;
}

.input-loading {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
}
</style>

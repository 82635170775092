import swal from "sweetalert2";
import env from "../../../env";
import UsersRepository from "../../../repositories/v1/UsersRepository";
import PedidoRepository from "../../../repositories/v1/PedidoRepository";
import PedidoWebRepository from "../../../repositories/v2/PedidoWebRepository";

export class PedidoResumoService {
  data;
  vm;
  id;
  label;
  UsersRepository;
  PedidoRepository;
  PedidoWebRepository;

  constructor(usersRepository, pedidoRepository, pedidoWebRepository) {
    this.usersRepository = usersRepository;
    this.pedidoRepository = pedidoRepository;
    this.pedidoWebRepository = pedidoWebRepository;
  }

  static build() {
    const usersRepository = UsersRepository.build();
    const pedidoRepository = PedidoRepository.build();
    const pedidoWebRepository = PedidoWebRepository.build();
    return new PedidoResumoService(
      usersRepository,
      pedidoRepository,
      pedidoWebRepository
    );
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  formatDate(data) {
    let out = "";
    if (data) {
      out = data.substr(0, 10).split("-").reverse().join("/");
      out = out + " " + data.substr(10, 10);
    }
    return out;
  }

  report() {
    const { volumesLinha1, volumesLinha2, volumesLinha3, volumesLinha4 } =
      this.vm.detalhesDoPedido;
    const volumesQuantidade =
      volumesLinha1 + volumesLinha2 + volumesLinha3 + volumesLinha4;
    if (!volumesQuantidade) {
      swal.fire({
        icon: "warning",
        title:
          "Esse pedido não tem volumes. Portanto, o relatório não está disponível.",
      });
      return;
    }
    if (this.vm.total_items > 100) {
      let html = "<div>";
      const totalPaginas = Math.ceil(
        this.vm.total_items / 100 + +(this.vm.total_items % 100 === 0)
      );
      html += `<h4>Esse relatório possui ${totalPaginas} páginas. Clique em cada item para efetuar o download.</h4>`;
      Array.from({ length: totalPaginas }, (_, i) => i + 1).forEach((page) => {
        const url =
          env.baseUrl +
          "/pedido/rel-embarque/" +
          this.vm.detalhesDoPedido.numeroPedido +
          "/" +
          page;
        html += `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">Página ${page}</a>`;
        if (page < totalPaginas) {
          html += `<br><br>`;
        }
      });
      swal.fire({
        title: "Relatório do pedido " + this.vm.detalhesDoPedido.numeroPedido,
        html,
      });
      html = "</div>";
    } else {
      window.open(
        env.baseUrl +
          "/pedido/rel-embarque/" +
          this.vm.detalhesDoPedido.numeroPedido
      );
    }
  }

  cancelarDespacho() {
    if (confirm("Cancelar Despacho?")) {
      this.pedidoWebRepository
        .cancelarDespacho(this.vm.detalhesDoPedido.numeroPedido)
        .then(() => {
          alert("Despacho cancelado com sucesso!");
          window.location.href = "/pedidos-ss/aguardando-embarque";
        })
        .catch(function (e) {
          alert(e);
        });
    }
  }

  actionZerar(id) {
    if (confirm("Confirma Zerar Bipagem?")) {
      this.save_ped = {
        numped: id,
      };

      this.pedidoRepository
        .zerar(this.save_ped)
        .then(() => {
          alert("Pedido foi zerado com sucesso!");
          window.location.href = "/pedidos-ss/aguardando-embarque";
        })
        .catch(function (e) {
          alert(e);
        });
    }
  }

  status(label) {
    let out = "";

    switch (label) {
      case "A":
        out = "AGUARDANDO PRODUÇÃO";
        break;
      case "L":
        out = "EM LINHA DE PRODUÇÃO";
        break;
      case "N":
        out = "AGUARDANDO CONFERÊNCIA";
        break;
      case "F":
        out = "EM CONFERÊNCIA";
        break;
      case "T":
        out = "CONFERÊNCIA COM PROBLEMA";
        break;
      case "E":
        out = "AGUARDANDO EMBARQUE";
        break;
      case "D":
        out = "DESPACHADO";
        break;
      case "C":
        out = "CANCELADO";
        break;
      default:
        out = "";
    }

    return out;
  }
}

import router from "../../router";
import { HttpRestService } from "../http/HttpRestService";
import store from "../../store/store.js";

export class ZeragemBipagemTotalService {
  numped = "";
  vm;

  static build() {
    return new ZeragemBipagemTotalService();
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  setNumped(numped) {
    this.numped = numped;
    return this;
  }

  async run() {
    if (!confirm("Confirma Zerar Bipagem?")) {
      return;
    }
    const { numped } = this;
    const body = { numped };
    await this.enviarRequest(body);
  }
  async enviarRequest(body) {
    const url = "/api/pedido/zerar";

    try {
      const response = await HttpRestService.post(url, body);
      if (response.status === 200) {
        await this.onSuccess();
      } else {
        await this.onError(response);
      }
    } catch (error) {
      await this.onError(error);
    }
  }

  async onSuccess() {
    let successObj = {
      successStatus: true,
      successMessage: "Pedido foi zerado com sucesso!",
    };
    store.commit("notifications/setSuccessNotification", successObj, {
      root: true,
    });
    await router.replace({ name: "Administração" });
  }

  async onError() {
    let errorObj = {
      errorStatus: true,
      errorMessage: "Erro ao zerar pedido!",
    };
    store.commit("notifications/setErrorNotification", errorObj, {
      root: true,
    });
    await router.replace({ name: "Administração" });
  }
}

<template>
  <div>
    <div class="log"></div>
    <div class="content">
      <div class="box-content">
        <div class="pedido-det">
          <div class="topo-det">
            <div class="item-top">
              PEDIDO: {{ pedidoDetalhes.numeroPedido }}
              <div>Transportadora: {{ pedidoDetalhes.transportadoraNome }}</div>
            </div>
            <div class="det-info">
              <div class="item">
                <button
                  type="submit"
                  class="btn btn-danger"
                  v-if="typeIsAdmin && !isPedidoFixador"
                  @click="zerarBipagemTotal()"
                >
                  Zerar Bipagem Total
                </button>
              </div>
              <div class="item" v-if="typeIsAdmin && !isPedidoFixador">
                <select id="linhaz" style="margin: 3px 10px 0">
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="ac">ac</option>
                </select>
              </div>
              <div class="item" v-if="typeIsAdmin && !isPedidoFixador">
                <button
                  type="submit"
                  class="btn btn-danger"
                  @click="zerarLinha"
                >
                  Zerar Linha
                </button>
              </div>
            </div>
          </div>

          <div class="det-info">
            <div class="item">
              <span>CLIENTE:</span> {{ pedidoDetalhes.clienteNome }}
            </div>
            <div class="item">
              <span>DATA DE PRODUÇÃO:</span>
              {{ pedidoDetalhes.dataProducao }}
            </div>
          </div>

          <div class="det-info">
            <div class="item">
              <span>KIT:</span> {{ pedidoDetalhes.kitDescricao }}
            </div>
            <div class="item">
              <span>STATUS:</span> {{ pedidoDetalhes.statusDescricao }}
            </div>
          </div>

          <div
            class="prods min"
            id="prods"
            ref="prod"
            v-if="!pedidoJaFoiFinalizado"
          >
            <div class="item">
              <div class="linha linha-1">
                <div class="topo">
                  <div class="det-info common-title">
                    <h3>ESCOLHER LINHA PROD.</h3>
                    <h3>{{ pedidoDetalhes.kitDescricao }}</h3>
                  </div>
                </div>
                <div class="infos">
                  <div class="det-info" style="justify-content: space-around">
                    <button
                      type="submit"
                      class="btn send"
                      style="color: white"
                      v-if="possuiLinhaPaineis"
                      @click="mostrarProducaoLinhaPaineis"
                    >
                      LINHA 1
                    </button>
                    <button
                      type="submit"
                      class="btn send"
                      style="color: white"
                      v-if="possuiLinhaCabos"
                      @click="mostrarProducaoLinhaCabos"
                    >
                      LINHA 2
                    </button>
                    <button
                      type="submit"
                      class="btn send"
                      style="color: white"
                      v-if="possuiLinhaDiversos"
                      @click="mostrarProducaoLinhaDiversos"
                    >
                      LINHA 3
                    </button>
                    <button
                      type="submit"
                      class="btn send"
                      style="color: white"
                      v-if="possuiLinhaPerfis"
                      @click="mostrarProducaoLinhaPerfis"
                    >
                      LINHA 4
                    </button>
                    <button
                      type="submit"
                      class="btn send"
                      style="color: white"
                      v-if="possuiLinhaInversores"
                      @click="mostrarProducaoLinhaInversores"
                    >
                      LINHA 5
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="pedidoJaFoiFinalizado">
          <h3>Todos os Itens da sua linha foram bipados!</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "../../../../mixins/routesValidationMixin";
import httpRequestMixin from "../../../../mixins/httpRequestMixin";
import { RecaptchaService } from "../../../../services/RecaptchaService";
import { ZeragemBipagemTotalService } from "../../../../services/pedido_web_producao/ZeragemBipagemTotalService";
import { SelecaoLinhasRenderService } from "../../../../services/pedido_web_producao/selecao_linhas/SelecaoLinhasRenderService";
import SelecaoLinhasService from "../../../../services/pedido_web_producao/selecao_linhas/SelecaoLinhasService";
import { PedidoDetalhesModel } from "../../../../models/PedidoDetalhesModel";
import { zerarLinha } from "../../../../services/pedido_web_producao/zeragemLinhasExpress";

export default {
  components: {},
  mixins: [routesValidationMixin, httpRequestMixin],
  data() {
    return {
      insumos: [],
      perfilUsuarioAtual: "",
      selecaoLinhasRenderService: SelecaoLinhasRenderService.build(),
      selecaoLinhasService: SelecaoLinhasService.build(),
      possuiLinhaPaineis: false,
      possuiLinhaCabos: false,
      possuiLinhaDiversos: false,
      possuiLinhaPerfis: false,
      possuiLinhaInversores: false,
      pedidoJaFoiFinalizado: false,
      pedidoDetalhes: PedidoDetalhesModel.build(),
    };
  },
  async created() {
    this.zeragemBipagemTotalService =
      ZeragemBipagemTotalService.build().setVm(this);
    this.selecaoLinhasRenderService =
      SelecaoLinhasRenderService.build().setVm(this);
    this.selecaoLinhasService = SelecaoLinhasService.build();
  },
  async mounted() {
    await this.selecaoLinhasRenderService.run();
    await RecaptchaService.build(this).applyScript();
  },
  computed: {
    isPedidoFixador() {
      return this.$route.query.tipoPedido === "lote-fixador";
    },
    typeIsAdmin() {
      return this.perfilUsuarioAtual === "ADMIN";
    },
    numeroPedido() {
      return this.$route.params.numped;
    },
  },
  methods: {
    zerarBipagemTotal() {
      this.zeragemBipagemTotalService.setNumped(this.numeroPedido).run();
    },
    mostrarProducaoLinhaPaineis() {
      this.selecaoLinhasService
        .setNumped(this.numeroPedido)
        .goToLinhaPaineisExpress();
    },
    mostrarProducaoLinhaCabos() {
      this.selecaoLinhasService
        .setNumped(this.numeroPedido)
        .goToLinhaCabosExpress();
    },
    mostrarProducaoLinhaDiversos() {
      this.selecaoLinhasService
        .setNumped(this.numeroPedido)
        .goToLinhaDiversosExpress();
    },
    mostrarProducaoLinhaPerfis() {
      this.selecaoLinhasService
        .setNumped(this.numeroPedido)
        .goToLinhaPerfisExpress();
    },
    mostrarProducaoLinhaInversores() {
      this.selecaoLinhasService
        .setNumped(this.numeroPedido)
        .goToLinhaInversoresExpress();
    },
    zerarLinha() {
      zerarLinha(this.numeroPedido);
    },
  },
};
</script>

import PainelBase from "./PainelBase";

export default class PainelJASolar325W extends PainelBase {
  getCode = () => 5278;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 5278 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Segundo a consulta "select numerosseriecontrolados from sspedido where codprodmp = 5278",
            todos os 6 seriais desse painel estão nulos no sistema. Portanto, essa amostra será desoconsiderada.
            e quando houver alguma bipagem desse painel novamente, o sistema deixará passar sem validação.
         */
    return true;
  }

  static build() {
    return new PainelJASolar325W();
  }
}

import router from "../../router";
import { dateTimeInFormatYmdhis } from "../dateService";
import { LocalStorageService } from "../LocalStorageService";
import { UsuarioLogado } from "../login/UsuarioLogado";
import InsumoPedidoRepository from "@/repositories/v2/InsumoPedidoRepository";
import PedidoWebRepository from "@/repositories/v2/PedidoWebRepository";
import Swal from "sweetalert2";

export class EditarLinhaDiversosRenderService {
  localStorageService;
  InsumoPedidoRepository;
  PedidoWebRepository;

  constructor(
    localStorageService,
    insumoPedidoRepository,
    pedidoWebRepository
  ) {
    this.localStorageService = localStorageService;
    this.insumoPedidoRepository = insumoPedidoRepository;
    this.pedidoWebRepository = pedidoWebRepository;
  }

  static build() {
    const pedidoWebRepository = PedidoWebRepository.build();
    const localStorageService = LocalStorageService.build();
    const insumoPedidoRepository = InsumoPedidoRepository.build();
    return new EditarLinhaDiversosRenderService(
      localStorageService,
      insumoPedidoRepository,
      pedidoWebRepository
    );
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async requestUserFuncoes() {
    this.vm.perfilUsuarioAtual = "";
    if (UsuarioLogado.getPerfil().includes("administrador")) {
      this.vm.perfilUsuarioAtual = "ADMIN";
    } else {
      await router.replace({ path: "/dashboard" });
    }
    await this.requestPedidosLinhaPaineisList();
  }

  async requestPedidosLinhaPaineisList() {
    const numeroPedido = this.vm.$route.params.numped;
    const response = await this.insumoPedidoRepository.getLinhaDiversos(
      numeroPedido
    );
    if (response.status === 200) {
      this.adicaoDeDadosResponse(response);
    } else {
      await Swal.fire({
        icon: "error",
        text: response.data.message + " Entre em contato com o Suporte.",
      });
    }
  }

  adicaoDeDadosResponse(response) {
    // Mess code
    this.vm.insumosLinhaPaineis = response.data.data.insumos;
    this.vm.detalhesDoPedido = response.data.data;
    this.vm.volumesLinhaPaineis = response.data.data.volumes;
    this.vm.horaInicialBipagem = dateTimeInFormatYmdhis();

    // Correct code
    const { data } = response.data;
    let index = 0;
    this.vm.insumos = data.insumos.map((i) => ({
      insumoId: i.insumoId,
      winthorCodigoProduto: i.winthorCodigoProduto,
      insumoDescricao: i.insumoDescricao,
      bipado: i.bipado,
      serial: i.serial,
      quantidade: i.quantidade,
      sspedidoNumSeq: i.sspedidoNumSeq,
      volumeId: i.volumeId,
      index: ++index,
    }));
    index = 0;
    this.vm.volumes = data.volumes.map((v) => ({
      volumeId: v.volumeId,
      codigo: v.codigo,
      insumos: this.vm.insumos.filter((i) => i.volumeId === v.volumeId),
      index: ++index,
    }));
    this.vm.pedido = {
      numeroPedido: data.numeroPedido,
      clienteNome: data.clienteNome,
      transportadoraNome: data.transportadoraNome,
      transportadoraCodigo: data.transportadoraCodigo,
      kitDescricao: data.kitDescricao,
      dataProducao: data.dataProducao,
      status: data.linhaProducao1Status.toUpperCase(),
    };
    //
  }

  // async enviarInsumoBipadoLinhaPainel(body){
  //     const response = await this.pedidoWebRepository.biparInsumoPaineis(body)
  //     this.vm.insumosBipados.length = 0;
  //     return response
  // }
}

import router from "../../router";

export default class ContinuacaoProducaoPedido {
  numeroPedido = 0;
  vm;
  static build() {
    return new ContinuacaoProducaoPedido();
  }

  setNumeroPedido(numeroPedido) {
    this.numeroPedido = numeroPedido;
    return this;
  }

  setTipoPedido(tipoPedido) {
    this.tipoPedido = tipoPedido;
    return this;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async continuarProducaoPedido() {
    await router.push({
      name: "pedidoWebProducaoSelecaoPaineis",
      params: { numped: this.numeroPedido },
      query: {
        tipoPedido: this.tipoPedido,
      },
    });
  }

  async continuarProducaoPedidoExpress() {
    await router.replace({
      name: "PedidoWebProducaoSelecaoLinhasExpress",
      params: { numped: this.numeroPedido },
      query: {
        tipoPedido: this.tipoPedido,
      },
    });
  }
}

import { HttpRestService } from "@/services/http/HttpRestService";

export default class InsumoPedidoRepository {
  static build() {
    return new InsumoPedidoRepository();
  }

  getLinhaDiversos(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/linha/diversos`
    );
  }

  getLinhaCabos(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/linha/cabos`
    );
  }

  getLinhaPerfis(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/linha/perfis`
    );
  }

  getLinhaInversores(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/linha/inversores`
    );
  }

  getRelacaoInsumosQuantidade(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/relacao-insumos-quantidade`
    );
  }
}

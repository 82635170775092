<template>
  <div>
    <h1>
      <b-row class="texto-carregamento">
        <b-col>
          Aguarde enquanto o sistema está carregando<br />
          as informações desse pedido...<br />
          <!-- Estamos carregando as<br />
          informações da página. Por favor, aguarde!<br /> -->

          <br />
          <b-spinner
            type="border"
            class="align-self-center color"
            style="width: 150px; height: 150px; text-align: center"
          />
          <br />
        </b-col>
      </b-row>
    </h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.texto-carregamento {
  color: black;
  text-align: center;
  animation: animate 1s linear infinite;
  padding: 10%;
}
@keyframes animate {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.3;
  }
}

.color {
  color: #ff7d27 !important;
}
</style>

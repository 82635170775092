import { HttpRestService } from "@/services/http/HttpRestService";

export default class Repository {
  static getGroundMounts() {
    return HttpRestService.get(`/api/v2/insumo/coeficiente/mesa-solo`);
  }
  static editGroundMount(body) {
    return HttpRestService.put(`/api/v2/insumo/coeficiente/mesa-solo`, body);
  }
}

<template>
  <div>
    <div class="log"></div>
    <div class="content">
      <div class="box-content" v-if="!isLoading">
        <line-header
          v-if="pedidos"
          :numero-linha="'Conferência Final - Sou Serials'"
          :numero-pedido="pedidos.numeroPedido"
          :pedidoTransportadora="pedidos.transportadoraNome"
          :pedidoDataSeProd="pedidos.dataProducao | dateDMY"
          :pedidoStatus="pedidos.pedidoStatusWinthor"
          :volumesLinhaPaineis="list.pedidos1.quantidadeVolumes || 0"
          :quantidade-caixas="list.total"
        >
        </line-header>
        <b-row align-h="end" class="mr-1">
          <default-button-caixa
            nameButton="Informar Problema"
            class="text-center my-4"
            @click="$bvModal.show('bv-modal-mudar-report')"
          ></default-button-caixa>
        </b-row>
        <div class="pedido-det" v-if="pedidosVolume.length > 0">
          <!-- <div class="topo-det">
            <div class="det-info">
              <div class="item">
                <b-row align-h="between">
                  <b-col cols="9">
                    CONFERIR PEDIDO: {{ pedidos.numeroPedido }} - Transportadora:
                    {{ pedidos[0].TRANSPORTADORA }}
                  </b-col>
                  <b-col cols="3">
                    <default-button
                      name="Confirmação com Problema"
                      color="red-color"
                      @click="$bvModal.show('bv-modal-mudar-report')"
                      style="border-radius: 5px !important"
                    ></default-button>
                  </b-col>
                </b-row>
              </div>
              <div></div>
            </div>
          </div> -->
          <!-- <div class="det-info">
            <div class="item">
              <span>CLIENTE:</span> {{ pedidos[0].CLIENTE }}
            </div>
            <div class="item">
              <span>DATA DE PRODUÇÃO:</span>
              {{ formatDate(pedidos[0].DATASEPROD) }}
            </div>
          </div>
          <div class="det-info">
            <div class="item"><span>KIT:</span> {{ pedidos.kitDescricao }}</div>
            <div class="item">
              <span>LOCALIZAÇÃO DO ESTOQUE: </span
              >{{ pedidos[0].LOCALIZACAOPEDIDO }}
            </div>
          </div> -->

          <div class="prods" id="prods">
            <table class="table table-bordered" v-if="list.pedidos1.length > 0">
              <thead>
                <tr>
                  <th>Linha 1 - Painéis</th>
                  <th width="120">
                    Volume: {{ list.pedidos1[0].quantidadeVolumes }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in list.pedidos1" :key="index">
                  <td colspan="2">
                    {{ pedido.codigoProdutoMateriaPrima }} -
                    {{ pedido.descricao }}
                    <span class="qt-table"
                      >QUANTIDADE: {{ pedido.quantidade }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-bordered" v-if="list.pedidos2.length > 0">
              <thead>
                <tr>
                  <th>Linha 2 - Cabos</th>
                  <th width="120">
                    Volume: {{ list.pedidos2[0].quantidadeVolumes }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in list.pedidos2" :key="index">
                  <td colspan="2">
                    {{ pedido.codigoProdutoMateriaPrima }} -
                    {{ pedido.descricao }}
                    <span class="qt-table"
                      >QUANTIDADE: {{ pedido.quantidade }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-bordered" v-if="list.pedidos3.length > 0">
              <thead>
                <tr>
                  <th>Linha 3 - Diversos</th>
                  <th width="120">
                    Volume: {{ list.pedidos3[0].quantidadeVolumes }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in list.pedidos3" :key="index">
                  <td colspan="2">
                    {{ pedido.codigoProdutoMateriaPrima }} -
                    {{ pedido.descricao }}
                    <span class="qt-table"
                      >QUANTIDADE: {{ pedido.quantidade }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="table table-bordered" v-if="list.pedidos4.length > 0">
              <thead>
                <tr>
                  <th>Linha 4 - Perfis</th>
                  <th width="120">
                    Volume: {{ list.pedidos4[0].quantidadeVolumes }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in list.pedidos4" :key="index">
                  <td colspan="2">
                    {{ pedido.codigoProdutoMateriaPrima }} -
                    {{ pedido.descricao }}
                    <span class="qt-table"
                      >QUANTIDADE: {{ pedido.quantidade }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="table table-bordered" v-if="list.pedidos5.length > 0">
              <thead>
                <tr>
                  <th>Linha 5 - Inversores</th>
                  <th width="120">
                    Volume: {{ list.pedidos5[0].quantidadeVolumes }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in list.pedidos5" :key="index">
                  <td colspan="2">
                    {{ pedido.codigoProdutoMateriaPrima }} -
                    {{ pedido.descricao }}
                    <span class="qt-table"
                      >QUANTIDADE: {{ pedido.quantidade }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>

            <div>
              <b-row align-h="between">
                <b-col cols="5" sm="3" md="3" lg="3" xl="3" align-self="center">
                  <!-- <default-button
                    name="Alterar volumes"
                    @click="showVol"
                    color="red-color"
                    style="border-radius: 5px !important"
                  >
                  </default-button> -->
                </b-col>
                <b-col cols="6" sm="6" md="4" lg="4" xl="4" align-self="center">
                  <h4 style="text-align: right">
                    Total Volumes: {{ list.total }}
                  </h4>
                  <br />
                </b-col>
              </b-row>
            </div>

            <div class="prods">
              <div class="item">
                <div class="linha linha-1" v-show="insert_vol > 0 && false">
                  <div class="topo">
                    <div class="det-info">
                      <h3>Alterar Volumes</h3>
                      <h3>{{ pedidos.kitDescricao }}</h3>
                    </div>
                  </div>

                  <div class="infos">
                    <div class="det-info">
                      <div class="item item-cod">
                        <label>Volume Linha 1</label>
                        <input type="number" class="volume1" />
                      </div>
                      <div class="item item-cod">
                        <label>Volume Linha 2</label>
                        <input type="number" class="volume2" />
                      </div>
                      <div class="item item-cod">
                        <label>Volume Linha 3</label>
                        <input type="number" class="volume3" />
                      </div>
                      <div class="item item-cod">
                        <label>Volume Linha 4</label>
                        <input type="number" class="volume4" />
                      </div>
                      <div class="item item-cod">
                        <label>Volume Linha 5</label>
                        <input type="number" class="volume5" />
                      </div>

                      <div class="item item-send">
                        <button type="submit" class="btn send" @click="saveVol">
                          Enviar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="prods" v-show="mostra_motivo > 0">
              <div class="item">
                <div class="linha linha-1">
                  <div class="topo">
                    <div class="det-info">
                      <h3>Confirmação com Problema</h3>
                      <h3>{{ pedidos.kitDescricao }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <b-row
                align-h="between"
                class="mx-0 mt-2 mx-sm-5 mt-sm-5 mx-md-5 mt-md-5 mx-lg-5 mt-lg-5 mx-xl-5 mt-xl-5"
              >
                <b-col
                  cols="6"
                  sm="3"
                  md="4"
                  lg="4"
                  xl="4"
                  align-self="center"
                  class="mx-0 mt-2 mx-sm-5 mt-sm-5 mx-md-5 mt-md-2 mx-lg-5 mt-lg-2 mx-xl-5 mt-xl-2"
                >
                  <default-button-caixa
                    :nameButton="'Detalhar Cargas'"
                    v-if="mostraBtnDetalharCarga"
                    :disabled="!habilitaBtnDetalharCarga"
                    :loading="btnDetalharCargaLoading"
                    class="text-center my-4"
                    @click="detalharCargas"
                  />
                </b-col>
                <b-col
                  cols="6"
                  sm="3"
                  md="4"
                  lg="4"
                  xl="4"
                  align-self="center"
                  class="mx-0 mt-2 mx-sm-5 mt-sm-5 mx-md-5 mt-md-2 mx-lg-5 mt-lg-2 mx-xl-5 mt-xl-2"
                >
                  <default-button-caixa
                    :nameButton="'Fechar Carga'"
                    v-if="mostraBtnFecharCarga"
                    :disabled="!habilitaBtnFecharCarga"
                    :loading="btnFecharCargaLoading"
                    class="text-center my-4"
                    @click="fecharCarga"
                  />
                  <default-button-caixa
                    :nameButton="'Finalizar Conferencia'"
                    v-if="mostraBtnFinalizarConferencia"
                    :disabled="!habilitaBtnFinalizarConferencia"
                    :loading="btnFinalizarConferenciaLoading"
                    class="text-center my-4"
                    @click="finalizarConferencia"
                  />
                </b-col>
              </b-row>
            </div>

            <div class="prods" v-if="bipar_volumes > 0">
              <div class="item">
                <div class="linha linha-1">
                  <div class="topo">
                    <div class="det-info" style="color: white">
                      <h3>BIPAR VOLUMES</h3>
                      <h3>{{ pedidos.kitDescricao }}</h3>
                    </div>
                  </div>

                  <div class="infos">
                    <div v-if="pedidosVolumeListaveis.length">
                      <div v-for="(volume, n) in pedidosVolume" :key="n">
                        <div v-if="volume.carga_id == 0">
                          <b-row align-h="center" align-v="end" class="my-3">
                            <b-col cols="12">
                              <h4 class="font-weight-bold topo--text">
                                Volume {{ n + 1 }}
                              </h4>
                            </b-col>
                            <b-col cols="12">
                              <input
                                type="number"
                                :ref="'volume-' + n"
                                :class="'volume-' + n"
                                :id="'volume-' + n"
                                class="volume"
                                @keydown="checkEnter(numped, n, $event, volume)"
                              />
                            </b-col>
                          </b-row>
                        </div>
                      </div>
                    </div>
                    <div v-else class="text-center my-3 gray--text font-italic">
                      Não há volumes disponivéis para bipagem.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="text-center my-3 gray--text font-italic">
          Este pedido não possui volumes.
        </div>
      </div>
      <div v-else>
        <alerta-de-espera />
      </div>
    </div>
    <div>
      <b-modal data-bv id="bv-modal-mudar-report" hide-footer>
        <template #modal-title><h3>Reportar Problema</h3></template>
        <form @submit.prevent="motivo(pedidos.numeroPedido)">
          <b-form-group>
            <input type="text" class="motivo" />
          </b-form-group>

          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Enviar</button>
          </div>
        </form>
      </b-modal>
    </div>
    <modal-detalhes-conferencia-final
      :volumes="pedidosVolumeDetalhes"
      v-if="mostrarModalDetalhes"
      id="modal-detalhes-conferencia-final"
    />
    <div>
      <modal-alert-conferencia-final :modalAlert="modalAlert" />
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "../../../../mixins/routesValidationMixin";
import httpRequestMixin from "../../../../mixins/httpRequestMixin";
import { UsuarioLogado } from "../../../../services/login/UsuarioLogado";
import PedidoRepository from "../../../../repositories/v1/PedidoRepository";
import PedidoWebRepository from "../../../../repositories/v2/PedidoWebRepository";
import env from "../../../../env";
import { ConferenciaFinalService } from "@/services/pedido_web_producao/conferencia_final/ConferenciaFinalService";
import ModalDetalhesConferenciaFinal from "./ConferenciaFinalComponents/ModalDetalhesConferenciaFinal";
import AlertaDeEspera from "@/components/AlertaDeEspera";
import DefaultButtonCaixa from "../components/DefaultButtonCaixa.vue";
// import DefaultButton from "@/components/DefaultButton";
import ModalAlertConferenciaFinal from "./ConferenciaFinalComponents/ModalAlertConferenciaFinal.vue";
import LineHeader from "../components/LineHeader.vue";

export default {
  name: "PedidoConfere-SS",
  components: {
    DefaultButtonCaixa,
    ModalDetalhesConferenciaFinal,
    AlertaDeEspera,
    // DefaultButton,
    ModalAlertConferenciaFinal,
    LineHeader,
  },
  mixins: [(routesValidationMixin, httpRequestMixin)],
  data() {
    return {
      mostraBtnFecharCarga: true,
      habilitaBtnFecharCarga: false,
      btnFecharCargaLoading: false,
      mostraBtnFinalizarConferencia: false,
      habilitaBtnFinalizarConferencia: true,
      btnFinalizarConferenciaLoading: false,
      mostraBtnDetalharCarga: true,
      habilitaBtnDetalharCarga: true,
      btnDetalharCargaLoading: false,
      mostrarModalDetalhes: false,
      isLoading: false,
      modalAlert: {},
      pedidoId: 0,
      volumesFecharCarga: [],
      list: [],
      pedidos: [],
      pedidosVolume: [],
      pedidosVolumeListaveis: [],
      pedidosVolumeDetalhes: [],
      vol_send: [],
      save_ped: {},
      item_save: 1,
      linha_atual: 1,
      step: 1,
      type: "",
      total_items: 0,
      numped: 0,
      insert_vol: 0,
      mostra_motivo: 0,
      bipar_volumes: 1,
      pedidoRepository: PedidoRepository.build(),
      pedidoWebRepository: PedidoWebRepository.build(),
      indexVols: 0,
      volumesConferidos: [],
      conferenciaFinalService: ConferenciaFinalService.build(),
    };
  },
  mounted() {
    this.conferenciaFinalService = ConferenciaFinalService.build().setVm(this);
    this.listarPedidosVolume();
  },
  async created() {
    try {
      this.pedidoRepository = PedidoRepository.build();
      this.pedidoWebRepository = PedidoWebRepository.build();

      const response = await this.HTTP_REQUEST_MIXIN_get(
        `/api/user-func/${UsuarioLogado.getUserId()}`
      );
      const { roles } = response.data;

      if (roles.includes("ADMIN")) {
        this.type = "ADMIN";
      } else if (roles.includes("CONFERENCIA")) {
        this.type = "CONFERENCIA";
      } else {
        window.location.href = "/dashboard";
        return;
      }

      const pedidoResponse = await this.pedidoRepository.pedidoConfere(
        this.$route.params.id
      );
      const {
        detalhesPedido,
        detalhesLinhaPaineis,
        detalhesLinhaCabos,
        detalhesLinhaDiversos,
        detalhesLinhaPerfis,
        detalhesLinhaInversores,
      } = pedidoResponse.data.data;

      if (detalhesPedido.pedidoStatusWinthor === "ac") {
        alert("O pedido não foi aprovado pelo Confêrencia AC");
        window.location.href = "/pedidos-ss/aguardando-conferencia";
        return;
      }
      const detalhesLinha = [
        detalhesLinhaPaineis[0],
        detalhesLinhaCabos[0],
        detalhesLinhaDiversos[0],
        detalhesLinhaPerfis[0],
        detalhesLinhaInversores[0],
      ];

      const total = detalhesLinha.reduce(
        (sum, item) => sum + (item?.quantidadeVolumes || 0),
        0
      );

      this.list = {
        pedidos1: detalhesLinhaPaineis,
        pedidos2: detalhesLinhaCabos,
        pedidos3: detalhesLinhaDiversos,
        pedidos4: detalhesLinhaPerfis,
        pedidos5: detalhesLinhaInversores,
        total: total,
      };

      this.pedidos = detalhesPedido;
      this.numped = detalhesPedido.numeroPedido;

      if (this.pedidos) {
        if (detalhesPedido.pedidoStatusWinthor !== "F") {
          window.location.href = "/pedidos-ss/aguardando-conferencia";
          return;
        }

        this.item_save = detalhesPedido.totalVolumes; // NUMSEQ
        this.total_items = detalhesPedido.totalVolumes;
        if (this.list.total) {
          this.$nextTick(() => {
            const className = "volume-1";
            const nextInput = document.getElementsByClassName(className)[0];
            if (nextInput) {
              nextInput.focus();
            }
          });
        }
      } else {
        alert("O pedido não tem itens para ser conferidos");
        window.location.href = "/pedidos-ss/aguardando-conferencia";
        return;
      }
    } catch (error) {
      console.error(error);
    }
  },

  watch: {
    volumesConferidos(newVolumesConferidos) {
      if (newVolumesConferidos.length) {
        this.habilitaBtnFecharCarga = true;
      }
    },
    pedidosVolumeListaveis(newPedidosVolumeListaveis) {
      if (!newPedidosVolumeListaveis.length) {
        this.mostraBtnFecharCarga = false;
        this.mostraBtnFinalizarConferencia = true;
      }
    },
  },
  computed: {
    vols() {
      const pedidos = [
        this.list.pedidos1[0],
        this.list.pedidos2[0],
        this.list.pedidos3[0],
        this.list.pedidos4[0],
        this.list.pedidos5[0],
      ];
      console.log(pedidos);
      const vols = [];

      pedidos.forEach((pedido, index) => {
        if (pedido) {
          const quantidadeVolumes = pedido.quantidadeVolumes || 0;
          const numped = this.pedidos.numeroPedido;
          let count = 1;

          for (let i = 0; i < quantidadeVolumes; i++) {
            const cod = count.toString().padStart(3, "0");
            vols.push(`${numped}${index + 1}${cod}`);
            count++;
          }
        }
      });

      return vols;
    },
  },
  methods: {
    finalizarConferencia() {
      this.conferenciaFinalService.finalizarConferencia();
    },
    fecharCarga() {
      this.conferenciaFinalService.fecharCarga().then(() => {
        this.conferenciaFinalService.listarConferencia(this.$route.params.id);
      });
    },
    async listarPedidosVolume() {
      this.isLoading = true;
      await this.conferenciaFinalService.listarConferencia(
        this.$route.params.id
      );
      this.isLoading = false;
    },
    detalharCargas() {
      this.mostrarModalDetalhes = true;
      this.$bvModal.show("modal-detalhes-conferencia-final");
    },
    zerarLinha(id) {
      if (confirm("Confirma Zerar Linha 3?")) {
        this.save_ped = {
          numped: id,
          linha: 3,
          type: "conf",
        };
        this.pedidoRepository
          .pedidoLimpaLinha(this.save_ped)
          .then(() => {
            alert("Linha foi zerada com sucesso!");
            window.location.href = "/linha-prod";
          })
          .catch(function () {
            window.location.href = "/dashboard";
          });
      }
    },
    cleanInput(checkEmpty) {
      checkEmpty.disabled = true;
      checkEmpty.value = "";
      checkEmpty.disabled = false;
      checkEmpty.focus();
    },
    checkEnter(id, item, e, volume) {
      const checkEmpty = document.querySelector(".volume-" + item);
      if (
        e.keyCode !== undefined &&
        (e.keyCode === 0 || e.keyCode === 13 || e.keyCode === 229)
      ) {
        let self = this;
        setTimeout(() => {
          if (
            checkEmpty.value &&
            checkEmpty.value.length > 4 &&
            checkEmpty.value.trim().length > 0
          ) {
            self.bipaVol(id, item, e, volume);
          } else {
            this.cleanInput(checkEmpty);
          }
        }, 10);
      }
    },
    bipaVol(id, item, e) {
      console.log(id, item, e);
      const val_check = document.querySelector(".volume-" + item);
      // const val_correct = this.vols[this.indexVols];
      if (
        e.keyCode !== undefined &&
        (e.keyCode === 0 || e.keyCode === 13 || e.keyCode === 229)
      ) {
        const codigoBarras = val_check.value.trim();
        const volumesBipados = this.pedidosVolumeDetalhes.map(
          (i) => i.volume_codigo
        );
        if (
          this.volumesConferidos.includes(codigoBarras) ||
          volumesBipados.includes(codigoBarras)
        ) {
          alert("Esse volume já foi bipado.");
          val_check.value = "";
          // this.habilitaBtnFecharCarga = false;
          return;
        }
        if (
          this.vols.includes(codigoBarras) &&
          !volumesBipados.includes(codigoBarras)
        ) {
          const getSelectedVolume = this.pedidosVolume.find(
            (c) => c.volume_codigo == codigoBarras
          );
          const volumeId = getSelectedVolume.volume_id;
          this.volumesFecharCarga.push(volumeId);
          const idName = "volume-" + item;
          document.getElementById(idName).setAttribute("disabled", "disabled");
          let next_item = item + 1;
          if (document.querySelector(".volume-" + next_item)) {
            this.volumesConferidos.push(codigoBarras);
            this.indexVols++;
            const className = "volume-" + next_item;
            const nextInput = document.getElementsByClassName(className)[0];
            nextInput.focus();
          }
          const inputVols = document.querySelectorAll("input.volume");
          const emptyInputs = Array.from(inputVols).filter(
            (input) => input.value === ""
          );
          if (emptyInputs.length == 0) {
            this.volumesConferidos.push(codigoBarras);
            this.save_ped = {
              numped: this.pedidos.numeroPedido,
            };
          }
        } else {
          alert("Código do volume está incorreto.");

          val_check.value = "";
        }
      }
    },
    impEt(numped, pag) {
      if (window.confirm("Confirma imprimir volume?")) {
        const vol1 = document.querySelector(".vol1").value;
        const vol2 = document.querySelector(".vol2").value;
        if (pag && vol1 && vol2) {
          window.open(
            "http://" +
              window.location.host +
              "/pedido/volume-imp-page/" +
              numped +
              "/" +
              vol1 +
              "/" +
              vol2
          );
        } else {
          window.open(
            "http://" +
              window.location.host +
              "/pedido/volume-imp-total/" +
              +numped
          );
        }
      }
    },
    problema() {
      this.mostra_motivo = 1;
    },
    motivo(id) {
      const motivo = document.querySelector(".motivo").value;
      if (confirm("Confirma envio do problema na conferência?")) {
        if (!motivo) {
          alert("Favor digitar um motivo");
        } else {
          this.save_ped = {
            numped: id,
            motivo: motivo,
          };
          this.pedidoRepository
            .confereComProblema(this.save_ped)
            .then(() => {
              alert(
                "Pedido foi enviado para listagem de Conferência com Problema"
              );
              window.location.href = "/confere-com-problema";
            })
            .catch(function () {});
        }
      }
    },
    showVol() {
      this.insert_vol = 1;
    },
    saveVol() {
      if (confirm("Confirma alteração nos volumes?")) {
        this.save_ped = {
          numped: this.$route.params.id,
          volume1: document.querySelector(".volume1").value,
          volume2: document.querySelector(".volume2").value,
          volume3: document.querySelector(".volume3").value,
          volume4: document.querySelector(".volume4").value,
          volume5: document.querySelector(".volume5").value,
        };
        this.pedidoRepository
          .alteraVolume(this.save_ped)
          .then(() => {
            window.location.href =
              "/pedido-confere/" + this.pedidos.numeroPedido;
            window.open(
              `${env.baseUrl}/qr-code/${this.pedidos.numeroPedido}/insumos`
            );
          })
          .catch(function (e) {
            console.error(e);
          });
      }
    },
    removeConfere(item, type, linha, e) {
      if (confirm("Confirma remover confirmado?")) {
        if (
          (e.keyCode !== undefined &&
            (e.keyCode === 0 || e.keyCode === 13 || e.keyCode === 229)) ||
          type === "N"
        ) {
          this.save_ped = {
            item: item,
            linha: linha,
            numped: this.pedidos.numeroPedido,
          };
          this.pedidoRepository
            .pedidoRetirarConfere(this.save_ped)
            .then((res) => {
              if (res.data) {
                let bt = document.querySelector(".bt-" + res.data);
                bt.classList.remove("ok");
                bt.innerText = "Confirmar";
                let remove = document.querySelector(".remove-" + res.data);
                remove.classList.add("hide");
              }
            })
            .catch(function (error) {
              console.error(error);
            });
        }
      }
    },
    formatDate(data) {
      let out = "";
      if (data) out = data.substr(0, 10).split("-").reverse().join("/");
      return out;
    },
  },
};
</script>

<style scoped>
.linha.linha-1 {
  background: #f1f1f1 !important;
}
.topo {
  background: #120947 !important;
}
</style>

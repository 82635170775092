import PainelBase from "./PainelBase";

export default class PainelDahMono545W extends PainelBase {
  getCode = () => 17429;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 17429 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 17429)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 17429 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 17429 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 4) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 17429
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +-------+---------+---------+-------+
                    |PREFIX |Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +-------+---------+---------+-------+
                    |1858210|44174    |1837     |72%    |
                    |8012107|12768    |556      |21%    |
                    |8582105|419      |93       |1%     |
                    |8210510|383      |97       |1%     |
                    |5821051|254      |82       |0%     |
                    |012107D|158      |33       |0%     |
                    |8582106|156      |61       |0%     |
                    |8582102|149      |54       |0%     |
                    |8582103|146      |52       |0%     |
                    |8582101|143      |45       |0%     |
                    |8582104|133      |47       |0%     |
                    |8210310|125      |43       |0%     |
                    |8210410|109      |46       |0%     |
                    |2105100|102      |50       |0%     |
                    |8210610|100      |49       |0%     |
                    |8210110|98       |50       |0%     |
                    |5821031|94       |36       |0%     |
                    |5821061|93       |40       |0%     |
                    |8210210|93       |39       |0%     |
                    |5821011|83       |35       |0%     |
                    |5821021|81       |42       |0%     |
                    |5821041|80       |42       |0%     |
                    |2106100|62       |23       |0%     |
                    |2107D05|59       |24       |0%     |
                    |2101100|55       |20       |0%     |
                    |12107D0|53       |23       |0%     |
                    |2102100|51       |24       |0%     |
                    |2103100|49       |26       |0%     |
                    |1585210|47       |9        |0%     |
                    |1051004|43       |21       |0%     |
                    |2104100|37       |15       |0%     |
                    |1210718|29       |2        |0%     |
                    |1031004|29       |10       |0%     |
                    |8582107|27       |7        |0%     |
                    |107D050|26       |17       |0%     |
                    |1011004|24       |7        |0%     |
                    |1061004|22       |6        |0%     |
                    |0510040|20       |14       |0%     |
                    |1041004|16       |7        |0%     |
                    |0410040|15       |8        |0%     |
                    |0610040|14       |7        |0%     |
                    |07D0500|14       |11       |0%     |
                    |1021004|14       |8        |0%     |
                    |0210040|13       |6        |0%     |
                    |7420210|11       |1        |0%     |
                    |U624MM2|11       |1        |0%     |
                    |5821060|11       |8        |0%     |
                    |5821020|11       |6        |0%     |
                    |2106050|10       |5        |0%     |
                    |0110040|10       |5        |0%     |
                    |7D05004|9        |5        |0%     |
                    |8210204|8        |5        |0%     |
                    |8210605|8        |6        |0%     |
                    |1004054|7        |2        |0%     |
                    |1100405|7        |3        |0%     |
                    |4202105|7        |1        |0%     |
                    |5821040|7        |5        |0%     |
                    |5821070|7        |6        |0%     |
                    |8210104|6        |3        |0%     |
                    |8210705|6        |4        |0%     |
                    |D050043|6        |4        |0%     |
                    |9928210|6        |2        |0%     |
                    |0210501|6        |1        |0%     |
                    |0310040|6        |5        |0%     |
                    |5100402|6        |5        |0%     |
                    |1004055|5        |4        |0%     |
                    |185821&|5        |5        |0%     |
                    |0500434|5        |2        |0%     |
                    |2021050|5        |1        |0%     |
                    |0500425|5        |2        |0%     |
                    |6100405|5        |2        |0%     |
                    |2107D02|5        |2        |0%     |
                    |0040542|4        |2        |0%     |
                    |1060500|4        |3        |0%     |
                    |1004056|4        |2        |0%     |
                    |5100401|4        |3        |0%     |
                    |1858200|3        |1        |0%     |
                    |6100404|3        |3        |0%     |
                    |07D0213|3        |3        |0%     |
                    |0040533|3        |1        |0%     |
                    |D050041|3        |2        |0%     |
                    |D050042|3        |1        |0%     |
                    |1004053|3        |1        |0%     |
                    |4100405|3        |2        |0%     |
                    |8210504|3        |1        |0%     |
                    |1004069|3        |2        |0%     |
                    |0605004|3        |2        |0%     |
                    |8210205|3        |2        |0%     |
                    |1100404|3        |3        |0%     |
                    |7D02132|3        |3        |0%     |
                    |8210405|3        |3        |0%     |
                    |2107050|3        |3        |0%     |
                    |1004045|3        |2        |0%     |
                    |5821010|3        |3        |0%     |
                    |107D021|3        |3        |0%     |
                    |8210604|2        |1        |0%     |
                    |0040532|2        |2        |0%     |
                    |1020401|2        |1        |0%     |
                    |1004068|2        |2        |0%     |
                    |1858512|2        |1        |0%     |
                    |2102050|2        |1        |0%     |
                    |1050401|2        |1        |0%     |
                    |;801210|2        |1        |0%     |
                    |1600601|2        |1        |0%     |
                    |9185821|2        |2        |0%     |
                    |0104013|2        |1        |0%     |
                    |0405426|2        |1        |0%     |
                    |0040521|2        |1        |0%     |
                    |1004061|2        |1        |0%     |
                    |2107180|2        |1        |0%     |
                    |0405891|2        |2        |0%     |
                    |185821(|2        |2        |0%     |
                    |2105050|2        |2        |0%     |
                    |5004342|2        |2        |0%     |
                    |1010401|2        |2        |0%     |
                    |2100405|2        |1        |0%     |
                    |0040535|2        |2        |0%     |
                    |5004257|2        |1        |0%     |
                    |1004020|2        |2        |0%     |
                    |3100404|2        |2        |0%     |
                    |2101040|2        |1        |0%     |
                    |5185821|2        |1        |0%     |
                    |5100404|2        |2        |0%     |
                    |1858100|2        |2        |0%     |
                    |3200130|1        |1        |0%     |
                    |4018063|1        |1        |0%     |
                    |0040572|1        |1        |0%     |
                    |2118582|1        |1        |0%     |
                    |8210500|1        |1        |0%     |
                    |1622185|1        |1        |0%     |
                    |72818  |1        |1        |0%     |
                    |8300005|1        |1        |0%     |
                    |0040415|1        |1        |0%     |
                    |6000020|1        |1        |0%     |
                    |21858  |1        |1        |0%     |
                    |0040518|1        |1        |0%     |
                    |55653  |1        |1        |0%     |
                    |0418582|1        |1        |0%     |
                    |2007180|1        |1        |0%     |
                    |0405929|1        |1        |0%     |
                    |4046898|1        |1        |0%     |
                    |59396  |1        |1        |0%     |
                    |46411  |1        |1        |0%     |
                    |4069814|1        |1        |0%     |
                    |4043462|1        |1        |0%     |
                    |2300321|1        |1        |0%     |
                    |1858051|1        |1        |0%     |
                    |1858204|1        |1        |0%     |
                    |1810040|1        |1        |0%     |
                    |1810110|1        |1        |0%     |
                    |2500269|1        |1        |0%     |
                    |5004315|1        |1        |0%     |
                    |6050041|1        |1        |0%     |
                    |0425697|1        |1        |0%     |
                    |801217D|1        |1        |0%     |
                    |8005437|1        |1        |0%     |
                    |058172 |1        |1        |0%     |
                    |5821050|1        |1        |0%     |
                    |5435618|1        |1        |0%     |
                    |429218 |1        |1        |0%     |
                    |3003381|1        |1        |0%     |
                    |5204718|1        |1        |0%     |
                    |4054302|1        |1        |0%     |
                    |360218 |1        |1        |0%     |
                    |0405140|1        |1        |0%     |
                    |0040529|1        |1        |0%     |
                    |0405609|1        |1        |0%     |
                    |2300282|1        |1        |0%     |
                    |4045584|1        |1        |0%     |
                    |051404 |1        |1        |0%     |
                    |0405742|1        |1        |0%     |
                    |185821E|1        |1        |0%     |
                    |0178081|1        |1        |0%     |
                    |57161  |1        |1        |0%     |
                    |56628  |1        |1        |0%     |
                    |4011000|1        |1        |0%     |
                    |0554061|1        |1        |0%     |
                    |0040218|1        |1        |0%     |
                    |41908  |1        |1        |0%     |
                    |1045146|1        |1        |0%     |
                    |5004096|1        |1        |0%     |
                    |1014576|1        |1        |0%     |
                    |((40FC%|1        |1        |0%     |
                    |1100406|1        |1        |0%     |
                    |0402482|1        |1        |0%     |
                    |4021155|1        |1        |0%     |
                    |1804051|1        |1        |0%     |
                    |1831004|1        |1        |0%     |
                    |7050041|1        |1        |0%     |
                    |0040827|1        |1        |0%     |
                    |0043166|1        |1        |0%     |
                    |8320180|1        |1        |0%     |
                    |415500 |1        |1        |0%     |
                    |1020500|1        |1        |0%     |
                    |4948012|1        |1        |0%     |
                    |5004340|1        |1        |0%     |
                    |0500431|1        |1        |0%     |
                    |5004310|1        |1        |0%     |
                    |0040235|1        |1        |0%     |
                    |6700036|1        |1        |0%     |
                    |0401300|1        |1        |0%     |
                    |0405642|1        |1        |0%     |
                    |73118  |1        |1        |0%     |
                    |0040541|1        |1        |0%     |
                    |0405623|1        |1        |0%     |
                    |4013003|1        |1        |0%     |
                    |8582151|1        |1        |0%     |
                    |058214 |1        |1        |0%     |
                    |0040576|1        |1        |0%     |
                    |4058029|1        |1        |0%     |
                    |051891 |1        |1        |0%     |
                    |055881 |1        |1        |0%     |
                    |0405434|1        |1        |0%     |
                    |0040548|1        |1        |0%     |
                    |0040547|1        |1        |0%     |
                    |0040458|1        |1        |0%     |
                    |2102040|1        |1        |0%     |
                    |0425931|1        |1        |0%     |
                    |6897185|1        |1        |0%     |
                    |0405956|1        |1        |0%     |
                    |1100333|1        |1        |0%     |
                    |0619601|1        |1        |0%     |
                    |4055360|1        |1        |0%     |
                    |0040146|1        |1        |0%     |
                    |4017953|1        |1        |0%     |
                    |0406641|1        |1        |0%     |
                    |4070107|1        |1        |0%     |
                    |0040696|1        |1        |0%     |
                    |1841405|1        |1        |0%     |
                    |8210305|1        |1        |0%     |
                    |0500410|1        |1        |0%     |
                    |0500436|1        |1        |0%     |
                    |0500439|1        |1        |0%     |
                    |3016717|1        |1        |0%     |
                    |8210505|1        |1        |0%     |
                    |431544 |1        |1        |0%     |
                    |0425497|1        |1        |0%     |
                    |%+80121|1        |1        |0%     |
                    |]185821|1        |1        |0%     |
                    |6400021|1        |1        |0%     |
                    |1100125|1        |1        |0%     |
                    |4055276|1        |1        |0%     |
                    |613185 |1        |1        |0%     |
                    |0405534|1        |1        |0%     |
                    |3611858|1        |1        |0%     |
                    |6364185|1        |1        |0%     |
                    |0558491|1        |1        |0%     |
                    |5585518|1        |1        |0%     |
                    |4053984|1        |1        |0%     |
                    |0565411|1        |1        |0%     |
                    |0040544|1        |1        |0%     |
                    |3100405|1        |1        |0%     |
                    |4051405|1        |1        |0%     |
                    |0533651|1        |1        |0%     |
                    |0547091|1        |1        |0%     |
                    |0405485|1        |1        |0%     |
                    |0040571|1        |1        |0%     |
                    |4046886|1        |1        |0%     |
                    |0469391|1        |1        |0%     |
                    |1004062|1        |1        |0%     |
                    |059502 |1        |1        |0%     |
                    |4014903|1        |1        |0%     |
                    |4073658|1        |1        |0%     |
                    |2300328|1        |1        |0%     |
                    |F85*F1(|1        |1        |0%     |
                    |1858310|1        |1        |0%     |
                    |0500415|1        |1        |0%     |
                    |6200178|1        |1        |0%     |
                    |4135411|1        |1        |0%     |
                    |8006372|1        |1        |0%     |
                    |8006082|1        |1        |0%     |
                    |0041870|1        |1        |0%     |
                    |0042549|1        |1        |0%     |
                    |4248408|1        |1        |0%     |
                    |1210703|1        |1        |0%     |
                    |0040249|1        |1        |0%     |
                    |2115102|1        |1        |0%     |
                    |2105010|1        |1        |0%     |
                    |2600316|1        |1        |0%     |
                    |9000004|1        |1        |0%     |
                    |9100001|1        |1        |0%     |
                    |3014836|1        |1        |0%     |
                    |2300253|1        |1        |0%     |
                    |2103040|1        |1        |0%     |
                    |3015275|1        |1        |0%     |
                    |1*58210|1        |1        |0%     |
                    |0547691|1        |1        |0%     |
                    |0040545|1        |1        |0%     |
                    |4054242|1        |1        |0%     |
                    |0542185|1        |1        |0%     |
                    |1004041|1        |1        |0%     |
                    |0542371|1        |1        |0%     |
                    |5549618|1        |1        |0%     |
                    |1858206|1        |1        |0%     |
                    |0405326|1        |1        |0%     |
                    |4053423|1        |1        |0%     |
                    |0040564|1        |1        |0%     |
                    |0405051|1        |1        |0%     |
                    |0405705|1        |1        |0%     |
                    |4057287|1        |1        |0%     |
                    |0403943|1        |1        |0%     |
                    |0040396|1        |1        |0%     |
                    |0172391|1        |1        |0%     |
                    |0040585|1        |1        |0%     |
                    |4059292|1        |1        |0%     |
                    |1858F1E|1        |1        |0%     |
                    |18582F0|1        |1        |0%     |
                    |1040501|1        |1        |0%     |
                    |0401282|1        |1        |0%     |
                    |0505018|1        |1        |0%     |
                    |5050180|1        |1        |0%     |
                    |1010501|1        |1        |0%     |
                    |1810410|1        |1        |0%     |
                    |1810580|1        |1        |0%     |
                    |7180071|1        |1        |0%     |
                    |8320020|1        |1        |0%     |
                    |2500266|1        |1        |0%     |
                    |6800033|1        |1        |0%     |
                    |4029422|1        |1        |0%     |
                    |4059167|1        |1        |0%     |
                    |2300286|1        |1        |0%     |
                    |5804618|1        |1        |0%     |
                    |0040543|1        |1        |0%     |
                    |0040563|1        |1        |0%     |
                    |3441858|1        |1        |0%     |
                    |0563501|1        |1        |0%     |
                    |51726  |1        |1        |0%     |
                    |60218  |1        |1        |0%     |
                    |0562431|1        |1        |0%     |
                    |4052803|1        |1        |0%     |
                    |057833 |1        |1        |0%     |
                    |1004051|1        |1        |0%     |
                    |8005503|1        |1        |0%     |
                    |4054528|1        |1        |0%     |
                    |5100405|1        |1        |0%     |
                    |0040421|1        |1        |0%     |
                    |2461858|1        |1        |0%     |
                    |0040577|1        |1        |0%     |
                    |4058564|1        |1        |0%     |
                    |18793  |1        |1        |0%     |
                    |1076942|1        |1        |0%     |
                    |1004024|1        |1        |0%     |
                    |025291 |1        |1        |0%     |
                    |7289418|1        |1        |0%     |
                    |69520  |1        |1        |0%     |
                    |1060501|1        |1        |0%     |
                    |1850210|1        |1        |0%     |
                    |1858214|1        |1        |0%     |
                    |1858215|1        |1        |0%     |
                    |0041617|1        |1        |0%     |
                    |2104050|1        |1        |0%     |
                    |0105004|1        |1        |0%     |
                    |3016839|1        |1        |0%     |
                    |0431565|1        |1        |0%     |
                    |14024  |1        |1        |0%     |
                    |34140  |1        |1        |0%     |
                    |425475 |1        |1        |0%     |
                    |6685490|1        |1        |0%     |
                    |185*210|1        |1        |0%     |
                    |8005469|1        |1        |0%     |
                    |555418 |1        |1        |0%     |
                    |4039846|1        |1        |0%     |
                    |2105040|1        |1        |0%     |
                    |0040558|1        |1        |0%     |
                    |4053071|1        |1        |0%     |
                    |0405453|1        |1        |0%     |
                    |4054346|1        |1        |0%     |
                    |0405415|1        |1        |0%     |
                    |7900010|1        |1        |0%     |
                    |0536661|1        |1        |0%     |
                    |4118582|1        |1        |0%     |
                    |4052197|1        |1        |0%     |
                    |0405531|1        |1        |0%     |
                    |0040559|1        |1        |0%     |
                    |2300281|1        |1        |0%     |
                    |1100296|1        |1        |0%     |
                    |4023091|1        |1        |0%     |
                    |052904 |1        |1        |0%     |
                    |1004059|1        |1        |0%     |
                    |0040566|1        |1        |0%     |
                    |6893185|1        |1        |0%     |
                    |4011002|1        |1        |0%     |
                    |5821030|1        |1        |0%     |
                    |1004044|1        |1        |0%     |
                    |2452218|1        |1        |0%     |
                    |0040768|1        |1        |0%     |
                    |0407001|1        |1        |0%     |
                    |085221 |1        |1        |0%     |
                    |3015293|1        |1        |0%     |
                    |0500432|1        |1        |0%     |
                    |2500271|1        |1        |0%     |
                    |413425 |1        |1        |0%     |
                    |5004189|1        |1        |0%     |
                    |7183801|1        |1        |0%     |
                    |,801210|1        |1        |0%     |
                    |2102150|1        |1        |0%     |
                    |6500051|1        |1        |0%     |
                    |0402113|1        |1        |0%     |
                    |0040462|1        |1        |0%     |
                    |0458241|1        |1        |0%     |
                    |0569161|1        |1        |0%     |
                    |1040185|1        |1        |0%     |
                    |061818 |1        |1        |0%     |
                    |6171858|1        |1        |0%     |
                    |8218581|1        |1        |0%     |
                    |4055640|1        |1        |0%     |
                    |0040527|1        |1        |0%     |
                    |5800068|1        |1        |0%     |
                    |0405589|1        |1        |0%     |
                    |0405636|1        |1        |0%     |
                    |054026 |1        |1        |0%     |
                    |4054265|1        |1        |0%     |
                    |5356518|1        |1        |0%     |
                    |1004049|1        |1        |0%     |
                    |1040130|1        |1        |0%     |
                    |4054869|1        |1        |0%     |
                    |4054109|1        |1        |0%     |
                    |7800012|1        |1        |0%     |
                    |469901 |1        |1        |0%     |
                    |1742718|1        |1        |0%     |
                    |1030500|1        |1        |0%     |
                    |0500407|1        |1        |0%     |
                    |0668461|1        |1        |0%     |
                    |988822 |1        |1        |0%     |
                    |0406982|1        |1        |0%     |
                    |4044029|1        |1        |0%     |
                    |77485  |1        |1        |0%     |
                    |1858211|1        |1        |0%     |
                    |0213202|1        |1        |0%     |
                    |8041838|1        |1        |0%     |
                    |8006454|1        |1        |0%     |
                    |5004341|1        |1        |0%     |
                    |0500416|1        |1        |0%     |
                    |1635718|1        |1        |0%     |
                    |2103050|1        |1        |0%     |
                    |5004187|1        |1        |0%     |
                    |3185821|1        |1        |0%     |
                    |801207D|1        |1        |0%     |
                    |NULL   |0        |0        |0%     |
                    +-------+---------+---------+-------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 7 dígitos do insumo em questão, mostrando
                    439 resultados. Perceba que as iniciais com alguma relevância são as 1858210 e 8012107.
                    Portanto, vamos considerar que esse painel sempre comece com uma dessas duas sequêncisa.
            Tipagem:
                Foi verificado que os seriais desse painel são compostos apenas por letras e números.
                Consulta:
                    select
                        count(*)
                    from sspedido s
                    where
                          s.codprodmp = 17429
                          and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 17429 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 17429 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 17429
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |18    |55592    |2300     |
                    |19    |952      |191      |
                    |17    |940      |199      |
                    |16    |623      |172      |
                    |20    |591      |171      |
                    |21    |520      |179      |
                    |15    |507      |169      |
                    |22    |228      |87       |
                    |14    |212      |90       |
                    |36    |104      |18       |
                    |23    |95       |43       |
                    |13    |84       |43       |
                    |24    |70       |34       |
                    |12    |59       |32       |
                    |11    |59       |31       |
                    |26    |56       |27       |
                    |9     |54       |24       |
                    |8     |53       |33       |
                    |25    |49       |25       |
                    |7     |49       |32       |
                    |10    |48       |22       |
                    |27    |45       |24       |
                    |29    |34       |20       |
                    |28    |27       |15       |
                    |6     |22       |14       |
                    |31    |22       |15       |
                    |30    |19       |13       |
                    |35    |18       |8        |
                    |32    |17       |13       |
                    |34    |16       |10       |
                    |5     |16       |10       |
                    |33    |11       |9        |
                    |46    |9        |2        |
                    |52    |8        |2        |
                    |38    |8        |4        |
                    |39    |7        |6        |
                    |40    |7        |4        |
                    |50    |6        |3        |
                    |44    |6        |3        |
                    |42    |6        |5        |
                    |55    |5        |2        |
                    |48    |4        |2        |
                    |43    |4        |2        |
                    |37    |4        |2        |
                    |54    |4        |3        |
                    |41    |4        |2        |
                    |53    |4        |1        |
                    |45    |4        |4        |
                    |51    |3        |1        |
                    |57    |2        |1        |
                    |47    |2        |1        |
                    |67    |2        |1        |
                    |49    |2        |2        |
                    |133   |1        |1        |
                    |62    |1        |1        |
                    |66    |1        |1        |
                    |64    |1        |1        |
                    |58    |1        |1        |
                    |104   |1        |1        |
                    |61    |1        |1        |
                    |97    |1        |1        |
                    |91    |1        |1        |
                    |89    |1        |1        |
                    |70    |1        |1        |
                    |107   |1        |1        |
                    |75    |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+


                Interpretação:
                    Perceba que a quantidade de seriais com 18 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1858210 e 8012107 mais 11 dígitos alfanuméricos, totalizando 18 dígitos.
         */
    return !!this.serial.match(/^(1858210|8012107)(\d|\w){11}$/);
  }

  static build() {
    return new PainelDahMono545W();
  }
}

<template>
  <v-pagination
    class="mt-2"
    v-on="$listeners"
    v-bind="$attrs"
    prev-icon="mdi-menu-left"
    next-icon="mdi-menu-right"
    total-visible="7"
  >
  </v-pagination>
</template>

<script>
export default {
  name: "DefaultPagination",
};
</script>

<style scoped></style>

export default {
  setSortingBays(state, payload) {
    state.sortingBays = payload;
  },
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },
  setPagination(state, payload) {
    state.pagination = payload;
  },
  setUrlParams(state, payload) {
    state.urlParams = payload;
  },
  setIsRegisterBayButtonDisabled(state, payload) {
    state.isRegisterBayButtonDisabled = payload;
  },
  setIsEditBayModalOpen(state, payload) {
    state.isEditBayModalOpen = payload;
  },
  setIsRegisterBayModalOpen(state, payload) {
    state.isRegisterBayModalOpen = payload;
  },
};

export default {
  setDrawer({ commit }, value) {
    commit("setDrawer", value);
  },

  setDarkMode({ commit }, value) {
    commit("setDarkMode", value);
  },
  setEnvironment({ commit }, payload) {
    commit("setEnvironment", payload);
  },
};

import PainelBase from "./PainelBase";

export default class PainelTrina330W extends PainelBase {
  getCode = () => 61;

  isValid() {
    /*  ESTUDO DE PADRÃO DE PAINEL 61 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            +--------------------------------------------------------------------+-------------------+--------+
            |NUMEROSSERIECONTROLADOS                                             |DATASEPROD         |NUMPED  |
            +--------------------------------------------------------------------+-------------------+--------+
            |190101400828S85190101S85190101400828S851901014008                   |2021-07-16 11:30:00|58000626|
            |S69190600300062                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300106                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300024                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300049                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300060                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300058                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300068                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300108                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300086                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300087                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300109                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300122                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300131                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300104                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300069                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300113                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300137                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300090                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300136                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300418                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300071                                                     |2021-07-16 11:30:00|58000626|
            |S85190101400631                                                     |2021-07-16 11:30:00|58000626|
            |S85190101401371                                                     |2021-07-16 11:30:00|58000626|
            |S85190101402057                                                     |2021-07-16 11:30:00|58000626|
            |D02190230704919                                                     |2021-07-16 11:30:00|58000626|
            |D02190230705078                                                     |2021-07-16 11:30:00|58000626|
            |D02190230705179                                                     |2021-07-16 11:30:00|58000626|
            |D02190230704978                                                     |2021-07-16 11:30:00|58000626|
            |D02190230705123                                                     |2021-07-16 11:30:00|58000626|
            |D02190230705089                                                     |2021-07-16 11:30:00|58000626|
            |D02190230806171                                                     |2021-07-16 11:30:00|58000626|
            |D02190230806147                                                     |2021-07-16 11:30:00|58000626|
            |D02190230806107                                                     |2021-07-16 11:30:00|58000626|
            |D02190230805972                                                     |2021-07-16 11:30:00|58000626|
            |D02190230805985                                                     |2021-07-16 11:30:00|58000626|
            |D02190230805979                                                     |2021-07-16 11:30:00|58000626|
            |D02190230805325                                                     |2021-07-16 11:30:00|58000626|
            |D02190230806136                                                     |2021-07-16 11:30:00|58000626|
            |S85190101400828                                                     |2021-07-16 11:30:00|58000626|
            |S85190101502294                                                     |2021-07-16 11:30:00|58000626|
            |S85190101400039                                                     |2021-07-16 11:30:00|58000626|
            |S85190101400205                                                     |2021-07-16 11:30:00|58000626|
            |S85190101401722                                                     |2021-07-16 11:30:00|58000626|
            |S85190101401454                                                     |2021-07-16 11:30:00|58000626|
            |S85190101401372                                                     |2021-07-16 11:30:00|58000626|
            |S85190101401337                                                     |2021-07-16 11:30:00|58000626|
            |S85190101401438                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300190                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300163                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300173                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300161                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300160                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300379                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300320                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300314                                                     |2021-07-16 11:30:00|58000626|
            |S69190600400816                                                     |2021-07-16 11:30:00|58000626|
            |S85190101400026                                                     |2021-07-16 11:30:00|58000626|
            |S85190101400828S                                                    |2021-07-16 11:30:00|58000626|
            |85190101400828                                                      |2021-07-16 11:30:00|58000626|
            |5190101400828                                                       |2021-07-16 11:30:00|58000626|
            |S85190101400828S8                                                   |2021-07-16 11:30:00|58000626|
            |5190101400828S8                                                     |2021-07-16 11:30:00|58000626|
            |5190101400828S85                                                    |2021-07-16 11:30:00|58000626|
            |85190101400828S85                                                   |2021-07-16 11:30:00|58000626|
            |190101400828S                                                       |2021-07-16 11:30:00|58000626|
            |S85190101400828S85190101400                                         |2021-07-16 11:30:00|58000626|
            |S85190101400828S851                                                 |2021-07-16 11:30:00|58000626|
            |90101400828                                                         |2021-07-16 11:30:00|58000626|
            |S851901014008S851901014S851901014S851901014S                        |2021-07-16 11:30:00|58000626|
            |85190101400828S85190101400828S                                      |2021-07-16 11:30:00|58000626|
            |S8519010140S851901010S8519010140S8519010140S85190101400828          |2021-07-16 11:30:00|58000626|
            |S851901014008S85190101400828S85                                     |2021-07-16 11:30:00|58000626|
            |1901014008S85190101400828                                           |2021-07-16 11:30:00|58000626|
            |400828                                                              |2021-07-16 11:30:00|58000626|
            |S85190101400828S85                                                  |2021-07-16 11:30:00|58000626|
            |190101S8512S851901014008S                                           |2021-07-16 11:30:00|58000626|
            |85190101400828S851901                                               |2021-07-16 11:30:00|58000626|
            |0140082S851S851901S85190101400828S8519010                           |2021-07-16 11:30:00|58000626|
            |1400828                                                             |2021-07-16 11:30:00|58000626|
            |0101400828S                                                         |2021-07-16 11:30:00|58000626|
            |851901S851901014002S85190101400828S85190101400828S85190101400       |2021-07-16 11:30:00|58000626|
            |828S8519                                                            |2021-07-16 11:30:00|58000626|
            |0101408S85190S85                                                    |2021-07-16 11:30:00|58000626|
            |1901S8519010S85190101400828S                                        |2021-07-16 11:30:00|58000626|
            |8519010140082S85190101408S851901014S85190101400828                  |2021-07-16 11:30:00|58000626|
            |S85190101400828S85190101                                            |2021-07-16 11:30:00|58000626|
            |S85190101400S85190101S85190101400828S8519                           |2021-07-16 11:30:00|58000626|
            |01014008S85190101400828S                                            |2021-07-16 11:30:00|58000626|
            |19010140S85190101400S85190108S85190101400828S851901014008           |2021-07-16 11:30:00|58000626|
            |S851901S85S8519010140S85190101400828S851901014                      |2021-07-16 11:30:00|58000626|
            |S8519010140082S85190101400828S85                                    |2021-07-16 11:30:00|58000626|
            |1901014S8519S8519010140S85190101400828S8519010140082S85190101400828S|2021-07-16 11:30:00|58000626|
            |85190S851011S85190101400S85190101400S85190101400828S85190101        |2021-07-16 11:30:00|58000626|
            |1901014S85191S85190101400828S851901                                 |2021-07-16 11:30:00|58000626|
            |01400828S                                                           |2021-07-16 11:30:00|58000626|
            |8519010S8519S85190101400828S851                                     |2021-07-16 11:30:00|58000626|
            |D02190230705019                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300352                                                     |2021-07-16 11:30:00|58000626|
            |85190101400828S851900S8519010S8519010148S                           |2021-07-16 11:30:00|58000626|
            |5190101400828S85190101                                              |2021-07-16 11:30:00|58000626|
            |S850S85190S851901014S85190101400828                                 |2021-07-16 11:30:00|58000626|
            |40S85S85190101488S85190101400828S                                   |2021-07-16 11:30:00|58000626|
            |D02190230705275                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300395                                                     |2021-07-16 11:30:00|58000626|
            |00828S                                                              |2021-07-16 11:30:00|58000626|
            |S69190600300008                                                     |2021-07-16 11:30:00|58000626|
            |D02190230705276                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300227                                                     |2021-07-16 11:30:00|58000626|
            |S85190101400144                                                     |2021-07-16 11:30:00|58000626|
            |90101400828S85                                                      |2021-07-16 11:30:00|58000626|
            |S69190600300085                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300063                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300132                                                     |2021-07-16 11:30:00|58000626|
            |S85190101400704                                                     |2021-07-16 11:30:00|58000626|
            |D02190230806127                                                     |2021-07-16 11:30:00|58000626|
            |D02190230805812                                                     |2021-07-16 11:30:00|58000626|
            |S85190101401923                                                     |2021-07-16 11:30:00|58000626|
            |S85190101401404                                                     |2021-07-16 11:30:00|58000626|
            |S69190600300390                                                     |2021-07-16 11:30:00|58000626|
            |S85190101401314                                                     |2021-07-16 11:30:00|58000626|
            |190101400828                                                        |2021-07-16 11:30:00|58000626|
            |5190101400828S85190101400828                                        |2021-07-16 11:30:00|58000626|
            |5190101400828S                                                      |2021-07-16 11:30:00|58000626|
            |85190101400828S                                                     |2021-07-16 11:30:00|58000626|
            |S851S851S85190101400828S8519                                        |2021-07-16 11:30:00|58000626|
            |81S8519S8519010S85190101400828S                                     |2021-07-16 11:30:00|58000626|
            |851901S8519010140S85190101400828                                    |2021-07-16 11:30:00|58000626|
            |S85190101401356                                                     |2021-07-16 11:30:00|58000626|
            |85190101400828S8                                                    |2021-07-16 11:30:00|58000626|
            |S85190101400S85190101400828S85                                      |2021-07-16 11:30:00|58000626|
            |S69190600300089                                                     |2021-07-16 11:30:00|58000626|
            |D02190230805939                                                     |2021-07-16 11:30:00|58000626|
            |190101400828S851                                                    |2021-07-16 11:30:00|58000626|
            |S85190101400691                                                     |2021-07-16 11:30:00|58000626|
            +--------------------------------------------------------------------+-------------------+--------+

            Como pode-se observar dos dados do sistema, só existem 135 insumos em um único pedido
            desse painel. Portanto, o Winthor não possui dados suficientes para escrever uma regra de validação.
            Por isso, quando esse serial for bipado novamente, o sistema deixará passar sem regras.
         */
    return true;
  }

  static build() {
    return new PainelTrina330W();
  }
}

import { dateTimeInFormatYmdhis } from "../../dateService";
import { LocalStorageService } from "../../LocalStorageService";
import SelecaoAlgoritmoValidacaoPainel from "./validacao_paineis/SelecaoAlgoritmoValidacaoPainel";
import ValidacaoPainel from "./validacao_paineis/ValidacaoPainel";

export class BipagemService {
  insumosBipados = [];
  insumo;
  index;
  vm;
  event;
  input;
  log;
  produto;
  insumoParaRequest;
  localStorageService;
  selecaoAlgoritmoValidacaoPainel = SelecaoAlgoritmoValidacaoPainel.build();
  validacaoPainel = ValidacaoPainel.build();

  constructor(
    localStorageService,
    selecaoAlgoritmoValidacaoPainel,
    validacaoPainel
  ) {
    this.localStorageService = localStorageService;
    this.selecaoAlgoritmoValidacaoPainel = selecaoAlgoritmoValidacaoPainel;
    this.validacaoPainel = validacaoPainel;
  }

  static build() {
    const selecaoAlgoritmoValidacaoPainel =
      SelecaoAlgoritmoValidacaoPainel.build();
    const localStorageService = LocalStorageService.build();
    const validacaoPainel = ValidacaoPainel.build();
    return new BipagemService(
      localStorageService,
      selecaoAlgoritmoValidacaoPainel,
      validacaoPainel
    );
  }

  getInsumosBipados() {
    return this.vm.insumosBipados;
  }

  setInsumo(insumo) {
    this.insumo = insumo;
    return this;
  }

  setIndex(index) {
    this.index = +index;
    return this;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  setEvent(event) {
    this.event = event;
    return this;
  }

  setLog() {
    this.log = document.querySelector(".log");
  }

  run() {
    try {
      this.validateBipagem();
    } catch (error) {
      document.getElementById(`prod-cod-${this.insumo.insumoId}`).placeholder =
        error.message;
      console.error(error.message);
    }
  }

  validateBipagem() {
    this.setInput();
    this.validateIfEnterWasPressed();
    this.validateInput();
    this.setInsumoParaRequest();
    this.validarEAdicionarNaListagem();
    this.avaliarAvancoParaProximoInput();
    this.setInsumoBipado();
  }

  setInsumoBipado() {
    this.vm.insumosLinhaPaineis.forEach((value) => {
      if (value.insumoId === this.insumo.insumoId) {
        value.bipado = true;
      }
    });
  }

  setInput() {
    this.input = document.getElementById("prod-cod-" + this.insumo.insumoId);
  }

  validateIfEnterWasPressed() {
    const enterWasPressed = [0, 13, 229].includes(this.event.keyCode);
    if (!enterWasPressed) {
      throw new Error(
        "O enter não foi pressionado. Portanto, não pode avançar."
      );
    }
  }
  //TODO: Metodo usável.
  validateInput() {
    const inputIsValid =
      !this.input.disabled &&
      this.input.value.length > 4 &&
      this.input.value.trim().length > 0;
    if (!inputIsValid) {
      this.input.disabled = false;
      throw new Error(
        "O input não está pronto para ser avaliado para bipagem."
      );
    }
  }
  //TODO: Backend?
  validateIfValorDigitadoEUnico() {
    if (!this.valorDigitadoEUnico()) {
      this.cleanInput();
      alert("Esse serial já foi bipado.");
      throw new Error("O serial digitado não é único.");
    }
  }
  //TODO: ver necessidade deste método.
  cleanInput() {
    this.input.value = "";
    this.input.disabled = false;
    this.input.focus();
  }

  setInsumoParaRequest() {
    this.insumoParaRequest = {
      serial: this.input.value.trim(),
      numseq: this.insumo.sspedidoNumSeq,
      numped: this.vm.numeroPedido,
    };
    this.storeSerialOnLocalStorage();
  }

  validarEAdicionarNaListagem() {
    const insumoIndex = this.vm.insumosBipados.findIndex(
      (insumo) => insumo.item === this.insumo.insumoId
    );
    const inputAtualJaFoiBipado = insumoIndex !== -1;
    if (inputAtualJaFoiBipado) {
      this.vm.insumosBipados[insumoIndex] = this.insumoParaRequest; // Substitui serial bipado
      return;
    }
    this.detectarErroBipagem();
    this.vm.insumosBipados.push(this.insumoParaRequest);
  }
  //TODO: Verificar localStorage com Wesley
  storeSerialOnLocalStorage() {
    const serial = this.input.value.trim();
    const { className } = this.input;
    this.localStorageService.storeSerialBipado(serial, className);
  }
  //TODO: Detecção de erro na bipagem é apenas no back agora?
  detectarErroBipagem() {
    for (let i = this.vm.insumosBipados.length - 1; i >= 0; i--) {
      const sspedidoNumSeqAtual = this.vm.insumosBipados[i].sspedidoNumSeq;
      const sspedidoNumSeqBipado = this.insumoParaRequest.sspedidoNumSeq;
      if (sspedidoNumSeqAtual !== sspedidoNumSeqBipado) continue;
      const contemErroBipagem =
        !this.seriaisTemMesmoTamanho(i) || !this.seriaisTemMesmasIniciais(i);
      if (contemErroBipagem) {
        alert(
          "Provavelmente aconteceu um erro na bipagem. Favor, conferir se o número bipado corresponde ao número correto do painel."
        );
        break;
      }
    }
  }
  //TODO: Backend?
  seriaisTemMesmoTamanho(i) {
    const lengthSerialBipado = this.insumoParaRequest.serial.length;
    const lengthSerialIteracaoAtual = this.vm.insumosBipados[i].serial.length;
    return lengthSerialBipado === lengthSerialIteracaoAtual;
  }
  //TODO: Backend?
  seriaisTemMesmasIniciais(i) {
    const iniciaisSerialBipado = this.insumoParaRequest.serial.substr(0, 2);
    const iniciaisSerialIteracaoAtual = this.vm.insumosBipados[i].serial.substr(
      0,
      2
    );
    return iniciaisSerialBipado === iniciaisSerialIteracaoAtual;
  }
  //TODO: necessáro
  avaliarAvancoParaProximoInput() {
    this.vm.$nextTick(() => {
      this.vm.horaInicialBipagem = dateTimeInFormatYmdhis();
      this.avancarParaProximoInput();
    });
  }
  //TODO: necessáro
  avancarParaProximoInput() {
    const itensBipagem = Array.from(document.querySelectorAll(".item-bipagem"));
    const indexInput = itensBipagem.findIndex(
      (input) => input.className === this.input.className
    );
    if (indexInput === -1) {
      return;
    }
    const proximoItem = itensBipagem[indexInput + 1];
    proximoItem && proximoItem.focus();
  }
  //TODO: ver necessidade deste método.
  getNumSeqInput() {
    return +this.input.className
      .replace(/\D+/, "")
      .replace(this.vm.numeroPedido, "");
  }
}

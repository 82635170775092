import SelecionarPalletRepository from "@/repositories/v2/SelecionarPalletRepository";

export class SelecionarPalletService {
  vm;
  selecionarPalletRepository = SelecionarPalletRepository.build();

  constructor(selecionarPalletRepository) {
    this.selecionarPalletRepository = selecionarPalletRepository;
  }

  static build() {
    const selecionarPalletRepository = SelecionarPalletRepository.build();
    return new SelecionarPalletService(selecionarPalletRepository);
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async listarPallets() {
    const body = this.getBody();

    this.vm.isLoadingDialog = true;

    try {
      await this.selecionarPalletRepository
        .listarPallets(body.codigoWinthor, body.qntdInsumo)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.vm.pallets = response.data.data.map((pallet) => ({
              ...pallet,
              descricaoTamanho: `${pallet.descricao} - ${pallet.tamanho} ${
                pallet.compativel ? "- (COMPATÍVEL)" : ""
              }`,
            }));
            this.vm.$bvModal.show("modal-selecionar-pallet");
          } else {
            const message = response.data.message;
            this.vm.modalAlert = {
              message: message,
              variant: "danger",
            };
            this.vm.$bvModal.show("modal-error");
          }
        });
    } catch (e) {
      console.error(e);
    }
    this.vm.isLoadingDialog = false;
  }

  getBody() {
    const data = this.vm;
    return {
      codigoWinthor: data.selectPalletDialogData.codigoWinthor,
      qntdInsumo: data.selectPalletDialogData.qntdInsumo,
    };
  }
}

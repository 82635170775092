<template>
  <v-btn-toggle
    ref="btnToggle"
    rounded
    dense
    class="d-flex mr-12"
    @change="$refs.btnToggle.internalLazyValue = -1"
  >
    <default-button :loading="loading" elevation="1" @click="$emit('filter')"
      >Filtrar</default-button
    >
    <v-menu offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <default-button
          title="Ver Mais"
          color="secondary"
          elevation="1"
          :block="false"
          v-on="on"
          v-bind="attrs"
          ><v-icon>mdi-menu-down</v-icon></default-button
        >
      </template>
      <v-list>
        <v-list-item v-if="hasFilterAll" @click="$emit('filterAll')">
          <v-list-item-title>Filtrar Todos</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="hasFilterCleaner" @click="$emit('cleanFilter')">
          <v-list-item-title>Limpar Filtros</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn-toggle>
</template>

<script>
import DefaultButton from "./DefaultButton.vue";
export default {
  name: "DefaultFilterButton",
  components: {
    DefaultButton,
  },
  props: {
    hasFilterAll: {
      type: Boolean,
      default: false,
    },
    hasFilterCleaner: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // block: {
    //   type: Boolean,
    //   default: true,
    // },
  },
};
</script>

<style scoped>
::v-deep .v-btn-toggle > .v-btn,
.v-btn--is-elevated {
  opacity: 1 !important;
}
</style>

<template>
  <div>
    <div class="cordefundo">
      <div class="bv-example-row px-3">
        <div style="font-size: 30px">
          <b-row class="py-3 top">
            <b-col sm="auto">
              <span style="font-size: 30px"> Conferência Ac </span>
            </b-col>
            <b-col sm="auto" class="text-auto py-2" v-if="isPedidoFixador">
              <b-button
                size="sm"
                class="default-button"
                variant="danger"
                @click="btnResendEtiqueta"
              >
                Reimprimir Etiqueta
              </b-button></b-col
            >
          </b-row>
        </div>
        <!-- <div class="top"> -->
        <b-row class="py-3 top">
          <b-col sm="auto"><span>Pedido: </span>{{ numeroPedido }}</b-col>
          <b-col sm="auto" class="text-auto py-2"
            ><span>Data:</span> {{ dataProducao }}</b-col
          >
        </b-row>
        <b-row class="py-3 top">
          <b-col sm="auto">
            <span> Transportadora:</span>
            {{ transportadora }}</b-col
          >
          <b-col sm="auto" class="text-auto py-2"
            ><span>Status:</span> {{ linhaStatus }}</b-col
          >
        </b-row>
        <b-row class="py-3 top">
          <b-col sm="auto">
            <span> Separador da Linha 3:</span>
            {{ separadorLinhaTres }}</b-col
          >
        </b-row>
        <b-row class="py-3 top">
          <b-col sm="12" md="12" lg="12" xl="12" class="text-right py-2">
            <span>
              Quantidade de caixas:
              <span style="color: #ff7d27">
                {{ quantidadeCaixas }}
              </span>
            </span>
          </b-col>
        </b-row>
        <!-- </div> -->

        <b-row
          v-for="(insumo, i) in insumos"
          :key="i"
          class="top"
          :style="`font-family: 'Poppins';`"
        >
          <b-col sm="auto" class="text-auto py-2">
            <h5 class="font-weight-bold">
              {{ insumo.descricao }}:
              <span
                class="insumoQuantidade"
                :style="`font-family: 'Poppins';background: ${
                  insumo.quantidadeRestante > 0
                    ? insumo.quantidade === insumo.quantidadeRestante
                      ? 'lightcoral'
                      : '#FFD700'
                    : '#38BB3E'
                }`"
              >
                {{ insumo.quantidade }} unidades
              </span>
            </h5>
          </b-col>
        </b-row>
      </div>
    </div>
    <div>
      <modal-resend-etiqueta :numped="numped" />
    </div>
  </div>
</template>

<script>
import ModalResendEtiqueta from "./Etiqueta/ModalResendEtiqueta.vue";

export default {
  name: "CabecalhoConferenciaAc",
  components: { ModalResendEtiqueta },
  data: () => ({
    numped: "",
  }),
  props: {
    numeroPedido: {
      type: Number,
      required: true,
    },
    separadorLinhaTres: {
      type: String,
      required: true,
    },
    transportadora: {
      type: String,
      required: true,
    },
    dataProducao: {
      type: String,
      required: true,
    },
    linhaStatus: {
      type: String,
      required: true,
    },
    quantidadeCaixas: {
      type: Number,
      required: true,
    },
    insumos: {
      type: Array,
      required: true,
    },
  },
  methods: {
    btnResendEtiqueta() {
      this.numped = this.numeroPedido;
      this.$bvModal.show("modal-resend-etiqueta");
    },
  },
  computed: {
    isPedidoFixador() {
      return this.$route.query.tipoPedido === "lote-fixador";
    },
  },
  // methods: {
  //   onClick(evt) {
  //     this.$emit("click", evt);
  //   },
  //   // tratarString(str) {
  //   //   let substituirStr = str.toLocaleLowerCase();
  //   //   let novaStr = substituirStr[0].toUpperCase() + substituirStr.substr(1);
  //   //   return novaStr;
  //   // },
  // },
};
</script>

<style scoped>
.insumoQuantidade {
  color: #002233;
  padding: 3px;
  border-radius: 10px;
  max-width: max-content;
}

.cordefundo {
  background: #f2f4f5;
  color: #002233;
}
.linha1 {
  font-family: sans-serif;
  font-size: 30px;
}
.top {
  font-family: sans-serif;
  font-size: 17px;
  line-height: 10px;
}
.qLinha {
  font-size: 20px;
}

.default-button {
  text-align: center;
  padding: 0.625rem;
  outline: none;
  border-radius: 1rem;
  font-weight: bold !important;
  font-family: inherit;
  font-size: 14px;
  cursor: pointer;
  border: none;
  transition: background-color, padding 0.15s;
  background-color: #cc5d02;
  color: white;
}

.default-button-disabled {
  cursor: context-menu;
  pointer-events: none;
  background-color: #e9e9e9 !important;
  color: #252525 !important;
}

.default-button:hover {
  background-color: #e6781e;
  color: white;
  transition: 300ms all ease-in-out;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cordefundo"},[_c('div',{staticClass:"bv-example-row px-3"},[_c('div',{staticStyle:{"font-size":"30px"}},[_c('b-row',{staticClass:"py-3 top"},[_c('b-col',{attrs:{"sm":"auto"}},[_c('span',{staticStyle:{"font-size":"30px"}},[_vm._v(" Conferência Ac ")])]),(_vm.isPedidoFixador)?_c('b-col',{staticClass:"text-auto py-2",attrs:{"sm":"auto"}},[_c('b-button',{staticClass:"default-button",attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.btnResendEtiqueta}},[_vm._v(" Reimprimir Etiqueta ")])],1):_vm._e()],1)],1),_c('b-row',{staticClass:"py-3 top"},[_c('b-col',{attrs:{"sm":"auto"}},[_c('span',[_vm._v("Pedido: ")]),_vm._v(_vm._s(_vm.numeroPedido))]),_c('b-col',{staticClass:"text-auto py-2",attrs:{"sm":"auto"}},[_c('span',[_vm._v("Data:")]),_vm._v(" "+_vm._s(_vm.dataProducao))])],1),_c('b-row',{staticClass:"py-3 top"},[_c('b-col',{attrs:{"sm":"auto"}},[_c('span',[_vm._v(" Transportadora:")]),_vm._v(" "+_vm._s(_vm.transportadora))]),_c('b-col',{staticClass:"text-auto py-2",attrs:{"sm":"auto"}},[_c('span',[_vm._v("Status:")]),_vm._v(" "+_vm._s(_vm.linhaStatus))])],1),_c('b-row',{staticClass:"py-3 top"},[_c('b-col',{attrs:{"sm":"auto"}},[_c('span',[_vm._v(" Separador da Linha 3:")]),_vm._v(" "+_vm._s(_vm.separadorLinhaTres))])],1),_c('b-row',{staticClass:"py-3 top"},[_c('b-col',{staticClass:"text-right py-2",attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('span',[_vm._v(" Quantidade de caixas: "),_c('span',{staticStyle:{"color":"#ff7d27"}},[_vm._v(" "+_vm._s(_vm.quantidadeCaixas)+" ")])])])],1),_vm._l((_vm.insumos),function(insumo,i){return _c('b-row',{key:i,staticClass:"top",style:(`font-family: 'Poppins';`)},[_c('b-col',{staticClass:"text-auto py-2",attrs:{"sm":"auto"}},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(insumo.descricao)+": "),_c('span',{staticClass:"insumoQuantidade",style:(`font-family: 'Poppins';background: ${
                insumo.quantidadeRestante > 0
                  ? insumo.quantidade === insumo.quantidadeRestante
                    ? 'lightcoral'
                    : '#FFD700'
                  : '#38BB3E'
              }`)},[_vm._v(" "+_vm._s(insumo.quantidade)+" unidades ")])])])],1)})],2)]),_c('div',[_c('modal-resend-etiqueta',{attrs:{"numped":_vm.numped}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{staticClass:"py-2 px-2",attrs:{"data-bv":"","id":"modal-detalhes-volume","body-class":"rounded","size":"lg sm","hide-footer":"","hide-header":"","centered":""}},[_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"d-block text-left my-3"},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v("Detalhar Caixas")])]),_c('div',[_c('h5',[_vm._v(" Quantidade de caixas: "),_c('b',[_vm._v(_vm._s(_vm.quantidadeVolumes))])])]),_c('div',{staticStyle:{"overflow-y":"auto","max-height":"600px"}},_vm._l((_vm.volumes),function(volume,j){return _c('div',{key:j},[_c('div',{staticClass:"mb-3 px-4 py-4"},[_c('b-card',{attrs:{"header":"featured","header-tag":"div","header-class":'cor-background-azul'},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"mb-6 font-weight-bold",staticStyle:{"color":"white"}},[_vm._v(" Caixa "+_vm._s(j + 1)+" - "+_vm._s(volume.codigo)+" ")])]},proxy:true}],null,true)},_vm._l((volume.insumos),function(volumeInsumo,k){return _c('b-row',{key:k,staticClass:"mb-0 px-1 py-1"},[_c('b-col',[_c('label',{style:(`align-items: center; font-family: 'Poppins'; font-size: 15px` +
                    (volumeInsumo.erroMotivo
                      ? volumeInsumo.erroCorrigido
                        ? ';color:orange'
                        : ';color:red'
                      : ''))},[_vm._v(" "+_vm._s((volumeInsumo.insumo || {}).descricao || "")+" ")])]),_c('b-col',[_c('label',{style:(volumeInsumo.erroMotivo
                      ? volumeInsumo.erroCorrigido
                        ? ';color:orange'
                        : ';color:red'
                      : '')},[_vm._v(_vm._s(volumeInsumo.quantidade)+" unidades - "+_vm._s(volumeInsumo.erroMotivo))])])],1)}),1)],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
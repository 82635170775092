<template>
  <div>
    <div class="content">
      <div class="box-content">
        <div class="pedido-det" v-if="this.pedidos.length">
          <div class="topo-det">
            <div class="det-info">
              <div class="item">
                CONFERIR PEDIDO COM PROBLEMA: {{ this.pedidos[0].NUMPED }} -
                Transportadora: {{ this.pedidos[0].TRANSPORTADORA }}
              </div>
              <div class="item">
                <!--                <button-->
                <!--                  type="submit"-->
                <!--                  class="btn btn-danger"-->
                <!--                  style="margin-right: 10px"-->
                <!--                  @click="cancelar(this.pedidos[0].NUMPED)"-->
                <!--                >-->
                <!--                  Cancelar Pedido-->
                <!--                </button>-->

                <!--                <button-->
                <!--                  type="submit"-->
                <!--                  class="btn btn-danger"-->
                <!--                  @click="zerar(this.pedidos[0].NUMPED)"-->
                <!--                >-->
                <!--                  Zerar Bipagem-->
                <!--                </button>-->
              </div>

              <div class="item">
                <!--                <button-->
                <!--                  type="submit"-->
                <!--                  class="btn btn-danger"-->
                <!--                  @click="zerarLinha(this.pedidos[0].NUMPED)"-->
                <!--                >-->
                <!--                  Zerar Linha 3-->
                <!--                </button>-->
              </div>
            </div>
          </div>
          <div class="det-info">
            <div class="item">
              <span>CLIENTE:</span> {{ this.pedidos[0].CLIENTE }}
            </div>
            <div class="item">
              <span>DATA DE PRODUÇÃO:</span>
              {{ formatDate(this.pedidos[0].DATASEPROD) }}
            </div>
          </div>
          <div class="det-info">
            <div class="item">
              <span>KIT:</span> {{ this.pedidos[0].DESC_KIT }}
            </div>
            <div class="item">
              <span>LOCALIZAÇÃO DO ESTOQUE: </span
              >{{ this.pedidos[0].LOCALIZACAOPEDIDO }}
            </div>
          </div>
          <div class="det-info">
            <div class="item">
              <h4>Motivo: {{ this.pedidos[0].MOTIVO }}</h4>
            </div>
            <div class="item">
              <h4>
                Funcionário Conf.:
                {{
                  this.pedidos[0].usuario_confirmacao
                    ? this.pedidos[0].usuario_confirmacao.NOME
                    : ""
                }}
              </h4>
            </div>
          </div>

          <div class="counter">
            <h3>Total Itens: {{ this.total_items }}</h3>
          </div>

          <div class="prods">
            <table
              class="table table-bordered"
              v-if="this.list.pedidos1.length"
            >
              <thead>
                <tr>
                  <th>Linha 1</th>
                  <th width="120">
                    Volume: {{ this.list.pedidos1[0].NUMVOL }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(pedido, index) in list.pedidos1" :key="index">
                  <td colspan="2">
                    {{ pedido.PAINEIS }}
                    <span class="qt-table">QUANTIDADE: {{ pedido.TOTAL }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

            <table
              class="table table-bordered"
              v-if="this.list.pedidos2.length"
            >
              <thead>
                <tr>
                  <th colspan="3">Linha 2</th>
                  <th width="120">
                    Volume: {{ this.list.pedidos2[0].NUMVOL }}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Funcionário Bipagem</th>
                  <th>Dt Incial Sep.</th>
                  <th>Dt Final Sep.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in list.pedidos2" :key="index">
                  <td>{{ pedido.CODPRODMP }} - {{ pedido.DESCRICAO }}</td>
                  <td>
                    {{ pedido.CODFUNCSEP ? pedido.usuario_sep.NOME : "" }}
                  </td>
                  <td>{{ formatDate(pedido.DTINICIALSEP) }}</td>
                  <td>{{ formatDate(pedido.DTFINALSEP) }}</td>
                </tr>
              </tbody>
            </table>

            <table
              class="table table-bordered"
              v-if="this.list.pedidos3.length"
            >
              <thead>
                <tr>
                  <th colspan="3">Linha 3</th>
                  <th width="120">
                    Volume: {{ this.list.pedidos3[0].NUMVOL }}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Funcionário Bipagem</th>
                  <th>Dt Incial Sep.</th>
                  <th>Dt Final Sep.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in list.pedidos3" :key="index">
                  <td>{{ pedido.CODPRODMP }} - {{ pedido.DESCRICAO }}</td>
                  <td>
                    {{ pedido.CODFUNCSEP ? pedido.usuario_sep.NOME : "" }}
                  </td>
                  <td>{{ formatDate(pedido.DTINICIALSEP) }}</td>
                  <td>{{ formatDate(pedido.DTFINALSEP) }}</td>
                </tr>
              </tbody>
            </table>

            <table
              class="table table-bordered"
              v-if="this.list.pedidos4.length"
            >
              <thead>
                <tr>
                  <th colspan="3">Linha 4</th>
                  <th width="120">
                    Volume: {{ this.list.pedidos4[0].NUMVOL }}
                  </th>
                </tr>
              </thead>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Funcionário Bipagem</th>
                  <th>Dt Incial Sep.</th>
                  <th>Dt Final Sep.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pedido, index) in list.pedidos4" :key="index">
                  <td>{{ pedido.CODPRODMP }} - {{ pedido.DESCRICAO }}</td>
                  <td>
                    {{ pedido.CODFUNCSEP ? pedido.usuario_sep.NOME : "" }}
                  </td>
                  <td>{{ formatDate(pedido.DTINICIALSEP) }}</td>
                  <td>{{ formatDate(pedido.DTFINALSEP) }}</td>
                </tr>
              </tbody>
            </table>

            <br />
            <h3>Histórico</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Data</th>
                  <th>Funcionário</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in list.status" :key="index">
                  <td>{{ status(item.STATUS) }}</td>
                  <td>{{ formatDate(item.CREATED_AT) }}</td>
                  <td>{{ item.usuario ? item.usuario.NOME : "" }}</td>
                </tr>
              </tbody>
            </table>

            <br />
            <h3>Log Modificações com autorização</h3>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Antigo Status</th>
                  <th>Status</th>
                  <th>Data</th>
                  <th>Funcionário</th>
                  <th>Obs</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in list.logs" :key="index">
                  <td>{{ status(item.STATUS) }}</td>
                  <td>{{ status(item.NEW_STATUS) }}</td>
                  <td>{{ formatDate(item.CREATED_AT) }}</td>
                  <td>{{ item.usuario ? item.usuario.NOME : "" }}</td>
                  <td>{{ item.OBS }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "../../../../mixins/routesValidationMixin";
import { UsuarioLogado } from "../../../../services/login/UsuarioLogado";
import PedidoReposiroty from "../../../../repositories/v1/PedidoRepository";
import UsuarioRepository from "../../../../repositories/v1/UsersRepository";

export default {
  components: {},
  data() {
    return {
      pedidos: [],
      list: [],
      save_ped: {},
      total_items: 0,
      pedidoReposiroty: PedidoReposiroty.build(),
      usuarioRepository: UsuarioRepository.build(),
    };
  },
  mixins: [routesValidationMixin],
  created() {
    this.pedidoReposiroty = PedidoReposiroty.build();
    this.usuarioRepository = UsuarioRepository.build();

    this.usuarioRepository
      .getFuncoesByUser(`${UsuarioLogado.getUserId()}`)
      .then((response) => {
        if (response.data.roles.includes("ADMIN")) {
          this.type = "ADMIN";
        } else {
          window.location.href = "/dashboard";
        }

        this.pedidoReposiroty
          .pedidoResumo(`${this.$route.params.id}`)
          .then((response) => {
            this.list = response.data;
            this.pedidos = response.data.pedidos;

            console.log(response.data);

            if (this.pedidos.length) {
              if (response.data.pedidos[0].STATUS !== "T") {
                window.location.href = "/aguardando-confere";
              }

              this.item_save = this.pedidos[0].NUMSEQ;
              this.total_items = this.pedidos.length;
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  },
  methods: {
    zerarLinha(id) {
      if (confirm("Confirma Zerar Linha 3?")) {
        this.save_ped = {
          numped: id,
          linha: 3,
          type: "conf",
        };

        this.pedidoRepository
          .pedidoLimpaLinha(this.save_ped)
          .then(() => {
            alert("Linha foi zerada com sucesso!");
            window.location.href = "/linha-prod";
          })
          .catch(function () {
            window.location.href = "/dashboard";
          });
      }
    },
    cancelar(id) {
      if (confirm("Confirma Confirma Cancelar Pedido?")) {
        this.save_ped = {
          numped: id,
        };

        this.pedidoRepository
          .cancelar(this.save_ped)
          .then(() => {
            alert("Pedido foi canelado com sucesso!");
            window.location.href = "/cancelados";
          })
          .catch(function () {
            window.location.href = "/dashboard";
          });
      }
    },
    zerar(id) {
      if (confirm("Confirma Zerar Bipagem?")) {
        this.save_ped = {
          numped: id,
        };

        this.pedidoRepository
          .zerar(this.save_ped)
          .then(() => {
            alert("Pedido foi zerado com sucesso!");
            window.location.href = "/aguardando-prod";
          })
          .catch(function () {
            window.location.href = "/dashboard";
          });
      }
    },
    formatDate(data) {
      let out = "";
      if (data) {
        out = data.substr(0, 10).split("-").reverse().join("/");
        out = out + " " + data.substr(10, 10);
      }
      return out;
    },
    status(label) {
      let out = "";

      switch (label) {
        case "A":
          out = "AGUARDANDO PRODUÇÃO";
          break;
        case "L":
          out = "EM LINHA DE PRODUÇÃO";
          break;
        case "N":
          out = "AGUARDANDO CONFERÊNCIA";
          break;
        case "F":
          out = "EM CONFERÊNCIA";
          break;
        case "T":
          out = "CONFERÊNCIA COM PROBLEMA";
          break;
        case "E":
          out = "AGUARDANDO EMBARQUE";
          break;
        case "D":
          out = "DESPACHADO";
          break;
        case "C":
          out = "CANCELADO";
          break;
        default:
          out = "";
      }

      return out;
    },
  },
};
</script>

export default [
  {
    nome: "CSI-100K-T400GL02-E (WIFI INCLUSO)",
    registros: 3,
    inicioPadrao: ["114102"],
    finalPadrao: [],
    totalCaracteres: ["114102213260037".length],
    possuiLetras: false,
    codigoProducao: 22852,
  },
  {
    nome: "CSI-110K-T400GL02-E (COM WIFI INCLUSO)",
    registros: 10,
    inicioPadrao: ["114222"],
    finalPadrao: [],
    totalCaracteres: ["114222218020010".length],
    possuiLetras: false,
    codigoProducao: 26150,
  },
  {
    nome: "CSI-15K-T400GL01-E (WIFI INCLUSO)",
    registros: 344,
    inicioPadrao: ["1548"],
    finalPadrao: [],
    totalCaracteres: ["154832217310279".length],
    possuiLetras: false,
    codigoProducao: 22846,
  },
  {
    nome: "CSI-20KTL-GI-LFL 220V (WIFI INCLUSO)",
    registros: 42,
    inicioPadrao: ["1544F"],
    finalPadrao: [],
    totalCaracteres: ["1544F0202190062".length],
    possuiLetras: true,
    codigoProducao: 17217,
  },
  {
    nome: "CSI-20K-T400GL01-E (WIFI INCLUSO)",
    registros: 124,
    inicioPadrao: ["1547E"],
    finalPadrao: [],
    totalCaracteres: ["1547E2218100223".length],
    possuiLetras: true,
    codigoProducao: 23342,
  },
  {
    nome: "CSI-25KTL-GI-L 220V (WIFI INCLUSO)",
    registros: 31,
    inicioPadrao: ["15467"],
    finalPadrao: [],
    totalCaracteres: ["154670201170014".length],
    possuiLetras: false,
    codigoProducao: 17218,
  },
  {
    nome: "CSI-25K-T400GL02-E (WIFI INCLUSO)",
    registros: 71,
    inicioPadrao: ["154D0"],
    finalPadrao: [],
    totalCaracteres: ["154D02218170015".length],
    possuiLetras: true,
    codigoProducao: 22847,
  },
  {
    nome: "CSI-3K-S22002-ED 220V (WIFI INCLUSO)",
    registros: 689,
    inicioPadrao: ["A3B"],
    finalPadrao: ["J000"],
    totalCaracteres: ["A3B220380097J000".length],
    possuiLetras: true,
    codigoProducao: 20468,
  },
  {
    nome: "CSI-30KTL-GI-L 220V (WIFI INCLUSO)",
    registros: 24,
    inicioPadrao: ["1546", "40"],
    finalPadrao: [],
    totalCaracteres: ["154680202140069".length, "4028225680".length],
    possuiLetras: false,
    codigoProducao: 17219,
  },
  {
    nome: "CSI-30K-T400GL02-E (COM WI-FI INCLUSO)",
    registros: 79,
    inicioPadrao: ["154D"],
    finalPadrao: [],
    totalCaracteres: ["154D12218020060".length],
    possuiLetras: true,
    codigoProducao: 26159,
  },
  {
    nome: "CSI-5K-S22002-E (WIFI INCLUSO)",
    registros: 665,
    inicioPadrao: ["A5B821"],
    finalPadrao: ["J000"],
    totalCaracteres: ["A5B821200288J000".length],
    possuiLetras: true,
    codigoProducao: 23340,
  },
  {
    nome: "CSI-5K-S22002-ED (WIFI INCLUSO)",
    registros: 323,
    inicioPadrao: ["A5B62", "A5B32"],
    finalPadrao: ["J000"],
    totalCaracteres: ["A5B621060064J000".length],
    possuiLetras: true,
    codigoProducao: 22837,
  },
  {
    nome: "CSI-5KTL1P-FL (WIFI INCLUSO)",
    registros: 61,
    inicioPadrao: ["A5B42", "A5B82"],
    finalPadrao: ["J000"],
    totalCaracteres: ["A5B420510349J000".length],
    possuiLetras: true,
    codigoProducao: 22841,
  },
  {
    nome: "CSI-50KTL-GI (WIFI INCLUSO)",
    registros: 12,
    inicioPadrao: ["1546"],
    finalPadrao: [],
    totalCaracteres: ["154610213260021".length],
    possuiLetras: false,
    codigoProducao: 22848,
  },
  {
    nome: "CSI-60KTL-GI (WIFI INCLUSO)",
    registros: 9,
    inicioPadrao: ["1546"],
    finalPadrao: [],
    totalCaracteres: ["154690213230002".length],
    possuiLetras: false,
    codigoProducao: 22850,
  },
  {
    nome: "CSI-7K-S22002-E (WIFI INCLUSO)",
    registros: 251,
    inicioPadrao: ["A7B82"],
    finalPadrao: ["J000"],
    totalCaracteres: ["A7B821310106J000".length],
    possuiLetras: true,
    codigoProducao: 23341,
  },
  {
    nome: "CSI-7K-S22002-ED (WIFI INCLUSO)",
    registros: 131,
    inicioPadrao: ["A7B12"],
    finalPadrao: ["J000"],
    totalCaracteres: ["A7B121020191J000".length],
    possuiLetras: true,
    codigoProducao: 22843,
  },
  {
    nome: "CSI-75K-T400GL02-E (WIFI INCLUSO)",
    registros: 12,
    inicioPadrao: ["A7B12"],
    finalPadrao: ["J000"],
    totalCaracteres: ["A7B121020177J000".length],
    possuiLetras: true,
    codigoProducao: 22851,
  },
  {
    nome: "CSI-8KTL1P-GI-FL (WIFI INCLUSO)",
    registros: 53,
    inicioPadrao: ["07", "40", "154B"],
    finalPadrao: [],
    totalCaracteres: ["0723001470".length],
    possuiLetras: false,
    codigoProducao: 22845,
  },
  {
    nome: "CSI-9K-S22002-E (WIFI INCLUSO)",
    registros: 353,
    inicioPadrao: ["A9B82"],
    finalPadrao: ["J000"],
    totalCaracteres: ["A9B821310016J000".length],
    possuiLetras: true,
    codigoProducao: 23339,
  },
  {
    nome: "GEP3.6-1-10 - 3,6KW - 220V",
    registros: 3,
    inicioPadrao: ["93600PSB"],
    finalPadrao: [],
    totalCaracteres: ["93600PSB214W0034".length],
    possuiLetras: true,
    codigoProducao: 13478,
  },
  {
    nome: "GEP5.0-1-10 - 5KW - 220V",
    registros: 5,
    inicioPadrao: ["95000PSB"],
    finalPadrao: [],
    totalCaracteres: ["95000PSB214W0051".length],
    possuiLetras: true,
    codigoProducao: 13481,
  },
  {
    nome: "GEP7.0-1-10 - 7KW - 220V",
    registros: 5,
    inicioPadrao: ["97000PSC"],
    finalPadrao: [],
    totalCaracteres: ["97000PSC213W0029".length],
    possuiLetras: true,
    codigoProducao: 13483,
  },
  {
    nome: "GEP9.0-1-10 - 9KW - 220V",
    registros: 5,
    inicioPadrao: ["99000PSC"],
    finalPadrao: [],
    totalCaracteres: ["99000PSC213W0002".length],
    possuiLetras: true,
    codigoProducao: 13485,
  },
  {
    nome: "GROWATT MAC 50KTL3-X",
    registros: 5,
    inicioPadrao: ["QEE9A"],
    finalPadrao: [],
    totalCaracteres: ["QEE9A2900D".length],
    possuiLetras: true,
    codigoProducao: 2860,
  },
  {
    nome: "GROWATT MAX 80KTL3-LV",
    registros: 17,
    inicioPadrao: ["GFD99"],
    finalPadrao: [],
    totalCaracteres: ["GFD9942004".length, "GFD994201CSE".length],
    possuiLetras: true,
    codigoProducao: 2121,
  },
  {
    nome: "GROWATT MID 17KTL3-X (WIFI-X INCLUSO)",
    registros: 64,
    inicioPadrao: ["PGE"],
    finalPadrao: [],
    totalCaracteres: ["PGE4B0502V".length],
    possuiLetras: true,
    codigoProducao: 4117,
  },
  {
    nome: "GROWATT MID 25KTL3-X (WIFI-X INCLUSO)",
    registros: 44,
    inicioPadrao: ["PKE"],
    finalPadrao: [],
    totalCaracteres: ["PKE3A3507P".length],
    possuiLetras: true,
    codigoProducao: 4118,
  },
  {
    nome: "GROWATT MIN 4200TL-X (WI-FI INCLUSO)",
    registros: 260,
    inicioPadrao: ["YHDB"],
    finalPadrao: [],
    totalCaracteres: ["YHDBA4904Q".length],
    possuiLetras: true,
    codigoProducao: 2393,
  },
  {
    nome: "GROWATT MIN 6000TL-X (WI-FI INCLUSO)",
    registros: 38,
    inicioPadrao: ["YID"],
    finalPadrao: [],
    totalCaracteres: ["YIDBA460GK".length],
    possuiLetras: true,
    codigoProducao: 2394,
  },
  {
    nome: "GROWATT 8000MTL-S",
    registros: 410,
    inicioPadrao: ["RBE", "RID"],
    finalPadrao: [],
    totalCaracteres: ["RID0A4717X".length, "RBE4A480AF".length],
    possuiLetras: true,
    codigoProducao: 2395,
  },
  {
    nome: "LIVOLTEK GT1-10K-D 10 KW (WI-FI INCLUSO)",
    registros: 562,
    inicioPadrao: ["2132", "2139", "2137", "2135"],
    finalPadrao: ["D"],
    totalCaracteres: ["213575720340D".length],
    possuiLetras: true,
    codigoProducao: 20461,
  },
  {
    nome: "LIVOLTEK GT1-3K-S  3 KW  (WI-FI INCLUSO)",
    registros: 3047,
    inicioPadrao: ["2119", "2120", "2121", "2122", "2123", "2124", "2125"],
    finalPadrao: ["D"],
    totalCaracteres: ["211459410560D".length],
    possuiLetras: true,
    codigoProducao: 15647,
  },
  {
    nome: "LIVOLTEK GT1-5K-D  5 KW  (WI-FI INCLUSO)",
    registros: 1212,
    inicioPadrao: ["2124", "2119", "2122", "2123", "2132"],
    finalPadrao: ["D"],
    totalCaracteres: ["213264490818D".length],
    possuiLetras: true,
    codigoProducao: 22189,
  },
  {
    nome: "LIVOLTEK GT1-6K-D  6 KW  (WI-FI INCLUSO)",
    registros: 2088,
    inicioPadrao: ["2124", "2119", "2122", "2127", "2130"],
    finalPadrao: ["D"],
    totalCaracteres: ["211459220971D".length],
    possuiLetras: true,
    codigoProducao: 15649,
  },
  {
    nome: "LIVOLTEK GT1-6K-DS  6 KW (WI-FI INCLUSO)",
    registros: 222,
    inicioPadrao: ["2142"],
    finalPadrao: ["D"],
    totalCaracteres: ["214281021174D".length],
    possuiLetras: true,
    codigoProducao: 35823,
  },
  {
    nome: "LIVOLTEK GT1-8K-D 8 KW (WI-FI INCLUSO)",
    registros: 404,
    inicioPadrao: ["2137", "2139", "2141"],
    finalPadrao: ["D"],
    totalCaracteres: ["213975750634D".length],
    possuiLetras: true,
    codigoProducao: 23165,
  },
  {
    nome: "LIVOLTEK GT3-25K-D 25 KW (WI-FI INCLUSO)",
    registros: 16,
    inicioPadrao: ["2114", "2119"],
    finalPadrao: ["D"],
    totalCaracteres: ["211459480464D".length],
    possuiLetras: true,
    codigoProducao: 15650,
  },
  {
    nome: "LIVOLTEK GT3-30K-D 30 KW (WI-FI INCLUSO)",
    registros: 12,
    inicioPadrao: ["2114"],
    finalPadrao: ["D"],
    totalCaracteres: ["211459510486D".length],
    possuiLetras: true,
    codigoProducao: 15652,
  },
  {
    nome: "MICROINVERSOR HOYMILES HMS - 1800",
    registros: 489,
    inicioPadrao: ["1164"],
    finalPadrao: [],
    totalCaracteres: ["116473506726".length],
    possuiLetras: false,
    codigoProducao: 22643,
  },
  {
    nome: "MICROINVERSOR HOYMILES 1200W",
    registros: 116,
    inicioPadrao: ["1061"],
    finalPadrao: [],
    totalCaracteres: ["106170304446".length],
    possuiLetras: false,
    codigoProducao: 58,
  },
  {
    nome: "MICROINVERSOR HOYMILES 1500W",
    registros: 17409,
    inicioPadrao: ["10627"],
    finalPadrao: [],
    totalCaracteres: ["106273427040".length],
    possuiLetras: false,
    codigoProducao: 7301,
  },
  {
    nome: "SMA SHP75-10",
    registros: 3,
    inicioPadrao: ["139F"],
    finalPadrao: [],
    totalCaracteres: ["139F5006908901N289".length],
    possuiLetras: true,
    codigoProducao: 45,
  },
  {
    nome: "SMA STP 25.000TL-30",
    registros: 24,
    inicioPadrao: ["1980"],
    finalPadrao: [],
    totalCaracteres: ["1980400972".length],
    possuiLetras: false,
    codigoProducao: 48,
  },
  {
    nome: "SMA STP50-40",
    registros: 45,
    inicioPadrao: ["3008"],
    finalPadrao: [],
    totalCaracteres: ["3008294631".length],
    possuiLetras: false,
    codigoProducao: 49,
  },
  {
    nome: "SOLIS S6-GR1P5K - 5 KW (C/ WI-FI)",
    registros: 168,
    inicioPadrao: ["408", "407"],
    finalPadrao: [],
    totalCaracteres: ["4088245074".length],
    possuiLetras: false,
    codigoProducao: 27814,
  },
  {
    nome: "SOLIS 1P5K-4G - 5 KW (C/ WI-FI)",
    registros: 1287,
    inicioPadrao: ["404", "405", "403"],
    finalPadrao: [],
    totalCaracteres: ["4051694825".length],
    possuiLetras: false,
    codigoProducao: 7519,
  },
  {
    nome: "SOLIS 1P8K-4G - 8 KW (C/ WI-FI)",
    registros: 472,
    inicioPadrao: ["403", "404", "405"],
    finalPadrao: [],
    totalCaracteres: ["4049280809".length],
    possuiLetras: false,
    codigoProducao: 7521,
  },
  {
    nome: "SOLIS 110K-5G - 110 KW (C/ WI-FI)",
    registros: 19,
    inicioPadrao: ["400", "404", "403"],
    finalPadrao: [],
    totalCaracteres: ["4007430528".length],
    possuiLetras: false,
    codigoProducao: 7490,
  },
  {
    nome: "SOLIS 25K-5G - 25 KW (C/ WI-FI)",
    registros: 212,
    inicioPadrao: ["404", "405", "403"],
    finalPadrao: [],
    totalCaracteres: ["4051229559".length],
    possuiLetras: false,
    codigoProducao: 7523,
  },
  {
    nome: "SOLIS 3P15K-4G - 15 KW (C/ WI-FI)",
    registros: 366,
    inicioPadrao: ["072", "404", "403", "405"],
    finalPadrao: [],
    totalCaracteres: ["0723424610".length],
    possuiLetras: false,
    codigoProducao: 7522,
  },
  {
    nome: "SOLIS 30K-5G - 30 KW (C/ WI-FI)",
    registros: 139,
    inicioPadrao: ["072", "404", "403"],
    finalPadrao: [],
    totalCaracteres: ["4039324202".length],
    possuiLetras: false,
    codigoProducao: 7525,
  },
  {
    nome: "SOLIS 50K - 50 KW (C/ WI-FI)",
    registros: 75,
    inicioPadrao: ["401", "402", "403"],
    finalPadrao: [],
    totalCaracteres: ["4023980687".length],
    possuiLetras: false,
    codigoProducao: 7527,
  },
  {
    nome: "SOLIS 60K-4G - 60 KW (C/ WI-FI)",
    registros: 98,
    inicioPadrao: ["403", "404", "405"],
    finalPadrao: [],
    totalCaracteres: ["4037242617".length],
    possuiLetras: false,
    codigoProducao: 7531,
  },
  {
    nome: "SOLIS 75K-5G - 75 KW (C/ WI-FI)",
    registros: 41,
    inicioPadrao: ["403", "404", "405"],
    finalPadrao: [],
    totalCaracteres: ["4039948041".length],
    possuiLetras: false,
    codigoProducao: 7534,
  },
  {
    nome: "SUNGROW SG75CX - 75 KW (C/ WI-FI)",
    registros: 29,
    inicioPadrao: ["SG75CX"],
    finalPadrao: [],
    totalCaracteres: ["SG75CXV11A2182402117".length],
    possuiLetras: true,
    codigoProducao: 29472,
  },
];

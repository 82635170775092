export const dateTimeInFormatYmdhis = () => {
  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  let hour = now.getHours();
  let minute = now.getMinutes();
  let second = now.getSeconds();
  if (month.toString().length === 1) {
    month = "0" + month;
  }
  if (day.toString().length === 1) {
    day = "0" + day;
  }
  if (hour.toString().length === 1) {
    hour = "0" + hour;
  }
  if (minute.toString().length === 1) {
    minute = "0" + minute;
  }
  if (second.toString().length === 1) {
    second = "0" + second;
  }
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
};

export const formatDate = (data) => {
  let out = "";
  if (data) {
    out = data.substr(0, 10).split("-").reverse().join("/");
    out = out + " " + data.substr(10, 10);
  }
  return out;
};

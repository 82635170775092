<template>
  <div>
    <div class="content">
      <div class="box-content">
        <div class="pedido-det" v-if="!isLoadingTela">
          <div class="topo-det">
            <div class="item-top">
              Pedido: {{ pedidoDetalhes.numeroPedido }}
              <div>Transportadora: {{ pedidoDetalhes.transportadoraNome }}</div>
            </div>
            <div class="det-info">
              <div class="item">
                <span>Cliente:</span> {{ pedidoDetalhes.clienteNome }}
              </div>
            </div>
          </div>
          <div>
            <div class="det-info">
              <div class="item">
                <span>Data de Produção:</span>
                {{ pedidoDetalhes.dataProducao }}
              </div>
              <div class="item">
                <span>Kit:</span> {{ pedidoDetalhes.kitDescricao }}
              </div>
              <div class="item">
                <span>Status:</span> {{ pedidoDetalhes.statusDescricao }}
              </div>
            </div>
          </div>
          <div class="prods min" id="prods" ref="prod">
            <div class="item">
              <div class="linha linha-1">
                <div class="topo">
                  <div class="det-info common-title">
                    <h3>ESCOLHER LINHA PARA EDIÇÃO</h3>
                    <h3>{{ pedidoDetalhes.kitDescricao }}</h3>
                  </div>
                </div>
                <div class="infos">
                  <div class="det-info" style="justify-content: space-around">
                    <button
                      type="submit"
                      class="btn send"
                      style="color: white"
                      @click="goToEditarLinhaPaineis"
                    >
                      LINHA 1
                    </button>
                    <button
                      type="submit"
                      class="btn send"
                      style="color: white"
                      @click="goToEditarLinhaDiversos"
                    >
                      LINHA 3
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <alerta-de-espera />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "@/mixins/routesValidationMixin";
import httpRequestMixin from "@/mixins/httpRequestMixin";
import { EditarSelecaoLinhasService } from "@/services/editar_linhas/EditarSelecaoLinhasService";
import EditarLinhasRotasService from "@/services/editar_linhas/EditarLinhasRotasService";
import { PedidoDetalhesModel } from "@/models/PedidoDetalhesModel";
import AlertaDeEspera from "@/components/AlertaDeEspera";

export default {
  components: { AlertaDeEspera },
  mixins: [routesValidationMixin, httpRequestMixin],
  data() {
    return {
      isLoadingTela: false,
      insumos: [],
      perfilUsuarioAtual: "",
      editarSelecaoLinhasService: EditarSelecaoLinhasService.build(),
      editarLinhasRotasService: EditarLinhasRotasService.build(),
      possuiLinhaPaineis: true,
      possuiLinhaDiversos: true,
      pedidoDetalhes: PedidoDetalhesModel.build(),
    };
  },
  async created() {
    this.editarSelecaoLinhasService =
      EditarSelecaoLinhasService.build().setVm(this);
    this.editarLinhasRotasService = EditarLinhasRotasService.build();
  },
  async mounted() {
    this.isLoadingTela = true;
    await this.editarSelecaoLinhasService.run();
    this.isLoadingTela = false;
  },
  computed: {
    typeIsAdmin() {
      return this.perfilUsuarioAtual === "ADMIN";
    },
    numeroPedido() {
      return this.$route.params.numped;
    },
  },
  methods: {
    goToEditarLinhaPaineis() {
      this.editarLinhasRotasService
        .setNumped(this.numeroPedido)
        .goToEditarLinhaPaineis();
    },
    goToEditarLinhaDiversos() {
      this.editarLinhasRotasService
        .setNumped(this.numeroPedido)
        .goToEditarLinhaDiversos();
    },
  },
};
</script>

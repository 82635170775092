import { EtiquetaRepository } from "@/repositories/v2/EtiquetaRepository";

export class EtiquetaService {
  vm;
  etiquetaRepository = EtiquetaRepository.build();

  constructor(etiquetaRepository) {
    this.etiquetaRepository = etiquetaRepository;
  }

  static build() {
    const etiquetaRepository = EtiquetaRepository.build();
    return new EtiquetaService(etiquetaRepository);
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async sendEtiqueta() {
    if (!this.validaDados()) return;

    const codigoCaixa = this.vm.codigoCaixa;
    const impressoraId = this.vm.selectImpressora;

    await this.etiquetaRepository
      .sendEtiqueta(codigoCaixa, impressoraId)
      .then((response) => {
        if (response.status === 200) {
          const message = response.data.message;
          this.vm.modalAlert = {
            message: message,
            variant: "success",
          };
          this.vm.$bvModal.show("modal-etiqueta-alert");
        } else {
          const message = response.data.message;
          this.vm.modalAlert = {
            message: message,
            variant: "danger",
          };
          this.vm.$bvModal.show("modal-etiqueta-alert");
        }
      });
  }

  async resendEtiqueta() {
    const numped = this.vm.numped;

    await this.etiquetaRepository.resendEtiqueta(numped).then((response) => {
      if (response.status === 200) {
        const message = response.data.message;
        this.vm.modalAlert = {
          message: message,
          variant: "success",
        };
        this.vm.$bvModal.show("modal-resend-etiqueta-alert");
      } else {
        const message = response.data.message;
        this.vm.modalAlert = {
          message: message,
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-resend-etiqueta-alert");
      }
    });
  }

  async getImpressoras() {
    this.vm.isLoading = true;
    await this.etiquetaRepository.getImpressoras().then((response) => {
      if (response.status === 200) {
        this.vm.impressoras = response.data.data;
        this.vm.isLoading = false;
      } else {
        this.vm.isLoading = false;
        this.vm.modalAlert = {
          message: "Não foi possível carregar a listagem de impressoras",
          variant: "danger",
        };
        this.vm.$bvModal.show("modal-etiqueta-alert");
      }
    });
  }

  validaDados() {
    const selectImpressora = this.vm.selectImpressora;

    if (selectImpressora === null) {
      this.vm.modalAlert = {
        message: "Preencha os dados corretamente.",
        variant: "danger",
      };
      this.vm.$bvModal.show("modal-etiqueta-alert");
      return false;
    }
    return true;
  }
}

<template>
  <div>
    <div class="cordefundo">
      <div class="bv-example-row px-3">
        <div style="font-size: 30px">
          <b-row class="mb-7"
            ><b-col cols="4" class="p-3 mt-4">
              <span v-if="editarLinha"> Editar </span>
              <span> Linha {{ numeroLinha }} </span>
            </b-col>
          </b-row>
        </div>
        <!-- <div class="top"> -->
        <b-row class="py-3 top">
          <b-col sm="auto"><span>Pedido: </span>{{ numeroPedido }}</b-col>
          <b-col sm="auto" class="text-auto py-2"
            ><span>Data:</span> {{ pedidoDataSeProd }}</b-col
          >
        </b-row>
        <b-row class="py-3 top">
          <b-col sm="auto">
            <span> Transportadora:</span>
            {{ pedidoTransportadora }}</b-col
          >
          <b-col sm="auto" class="text-auto py-2"
            ><span>Status:</span> {{ pedidoStatus }}</b-col
          >
        </b-row>
        <b-row class="py-3 top">
          <b-col sm="12" md="12" lg="12" xl="12" class="text-right py-2">
            <span>
              Quantidade de caixas:
              <span style="color: #ff7d27">
                {{ quantidadeCaixas }}
              </span>
            </span>
          </b-col>
        </b-row>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LineHeader",
  props: {
    numeroLinha: {
      type: [String, Number],
      default: 0,
    },
    numeroPedido: {
      type: [String, Number],
      default: "",
    },
    pedidoTransportadora: {
      type: [String, Number],
      default: "",
    },
    pedidoDataSeProd: {
      type: [String, Number],
      default: "",
    },
    pedidoStatus: {
      type: [String, Number],
      default: "",
    },
    volumesLinhaPaineis: {
      type: Number,
      default: 0,
    },
    quantidadeCaixas: {
      type: Number,
      required: true,
    },
    editarLinha: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        click: this.onClick,
      };
    },
  },
  methods: {
    onClick(evt) {
      this.$emit("click", evt);
    },
    // tratarString(str) {
    //   let substituirStr = str.toLocaleLowerCase();
    //   let novaStr = substituirStr[0].toUpperCase() + substituirStr.substr(1);
    //   return novaStr;
    // },
  },
};
</script>

<style scoped>
.cordefundo {
  background: #f2f4f5;
  color: #002233;
}
.linha1 {
  font-family: sans-serif;
  font-size: 30px;
}
.top {
  font-family: sans-serif;
  font-size: 17px;
  line-height: 10px;
}
.qLinha {
  font-size: 20px;
}
</style>

import { DashboardRepository } from "../../repository/DashboardRepository";
import { ErrorNotifier } from "../../../../services/messages/ErrorNotifier";

export default {
  async dashboard({ dispatch }) {
    const request = [
      dispatch("getMetaDia"),
      dispatch("getMetaHora"),
      dispatch("getPedRealizados"),
      dispatch("getPedAtrasados"),
      dispatch("getPedFaturados"),
      dispatch("getLinha1"),
      dispatch("getLinha2"),
      dispatch("getLinha3"),
      dispatch("getLinha3Conf"),
      dispatch("getLinha4"),
      dispatch("getConfFinal"),
      dispatch("getAguardEmbarque"),
      dispatch("getPloomesAguardandoProducao"),
      dispatch("getPloomesEmLinhaDeProducao"),
      dispatch("getPloomesAguardandoEmbarque"),
      dispatch("getInversores"),
    ];
    await Promise.all(request);
  },

  async getMetaDia({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getMetaDia();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setMetaDia",
          response.data.total_pedidos[0].METADIA.length ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getMetaHora({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getMetaHora();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setMetaHora",
          response.data.total_pedidos[0].METAHORA.length ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getPedRealizados({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getConcluido();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setPedRealizados",
          response.data.total_pedidos[0].CONCLUIDO.length ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getPedAtrasados({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getAtrasados();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit("setPedAtrasados", response.data.atrasados.length ?? 0);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getPedFaturados({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getFaturados();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setPedFaturados",
          response.data.total_pedidos[0].FATURADOS.length ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getLinha1({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getPaineis();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit("setLinha1", response.data.total_pedidos[0].PAINEIS.length ?? 0);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getLinha2({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getCabos();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit("setLinha2", response.data.total_pedidos[0].CABO.length ?? 0);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getLinha3({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getDiversos();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setLinha3",
          response.data.total_pedidos[0].DIVERSOS.length ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getLinha3Conf({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getConferenciaAc();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setLinha3Conf",
          response.data.total_pedidos[0].CONFERENCIAAC.length ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getLinha4({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getPerfis();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit("setLinha4", response.data.total_pedidos[0].PERFIL.length ?? 0);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getInversores({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getInversores();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit("setLinha5", response.data.data[0].total.length ?? 0);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getConfFinal({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getConferenciaFinal();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setConfFinal",
          response.data.total_pedidos[0].CONFERENCIAFINAL.length ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getAguardEmbarque({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getAguardandoEmbarque();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit("setEmbarque", response.data.data[0].total ?? 0);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getPloomesAguardandoProducao({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getPloomesAguardandoProducao();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setPloomesAguardandoProducao",
          response.data.data[0].total ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getPloomesEmLinhaDeProducao({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getPloomesEmLinhaDeProducao();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit("setPloomesEmLinhaProducao", response.data.data[0].total ?? 0);
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async getPloomesAguardandoEmbarque({ dispatch, commit }) {
    try {
      const response = await DashboardRepository.getPloomesAguardandoEmbarque();
      if (response.status === 200) {
        if (response.data === "") response.data = 0;
        commit(
          "setPloomesAguardandoEmbarque",
          response.data.data[0].total ?? 0
        );
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },
  setListingInsumosNaoConfiguradosFilter({ commit }, value) {
    commit("setListingInsumosNaoConfiguradosFilter", value);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },
};

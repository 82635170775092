import { HttpRestService } from "@/services/http/HttpRestService";

export class NotificationRepository {
  static getNotificationList() {
    return HttpRestService.get("/notificacao/nao-lida");
  }

  static dismissNotification(payload) {
    return HttpRestService.post(`/notificacao/lida`, payload);
  }
}

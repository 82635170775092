<template>
  <div>
    <div class="content">
      <div class="box-content">
        <form @submit.prevent="newReg">
          <div class="form-group">
            <label>Seção</label>
            <input
              type="text"
              class="form-control"
              style="text-transform: uppercase"
              v-model="local.section"
            />
          </div>
          <div class="form-group">
            <label>NR.</label>
            <input type="number" class="form-control" v-model="local.nr" />
          </div>
          <button type="submit" class="btn btn-primary">Salvar >></button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "../../mixins/routesValidationMixin";
import httpRequestMixin from "../../mixins/httpRequestMixin";
import LocalizacaoEstoqueRepository from "@/repositories/v2/LocalizacaoEstoqueRepository";

export default {
  components: {},
  data() {
    return {
      local: {},
      localizacaoEstoqueRepository: LocalizacaoEstoqueRepository.build(),
    };
  },
  mixins: [routesValidationMixin, httpRequestMixin],
  methods: {
    newReg() {
      this.localizacaoEstoqueRepository = LocalizacaoEstoqueRepository.build();
      this.localizacaoEstoqueRepository
        .postLocalizacaoEmEstoque(this.local)
        .then(() => {
          this.$router.push({ name: "local", query: { res: "add" } });
        })
        .catch(function (error) {
          // window.location.href = "/dashboard";
          console.error(error);
        });
    },
  },
};
</script>

export default {
  linha: "",
  pedidos: [],
  list: [],
  linha1: [],
  linha2: [],
  linha3: [],
  linha4: [],
  linha5: [],
  historicoDoPedido: [],
  detalhesDoPedido: [],
  linhasProducao: [],
  total_items: 0,
  volumes: 0,
  isLoadingZerarLinha: false,
};

export default {
  linha1(state) {
    return state.linha1;
  },

  linha2(state) {
    return state.linha2;
  },

  linha3(state) {
    return state.linha3;
  },

  linha3Conf(state) {
    return state.linha3Conf;
  },

  linha4(state) {
    return state.linha4;
  },

  linha5(state) {
    return state.linha5;
  },

  metaDia(state) {
    return state.metaDia;
  },

  metaHora(state) {
    return state.metaHora;
  },

  pedRealizados(state) {
    return state.pedRealizados;
  },

  pedFaturados(state) {
    return state.pedFaturados;
  },

  confFinal(state) {
    return state.confFinal;
  },

  embarque(state) {
    return state.embarque;
  },

  pedAtrasados(state) {
    return state.pedAtrasados;
  },

  ploomesAguardandoProducao(state) {
    return state.ploomesAguardandoProducao;
  },

  ploomesEmLinhaProducao(state) {
    return state.ploomesEmLinhaProducao;
  },

  ploomesAguardandoEmbarque(state) {
    return state.ploomesAguardandoEmbarque;
  },
};

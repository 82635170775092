import PainelBase from "./PainelBase";

export default class PainelCanadianMono455W extends PainelBase {
  getCode = () => 24388;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 24388 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    select
                        prefix,
                        (select count(*) from sspedido s where s.codprodmp = 24388 and  substr(s.numerosseriecontrolados, 1, 4) = prefix) quantidade_insumos,
                        (select count(distinct s.numped) from sspedido s where s.codprodmp = 24388 and  substr(s.numerosseriecontrolados, 1, 4) = prefix) quantidade_pedidos
                    from (
                        select
                            distinct substr(numerosseriecontrolados, 1, 4) as prefix
                        from SSPEDIDO s
                        where s.codprodmp = 24388
                    ) order by quantidade_insumos desc
                Resultado:
                    +------+------------------+------------------+
                    |PREFIX|QUANTIDADE_INSUMOS|QUANTIDADE_PEDIDOS|
                    +------+------------------+------------------+
                    |1210  |56472             |3203              |
                    |2107  |141               |53                |
                    |2106  |43                |17                |
                    |RS8I  |42                |3                 |
                    |1071  |35                |25                |
                    |0718  |35                |21                |
                    |0703  |25                |12                |
                    |0601  |18                |8                 |
                    |1858  |17                |2                 |
                    |1070  |16                |8                 |
                    |7180  |15                |8                 |
                    |1060  |15                |9                 |
                    |1600  |8                 |1                 |
                    |0618  |5                 |5                 |
                    |7030  |5                 |5                 |
                    |6010  |5                 |4                 |
                    |8582  |5                 |1                 |
                    |2118  |3                 |3                 |
                    |8012  |3                 |2                 |
                    |8006  |2                 |2                 |
                    |0302  |2                 |1                 |
                    |8800  |2                 |2                 |
                    |1061  |2                 |2                 |
                    |5821  |2                 |1                 |
                    |2700  |2                 |1                 |
                    |0719  |2                 |1                 |
                    |1800  |2                 |2                 |
                    |2103  |1                 |1                 |
                    |2108  |1                 |1                 |
                    |0303  |1                 |1                 |
                    |8022  |1                 |1                 |
                    |6102  |1                 |1                 |
                    |3571  |1                 |1                 |
                    |0107  |1                 |1                 |
                    |8700  |1                 |1                 |
                    |1020  |1                 |1                 |
                    |0705  |1                 |1                 |
                    |1200  |1                 |1                 |
                    |0070  |1                 |1                 |
                    |2101  |1                 |1                 |
                    |7190  |1                 |1                 |
                    |3800  |1                 |1                 |
                    |9928  |1                 |1                 |
                    |1040  |1                 |1                 |
                    |8210  |1                 |1                 |
                    |4184  |1                 |1                 |
                    |NULL  |0                 |0                 |
                    +------+------------------+------------------+


                Interpretação:
                    Essa consulta devolve todos os prefixos de 4 dígitos do insumo em questão, mostrando
                    47 resultados. Perceba que apenas a inicial 1210 possui resultados consideráveis. Os
                    outros muito provavelmente são erros de bipagem.
            Tipagem:
                Foi verificado que todos os seriais desse painel são numéricos. Consulta:
                    select
                        s.numerosseriecontrolados
                    from sspedido s
                    where
                        s.codprodmp = 24388
                        and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
                Apenas de essa consulta retornar números de série com caracteres alfanuméricos, são tão poucos
                que podemos considerar como erro de bipagem.

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 24388 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 24388 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 24388
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |14    |56236    |3203     |
                    |13    |145      |62       |
                    |15    |134      |59       |
                    |17    |81       |45       |
                    |11    |71       |47       |
                    |12    |69       |44       |
                    |16    |69       |44       |
                    |22    |43       |4        |
                    |18    |35       |18       |
                    |10    |21       |17       |
                    |8     |8        |5        |
                    |9     |4        |4        |
                    |20    |4        |2        |
                    |7     |3        |3        |
                    |23    |3        |1        |
                    |19    |3        |3        |
                    |27    |3        |2        |
                    |28    |3        |3        |
                    |21    |3        |3        |
                    |24    |1        |1        |
                    |5     |1        |1        |
                    |6     |1        |1        |
                    |29    |1        |1        |
                    |25    |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+


                Interpretação:
                    Perceba que a quantidade de seriais com 14 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1210 mais 10 dígitos numéricos, totalizando 14 dígitos.
         */
    return !!this.serial.match(/^1210\d{10}$/);
  }

  static build() {
    return new PainelCanadianMono455W();
  }
}

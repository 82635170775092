<template>
  <b-modal data-bv
    id="modal-selecionar-pallet"
    v-model="isSelectPalletDialogVisible"
    body-class="rounded"
    size="md"
    hide-footer
    hide-header
    centered
    @hidden="closeSelectPalletDialog"
  >
    <div class="mt-2" v-if="!isLoadingDialog">
      <div class="d-block text-center my-3">
        <h4 class="font-weight-bold">Confirma palete utilizado?</h4>
        <h4 class="font-weight-bold">
          {{ selectPalletDialogData.painelNome }}
        </h4>
      </div>

      <div style="overflow-y: auto; max-height: 600px" class="text-center">
        <div>
          <b-form-select
            v-model="selectedPallet"
            :options="pallets"
            value-field="palletId"
            text-field="descricaoTamanho"
          ></b-form-select>
          <div>
            <b-button
              class="button-etiqueta"
              @click="habilitaFecharCaixa"
              :disabled="selectedPallet === null"
            >
              <b-spinner v-if="isLoadingButton" />
              <span v-else>Confimar</span></b-button
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-col cols="12" class="text-center mt-3">
        <default-spinner />
      </b-col>
    </div>
    <modal-error
      @close="showModal = false"
      :modalAlert="modalAlert"
    ></modal-error>
  </b-modal>
</template>

<script>
import DefaultSpinner from "@/modules/separacao_linhas/separacao_linha-1/components/DefaultSpinner.vue";
import ModalError from "@/modules/souserials/producao_pedido/ModalError.vue";
import { SelecionarPalletService } from "../../../../../services/pedido_web_producao/linha_paineis/SelecionarPalletService";

export default {
  name: "ModalSelecionarPallet",
  components: {
    DefaultSpinner,
    ModalError,
  },
  data() {
    return {
      modalAlert: {
        message: "",
        variant: "",
      },
      isLoadingDialog: false,
      isLoadingButton: false,
      pallets: [],
      selectedPallet: null,
    };
  },
  async created() {
    this.selecionarPalletService = SelecionarPalletService.build().setVm(this);
    await this.selecionarPalletService.listarPallets();
  },
  props: {
    selectPalletDialogData: { type: Object, required: true },
    isSelectPalletDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeSelectPalletDialog() {
      this.$emit("closeSelectPalletDialog");
    },
    habilitaFecharCaixa() {
      this.isLoadingButton = true;
      this.$emit("habilitaFecharCaixa", this.selectedPallet);
    },
  },
};
</script>

<style scoped>
.button-etiqueta {
  background: #ff7d27;
  border: none;
  color: #002233;
  font-weight: bold;
}
.button-etiqueta:hover {
  color: white;
}
</style>

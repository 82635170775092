import { ConfiguracaoDeInsumosRepository } from "../../repository/ConfiguracaoDeInsumosRepository";
import { ErrorNotifier } from "../../../../services/messages/ErrorNotifier";

export default {
  async insumos({ commit, state, dispatch }) {
    commit("setIsLoading", true);
    commit("setListingInsumos", []);
    commit("setInsumosPagination", {});
    commit("setListingInsumosNaoConfigurados", null);
    try {
      const response = await ConfiguracaoDeInsumosRepository.getListInsumos(
        state.urlParams,
        state.insumosNaoConfiguradosFilter
      );
      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        const { pagination, list } = data;
        commit("setIsLoading", false);

        if (typeof list[list.length - 1].insumosNaoConfigurados === "number") {
          commit(
            "setListingInsumosNaoConfigurados",
            list[list.length - 1].insumosNaoConfigurados ?? 0
          );
          list.pop();
          commit("setListingInsumos", list);
        } else {
          commit("setListingInsumos", list);
        }

        commit("setInsumosPagination", pagination);
        return;
      } else {
        commit("setIsLoading", false);
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  async editInsumoPut({ commit, dispatch, state }, body) {
    try {
      const response = await ConfiguracaoDeInsumosRepository.editInsumo(
        body,
        state.insumosNaoConfiguradosFilter
      );
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("setIsLoading", false);
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        await dispatch("insumos");
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },
  setListingInsumosNaoConfiguradosFilter({ commit }, value) {
    commit("setListingInsumosNaoConfiguradosFilter", value);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },
};

import router from "../../../router";
import { UsuarioLogado } from "../../login/UsuarioLogado";
import { PedidosRepository } from "../../../repositories/v2/PedidosRepository";
import { mapPedidosDetalhesData } from "../maps";
import { PedidoDetalhesModel } from "../../../models/PedidoDetalhesModel";
import LinhasEnum from "../../enums/LinhasEnum";
import { getUserLogged } from "@/services/userLogged";
import store from "../../../store/store";

export class SelecaoLinhasRenderService {
  vm;
  pedidos;
  pedidosRepository = PedidosRepository.build();
  pedidoDetalhes = PedidoDetalhesModel.build();
  permissions;
  perfil;

  constructor(pedidosRepository) {
    this.pedidosRepository = pedidosRepository;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  static build() {
    const pedidosRepository = PedidosRepository.build();
    return new SelecaoLinhasRenderService(pedidosRepository);
  }

  async run() {
    this.permissions = getUserLogged().permissoes;
    this.perfil = UsuarioLogado.getPerfil();
    await this.requestUserFuncoes();
  }

  async requestUserFuncoes() {
    this.vm.perfilUsuarioAtual = "";
    const linhasPermissions = this.permissions.filter((permission) =>
      permission.startsWith("separacao-da-linha")
    );

    if (this.perfil.includes("administrador")) {
      this.vm.perfilUsuarioAtual = "ADMIN";
    } else if (linhasPermissions.length > 0) {
      const lineRoles = linhasPermissions.map((permission) =>
        permission.replace(/\D+/g, "")
      );

      const numped = this.vm.$route.params.numped;

      const routes = [
        { 0: "pedidoLinhaPaineis" },
        { 1: "pedidoLinhaCabos" },
        { 2: "pedidoLinhaDiversos" },
        { 3: "pedidoLinhaPerfis" },
        { 4: "pedidoLinhaInversores" },
      ];

      const selectedLine = lineRoles.find((line) =>
        Object.keys(LinhasEnum).some((key) => LinhasEnum[key] === +line)
      );
      // in case of this:  selectedLine && routes[selectedLine]
      if (selectedLine) {
        const routeName = Object.values(routes[selectedLine - 1])[0];
        console.log(routes);
        await router.replace({
          name: routeName,
          params: { numped },
        });
      }
    } else {
      await router.replace({ path: "/dashboard" });
    }

    await this.getLinhasDisponiveisList();
    await this.requestPedidoDetalhes();
  }

  async getLinhasDisponiveisList() {
    const numeroPedido = this.vm.$route.params.numped;
    const response = await this.pedidosRepository.getPedidoLinhasPendentes(
      numeroPedido
    );
    const { data } = response.data;
    this.vm.possuiLinhaPaineis = data.linhaPaineisPendente;
    this.vm.possuiLinhaCabos = data.linhaCabosPendente;
    this.vm.possuiLinhaDiversos = data.linhaDiversosPendente;
    this.vm.possuiLinhaPerfis = data.linhaPerfisPendente;
    this.vm.possuiLinhaInversores = data.linhasInversoresPendente;
    this.vm.pedidoJaFoiFinalizado =
      (!this.vm.possuiLinhaPaineis &&
        !this.vm.possuiLinhaCabos &&
        !this.vm.possuiLinhaDiversos &&
        !this.vm.possuiLinhaPerfis &&
        !this.vm.possuiLinhaInversores) ||
      +this.vm.perfilUsuarioAtual === LinhasEnum.paineis;
  }

  async requestPedidoDetalhes() {
    const numeroPedido = this.vm.$route.params.numped;
    const response = await this.pedidosRepository.getPedidoDetalhes(
      numeroPedido
    );
    console.log(response);
    const { data } = response.data;
    this.vm.pedidoDetalhes = mapPedidosDetalhesData(data);
    this.pedidoDetalhes = this.vm.pedidoDetalhes;
    console.log(this.pedidoDetalhes);
    console.log(this.pedidoDetalhes.statusCodigo);
    if (this.pedidoDetalhes.statusCodigo !== "L") {
      let errorMessage = {
        errorStatus: true,
        errorMessage: "Pedido com status não permitido.",
      };
      store.commit("notifications/setErrorNotification", errorMessage, {
        root: true,
      });
      await router.replace({ path: "/pedidos-ss/em-linha-de-producao" });
    }
  }

  pedidoEstaSendoBipadoPorOutroUsuario(linha) {
    const pcEmprId = UsuarioLogado.getPcEmprId();
    if (this.vm.perfilUsuarioAtual === "ADMIN") {
      return false;
    }
    return this.pedidos.some((pedido) => {
      return (
        +pedido.LINHA === linha &&
        pedido.CODFUNCSEP &&
        +pedido.CODFUNCSEP !== +pcEmprId
      );
    });
  }
}

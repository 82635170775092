<template>
  <span>
    <div v-for="(volume, j) in volumes" :key="j">
      <div class="mb-3 px-1 py-4" v-show="!volume.codigo">
        <b-card
          class="shadow"
          header="featured"
          header-tag="div"
          :header-class="
            'cor-background-azul ' +
            (btnFecharCaixaLoading ? ' caixa-carregando' : '')
          "
        >
          <template #header>
            <h4 class="mb-6 font-weight-bold" style="color: white">
              Caixa {{ j + 1 }} - {{ volume.codigo }}
            </h4>
          </template>
          <b-row
            v-for="(volumeInsumo, k) in volume.insumos"
            :key="k"
            class="mb-0 px-1 py-1"
          >
            <b-col>
              <label
                style="
                  align-items: center;
                  font-family: 'Poppins';
                  font-size: 15px;
                "
                >{{ (volumeInsumo.insumo || {}).descricao || "" }}</label
              >
            </b-col>
            <div>
              <b-button
                class="number-modifier"
                @click="volumeInsumo.quantidade--"
                style="border-radius: 8px 0px 0px 8px !important"
                pill
                :disabled="btnFecharCaixaLoading"
              >
                -
              </b-button>
              <input
                class="py-0 px-0; my-0 mx-0 input-metragem text-center"
                v-model="volumeInsumo.quantidade"
                type="number"
                :disabled="btnFecharCaixaLoading"
              />
              <b-button
                class="number-modifier"
                @click="volumeInsumo.quantidade++"
                style="border-radius: 0px 8px 8px 0px !important"
                pill
                :disabled="btnFecharCaixaLoading"
              >
                +
              </b-button>
            </div>
          </b-row>
        </b-card>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: "ListagemCaixasLinhaPerfis",
  props: {
    volumes: {
      type: Array,
      required: true,
    },
    btnFecharCaixaLoading: {
      type: Boolean,
      required: true,
    },
  },
  // computed: {
  //   listeners() {
  //     return {
  //       ...this.$listeners,
  //       click: this.onClick,
  //     };
  //   },
  // },
  // methods: {
  //   onClick(evt) {
  //     this.$emit("click", evt);
  //   },
  //   // tratarString(str) {
  //   //   let substituirStr = str.toLocaleLowerCase();
  //   //   let novaStr = substituirStr[0].toUpperCase() + substituirStr.substr(1);
  //   //   return novaStr;
  //   // },
  // },
};
</script>

<style scoped>
.cor-background-azul {
  background: #002233;
}
.number-modifier {
  border-style: none;
  background: #ff7d27;
}

.input-metragem {
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  width: 80%;
  border-radius: 0px 0px 0px 0px;
}

.caixa-carregando {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>

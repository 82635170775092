import { HttpRestService } from "@/services/http/HttpRestService";

export default class Repository {
  static getBays(urlParams) {
    return HttpRestService.get(`/api/v2/baia/listar`, urlParams);
  }
  static editBay(bayToBeEdited) {
    return HttpRestService.put(
      `/api/v2/baia/alterar/${bayToBeEdited.id}`,
      bayToBeEdited
    );
  }
  static registerBay(bayToBeRegistered) {
    return HttpRestService.post(`/api/v2/baia/criar`, bayToBeRegistered);
  }
}

<template>
  <div>
    <div class="content">
      <div class="box-content">
        <div class="pedido-det">
          <div v-if="insumosForamCarregados">
            <cabecalho-linha-cabos
              :numeroPedido="pedido.numeroPedido"
              :transportadora="pedido.transportadoraNome"
              :data-producao="pedido.dataProducao | dateDMY"
              :linha-status="pedido.linhaStatus"
              :quantidade-caixas="quantidadeVolumes"
              :insumos="insumos"
            />
            <div class="px-3">
              <b-row align-h="around">
                <default-button-caixa
                  :nameButton="'Detalhar Caixas'"
                  class="text-center my-4"
                  @click="detalharCaixas"
                />
                <default-button-caixa
                  v-if="mostraBtnFecharCaixa"
                  :nameButton="'Fechar Caixa'"
                  class="text-center my-4"
                  @click="fecharCaixa"
                  :loading="btnFecharCaixaLoading"
                  :disabled="!habilitaBtnFecharCaixa"
                />
                <default-button-caixa
                  v-if="mostraBtnFinalizarLinha"
                  :nameButton="'Finalizar Linha'"
                  class="text-center my-4"
                  @click="finalizarLinha"
                  :loading="btnFinalizarLinhaLoading"
                />
              </b-row>
              <listagem-caixas-linha-cabos
                :volumes="volumes"
                :btn-fechar-caixa-loading="btnFecharCaixaLoading"
              />
            </div>
          </div>
          <div v-else>
            <alerta-de-espera />
          </div>
        </div>
      </div>
      <modal-detalhes-de-caixas-linha-cabos
        :volumes="volumes"
        :quantidade-volumes="quantidadeVolumes"
      />
    </div>
    <div>
      <modal-fechar-caixa :modalAlert="modalAlert" />
    </div>
    <div>
      <modal-usuario-iniciador :alertUsuarioIniciador="alertUsuarioIniciador" />
    </div>
  </div>
</template>

<script>
import InsumoPedidoRepository from "../../../../repositories/v2/InsumoPedidoRepository";
import PedidoWebRepository from "../../../../repositories/v2/PedidoWebRepository";
import DefaultButtonCaixa from "../components/DefaultButtonCaixa.vue";
import AlertaDeEspera from "../../../../components/AlertaDeEspera";
import router from "../../../../router";
import CabecalhoLinhaCabos from "./PedidoWebProducaoLinhaCabosComponents/CabecalhoLinhaCabos";
import ListagemCaixasLinhaCabos from "./PedidoWebProducaoLinhaCabosComponents/ListagemCaixasLinhaCabos";
import ModalDetalhesDeCaixasLinhaCabos from "./PedidoWebProducaoLinhaCabosComponents/ModalDetalhesDeCaixasLinhaCabos";
// import env from '../../../../env';
import Swal from "sweetalert2";
import ModalFecharCaixa from "./ModalFecharCaixa/ModalFecharCaixa";
import ModalUsuarioIniciador from "./ModalUsuarioIniciador";
import { UsuarioIniciadorService } from "@/services/pedido_web_producao/UsuarioIniciadorService";

export default {
  components: {
    ModalDetalhesDeCaixasLinhaCabos,
    ListagemCaixasLinhaCabos,
    CabecalhoLinhaCabos,
    AlertaDeEspera,
    DefaultButtonCaixa,
    ModalFecharCaixa,
    ModalUsuarioIniciador,
  },
  data: () => ({
    modalAlert: {},
    alertUsuarioIniciador: {},
    insumos: [],
    volumes: [],
    dataModalMoverInsumo: {},
    pedido: {
      clienteNome: "",
      dataProducao: "",
      kitDescricao: "",
      numeroPedido: 0,
      transportadoraCodigo: 0,
      transportadoraNome: "",
      linhaStatus: "",
    },
    repositories: {
      insumoPedidoRepository: InsumoPedidoRepository.build(),
      pedidoWebV2Repository: PedidoWebRepository.build(),
    },
    quantidadeVolumes: 0,
    btnFecharCaixaLoading: false,
    btnFinalizarLinhaLoading: false,
    loadingTela: false,
    usuarioIniciadorService: UsuarioIniciadorService.build(),
  }),
  async beforeCreate() {
    this.usuarioIniciadorService = UsuarioIniciadorService.build().setVm(this);
    this.loadingTela = true;
    await this.usuarioIniciadorService.usuarioIniciadorLinhaCabos(
      this.$route.params.numped
    );
    this.loadingTela = false;
  },
  async mounted() {
    this.listarInsumos();
  },
  watch: {
    volumes: {
      handler(volumes) {
        for (const volume of volumes) {
          for (const insumo of volume.insumos) {
            if (insumo.quantidade < 0) {
              insumo.quantidade = 0;
            }
            const insumoCabecalho =
              this.insumos.find(
                (i) => i.insumoId === (insumo.insumo || {}).insumoId
              ) || {};
            const quantidadeRestante = insumoCabecalho.quantidadeRestante || 0;
            if (
              insumo.quantidade > quantidadeRestante &&
              volume.volumeId === 0
            ) {
              insumo.quantidade = quantidadeRestante;
            }
            insumo.quantidade = +insumo.quantidade;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    insumosForamCarregados() {
      return !this.loadingTela;
    },
    volumeCoringa() {
      return this.volumes.find((v) => !v.volumeId) || {};
    },
    mostraBtnFinalizarLinha() {
      return (
        this.insumos.reduce(
          (acc, insumo) => acc + insumo.quantidadeRestante,
          0
        ) === 0 && this.pedido.linhaStatus !== "FINALIZADO"
      );
    },
    habilitaBtnFecharCaixa() {
      return (
        this.volumes.find((v) => v.volumeId === 0) || { insumos: [] }
      ).insumos.reduce((acc, i) => acc + i.quantidade, 0);
    },
    mostraBtnFecharCaixa() {
      return (
        !this.mostraBtnFinalizarLinha &&
        this.existeVolumeCoringa &&
        this.pedido.linhaStatus !== "FINALIZADO"
      );
    },
    existeVolumeCoringa() {
      return this.volumeCoringa.volumeId !== undefined;
    },
  },
  methods: {
    async listarInsumos() {
      this.loadingTela = true;
      const response =
        await this.repositories.insumoPedidoRepository.getLinhaCabos(
          this.$route.params.numped
        );
      const { data } = response.data;
      this.loadingTela = false;
      // if (response.status !== 200) {
      //   await Swal.fire({
      //     icon: "error",
      //     text: response.data.message + " Entre em contato com o Suporte.",
      //   });
      //   await this.$router.replace({ name: "pedidosWebLinhaProducao" });
      // }
      this.renderizarLista(data);
      this.loadingTela = false;
    },
    renderizarLista(data) {
      this.insumos = data.insumos.map((i) => {
        const {
          insumoDescricao,
          insumoId,
          quantidade,
          quantidadeRestante,
          sspedidoNumSeq,
          winthorCodigoProduto,
        } = i;
        return {
          descricao: insumoDescricao,
          insumoId,
          quantidade,
          quantidadeRestante,
          sspedidoNumSeq,
          winthorCodigoProduto,
        };
      });
      this.volumes = data.volumes.map((v) => {
        return {
          codigo: v.codigo,
          insumos: v.insumos.map((i) => {
            return {
              insumo: this.insumos.find(
                (insumo) => insumo.insumoId === i.insumoId
              ),
              quantidade: i.quantidade,
            };
          }),
          volumeId: v.volumeId,
        };
      });
      this.pedido = {
        clienteNome: data.clienteNome,
        dataProducao: data.dataProducao,
        kitDescricao: data.kitDescricao,
        numeroPedido: data.numeroPedido,
        transportadoraCodigo: data.transportadoraCodigo,
        transportadoraNome: data.transportadoraNome,
        linhaStatus: (data.linhaProducao2Status || "").toUpperCase(),
      };
      this.quantidadeVolumes = this.volumes.length;
      this.loadingTela = false;
    },
    async fecharCaixa() {
      const colocarNaEsteira = this.habilitaBtnFecharCaixa <= 500;
      const sspedidoNumSeqList = this.volumeCoringa.insumos.map((i) => ({
        sspedidoNumSeq: i.insumo.sspedidoNumSeq,
        quantidade: i.quantidade,
      }));
      this.btnFecharCaixaLoading = true;
      try {
        const body = { sspedidoNumSeqList, linha: 2 };
        const response =
          await this.repositories.pedidoWebV2Repository.criarVolumeCabos(
            this.pedido.numeroPedido,
            body
          );
        if (response.status === 200) {
          // let volumes = response.data.data.volumes
          // let volumesSemZero = volumes.filter((v)=> Math.max(v.volumeId))
          // let ultimoVolume = volumesSemZero[volumesSemZero.length -1]
          // let newUrl = `${env.baseUrl}/volume/${ultimoVolume.codigo}/pdf`;
          // window.open(newUrl)
          this.renderizarLista(response.data.data);
        }
        const quantidadeMenorIgual50 = sspedidoNumSeqList.every((item) => {
          const quantidadeNumerica = Number(item.quantidade);
          return quantidadeNumerica <= 50;
        });

        if (quantidadeMenorIgual50 && response.status === 200) {
          Swal.fire({
            icon: "success",
            html: "<span style='color:green;'>Sucesso ao fechar caixa</span><br><br><span style='color: #ff7d27;'><h3>Aviso!</h3></b></span><br>Este volume deve ser colocado na <b>BANDEJA.</b>",
          });
          this.btnFecharCaixaLoading = false;
          return;
        }
        if (response.status === 500) {
          await Swal.fire({
            icon: "error",
            text: "Ocorreu um problema no servidor.",
          });
          window.location.reload();
        } else {
          this.modalAlert = {
            message: response.data.message,
            variant: response.status === 200 ? "success" : "danger",
          };
          this.$bvModal.show("modal-fechar-caixa");
          if (!colocarNaEsteira && response.status === 200) {
            Swal.fire({
              icon: "warning",
              text: "Este volume não deve ser colocado na esteira.",
            });
          }
        }
      } catch (e) {
        alert("Ocorreu um erro inesperado. Entre em contato com o suporte.");
      }
      this.btnFecharCaixaLoading = false;
    },
    async finalizarLinha() {
      this.btnFinalizarLinhaLoading = true;
      try {
        const response =
          await this.repositories.pedidoWebV2Repository.finalizarLinhaCabos(
            this.pedido.numeroPedido
          );
        if (response.status === 200) {
          alert("A linha foi finalizada com sucesso.");
          await router.replace({ path: "/linha-prod" });
        } else {
          alert(
            response.data.message || "Ocorreu um erro na finalização da linha."
          );
        }
      } catch (e) {
        console.log(e.message);
        this.btnFinalizarLinhaLoading = false;
      }
      this.btnFinalizarLinhaLoading = false;
    },
    detalharCaixas() {
      this.$bvModal.show("modal-detalhes-volume");
    },
  },
};
</script>

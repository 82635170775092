<template>
  <div>
    <b-button
      :disabled="loading || disabled"
      @click="$emit('click')"
      class="button-caixa"
      size="lg"
    >
      <b-spinner v-if="loading" label="Spinning" style="color: white" />
      <b v-else>{{ nameButton }}</b>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "DefaultButtonCaixa",
  props: {
    nameButton: {
      type: [String],
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        click: this.onClick,
      };
    },
  },
  methods: {
    onClick(evt) {
      if (this.disabled) {
        return;
      }
      this.$emit("click", evt);
    },
  },
};
</script>

<style scoped>
.button-caixa {
  background: #ff7d27;
  border: none;
  color: #002233;
}
.button-caixa:hover {
  color: white;
}
</style>

import ConferenciaFinalAgilRepository from "@/repositories/v2/ConferenciaFinalAgilRepository";
import PedidoRepository from "@/repositories/v1/PedidoRepository";

export class ConferenciaFinalAgilService {
  vm;
  conferenciaFinalAgilRepository = ConferenciaFinalAgilRepository.build();
  pedidoRepository = PedidoRepository.build();

  constructor(conferenciaFinalAgilRepository, pedidoRepository) {
    this.conferenciaFinalAgilRepository = conferenciaFinalAgilRepository;
    this.pedidoRepository = pedidoRepository;
  }

  static build() {
    const conferenciaFinalAgilRepository =
      ConferenciaFinalAgilRepository.build();
    const pedidoRepository = PedidoRepository.build();
    return new ConferenciaFinalAgilService(
      conferenciaFinalAgilRepository,
      pedidoRepository
    );
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async biparCarga() {
    this.vm.btnFinalizarConferenciaLoading = true;
    const body = this.getBody();
    try {
      await this.conferenciaFinalAgilRepository
        .requestBiparCarga(body)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.vm.modalAlert = {
              message: response.data.message,
              variant: "success",
            };
            this.vm.$bvModal.show("modal-alert-conferencia-final-agil");
            this.vm.habilitaBtnFinalizarConferencia = true;
          } else {
            this.vm.modalAlert = {
              message: response.data.message,
              variant: "danger",
            };
            this.vm.$bvModal.show("modal-alert-conferencia-final-agil");
            this.vm.habilitaBtnFinalizarConferencia = false;
          }
        });
    } catch (e) {
      console.error(e);
    }
    this.vm.btnFinalizarConferenciaLoading = false;
  }

  getBody() {
    const data = this.vm;
    return {
      numeroPedido: data.numped,
      numeroLote: data.numeroLote,
    };
  }
}

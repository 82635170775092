import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";

import { BootstrapVueIcons } from "bootstrap-vue";
import filters from "./utils/filters";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.prototype.console = console;

Vue.config.productionTip = false;

Vue.use(BootstrapVueIcons);

new Vue({
  el: "#app",
  router,
  filters,
  store,
  vuetify,
  template: "<App/>",
  components: { App },
  render: (h) => h(App),
}).$mount("#app");

import Repository from "../repository";
import state from "./state";

export default {
  async roles({ commit, state }) {
    commit("setIsLoading", true);
    commit("setRoles", []);
    try {
      const response = await Repository.getRoles(state.urlParams);
      if (response.status >= 200 && response.status < 300) {
        const roles = response.data.data.list.map((role) => role.descricao);
        const pagination = response.data.data.pagination;
        commit("setRoles", roles);
        commit("setPagination", pagination);
        return;
      }
      const errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na listagem",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoading", false);
    }
  },
  resetUrlParams({ commit }) {
    const originalUrlParams = state.urlParams;
    commit("resetUrlParams", originalUrlParams);
  },
};

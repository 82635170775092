export class LocalStorageService {
  static build() {
    return new LocalStorageService();
  }

  getSeriaisBipados() {
    let seriais = localStorage.getItem("seriais");
    if (seriais) {
      seriais = JSON.parse(seriais);
    }
    return seriais || [];
  }

  storeSerialBipado(serial, inputSelector) {
    const seriais = this.getSeriaisBipados();
    for (let i = 0; i < seriais.length; i++) {
      if (seriais[i].inputSelector === inputSelector) {
        seriais.splice(i, 1);
      }
    }
    inputSelector = "." + inputSelector.replace(/\s+/g, " ").replace(" ", ".");
    seriais.push({ serial, inputSelector });
    localStorage.setItem("seriais", JSON.stringify(seriais));
  }

  removerSeriaisBipados() {
    localStorage.removeItem("seriais");
  }

  setPedidoDetalhesLocalStorage(pedidoDetalhes) {
    localStorage.setItem("pedidoDetalhes", JSON.stringify(pedidoDetalhes));
  }

  getPedidoDetalhesLocalStorage() {
    let pedidoDetalhes = localStorage.getItem("pedidoDetalhes");
    if (pedidoDetalhes) {
      pedidoDetalhes = JSON.parse(pedidoDetalhes);
    }
    return pedidoDetalhes || {};
  }
}

<template>
  <b-modal data-bv
    id="modal-error"
    body-class="rounded"
    hide-footer
    hide-header
    centered
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">Atenção!</h4>
      </div>
      <div class="col-8 text-center mx-auto">
        <b-alert show :variant="modalAlert.variant">
          {{ modalAlert.message }}
        </b-alert>
        <div
          :class="`btn-${modalAlert.variant}`"
          @click="$bvModal.hide('modal-error')"
          class="btn"
        >
          Ok
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalError",
  data: () => ({}),
  props: {
    modalAlert: { Type: Object, required: true },
  },
  methods: {},
};
</script>

<style scoped>
.alert-danger {
  background-color: #f8d7da !important;
}
.alert-success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
</style>

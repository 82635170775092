<template>
  <div>
    <div class="log"></div>
    <div class="content">
      <div class="box-content">
        <div class="pedido-det" v-if="pedidos.length">
          <div class="topo-det">
            <div class="det-info">
              <div class="item">
                RESUMO PEDIDO: {{ pedidos[0].NUMPED }} - STATUS
                {{ status(pedidos[0].STATUS) }} -
                <div
                  :class="pedidos[0].CODTRANSPORTADORA === 9999 ? 'alert' : ''"
                >
                  Transportadora: {{ pedidos[0].TRANSPORTADORA }}
                </div>
                - NFe:
                {{ pedidos[0].CHAVENFE }}
              </div>
              <div class="item">
                <button type="submit" class="btn btn-danger" @click="report">
                  Relatório
                </button>
              </div>

              <div class="item">
                <!--                <button type="submit" class="btn btn-danger" @click="confere">-->
                <!--                  Zerar Conferência-->
                <!--                </button>-->
              </div>

              <div class="item" v-if="type == 'ADMIN'">
                <!--                <button-->
                <!--                  type="submit"-->
                <!--                  class="btn btn-danger"-->
                <!--                  @click="zerar(pedidos[0].NUMPED)"-->
                <!--                >-->
                <!--                  Zerar Bipagem-->
                <!--                </button>-->
              </div>

              <!-- <div class="item">
              <button
                type="submit"
                class="btn send"
                style="background: red; margin-top: 10px"
                @click="impEt(pedidos[0].NUMPED, false)"
              >
                Imp. Etiqueta Total
              </button>
            </div> -->

              <!-- <div class="det-info row-grid">
              <input
                type="number"
                placeholder="Vol. Inicial"
                class="vol1 item-grid"
              />
              <input
                type="number"
                placeholder="Vol. Final"
                class="vol2 item-grid"
              />
              <button
                type="submit"
                class="btn send item-grid"
                style="background: red; margin-top: -10px"
                @click="impEt(pedidos[0].NUMPED, true)"
              >
                Imprimir >>
              </button>
            </div> -->
            </div>
          </div>
          <div class="det-info">
            <div class="item">
              <span>CLIENTE:</span> {{ pedidos[0].CLIENTE }}
            </div>
            <div class="item">
              <span>DATA DE PRODUÇÃO:</span>
              {{ formatDate(pedidos[0].DATASEPROD) }}
            </div>
          </div>
          <div class="det-info">
            <div class="item"><span>KIT:</span> {{ pedidos[0].DESC_KIT }}</div>
            <div class="item">
              <span>LOCALIZAÇÃO DO ESTOQUE: </span
              >{{ pedidos[0].LOCALIZACAOPEDIDO }}
            </div>
          </div>

          <div class="counter">
            <h3>Total Itens: {{ total_items }}</h3>
          </div>

          <table class="table table-bordered" v-if="list.pedidos1.length">
            <thead>
              <tr>
                <th>Linha 1</th>
                <th width="120">Volume: {{ list.pedidos1[0].NUMVOL }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pedido, index) in list.pedidos1" :key="index">
                <td colspan="2">
                  {{ pedido.PAINEIS }}
                  <span class="qt-table">QUANTIDADE: {{ pedido.TOTAL }}</span>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-bordered" v-if="list.pedidos2.length">
            <thead>
              <tr>
                <th>Linha 2</th>
                <th width="120">Volume: {{ list.pedidos2[0].NUMVOL }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pedido, index) in list.pedidos2" :key="index">
                <td colspan="2">
                  {{ pedido.CODPRODMP }} - {{ pedido.DESCRICAO }}
                  <span class="qt-table">QUANTIDADE: {{ pedido.QT }}</span>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-bordered" v-if="list.pedidos3.length">
            <thead>
              <tr>
                <th>Linha 3</th>
                <th width="120">Volume: {{ list.pedidos3[0].NUMVOL }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pedido, index) in list.pedidos3" :key="index">
                <td colspan="2">
                  {{ pedido.CODPRODMP }} - {{ pedido.DESCRICAO }}
                  <span class="qt-table">QUANTIDADE: {{ pedido.QT }}</span>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table table-bordered" v-if="list.pedidos4.length">
            <thead>
              <tr>
                <th>Linha 4</th>
                <th width="120">Volume: {{ list.pedidos4[0].NUMVOL }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pedido, index) in list.pedidos4" :key="index">
                <td colspan="2">
                  {{ pedido.CODPRODMP }} - {{ pedido.DESCRICAO }}
                  <span class="qt-table">QUANTIDADE: {{ pedido.QT }}</span>
                </td>
              </tr>
            </tbody>
          </table>

          <h4 style="text-align: right">Total Volumes: {{ list.total }}</h4>
          <br />

          <!-- <button type="submit" class="btn btn-danger" @click="showVol">
            Alterar volumes
          </button> -->

          <div class="prods">
            <div class="item">
              <div class="linha linha-1" v-show="insert_vol > 0">
                <div class="topo">
                  <div class="det-info">
                    <h3>Alterar Volumes</h3>
                    <h3>{{ pedidos[0].DESC_KIT }}</h3>
                  </div>
                </div>

                <div class="infos">
                  <div class="det-info">
                    <div class="item item-cod">
                      <label>Volume Linha 1</label>
                      <input type="number" class="volume1" />
                    </div>
                    <div class="item item-cod">
                      <label>Volume Linha 2</label>
                      <input type="number" class="volume2" />
                    </div>
                    <div class="item item-cod">
                      <label>Volume Linha 3</label>
                      <input type="number" class="volume3" />
                    </div>
                    <div class="item item-cod">
                      <label>Volume Linha 4</label>
                      <input type="number" class="volume4" />
                    </div>
                    <div class="item item-send">
                      <button type="submit" class="btn send" @click="saveVol">
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="prods" v-if="bipar_volumes > 0">
            <div class="item">
              <div class="linha linha-1">
                <div class="topo">
                  <div class="det-info">
                    <h3>Bipar Volumes</h3>
                    <h3>{{ pedidos[0].DESC_KIT }}</h3>
                  </div>
                </div>

                <div class="infos">
                  <div v-for="n in list.total" :key="n">
                    <div class="det-info">
                      <div class="item item-cod">
                        <h3>Volume</h3>
                        <input
                          type="number"
                          :ref="'volume-' + n"
                          :class="'volume-' + n"
                          class="volume"
                          @keydown="checkEnter(pedidos[0].NUMPED, n, $event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="topo-det" v-if="bipar_volumes === 0">
            <h3>Inserir Nota fiscal</h3>
            <div class="det-info">
              <div class="item nr-nota">
                <label>Nr. Nota fiscal:</label>
                <input type="number" autofocus class="nota" />
              </div>
              <div class="item">
                <button
                  type="submit"
                  class="btn send"
                  style="margin-top: 10px; background-color: orange"
                  @click="salvar(pedidos[0].NUMPED)"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routesValidationMixin from "../../../../mixins/routesValidationMixin";
import httpRequestMixin from "../../../../mixins/httpRequestMixin";
import { UsuarioLogado } from "../../../../services/login/UsuarioLogado";
import PedidoRepository from "../../../../repositories/v1/PedidoRepository";
import UsersRepository from "../../../../repositories/v1/UsersRepository";
import env from "../../../../env";

export default {
  components: {},
  data() {
    return {
      pedidos: [],
      list: [],
      vol_send: [],
      save_ped: {},
      type: "",
      insert_vol: 0,
      total_items: 0,
      bipar_volumes: 1,
      pedidoRepository: PedidoRepository.build(),
      userRepository: UsersRepository.build(),
      indexVols: 0,
      volsBipados: [],
    };
  },
  mixins: [routesValidationMixin, httpRequestMixin],
  computed: {
    vols() {
      let volume1 = (this.list.pedidos1[0] || {}).NUMVOL || 0;
      let volume2 = (this.list.pedidos2[0] || {}).NUMVOL || 0;
      let volume3 = (this.list.pedidos3[0] || {}).NUMVOL || 0;
      let volume4 = (this.list.pedidos4[0] || {}).NUMVOL || 0;
      const totalVolumes = volume1 + volume2 + volume3 + volume4;
      const numped = this.list.pedidos[0].NUMPED;
      const vols = [];
      let j1 = 1;
      let j2 = 1;
      let j3 = 1;
      let j4 = 1;
      for (let i = 0; i < totalVolumes; i++) {
        if (volume1 > 0) {
          let cod = (j1++).toString().padStart(3, "0");
          vols.push(`${numped}1${cod}`);
          volume1--;
          continue;
        }
        if (volume2 > 0) {
          let cod = (j2++).toString().padStart(3, "0");
          vols.push(`${numped}2${cod}`);
          volume2--;
          continue;
        }
        if (volume3 > 0) {
          let cod = (j3++).toString().padStart(3, "0");
          vols.push(`${numped}3${cod}`);
          volume3--;
          continue;
        }
        if (volume4 > 0) {
          let cod = (j4++).toString().padStart(3, "0");
          vols.push(`${numped}4${cod}`);
          volume4--;
          // continue;
        }
      }
      return vols;
    },
  },
  created() {
    this.pedidoRepository = PedidoRepository.build();

    //this.HTTP_REQUEST_MIXIN_get(`/api/user-func/${UsuarioLogado.getUserId()}`)
    this.userRepository
      .getFuncoesByUser(`${UsuarioLogado.getUserId()}`)
      .then((response) => {
        if (response.data.roles.includes("ADMIN")) {
          this.type = "ADMIN";
        }
        if (response.data.roles.includes("EMBARQUE")) {
          this.type = "EMBARQUE";
        }

        this.pedidoRepository
          .pedidoResumo(this.$route.params.id)
          .then((response) => {
            this.pedidos = response.data.pedidos;
            this.list = response.data;

            if (this.pedidos.length) {
              if (response.data.pedidos[0].STATUS !== "E") {
                window.location.href = "/pedidos-agil/aguardando-conferencia";
              }

              this.item_save = this.pedidos[0].NUMSEQ;
              this.total_items = this.pedidos.length;

              if (this.list.total) {
                //let self = this;
                this.$nextTick(function () {
                  //self.$refs["volume-1"].focus();
                  const className = "volume-1";
                  const nextInput =
                    document.getElementsByClassName(className)[0];
                  nextInput.focus();
                });
              }
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      })
      .catch(function (error) {
        console.error(error);
      });
  },
  methods: {
    showVol() {
      this.insert_vol = 1;
    },

    saveVol() {
      if (confirm("Confirma alteração nos volumes?")) {
        this.save_ped = {
          numped: this.pedidos[0].NUMPED,
          volume1: document.querySelector(".volume1").value,
          volume2: document.querySelector(".volume2").value,
          volume3: document.querySelector(".volume3").value,
          volume4: document.querySelector(".volume4").value,
        };

        this.pedidoRepository
          .alteraVolume(this.save_ped)
          .then(() => {
            window.location.href =
              "/pedido-embarque-agil/" + this.pedidos[0].NUMPED;
          })
          .catch(function (e) {
            console.error(e);
          });
      }
    },

    cleanInput(checkEmpty) {
      checkEmpty.disabled = true;
      checkEmpty.value = "";
      checkEmpty.disabled = false;
      checkEmpty.focus();
    },

    confere() {
      if (confirm("Confirma zerar conferência?")) {
        this.save_ped = {
          numped: this.pedidos[0].NUMPED,
        };

        this.pedidoRepository
          .zeraConfere(this.save_ped)
          .then((res) => {
            console.log(res);
            alert("Conferência foi zerada com sucesso!");
            this.$router.replace({ name: "pedidosWebAguardandoEmbarque" });
          })
          .catch((er) => {
            alert(er);
          });
      }
    },

    report() {
      window.open(
        env.baseUrl + "/pedido/rel-embarque/" + this.pedidos[0].NUMPED
      );
    },
    checkEnter(id, item, e) {
      const checkEmpty = document.querySelector(".volume-" + item);
      const transportadoraEhNaoExpedida = /NAO\s+EXPEDIR/.test(
        this.pedidos[0].TRANSPORTADORA
      );
      if (transportadoraEhNaoExpedida) {
        checkEmpty.value = "";
        alert(
          "Esse pedido não pode ser expedido. Entrar em contato com financeiro."
        );
        return;
      }
      if (
        e.keyCode !== undefined &&
        (e.keyCode === 0 || e.keyCode === 229 || e.keyCode === 13)
      ) {
        let self = this;
        setTimeout(() => {
          if (
            checkEmpty.value &&
            checkEmpty.value.length > 4 &&
            checkEmpty.value.trim().length > 0
          ) {
            self.bipaVol(id, item, e);
          } else {
            this.cleanInput(checkEmpty);
          }
        }, 10);
      }
    },

    zerar(id) {
      if (confirm("Confirma Zerar Bipagem?")) {
        this.save_ped = {
          numped: id,
        };

        this.pedidoRepository
          .zerar(this.save_ped)
          .then(() => {
            alert("Pedido foi zerado com sucesso!");
            window.location.href = "/aguardando-prod";
          })
          .catch(function (e) {
            alert(e);
          });
      }
    },

    bipaVol(id, item) {
      let val_check = document.querySelector(".volume-" + item);
      const inputData = val_check.value;
      // const startWithNumped = new RegExp(`^${id}`).test(inputData);
      const pedidoJaFoiBipado = this.volsBipados.includes(inputData);
      if (pedidoJaFoiBipado) {
        alert("Esse volume já foi bipado.");
        val_check.value = "";
        return;
      }
      // if (!startWithNumped) {
      //   alert("O código do volume está incorreto.");
      //   val_check.value = "";
      //   return;
      // }
      // const number_item = +inputData.replace(id, "");
      // const val_correct = this.vols[this.indexVols];
      if (!this.vols.includes(inputData)) {
        alert(
          "Código do volume está incorreto, favor entrar em contato com a Gerência."
        );
        val_check.value = "";
        return;
      }
      this.volsBipados.push(inputData);
      this.indexVols++;
      let next_item = item + 1;
      if (document.querySelector(".volume-" + next_item)) {
        // let self = this;
        // this.$nextTick(function () {
        //   self.$refs["volume-" + next_item].focus();
        // });
        const className = "volume-" + next_item;
        const nextInput = document.getElementsByClassName(className)[0];
        nextInput.focus();
      }
      const inputVols = document.querySelectorAll("input.volume");
      const emptyInputs = Array.from(inputVols).filter(
        (input) => input.value === ""
      );
      if (emptyInputs.length === 0) {
        this.bipar_volumes = 0;
      }
    },

    salvar(id) {
      if (confirm("Confirma o envio da nota fiscal?")) {
        let nota = document.querySelector(".nota").value;

        if (!nota) {
          alert("Favor enviar a nota fiscal!");
        } else {
          this.save_ped = {
            numped: id,
            nota: nota,
          };

          this.pedidoRepository
            .notaFiscal(this.save_ped)
            .then((res) => {
              console.log(res.data);
              if (res.data.data == "ok") {
                alert("Pedido foi salvo com sucesso!");
                window.location.href = "/pedidos-agil/aguardando-embarque";
              } else {
                alert(
                  "Nota Fiscal com nr. diferente. Favor entrar em contato com a gerência!"
                );
              }
            })
            .catch(function (e) {
              alert(e);
            });
        }
      }
    },
    formatDate(data) {
      let out = "";
      if (data) {
        out = data.substr(0, 10).split("-").reverse().join("/");
        out = out + " " + data.substr(10, 10);
      }
      return out;
    },
    status(label) {
      let out = "";

      switch (label) {
        case "A":
          out = "AGUARDANDO PRODUÇÃO";
          break;
        case "L":
          out = "EM LINHA DE PRODUÇÃO";
          break;
        case "N":
          out = "AGUARDANDO CONFERÊNCIA";
          break;
        case "F":
          out = "EM CONFERÊNCIA";
          break;
        case "T":
          out = "CONFERÊNCIA COM PROBLEMA";
          break;
        case "E":
          out = "AGUARDANDO EMBARQUE";
          break;
        case "D":
          out = "DESPACHADO";
          break;
        case "C":
          out = "CANCELADO";
          break;
        default:
          out = "";
      }

      return out;
    },
  },
};
</script>

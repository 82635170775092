<template>
  <div class="content">
    <div class="box-content">
      <div class="pedido-det">
        <div v-if="insumosForamCarregados">
          <cabecalho-conferencia-ac
            :numeroPedido="pedido.numeroPedido"
            :separadorLinhaTres="pedido.separadorLinhaTres"
            :transportadora="pedido.transportadoraNome"
            :data-producao="pedido.dataProducao | dateDMY"
            :linha-status="pedido.linhaStatus"
            :quantidade-caixas="quantidadeVolumes"
            :insumos="insumosCabecalho"
          />
          <div class="px-3">
            <b-row align-h="around">
              <default-button-caixa
                v-if="volumesConferidos.length"
                :nameButton="'Detalhar Caixas'"
                class="text-center my-4"
                @click="detalharCaixas"
              />
              <default-button-caixa
                v-if="mostraBtnFinalizarLinha"
                :nameButton="'Finalizar conferência'"
                class="text-center my-4"
                @click="finalizarLinha"
                :loading="btnFinalizarLinhaLoading"
              />
            </b-row>

            <b-card v-if="!mostraBtnFinalizarLinha">
              <b-row>
                <b-col>
                  <div
                    class="mt-2 font-weight-bold"
                    style="
                      align-items: center;
                      font-family: 'Poppins';
                      font-size: 20px;
                    "
                  >
                    Bipar Volume
                  </div>
                  <div class="mt-2">
                    <input
                      type="text"
                      class="input-bipagem-codigo-volume"
                      v-model="codigoVolumeBipado"
                      @keyup.enter="biparCaixa"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-card>
            <listagem-caixas-conferencia-ac
              :volumes="volumeEmBipagem"
              :btn-fechar-caixa-loading="btnFecharCaixaLoading"
              @bipar="biparInsumo($event)"
              @volumes-update="volumes = $event"
              @reportar-problema="reportarProblema($event)"
              @reportar-correcao="reportarCorrecaoErro($event)"
            />
          </div>
        </div>
        <div v-else>
          <alerta-de-espera />
        </div>
      </div>
    </div>
    <modal-detalhes-de-caixas-conferencia-ac
      :volumes="volumesConferidos"
      :quantidade-volumes="quantidadeVolumes"
    />
    <modal-reportar-problema-conferencia-ac
      :insumo-id="problemaInsumo.insumoId"
      :volume-id="problemaInsumo.volumeId"
      @atualizar-reportar-problema="atualizarEstadoReportarProblema($event)"
    />
  </div>
</template>

<script>
import InsumoPedidoRepository from "../../../../repositories/v2/InsumoPedidoRepository";
import PedidoWebRepository from "../../../../repositories/v2/PedidoWebRepository";
import DefaultButtonCaixa from "../components/DefaultButtonCaixa.vue";
import AlertaDeEspera from "../../../../components/AlertaDeEspera";
// import router from "../../../../router";
import CabecalhoConferenciaAc from "./ConferenciaAcComponents/CabecalhoConferenciaAc";
import ListagemCaixasConferenciaAc from "./ConferenciaAcComponents/ListagemCaixasConferenciaAc";
import ModalDetalhesDeCaixasConferenciaAc from "./ConferenciaAcComponents/ModalDetalhesDeCaixasConferenciaAc";
// import CabecalhoLinhaDiversos from "./PedidoWebProducaoLinhaDiversosComponents/CabecalhoLinhaDiversos";
// import ListagemCaixasLinhaDiversos from "./PedidoWebProducaoLinhaDiversosComponents/ListagemCaixasLinhaDiversos";
// import ModalDetalhesDeCaixasLinhaDiversos from "./PedidoWebProducaoLinhaDiversosComponents/ModalDetalhesDeCaixasLinhaDiversos";
import env from "../../../../env";
import Swal from "sweetalert2";
import ConfereACRepository from "../../../../repositories/v2/ConfereACRepository";
import ModalReportarProblemaConferenciaAc from "./ConferenciaAcComponents/ModalReportarProblemaConferenciaAc";
// import {UsuarioLogado} from "../../../../services/login/UsuarioLogado";

export default {
  components: {
    ModalReportarProblemaConferenciaAc,
    AlertaDeEspera,
    CabecalhoConferenciaAc,
    ListagemCaixasConferenciaAc,
    ModalDetalhesDeCaixasConferenciaAc,
    DefaultButtonCaixa,
  },
  data: () => ({
    codigoVolumeBipado: "",
    insumos: [],
    volumes: [],
    dataModalMoverInsumo: {},
    pedido: {
      clienteNome: "",
      dataProducao: "",
      kitDescricao: "",
      numeroPedido: 0,
      transportadoraCodigo: 0,
      transportadoraNome: "",
      linhaStatus: "",
      separadorLinhaTres: "",
    },
    repositories: {
      insumoPedidoRepository: InsumoPedidoRepository.build(),
      pedidoWebV2Repository: PedidoWebRepository.build(),
      confereACRepository: ConfereACRepository.build(),
    },
    quantidadeVolumes: 0,
    btnFecharCaixaLoading: false,
    btnFinalizarLinhaLoading: false,
    loadingTela: false,
    problemaInsumo: {
      insumoId: 0,
      volumeId: 0,
    },
  }),
  async mounted() {
    this.listarInsumos();
  },
  watch: {
    volumes: {
      handler(volumes) {
        for (const volume of volumes) {
          for (const insumo of volume.insumos) {
            if (insumo.quantidade < 0) {
              insumo.quantidade = 0;
            }
            const insumoCabecalho =
              this.insumos.find(
                (i) => i.insumoId === (insumo.insumo || {}).insumoId
              ) || {};
            const quantidadeRestante = insumoCabecalho.quantidadeRestante || 0;
            if (
              insumo.quantidade > quantidadeRestante &&
              volume.volumeId === 0
            ) {
              insumo.quantidade = quantidadeRestante;
            }
            insumo.quantidade = +insumo.quantidade;
          }
        }
      },
      deep: true,
    },
  },
  computed: {
    volumesConferidos() {
      return this.volumes.filter(({ insumos }) =>
        insumos.every(({ conferido }) => conferido)
      );
    },
    volumeEmBipagem() {
      return this.volumes.filter((v) => v.codigo === this.codigoVolumeBipado);
    },
    insumosForamCarregados() {
      return !this.loadingTela;
    },
    insumosCabecalhoNaoBipaveis() {
      return this.insumos.filter((i) => !i.precisaSerBipado);
    },
    insumosCabecalho() {
      return [
        ...this.insumosCabecalhoNaoBipaveis,
        ...this.insumosCabecalhoBipaveis,
      ];
    },
    insumosCabecalhoBipaveis() {
      return this.insumos
        .filter((i) => i.precisaSerBipado)
        .map((insumo, i, arr) => {
          const insumoMapped = { ...insumo };
          insumoMapped.quantidadeRestante = arr
            .filter(
              (i) =>
                i.winthorCodigoProduto === insumo.winthorCodigoProduto &&
                !i.bipado
            )
            .reduce((acc, rInsumo) => acc + rInsumo.quantidadeRestante, 0);
          insumoMapped.quantidade = arr.filter(
            (i) => i.winthorCodigoProduto === insumo.winthorCodigoProduto
          ).length;
          return insumoMapped;
        })
        .filter((insumo, index, arr) => {
          return (
            index ===
            arr.findIndex(
              (i) => i.winthorCodigoProduto === insumo.winthorCodigoProduto
            )
          );
        });
    },
    volumeCoringa() {
      return this.volumes.find((v) => !v.volumeId) || {};
    },
    mostraBtnFinalizarLinha() {
      return this.volumes.every(({ insumos }) =>
        insumos.every(({ conferido }) => conferido)
      );
    },
    mostraBtnFecharCaixa() {
      return (
        !this.mostraBtnFinalizarLinha &&
        this.existeVolumeCoringa &&
        this.pedido.linhaStatus !== "FINALIZADO"
      );
    },
    existeVolumeCoringa() {
      return this.volumeCoringa.volumeId !== undefined;
    },
  },
  methods: {
    async atualizarEstadoReportarProblema({ volumeId, insumoId, motivoErro }) {
      for (let j = 0; j < this.volumes.length; j++) {
        for (let k = 0; k < this.volumes[j].insumos.length; k++) {
          if (
            this.volumes[j].insumos[k].insumo.insumoId === insumoId &&
            this.volumes[j].volumeId === volumeId
          ) {
            this.volumes[j].insumos[k].erroMotivo = motivoErro;
          }
        }
      }
    },
    async reportarProblema({ insumoId, volumeId }) {
      this.problemaInsumo = { insumoId, volumeId };
      this.$bvModal.show("modal-reportar-problema");
    },
    async reportarCorrecaoErro({ volumeId, insumoId }) {
      this.repositories.confereACRepository
        .reportarCorrecao({ volumeId, insumoId })
        .then((response) => {
          if (response.status !== 200) {
            for (let j = 0; j < this.volumes.length; j++) {
              for (let k = 0; k < this.volumes[j].insumos.length; k++) {
                if (
                  this.volumes[j].insumos[k].insumo.insumoId === insumoId &&
                  this.volumes[j].volumeId === volumeId
                ) {
                  this.volumes[j].insumos[k].erroCorrigido = false;
                }
              }
            }
          }
        });
      for (let j = 0; j < this.volumes.length; j++) {
        for (let k = 0; k < this.volumes[j].insumos.length; k++) {
          if (
            this.volumes[j].insumos[k].insumo.insumoId === insumoId &&
            this.volumes[j].volumeId === volumeId
          ) {
            this.volumes[j].insumos[k].erroCorrigido = true;
          }
        }
      }
    },
    async biparInsumo({ insumoId, volumeId, serial }) {
      const nextInsumoBipavel = (() => {
        let nextIndex = -1;
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumoId) {
            nextIndex = i === this.insumos.length - 1 ? -1 : i + 1;
            break;
          }
        }
        return nextIndex >= 0 ? this.insumos[nextIndex].insumoId : 0;
      })();

      this.repositories.confereACRepository
        .biparInsumoConfereAc({ insumoId, volumeId, serial })
        .then(({ status }) => {
          if (status !== 200) {
            for (let j = 0; j < this.volumes.length; j++) {
              for (let k = 0; k < this.volumes[j].insumos.length; k++) {
                if (
                  this.volumes[j].insumos[k].insumo.insumoId === insumoId &&
                  this.volumes[j].volumeId === volumeId
                ) {
                  this.volumes[j].insumos[k].conferido = false;
                  this.volumes[j].insumos[k].bipado = false;
                }
              }
            }
          }
        });
      for (let j = 0; j < this.volumes.length; j++) {
        for (let k = 0; k < this.volumes[j].insumos.length; k++) {
          if (
            this.volumes[j].insumos[k].insumo.insumoId === insumoId &&
            this.volumes[j].volumeId === volumeId
          ) {
            this.volumes[j].insumos[k].conferido = true;
            this.volumes[j].insumos[k].bipado = true;
            console.log(nextInsumoBipavel);
            document.querySelector(".insumo-" + nextInsumoBipavel).focus();
          }
        }
      }
      const caixaFoiTotalmenteConferida = this.volumeEmBipagem.every(
        ({ insumos }) => insumos.every(({ conferido }) => conferido)
      );
      if (caixaFoiTotalmenteConferida) {
        alert(
          "Essa caixa foi totalmente conferida. Bipe o código da outra caixa."
        );
        this.codigoVolumeBipado = "";
        document.querySelector(".input-bipagem-codigo-volume").focus();
      }
    },
    biparCaixa() {
      console.log(this.codigoVolumeBipado);
    },
    async listarInsumos() {
      this.loadingTela = true;
      const numeroPedido = this.$route.params.id;
      const response =
        await this.repositories.confereACRepository.listaVolumesConferenciaAc({
          numeroPedido,
        });
      const { data } = response.data;
      this.loadingTela = false;
      if (response.status !== 200) {
        await Swal.fire({
          icon: "error",
          text: response.data.message + " Entre em contato com o Suporte.",
        });
        await this.$router.replace({ name: "pedidosWebConferenciaAc" });
      }
      this.renderizarLista(data);
      this.loadingTela = false;
    },
    renderizarLista(data) {
      this.insumos = data.insumos.map((i, index) => {
        const {
          insumoDescricao,
          insumoId,
          quantidade,
          quantidadeRestante,
          sspedidoNumSeq,
          winthorCodigoProduto,
          precisaSerBipado,
          serial,
        } = i;
        return {
          descricao: insumoDescricao,
          insumoId,
          quantidade,
          quantidadeRestante,
          sspedidoNumSeq,
          winthorCodigoProduto,
          precisaSerBipado,
          index: index + 1,
          serial,
        };
      });
      this.volumes = data.volumes.map((v) => {
        return {
          codigo: v.codigo,
          insumos: v.insumos.map((i) => {
            return {
              insumo: this.insumos.find(
                ({ insumoId }) => insumoId === i.insumoId
              ),
              quantidade: i.quantidade,
              conferido: i.conferido,
              erroMotivo: i.erroMotivo,
              erroCorrigido: i.erroCorrigido,
            };
          }),
          volumeId: v.volumeId,
        };
      });

      this.volumes = this.volumes.map((v) => ({
        ...v,
        insumosBipaveis: v.insumos.filter((i) => i.insumo.precisaSerBipado),
        insumosNaoBipaveis: v.insumos.filter((i) => !i.insumo.precisaSerBipado),
      }));

      this.pedido = {
        clienteNome: data.clienteNome,
        dataProducao: data.dataProducao,
        kitDescricao: data.kitDescricao,
        numeroPedido: data.numeroPedido,
        transportadoraCodigo: data.transportadoraCodigo,
        transportadoraNome: data.transportadoraNome,
        linhaStatus: (data.linhaProducao3Status || "").toUpperCase(),
        separadorLinhaTres: data.separadorLinhaTres,
      };
      this.quantidadeVolumes = this.volumes.length;
      this.loadingTela = false;
    },
    async fecharCaixa() {
      const sspedidoNumSeqListNaoBipados = this.volumeCoringa.insumosNaoBipaveis
        .map((i) => ({
          sspedidoNumSeq: i.insumo.sspedidoNumSeq,
          quantidade: i.quantidade,
        }))
        .filter((i) => i.quantidade);
      const sspedidoNumSeqListBipados = this.volumeCoringa.insumosBipaveis
        .filter(({ insumo }) => insumo.bipado)
        .map(({ insumo }) => ({
          quantidade: 1,
          sspedidoNumSeq: insumo.sspedidoNumSeq,
        }));
      const sspedidoNumSeqList = [
        ...sspedidoNumSeqListNaoBipados,
        ...sspedidoNumSeqListBipados,
      ];
      this.btnFecharCaixaLoading = true;
      try {
        const body = { sspedidoNumSeqList };
        const response =
          await this.repositories.pedidoWebV2Repository.criarVolumeDiversos(
            this.pedido.numeroPedido,
            body
          );
        if (response.status === 200) {
          let volumes = response.data.data.volumes;
          let volumesSemZero = volumes.filter((v) => Math.max(v.volumeId));
          let ultimoVolume = volumesSemZero[volumesSemZero.length - 1];
          let newUrl = `${env.baseUrl}/volume/${ultimoVolume.codigo}/pdf`;
          window.open(newUrl);
          this.renderizarLista(response.data.data);
        } else {
          alert(
            response.message ||
              "Ocorreu um erro inesperado. Entre em contato com suporte."
          );
        }
      } catch (e) {
        alert("Ocorreu um erro inesperado. Entre em contato com o suporte.");
      }
      this.btnFecharCaixaLoading = false;
    },
    async finalizarLinha() {
      alert("Linha de diversos foi totalmente conferida com sucesso.");
      await this.$router.replace({ name: "pedidosWebConferenciaAc" });
    },
    detalharCaixas() {
      this.$bvModal.show("modal-detalhes-volume");
    },
  },
};
</script>

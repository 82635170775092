import { HttpRestService } from "@/services/http/HttpRestService";
import swal from "sweetalert2";

export default class UploadPedido {
  arquivo = [];
  vm;
  loading = false;
  static build() {
    return new UploadPedido();
  }

  setArquivoPedido(arquivo) {
    this.arquivo = arquivo;
    return this;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async enviarArquivo() {
    var formData = new FormData();
    formData.append("arquivo", this.arquivo);
    HttpRestService.postFile("/api/register-serie-upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.statusCode >= 200 && response.statusCode < 300) {
          swal.fire(response.message, "", "success");
        } else if (response.statusCode === 400) {
          swal.fire(response.message, "", "error");
        }
      })
      .catch(function (error) {
        swal.fire("Não foi possível cadastrar os dados!", "", "error");
        console.error(error);
      });
  }
}

import MesasRepository from "@/repositories/v2/MesasRepository";

export class MesasService {
  vm;
  mesasRepository = MesasRepository.build();

  constructor(mesasRepository) {
    this.mesasRepository = mesasRepository;
  }

  static build() {
    const mesasRepository = MesasRepository.build();
    return new MesasService(mesasRepository);
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async separarMesa() {
    const body = this.getBody();

    this.vm.isLoading = true;

    try {
      await this.mesasRepository.separarMesa(body).then((response) => {
        const message = response.data.message;
        this.vm.modalAlert = {
          message:
            response.status === 504 ? "Erro na geração de etiquetas" : message,
          variant: response.status === 200 ? "success" : "danger",
        };
        this.vm.$bvModal.show("modal-mesas");
        // if (response.status === 200) {

        //   this.vm.$bvModal.show("modal-mesas");
        // } else {
        //   const message = response.data.message;
        //   this.vm.modalAlert = {
        //     message: message,
        //     variant: "danger",
        //   };
        //   this.vm.$bvModal.show("modal-mesas");
        // }
      });
    } catch (e) {
      console.error(e);
    }
    this.vm.isLoading = false;
  }

  getBody() {
    const data = this.vm;
    return {
      numeroPedido: data.pedidoId,
      insumoId: data.insumoId,
    };
  }
}

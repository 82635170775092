import { HttpRestService } from "../services/http/HttpRestService";

export default {
  methods: {
    async HTTP_REQUEST_MIXIN_get(url) {
      return HttpRestService.get(url);
    },

    async HTTP_REQUEST_MIXIN_put(url, data) {
      return HttpRestService.put(url, data);
    },

    async HTTP_REQUEST_MIXIN_post(url, data) {
      return HttpRestService.post(url, data);
    },

    async HTTP_REQUEST_MIXIN_delete(url) {
      return HttpRestService.delete(url);
    },
  },
};

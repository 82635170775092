import Repository from "../repository";

export default {
  async sortingBays({ commit, state, dispatch }) {
    commit("setIsLoading", true);
    commit("setIsRegisterBayButtonDisabled", true);
    commit("setSortingBays", []);
    try {
      const response = await Repository.getBays(state.urlParams);
      if (response.status === 200) {
        const sortingBays = response.data.data.list;
        const pagination = response.data.data.pagination;
        commit("setSortingBays", sortingBays);
        commit("setPagination", pagination);
        dispatch("setIsRegisterBayButtonDisabled");
        return;
      } else if (response.status === 400) {
        dispatch("setIsRegisterBayButtonDisabled");
        const errorObj = {
          errorStatus: true,
          errorMessage: "Não há baias cadastradas",
        };
        commit("notifications/setErrorNotification", errorObj, { root: true });
        return;
      }
      const errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na listagem",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    } catch (error) {
      console.log(error);
    } finally {
      commit("setIsLoading", false);
    }
  },
  async editBay({ commit, dispatch }, bayToBeEdited) {
    try {
      const response = await Repository.editBay(bayToBeEdited);
      if (response.status === 200) {
        const successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        commit("setIsEditBayModalOpen", false);
        dispatch("sortingBays");
        return;
      }
      const errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    } catch (error) {
      console.log(error);
    }
  },
  async registerBay({ commit, dispatch }, bayToBeRegistered) {
    try {
      const response = await Repository.registerBay(bayToBeRegistered);
      if (response.status === 200) {
        const successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        commit("setIsRegisterBayModalOpen", false);
        dispatch("sortingBays");
        return;
      }
      const errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    } catch (error) {
      console.log(error);
    }
  },
  setIsRegisterBayButtonDisabled({ state, commit }) {
    const currentNumberOfBays = state.sortingBays.length;
    const maxNumberOfBays = state.maxNumberOfBays;
    const reachedLimitOfBays = currentNumberOfBays >= maxNumberOfBays;
    commit("setIsRegisterBayButtonDisabled", reachedLimitOfBays);
  },
  setIsEditBayModalOpen({ commit }, payload) {
    commit("setIsEditBayModalOpen", payload);
  },
  setIsRegisterBayModalOpen({ commit }, payload) {
    commit("setIsRegisterBayModalOpen", payload);
  },
};

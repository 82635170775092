import PainelBase from "./PainelBase";

export default class PainelUlicaMonoHalfCut450W extends PainelBase {
  getCode = () => 8915;
  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 8915 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 8915 and numerosseriecontrolados is not null)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') percent from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 8915 and substr(s.numerosseriecontrolados, 1, 6) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 8915 and substr(s.numerosseriecontrolados, 1, 6) = prefix and s.NUMEROSSERIECONTROLADOS is not null) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 6) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 8915
                               and numerosseriecontrolados is not null
                           )
                      order by q_insumos desc
                    ) r;
                Resultado:
                    +------+---------+---------+-------+
                    |PREFIX|Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +------+---------+---------+-------+
                    |U624MM|26789    |1622     |96%    |
                    |u624mm|1084     |2        |4%     |
                    |160060|22       |1        |0%     |
                    |624MM2|22       |10       |0%     |
                    |24MM21|13       |6        |0%     |
                    |4MM21H|5        |3        |0%     |
                    |4MM21G|4        |4        |0%     |
                    |"624MM|3        |2        |0%     |
                    |U622MM|2        |2        |0%     |
                    |MM21H5|2        |2        |0%     |
                    |670005|2        |2        |0%     |
                    |MM21G5|2        |2        |0%     |
                    |BRSOE0|2        |2        |0%     |
                    |801210|2        |1        |0%     |
                    |012107|2        |1        |0%     |
                    |301631|2        |1        |0%     |
                    |760005|2        |1        |0%     |
                    |250027|2        |1        |0%     |
                    |%U624M|2        |2        |0%     |
                    |U62MM\|1        |1        |0%     |
                    |U66U62|1        |1        |0%     |
                    |+U624M|1        |1        |0%     |
                    |627Me2|1        |1        |0%     |
                    |U6F%MM|1        |1        |0%     |
                    |;U624M|1        |1        |0%     |
                    |M21H5A|1        |1        |0%     |
                    |U65U62|1        |1        |0%     |
                    |U62nMM|1        |1        |0%     |
                    |U%E847|1        |1        |0%     |
                    |U624MA|1        |1        |0%     |
                    |700004|1        |1        |0%     |
                    |MM21H2|1        |1        |0%     |
                    |U62U62|1        |1        |0%     |
                    |U624M2|1        |1        |0%     |
                    |660004|1        |1        |0%     |
                    |800643|1        |1        |0%     |
                    |680002|1        |1        |0%     |
                    +------+---------+---------+-------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 6 dígitos do insumo em questão, mostrando
                    99 resultados. Perceba que a inicial com relevância é U624MM.
                    Portanto, vamos considerar que esse painel sempre comece com essa sequência.
            Tipagem:
                Como a própria inicial já é composta por números e letras, a tipagem é alfanumérica.
            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 8915 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 8915 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 8915
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |16    |27835    |1622     |
                    |17    |41       |13       |
                    |15    |27       |17       |
                    |18    |19       |11       |
                    |19    |14       |10       |
                    |14    |13       |7        |
                    |8     |8        |3        |
                    |13    |7        |7        |
                    |11    |5        |4        |
                    |12    |5        |5        |
                    |22    |2        |2        |
                    |10    |2        |1        |
                    |20    |2        |2        |
                    |7     |1        |1        |
                    |21    |1        |1        |
                    |NULL  |0        |0        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com maior relevância é 16 caracteres.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais U624MM mais 10 dígitos alfanuméricos, totalizando 16 caracteres.
         */
    return !!this.serial.match(/^U624MM(\d|\w){10}$/);
  }

  static build() {
    return new PainelUlicaMonoHalfCut450W();
  }
}

<template>
  <v-dialog max-width="330" v-model="dialogVisibleTotal">
    <v-card>
      <v-card-title class="justify-center">
        <h3 class="mb-6 navy--text">Atenção</h3>
      </v-card-title>
      <v-card-text>
        <v-card-text class="font-weight-bold text-center">
          Confirmar a zeragem Total?
        </v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogVisibleTotal = false">
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="zerarTotal"
          :loading="getIsLoadingZerarLinha"
        >
          Zerar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ZerarTotal",
  data() {
    return {
      loading: false,
      dialogVisibleTotal: this.$props.enableTotal,
    };
  },
  methods: {
    async zerarTotal() {
      this.$emit("zerar-total");
    },
  },
  props: {
    enableTotal: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters("gerenciarPedido", ["getIsLoadingZerarLinha"]),
  },
  watch: {
    enableTotal(enable) {
      this.dialogVisibleTotal = enable;
    },
  },
};
</script>

<style></style>

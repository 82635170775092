export default class ValidacaoPainel {
  static build() {
    return new ValidacaoPainel();
  }

  setStrategy(strategy) {
    this.strategy = strategy;
    return this;
  }

  setSerial(serial) {
    this.serial = serial;
    return this;
  }

  validate() {
    return true; //this.strategy.setSerial(this.serial).isValid()
  }
}

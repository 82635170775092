import router from "../../router";
import PedidoRepository from "@/repositories/v1/PedidoRepository";
import ConfereACRepository from "@/repositories/v2/ConfereACRepository";

export const zerarLinha = async (numped) => {
  const linha = document.getElementById("linhaz").value;
  const ac = linha > 0 ? linha : "ac";

  if (linha > 0) {
    confirm("Confirma Zerar Linha " + linha + "?");
    const save_ped = {
      numped: numped,
      linha: linha,
    };
    const pedidoRepository = PedidoRepository.build();
    pedidoRepository
      .pedidoLimpaLinha(save_ped)
      .then(async () => {
        alert("Linha foi zerada com sucesso!");
        await router.replace({ name: "pedidosWebLinhaProducaoExpress" });
      })
      .catch(async () => {
        await router.replace({ path: "/dashboard" });
      });
  } else {
    confirm("Confirma Zerar Linha " + ac + "?");
    const confereACRepository = ConfereACRepository.build();
    confereACRepository
      .zerarConferencia(numped)
      .then(async (response) => {
        alert(response.data.message);
        await router.replace({ name: "pedidosWebLinhaProducao" });
      })
      .catch(async () => {
        await router.replace({ path: "/dashboard" });
      });
  }
};

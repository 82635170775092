import { HttpRestService } from "../../../services/http/HttpRestService";

export class ConfiguracaoDeInsumosRepository {
  static getListInsumos(urlParams, insumosType) {
    const urlInsumos = "/api/v2/insumo/configuracoes";
    const urlInsumoNaoConfigurados = "/api/v2/insumo/nao-configurados";
    const url = insumosType ? urlInsumoNaoConfigurados : urlInsumos;
    return HttpRestService.get(url, urlParams);
  }

  static editInsumo(body, insumosType) {
    const urlInsumos = `/api/v2/insumo/editar/configuracao`;
    const urlInsumoNaoConfigurados = "/api/v2/insumo/criar/configuracao";
    const url = insumosType ? urlInsumoNaoConfigurados : urlInsumos;
    if (insumosType) {
      return HttpRestService.post(url, body);
    } else {
      return HttpRestService.put(url, body);
    }
  }
}

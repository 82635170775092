import { render, staticRenderFns } from "./DefaultButtonCaixa.vue?vue&type=template&id=570d15d1&scoped=true&"
import script from "./DefaultButtonCaixa.vue?vue&type=script&lang=js&"
export * from "./DefaultButtonCaixa.vue?vue&type=script&lang=js&"
import style0 from "./DefaultButtonCaixa.vue?vue&type=style&index=0&id=570d15d1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "570d15d1",
  null
  
)

export default component.exports
import router from "@/router";

export class ErrorNotifier {
  static run(commit, dispatch, response) {
    let errorMessage = response?.data?.message || "";
    switch (response?.status) {
      case 500:
        this.showInternalErrorNotification(commit);
        break;
      case 404:
        errorMessage = errorMessage || "Recurso não encontrado.";
        this.showErrorNotification(commit, errorMessage);
        break;
      case 401:
        errorMessage = errorMessage || "Acesso não autorizado.";
        this.showErrorNotification(commit, errorMessage);
        break;
      case 400:
        errorMessage = errorMessage || "Registro não encontrado.";
        this.showErrorNotification(commit, errorMessage);
        break;
      case 403:
        errorMessage = errorMessage || "Acesso proibido.";
        this.showErrorNotification(commit, errorMessage);
        break;
      case 405:
        errorMessage = errorMessage || "Método não permitido.";
        this.showErrorNotification(commit, errorMessage);
        break;
      case 503:
        errorMessage = errorMessage || "Serviço indisponível.";
        this.showErrorNotification(commit, errorMessage);
        break;
      case 504:
        errorMessage = errorMessage || "Tempo de gateway esgotado.";
        this.showErrorNotification(commit, errorMessage);
        break;
      case "O token é inválido.":
        this.showInvalidToken(commit, dispatch);
        break;
      default:
        this.showDefaultErrorNotification(commit);
    }
  }

  static showErrorNotification(commit, errorMessage) {
    let errorObj = {
      errorStatus: true,
      errorMessage: errorMessage,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  }

  static showDefaultErrorNotification(commit) {
    let errorMessage = "Ocorreu um erro na requisição.";
    this.showErrorNotification(commit, errorMessage);
  }

  static showInternalErrorNotification(commit) {
    let errorMessage = "Ocorreu um erro interno no servidor.";
    this.showErrorNotification(commit, errorMessage);
  }

  static showInvalidToken(commit, dispatch) {
    let errorMessage = "Token de acesso inválido.";
    this.showErrorNotification(commit, errorMessage);
    if (router.currentRoute.path !== "/") {
      dispatch("login/logout", null, { root: true });
    }
  }
}

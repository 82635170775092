<template>
  <div class="content">
    <div class="box-content">
      <div>
        <listing-header
          title="Aguardando Produção"
          :relatorioBtn="pedidosStatus === 'A'"
          :etiquetaBtn="
            pedidosStatus === 'L' ||
            pedidosStatus === 'N' ||
            pedidosStatus === 'F'
          "
          :uploadBtn="pedidosStatus === 'A' || pedidosStatus === 'L'"
          @filter="filter"
        />
        <div class="list-component">
          <div :class="['list-header', `listing-component-${listColor}`]">
            <b-row no-gutters>
              <b-col
                lg="2"
                xl="2"
                class="list-titles mx-auto my-auto d-flex justify-content-center d-lg-none d-xl-none"
              >
                <li><b>Listagem de Pedidos</b></li>
              </b-col>
              <b-col
                lg="2"
                xl="2"
                class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
              >
                <li><b>Pedido</b></li>
              </b-col>
              <b-col
                lg="2"
                xl="2"
                class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
              >
                <li><b>Dt. Produção</b></li>
              </b-col>
              <b-col
                lg="2"
                xl="2"
                class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
              >
                <li><b>Cliente</b></li>
              </b-col>
              <b-col
                lg="2"
                xl="2"
                class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block text-truncate"
              >
                <li><b>Transportadora</b></li>
              </b-col>
              <b-col
                lg="2"
                xl="2"
                class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
              >
                <li><b>Kit</b></li>
              </b-col>
              <b-col
                lg="2"
                xl="2"
                class="list-titles mx-auto my-auto d-none d-sm-none d-md-none d-lg-block d-xl-block"
              >
                <li></li>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-if="!listagemPedidosServiceExpress.loading">
          <div v-for="(pedido, index) in pedidos" :key="index" class="my-3">
            <listing-card
              @openModalIniciarProducao="openModalIniciarProducao"
              @open-details="modalDetalhes = $event"
              :pedido="pedido"
            />
          </div>
        </div>
        <div v-else class="pad mt-5" style="text-align: center !important">
          <b-spinner style="width: 10rem; height: 10rem" />
        </div>
        <div class="pagination">
          <sliding-pagination
            v-if="pagination.lastPage && pedidos.length"
            :current="pagination.currentPage"
            :total="pagination.lastPage"
            @page-change="paginate"
          />
        </div>
      </div>
    </div>
    <modal-detalhes-pedido
      v-if="modalDetalhes.relacaoTiposInsumoQuantidade"
      :pedido="modalDetalhes.pedido"
      :relacao-tipos-insumo-quantidade="
        modalDetalhes.relacaoTiposInsumoQuantidade
      "
    />
    <modal-iniciar-producao
      :separadores="separadores"
      :localizacoesEstoque="localizacoesEstoque"
      :numeroPedido="numeroPedidoModal"
    />

    <modal-etiquetas relacao-tipos-insumo-quantidade="" pedido="" />
    <modal-total-etiquetas />
    <modal-upload />
  </div>
</template>

<script>
import { ParametrosIniciarProducaoPedido } from "../../../services/iniciar_producao/ParametrosIniciarProducaoPedido";
import ListagemPedidosServicesExpress from "../../../services/listagem_pedidos/ListagemPedidosServiceExpress";
import SlidingPagination from "vue-sliding-pagination";
import ListingHeader from "./ListingHeader";
import ModalDetalhesPedido from "./ModalDetalhesPedido";
import ModalIniciarProducao from "./ModalIniciarProducao.vue";
import ModalEtiquetas from "./ModalEtiquetas";
import ListingCard from "./ListingCard";
import ModalTotalEtiquetas from "./ModalTotalEtiquetas";
import ModalUpload from "./ModalUpload";

export default {
  components: {
    ModalDetalhesPedido,
    ModalEtiquetas,
    ModalTotalEtiquetas,
    ModalUpload,
    ListingHeader,
    ListingCard,
    SlidingPagination,
    ModalIniciarProducao,
  },
  props: {
    // },
  },
  data: () => ({
    page: 1,
    pedidos: [],
    separadores: [],
    localizacoesEstoque: [],
    numeroPedidoModal: 0,
    pagination: {
      total: 0,
      perPage: 0,
      currentPage: 1,
      lastPage: 0,
      previousPage: 0,
      nextPage: 0,
    },
    filtro: { dataInicio: "", dataFinal: "" },
    parametrosIniciarProducaoPedido: ParametrosIniciarProducaoPedido.build(),
    listagemPedidosServiceExpress: ListagemPedidosServicesExpress.build(),
    modalDetalhes: {
      relacaoTiposInsumoQuantidade: [],
      pedido: {},
    },
    telaTemFiltros: false,
  }),
  beforeMount() {
    this.getDate();
  },
  created() {
    this.listagemPedidosServiceExpress =
      ListagemPedidosServicesExpress.build().setVm(this);
    this.parametrosIniciarProducaoPedido =
      ParametrosIniciarProducaoPedido.build().setVm(this);
  },
  async mounted() {
    await this.listagemPedidosServiceExpress
      .setStatus(this.pedidosStatus)
      .list(this.page, this.filtro);
    await this.parametrosIniciarProducaoPedido.run();
  },
  watch: {
    async pedidosStatus() {
      await this.listagemPedidosServiceExpress
        .setStatus(this.pedidosStatus)
        .list(this.page, this.filtro);
    },
  },
  methods: {
    getDate() {
      var dateObj = new Date();
      var month = dateObj.getUTCMonth() + 1;
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();

      this.filtro.dataInicio = year + "-" + month + "-" + day;
      this.filtro.dataFinal = year + "-" + month + "-" + day;
    },
    async paginate(page) {
      await this.listagemPedidosServiceExpress.list(page, this.filtro);
    },
    async filter(params) {
      this.filtro = params;
      await this.listagemPedidosServiceExpress.list(
        this.pagination.currentPage,
        this.filtro
      );
    },
    openModalIniciarProducao(numeroPedido) {
      this.numeroPedidoModal = numeroPedido;
      this.$bvModal.show("modal-inicar-producao");
    },
  },
  computed: {
    pedidosStatus() {
      return this.$attrs.status;
    },
    // isManutencao() {
    //   if (this.pedidosStatus === 'M') {
    //      return true;
    //  }
    //  else{
    //   return false;
    //  }
    // },
    listColor() {
      // if (this.pedidosStatus === 'A') {
      //   return 'blue-color';
      // }
      return "navy-color";
    },
  },
};
</script>

<style scoped>
.list-titles {
  list-style-type: none;
  border-right: 0.063rem solid rgba(223, 223, 223, 0.308);
  padding: 1.25rem;
}
li {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1rem;
}
.list-component {
  background-color: #eef0f8;
  color: #ffffff;
  border-radius: 1rem;
}
.listing-component-navy-color {
  background: #120947;
}
.listing-component-blue-color {
  background: #3699ff;
}
.listing-component-green-color {
  background: #2eb03b;
}
.listing-component-red-color {
  background: #721212;
}
.listing-component-yellow-color {
  background: #dd9402;
}
.listing-component-orange-color {
  background: #c25904;
}
.listing-component-gray-color {
  background: #3f3f3f;
}
</style>

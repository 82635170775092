import PainelBase from "./PainelBase";

export default class PainelJinko280W extends PainelBase {
  getCode = () => 68;

  isValid() {
    /*  ESTUDO DE PADRÃO DE PAINEL 2468 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            +------------------------+
            |NUMEROSSERIECONTROLADOS |
            +------------------------+
            |154201171024110185420020|
            |154201171024110185420870|
            +------------------------+
            Como pode-se observar dos dados do sistema, só existem 2 insumos em um único pedido
            desse painel. Portanto, o Winthor não possui dados suficientes para escrever uma regra de validação.
            Por isso, quando esse serial for bipado novamente, o sistema deixará passar sem regras.
         */
    return true;
  }

  static build() {
    return new PainelJinko280W();
  }
}

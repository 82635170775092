import PainelBase from "./PainelBase";

export default class PainelLeapton210Mono66MH665W extends PainelBase {
  getCode = () => 31606;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 31606 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    with total as (select count(*) as c from sspedido where codprodmp = 31606)
                    select r.*, concat(round((r.q_insumos / (select c from total)) , 2) * 100, '%') from (
                      select
                             prefix,
                             (select count(*) from sspedido s where s.codprodmp = 31606 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_insumos,
                             (select count(distinct s.numped) from sspedido s where s.codprodmp = 31606 and substr(s.numerosseriecontrolados, 1, 4) = prefix) q_pedidos
                      from (
                               select distinct substr(numerosseriecontrolados, 1, 4) as prefix
                               from SSPEDIDO s
                               where s.codprodmp = 31606
                           )
                      order by q_insumos desc
                    ) r
                Resultado:
                    +---------+---------+---------+-------+
                    |PREFIX   |Q_INSUMOS|Q_PEDIDOS|PERCENT|
                    +---------+---------+---------+-------+
                    |166080121|505      |15       |100%   |
                    +---------+---------+---------+-------+

                Interpretação:
                    Essa consulta devolve todos os prefixos de 9 dígitos do insumo em questão, mostrando
                    6 resultados. Perceba que a única inicial que existe é a 166080121.
                    Portanto, vamos considerar que esse painel sempre comece com essa sequência.
            Tipagem:
                Foi verificado que os seriais desse painel são compostos apenas por números.
                Consulta:
                    select
                        count(*)
                    from sspedido s
                    where
                          s.codprodmp = 31606
                          and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null
            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 31606 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 31606 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 31606
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |17    |505      |15       |
                    |NULL  |0        |0        |
                    +------+---------+---------+

                Interpretação:
                    Perceba que a quantidade de seriais com 17 dígitos é moda nessa amostra. Para esse painel, não
                    ocorreram erros de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 166080121 mais 8 dígitos numéricos, totalizando 17 dígitos.
         */
    return !!this.serial.match(/^166080121\d{8}$/);
  }

  static build() {
    return new PainelLeapton210Mono66MH665W();
  }
}

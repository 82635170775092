import { HttpRestService } from "@/services/http/HttpRestService";

export default class RolesRepository {
  static build() {
    return new RolesRepository();
  }

  getRoles() {
    return HttpRestService.get(`/api/roles`);
  }

  getPermissions() {
    return HttpRestService.get(`/api/v2/perfil/listar`);
  }

  getRole(id) {
    return HttpRestService.get(`/api/roles/${id}`);
  }

  postRole(body) {
    return HttpRestService.post(`/api/roles`, body);
  }

  editRole(id, body) {
    return HttpRestService.put(`/api/roles/${id}`, body);
  }

  deleteRole(id) {
    return HttpRestService.delete(`/api/roles/${id}`);
  }
}

import PainelBase from "./PainelBase";

export default class PainelCanadianMono535W extends PainelBase {
  getCode = () => 19917;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 19917 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    select
                        prefix,
                        (select count(*) from sspedido s where s.codprodmp = 19917 and  substr(s.numerosseriecontrolados, 1, 4) = prefix) quantidade_insumos,
                        (select count(distinct s.numped) from sspedido s where s.codprodmp = 19917 and  substr(s.numerosseriecontrolados, 1, 4) = prefix) quantidade_pedidos
                    from (
                        select
                            distinct substr(numerosseriecontrolados, 1, 4) as prefix
                        from SSPEDIDO s
                        where s.codprodmp = 19917
                    ) order by quantidade_insumos desc
                Resultado:
                    +------+---------+---------+
                    |PREFIX|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |1210  |3465     |116      |
                    |2104  |53       |11       |
                    |0460  |39       |8        |
                    |1046  |22       |8        |
                    |4601  |10       |8        |
                    |6016  |5        |3        |
                    |0117  |1        |1        |
                    |2160  |1        |1        |
                    |0161  |1        |1        |
                    |1610  |1        |1        |
                    |0154  |1        |1        |
                    +------+---------+---------+
                Interpretação:
                    Essa consulta devolve todos os prefixos de 4 dígitos do insumo em questão, mostrando
                    11 resultados. Perceba que apenas a inicial 1210 possui resultados consideráveis. Os
                    outros muito provavelmente são erros de bipagem.
            Tipagem:
                Foi verificado que todos os seriais desse painel são numéricos. Consulta:
                    select
                        s.numerosseriecontrolados
                    from sspedido s
                    where
                        s.codprodmp = 19917
                        and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 19917 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 19917 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 19917
                    ) order by q_insumos desc
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |14    |3402     |116      |
                    |13    |37       |9        |
                    |15    |34       |8        |
                    |11    |32       |9        |
                    |17    |30       |8        |
                    |16    |19       |6        |
                    |12    |17       |9        |
                    |10    |8        |7        |
                    |19    |6        |4        |
                    |9     |3        |2        |
                    |18    |3        |3        |
                    |8     |2        |2        |
                    |7     |2        |1        |
                    |23    |2        |2        |
                    |21    |1        |1        |
                    |22    |1        |1        |
                    +------+---------+---------+
                Interpretação:
                    Perceba que a quantidade de seriais com 14 dígitos é moda nessa amostra. Para o outro caso, pro-
                    vavelmente foi um erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 1210 mais 10 dígitos numéricos, totalizando 14 dígitos.
         */
    return !!this.serial.match(/^1210\d{10}$/);
  }

  static build() {
    return new PainelCanadianMono535W();
  }
}

export class RecaptchaService {
  constructor(vm) {
    this.vm = vm;
  }

  static build(vm) {
    return new RecaptchaService(vm);
  }

  async applyScript() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://unpkg.com/sweetalert/dist/sweetalert.min.js"
    );
    document.head.appendChild(recaptchaScript);
  }
}

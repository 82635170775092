<template>
  <span>
    <div v-for="(volume, i) in volumes" :key="i">
      <div class="mb-3 px-1 py-4">
        <b-card
          class="shadow"
          header="featured"
          header-tag="div"
          :header-class="
            'cor-background-azul ' +
            (btnFecharCaixaLoading ? ' caixa-carregando' : '')
          "
        >
          <template #header>
            <h4 class="mb-6 font-weight-bold" style="color: white">
              Caixa {{ indexCaixa }} - {{ volume.codigo }}
            </h4>
          </template>
          <div
            v-for="(volumeInsumo, j) in volume.insumosNaoBipaveis"
            :key="j"
            :class="
              'mb-0 px-1 py-1' +
              (volumeInsumo.conferido || volumeInsumo.erroCorrigido
                ? ' insumo-conferido'
                : '') +
              (volumeInsumo.erroMotivo && !volumeInsumo.erroCorrigido
                ? ' insumo-com-erro'
                : '')
            "
          >
            <b-card>
              <b-row
                no-gutters
                style="justify-content: space-between !important"
              >
                <!--            <span v-if="!volumeInsumo.insumo.precisaSerBipado">-->
                <b-col cols="3" sm="6" md="6" lg="6" xl="6" align="start">
                  <label
                    style="
                      align-items: center;
                      font-family: 'Poppins';
                      font-size: 20px;
                    "
                    >{{ (volumeInsumo.insumo || {}).descricao || "" }}</label
                  >
                </b-col>

                <b-col cols="3" sm="2" md="2" lg="2" xl="2" align="start">
                  <label
                    style="align-items: center; font-size: 20px; color: red"
                  >
                    {{ volumeInsumo.erroMotivo.toUpperCase() }}
                  </label>
                </b-col>

                <b-col cols="3" sm="4" md="4" lg="4" xl="4" align="end">
                  <label
                    style="
                      align-items: center;
                      font-family: 'Poppins';
                      font-size: 20px;
                    "
                    >{{ volumeInsumo.quantidade }} UNIDADES</label
                  >
                </b-col>
              </b-row>

              <b-row v-if="!volumeInsumo.conferido">
                <b-col cols="1" sm="12" md="12" lg="12" xl="12" align="start">
                  <default-button
                    :name="'Confirmar'"
                    class="text-center my-4"
                    @click="
                      confirmarInsumo(
                        volumeInsumo.insumo.insumoId,
                        volume.volumeId
                      )
                    "
                  ></default-button>
                  <default-button
                    v-if="!volumeInsumo.erroMotivo"
                    :name="'Reportar problema'"
                    class="text-center mb-4"
                    color="red-color"
                    @click="
                      reportarProblema(
                        volumeInsumo.insumo.insumoId,
                        volume.volumeId
                      )
                    "
                  ></default-button>
                </b-col>
              </b-row>

              <b-row
                v-if="volumeInsumo.erroMotivo && !volumeInsumo.erroCorrigido"
              >
                <b-col cols="1" sm="12" md="12" lg="12" xl="12" align="start">
                  <default-button
                    :name="'Informar problema corrigido'"
                    class="text-center mb-4"
                    color="yellow-color"
                    @click="
                      reportarCorrecaoErro(
                        volume.volumeId,
                        volumeInsumo.insumo.insumoId
                      )
                    "
                  ></default-button>
                </b-col>
              </b-row>
            </b-card>
          </div>

          <span
            v-for="(volumeInsumo, k) in volume.insumosBipaveis"
            :key="k"
            :class="'mb-0 px-1 py-1'"
          >
            <!--                        <div v-if="!volumeInsumo.conferido">-->
            <!--               <default-button v-if="!volumeInsumo.erroMotivo" :name="'Reportar problema'" class="text-center mb-4" color="red-color" @click="reportarProblema(volumeInsumo.insumo.insumoId, volume.volumeId)"></default-button>-->
            <!--            </div>-->
            <linha-producao-input-bipavel
              :insumo="{
                ...volumeInsumo.insumo,
                serial: volumeInsumo.conferido
                  ? volumeInsumo.insumo.serial
                  : '',
              }"
              :total-insumos="volume.insumos.length"
              @click="biparInsumo($event, volumeInsumo.insumo, volume.volumeId)"
              :conferido="volumeInsumo.conferido"
              :erro-motivo="volumeInsumo.erroMotivo"
              :erro-corrigido="volumeInsumo.erroCorrigido"
              @reportar-problema="
                reportarProblema(volumeInsumo.insumo.insumoId, volume.volumeId)
              "
              @reportar-correcao-erro="
                reportarCorrecaoErro(
                  volume.volumeId,
                  volumeInsumo.insumo.insumoId
                )
              "
            />
          </span>
        </b-card>
      </div>
    </div>
  </span>
</template>

<script>
import LinhaProducaoInputBipavel from "../../components/LinhaProducaoInputBipavel";
import DefaultButton from "../../../../../components/DefaultButton";
export default {
  name: "ListagemCaixasConferenciaAc",
  data: () => ({}),
  components: { DefaultButton, LinhaProducaoInputBipavel },
  props: {
    volumes: {
      type: Array,
      required: true,
    },
    btnFecharCaixaLoading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    indexCaixa() {
      const numeroPedido = this.$route.params.id;
      const linha = 3;
      const codigos = this.volumes.map(({ codigo }) => codigo);
      if (!codigos.length) {
        return "";
      }
      return +codigos[0].replace(numeroPedido + linha, "");
    },
  },
  // computed: {
  //   volumesMapped() {
  //     return this.volumes.map(v => ({
  //        ...v,
  //        insumosBipaveis: v.insumos.filter(i => i.insumo.precisaSerBipado),
  //        insumosNaoBipaveis: v.insumos.filter(i => !i.insumo.precisaSerBipado)
  //      }))
  //   }
  // },
  methods: {
    async confirmarInsumo(insumoId, volumeId) {
      this.$emit("bipar", { insumoId, volumeId, serial: "" });
    },
    async biparInsumo({ event }, insumo, volumeId) {
      console.log({ event, insumo, volumeId });
      const target = event.target;
      const serialBipado = target.value;
      const { insumoId } = insumo;
      const serialCorreto = insumo.serial;
      console.log(serialCorreto);
      if (serialBipado !== serialCorreto) {
        alert("Serial não corresponde ao bipado na linha 3. Tente novamente.");
        target.value = "";
        target.focus();
        return;
      }
      this.$emit("bipar", { insumoId, volumeId, serial: serialBipado });
    },
    async reportarProblema(insumoId, volumeId) {
      console.log({
        insumoId,
        volumeId,
      });
      this.$emit("reportar-problema", { insumoId, volumeId });
    },

    async reportarCorrecaoErro(volumeId, insumoId) {
      console.log({ volumeId, insumoId });
      this.$emit("reportar-correcao", { volumeId, insumoId });
    },
  },
};
</script>

<style scoped>
.cor-background-azul {
  background: #002233;
}
.number-modifier {
  border-style: none;
  background: #ff7d27;
}

.input-metragem {
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 0px;
  width: 80%;
  border-radius: 0px 0px 0px 0px;
}

.caixa-carregando {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

.insumo-conferido {
  border-color: green;
  border-style: solid;
}

.insumo-com-erro {
  border-color: red;
  border-style: solid;
}
</style>

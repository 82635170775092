<template>
  <div>
    <div class="log"></div>
    <div class="content">
      <div class="box-content" v-if="(insumos || {}).length">
        <div class="pedido-det" v-if="(insumos || {}).length">
          <line-header
            :editarLinha="true"
            :numeroLinha="1"
            :numeroPedido="pedido.numeroPedido"
            :pedidoTransportadora="pedido.transportadoraNome"
            :pedidoDataSeProd="pedido.dataProducao | dateDMY"
            :pedidoStatus="pedido.status"
            :volumesLinhaPaineis="volumes.length"
            :quantidade-caixas="volumes.length"
          />
          <div class="listagemVolumes">
            <editar-listagem-volumes-linha-paineis
              :insumos="insumos"
              :volumes="volumes"
              :loading="volumesLoading"
              @validarBipagem="validarBipagem"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <alerta-de-espera />
      </div>
    </div>
    <modal-editar-linhas :modalAlert="modalAlert" />
  </div>
</template>

<script>
import routesValidationMixin from "@/mixins/routesValidationMixin";
import httpRequestMixin from "@/mixins/httpRequestMixin";
import { EditarLinhaPaineisRenderService } from "@/services/editar_linhas/EditarLinhaPaineisRenderService";
import EditarListagemVolumesLinhaPaineis from "./EditarListagemVolumesLinhaPaineis";
import LineHeader from "../../../components/LineHeader.vue";
import AlertaDeEspera from "@/components/AlertaDeEspera";
import EditarLinhasRepository from "@/repositories/v2/EditarLinhasRepository";
import ModalEditarLinhas from "../ModalEditarLinhas.vue";

export default {
  components: {
    AlertaDeEspera,
    EditarListagemVolumesLinhaPaineis,
    LineHeader,
    ModalEditarLinhas,
  },
  mixins: [routesValidationMixin, httpRequestMixin],
  data() {
    return {
      modalAlert: {},
      volumes: [],
      insumos: [],
      pedido: {
        numeroPedido: 0,
        clienteNome: "",
        transportadoraNome: "",
        transportadoraCodigo: 0,
        kitDescricao: "",
        dataProducao: "",
        linhaProducao1Status: "",
        status: "",
      },
      repositories: {
        editarLinhasRepository: EditarLinhasRepository.build(),
      },
      btnFecharCaixaLoading: false,
      btnFinalizarLinhaLoading: false,
      volumesLoading: false,
    };
  },
  async created() {
    this.editarLinhaPaineisRenderService =
      EditarLinhaPaineisRenderService.build().setVm(this);
  },
  computed: {
    numSeqBipados() {
      return this.insumos
        .filter((i) => i.bipado && !i.volumeId)
        .map((i) => i.sspedidoNumSeq);
    },
  },
  mounted() {
    this.editarLinhaPaineisRenderService.requestUserFuncoes();
  },
  methods: {
    async validarBipagem(value) {
      const { insumo } = value;

      const zerarSerial = () => {
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumo.insumoId) {
            this.insumos[i].serial = "";
            break;
          }
        }
      };

      const setLoading = (value) => {
        for (let i = 0; i < this.insumos.length; i++) {
          if (this.insumos[i].insumoId === insumo.insumoId) {
            this.insumos[i].loading = !!value;
            break;
          }
        }
      };

      // const nextInsumoId = (() => {
      //   let nextIndex = -1;
      //   for (let i = 0; i < this.insumos.length; i++) {
      //     if (this.insumos[i].insumoId === insumo.insumoId) {
      //       nextIndex = i === this.insumos.length - 1 ? -1 : i + 1;
      //       break;
      //     }
      //   }
      //   return nextIndex >= 0 ? this.insumos[nextIndex].insumoId : 0;
      // })();

      if (!insumo.serial) {
        alert("Esse serial está vazio.");
        return;
      }

      if (
        this.insumos.find(
          (i) => i.serial === insumo.serial && i.insumoId !== insumo.insumoId
        )
      ) {
        alert("Esse serial já foi bipado.");
        zerarSerial();
        return;
      }

      const serialCorreto = (
        this.insumos.find(
          (i) => i.insumoId !== insumo.insumoId && i.bipado && i.serial
        ) || { serial: "" }
      ).serial;
      if (
        serialCorreto.length > 0 &&
        insumo.serial.length !== serialCorreto.length
      ) {
        alert(
          "Provavelmente aconteceu um erro na bipagem. Favor, conferir se o número bipado corresponde ao número correto do painel. (tamanho inconsistente)"
        );
        // zerarSerial();
        // return;
      }

      if (
        serialCorreto.length > 0 &&
        serialCorreto.substr(0, 2) !== insumo.serial.substr(0, 2)
      ) {
        alert(
          "Provavelmente aconteceu um erro na bipagem. Favor, conferir se o número bipado corresponde ao número correto do painel. (iniciais inconsistente)"
        );
        // zerarSerial();
        // return;
      }

      setLoading(true);
      const response =
        await this.repositories.editarLinhasRepository.alterarSerial({
          insumoId: insumo.insumoId,
          serial: insumo.serial,
        });
      setLoading(false);

      this.modalAlert = {
        message: response.data.message,
        variant: response.status === 200 ? "success" : "danger",
      };
      this.$bvModal.show("modal-editar-linhas");
    },
  },
};
</script>

<style scoped>
.listagemVolumes {
  overflow-y: auto;
  max-height: 600px;
}
</style>

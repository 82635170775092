import PainelBase from "./PainelBase";

export default class PainelSunlight265W extends PainelBase {
  getCode = () => 69;

  isValid() {
    /*  ESTUDO DE PADRÃO DE PAINEL 69 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            +------------------------+-------------------+-------+
            |NUMEROSSERIECONTROLADOS |DATASEPROD         |NUMPED |
            +------------------------+-------------------+-------+
            |T11612010823O           |2021-06-24 18:00:00|3014409|
            |Q11612010473O           |2021-06-24 18:00:00|3014409|
            |T11612010443O           |2021-06-24 18:00:00|3014409|
            |T11612010897O           |2021-06-24 18:00:00|3014409|
            |11612010791OT1161201    |2021-06-24 18:00:00|3014409|
            |010369OT11              |2021-06-24 18:00:00|3014409|
            |612010893OT11612010791  |2021-06-24 18:00:00|3014409|
            |T11612010400OT11612010  |2021-06-24 18:00:00|3014409|
            |612010757OT1161         |2021-06-24 18:00:00|3014409|
            |11612010891OT11612010891|2021-06-24 18:00:00|3014409|
            |1612010764OT1161201075  |2021-06-24 18:00:00|3014409|
            |T11612010764OT11        |2021-06-24 18:00:00|3014409|
            |612010897O              |2021-06-24 18:00:00|3014409|
            |12010893O               |2021-06-24 18:00:00|3014409|
            |T11612010896O           |2021-06-24 18:00:00|3014409|
            |612010375OT1161         |2021-06-24 18:00:00|3014409|
            |12010429OT11            |2021-06-24 18:00:00|3014409|
            |11611293609OT116        |2021-06-24 18:00:00|3014409|
            |T11612010427OT1         |2021-06-24 18:00:00|3014409|
            |T11612010412O           |2021-06-24 18:00:00|3014409|
            |T11612010439O           |2021-06-24 18:00:00|3014409|
            |11612010723O            |2021-06-24 18:00:00|3014409|
            |T11612010487O           |2021-06-24 18:00:00|3014409|
            |0760O                   |2021-06-24 18:00:00|3014409|
            |11612010436O            |2021-06-24 18:00:00|3014409|
            |T11612010906O           |2021-06-24 18:00:00|3014409|
            |T11612010896OT11        |2021-06-24 18:00:00|3014409|
            |T11612010760O           |2021-06-24 18:00:00|3014409|
            |T11612010750OT          |2021-06-24 18:00:00|3014409|
            |T11612010703OT11        |2021-06-24 18:00:00|3014409|
            |612010703O              |2021-06-24 18:00:00|3014409|
            |T11612010457O           |2021-06-24 18:00:00|3014409|
            |T11612010400O           |2021-06-24 18:00:00|3014409|
            |400OT1                  |2021-06-24 18:00:00|3014409|
            |1612010466O             |2021-06-24 18:00:00|3014409|
            |T11612010656OT1         |2021-06-24 18:00:00|3014409|
            |1612010722OT11          |2021-06-24 18:00:00|3014409|
            |612010747OT11           |2021-06-24 18:00:00|3014409|
            |2010764OT               |2021-06-24 18:00:00|3014409|
            |11612010891OT11         |2021-06-24 18:00:00|3014409|
            |612010895OT1161         |2021-06-24 18:00:00|3014409|
            |2010898O                |2021-06-24 18:00:00|3014409|
            |T11612010898O           |2021-06-24 18:00:00|3014409|
            |T11612010895OT          |2021-06-24 18:00:00|3014409|
            |T11612010894O           |2021-06-24 18:00:00|3014409|
            |T11612010763O           |2021-06-24 18:00:00|3014409|
            |T11612010755OT11        |2021-06-24 18:00:00|3014409|
            |612010724O              |2021-06-24 18:00:00|3014409|
            |11612010891OT1          |2021-06-24 18:00:00|3014409|
            |T11612010400OT1         |2021-06-24 18:00:00|3014409|
            |T11612010656OT11        |2021-06-24 18:00:00|3014409|
            |612010722OT             |2021-06-24 18:00:00|3014409|
            |11612010747OT           |2021-06-24 18:00:00|3014409|
            |11612010757O            |2021-06-24 18:00:00|3014409|
            |612010891OT11           |2021-06-24 18:00:00|3014409|
            |612010895OT116          |2021-06-24 18:00:00|3014409|
            |12010898OT11            |2021-06-24 18:00:00|3014409|
            |T11612010823OT1         |2021-06-24 18:00:00|3014409|
            |1612010763OT11          |2021-06-24 18:00:00|3014409|
            |612010755OT             |2021-06-24 18:00:00|3014409|
            |11612010724OT           |2021-06-24 18:00:00|3014409|
            |11612010906OT1          |2021-06-24 18:00:00|3014409|
            |1612010896OT116         |2021-06-24 18:00:00|3014409|
            |T11612010791O           |2021-06-24 18:00:00|3014409|
            |T11612010760OT          |2021-06-24 18:00:00|3014409|
            |11612010750OT11         |2021-06-24 18:00:00|3014409|
            |612010723OT             |2021-06-24 18:00:00|3014409|
            |11612010703O            |2021-06-24 18:00:00|3014409|
            |T11612010893OT          |2021-06-24 18:00:00|3014409|
            |T11612010750O           |2021-06-24 18:00:00|3014409|
            |T11612010723OT11        |2021-06-24 18:00:00|3014409|
            |T11611293564O           |2021-06-24 18:00:00|3014409|
            |T11611293618OT11        |2021-06-24 18:00:00|3014409|
            |2010375OT11             |2021-06-24 18:00:00|3014409|
            |612010395OT             |2021-06-24 18:00:00|3014409|
            |11612010399OT116        |2021-06-24 18:00:00|3014409|
            |12010416OT1             |2021-06-24 18:00:00|3014409|
            |1612010425OT116         |2021-06-24 18:00:00|3014409|
            |612010434OT             |2021-06-24 18:00:00|3014409|
            |11612010442OT11         |2021-06-24 18:00:00|3014409|
            |612010452OT             |2021-06-24 18:00:00|3014409|
            |11293619O               |2021-06-24 18:00:00|3014409|
            |T11612010377OT          |2021-06-24 18:00:00|3014409|
            |11612010396O            |2021-06-24 18:00:00|3014409|
            |T11612010404O           |2021-06-24 18:00:00|3014409|
            |T11612010420O           |2021-06-24 18:00:00|3014409|
            |1612010430OT            |2021-06-24 18:00:00|3014409|
            |T11612010443OT1         |2021-06-24 18:00:00|3014409|
            |1611293614OT11612       |2021-06-24 18:00:00|3014409|
            |612010389O              |2021-06-24 18:00:00|3014409|
            |T11612010398O           |2021-06-24 18:00:00|3014409|
            |T11612010422OT11        |2021-06-24 18:00:00|3014409|
            |612010422O              |2021-06-24 18:00:00|3014409|
            |T11612010422O           |2021-06-24 18:00:00|3014409|
            |T11612010428O           |2021-06-24 18:00:00|3014409|
            |T11612010432OT          |2021-06-24 18:00:00|3014409|
            |11612010439O            |2021-06-24 18:00:00|3014409|
            |T11612010444O           |2021-06-24 18:00:00|3014409|
            |T11612010664O           |2021-06-24 18:00:00|3014409|
            +------------------------+-------------------+-------+

            Como pode-se observar dos dados do sistema, só existem 99 insumos em um único pedido
            desse painel. Portanto, o Winthor não possui dados suficientes para escrever uma regra de validação.
            Por isso, quando esse serial for bipado novamente, o sistema deixará passar sem regras.
         */
    return true;
  }

  static build() {
    return new PainelSunlight265W();
  }
}

<template>
  <div>
    <div
      v-if="
        insumos.filter((b) => b.bipado).length > 0 &&
        insumos.filter((p) => p.precisaSerBipado).length > 0
      "
    >
      <div v-for="(v, index) in volumes" :key="index">
        <table>
          <thead class="text-white">
            <tr>
              <th style="background-color: #002233 !important">
                Caixa {{ v.index }}
              </th>
            </tr>
          </thead>
        </table>
        <editar-listagem-insumos-linha-diversos
          :insumos="insumos"
          :volumes="volumes"
          :indexVol="index"
          :total-insumos="insumos.length"
          :loading="loading"
          v-on="$listeners"
        />
      </div>
    </div>
    <div v-else>
      <div class="box-content text-center py-5">
        <span class="h5 bold" style="color: gray"
          >Não há insumos bipados para edição.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import EditarListagemInsumosLinhaDiversos from "./EditarListagemInsumosLinhaDiversos.vue";
export default {
  name: "EditarListagemVolumesLinhaDiversos",
  components: { EditarListagemInsumosLinhaDiversos },
  data: () => ({
    numVol: 1,
  }),
  props: {
    insumos: { type: Array, required: true },
    volumes: { type: Array, required: true },
    loading: { type: Boolean, required: true },
  },
};
</script>
<style scoped></style>

<template>
  <div class="cordefundo">
    <div class="content">
      <div class="box-content">
        <div v-if="!isLoading">
          <b-row align-h="center" class="mx-2 mx-sm-2 mx-md-5 mx-lg-5 mx-xl-5">
            <b-col sm="11">
              <b-row>
                <label
                  for="selectEmploye"
                  class="font-weight-bolder text-style h5"
                  >Funcionário</label
                >
              </b-row>
              <b-row>
                <b-form-select
                  class="input-style"
                  size="lg"
                  v-model="user.pcemprId"
                  id="selectEmploye"
                  type="select"
                  :options="employes"
                  value-field="MATRICULA"
                  text-field="NOME"
                >
                </b-form-select>
              </b-row>
            </b-col>
          </b-row>
          <b-row align-h="around" class="mx-2 mx-sm-2 mx-md-5 mx-lg-5 mx-xl-5">
            <b-col sm="5" align-self="center">
              <b-row>
                <label for="setEmail" class="font-weight-bolder text-style h5"
                  >Login</label
                >
              </b-row>
              <b-row>
                <b-form-input
                  class="input-style"
                  size="lg"
                  type="text"
                  id="setEmail"
                  v-model="user.login"
                ></b-form-input>
              </b-row>
            </b-col>
            <b-col sm="5" align-self="center">
              <b-row>
                <label
                  for="setPassword"
                  class="font-weight-bolder text-style h5"
                  >Senha</label
                >
              </b-row>
              <b-row>
                <b-form-input
                  class="input-style"
                  size="lg"
                  type="password"
                  id="setPassword"
                  v-model="user.password"
                ></b-form-input>
              </b-row>
            </b-col>
          </b-row>
          <b-row align-h="around" class="mx-2 mx-sm-2 mx-md-5 mx-lg-5 mx-xl-5">
            <b-col sm="5" align-self="center">
              <b-row>
                <label
                  for="setImpressora"
                  class="font-weight-bolder text-style h5"
                  >Impressora</label
                >
              </b-row>
              <b-row>
                <b-form-select
                  class="input-style"
                  size="lg"
                  v-model="user.impressoraId"
                  id="setImpressora"
                  type="select"
                  :options="impressoras"
                  value-field="impressoraId"
                  text-field="nome"
                >
                </b-form-select>
              </b-row>
            </b-col>
            <b-col sm="5" align-self="center">
              <b-row>
                <label
                  for="setFunction"
                  class="font-weight-bolder text-style h5"
                  >Funções</label
                >
              </b-row>
              <b-row>
                <b-form-select
                  class="input-style"
                  size="lg"
                  v-model="user.permissions"
                  id="setFunction"
                  type="select"
                  :options="permissions"
                  value-field="id"
                  text-field="descricao"
                >
                </b-form-select>
              </b-row>
              <b-row>
                <b-form-select
                  class="input-style"
                  size="lg"
                  v-model="user.roles"
                  id="setFunction"
                  type="select"
                  :options="roles"
                  value-field="ID"
                  text-field="NAME"
                >
                </b-form-select>
              </b-row>
            </b-col>
          </b-row>
          <b-row
            align-h="center"
            class="mx-2 mx-sm-2 mx-md-5 mx-lg-5 mx-xl-5 justify-content-center justify-content-sm-center justify-content-md-center justify-content-xl-end justify-content-lg-end"
          >
            <b-col
              cols="5"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              align-self="center"
              class="my-4 mr-2 mr-sm-2 mr-md-3 mr-lg-3 mr-xl-3 d-flex justify-content-center"
            >
              <default-button
                name="Deletar"
                color="red-color"
                style="padding: 1rem !important"
                @click="deleteUser"
              ></default-button>
            </b-col>
            <b-col
              cols="5"
              sm="4"
              md="3"
              lg="2"
              xl="2"
              align-self="center"
              class="my-4 mr-2 mr-sm-2 mr-md-5 mr-lg-5 mr-xl-5 d-flex justify-content-center"
            >
              <default-button
                name="Salvar"
                style="padding: 1rem !important"
                @click="updateUser"
              ></default-button>
            </b-col>
          </b-row>
        </div>
        <div v-else class="pad mt-5" style="text-align: center !important">
          <b-spinner style="width: 10rem; height: 10rem" />
        </div>
      </div>
    </div>
    <modal-usuarios :modalAlert="modalAlert" />
  </div>
</template>

<script>
import routesValidationMixin from "../../mixins/routesValidationMixin";
import httpRequestMixin from "../../mixins/httpRequestMixin";
import DefaultButton from "../../components/DefaultButton";
import ModalUsuarios from "./ModalUsuarios";
import { EditarUsuarioService } from "../../services/usuarios/EditarUsuarioService";

export default {
  components: { DefaultButton, ModalUsuarios },
  data() {
    return {
      isLoading: false,
      modalAlert: {},
      roles: {
        type: Object,
        default: null,
      },
      permissions: {
        type: Object,
        default: null,
      },
      employes: [],
      impressoras: [],
      user: {
        id: 0,
        pcemprId: null,
        login: "",
        password: "",
        impressoraId: null,
        roles: null,
        permissions: null,
      },
      editarUsuarioService: EditarUsuarioService.build(),
    };
  },
  mixins: [routesValidationMixin, httpRequestMixin],
  mounted() {
    this.editarUsuarioService = EditarUsuarioService.build().setVm(this);
    this.editarUsuarioService.getEditarUsuarioData(this.$route.params.id);
  },
  methods: {
    updateUser() {
      this.editarUsuarioService.updateUser(this.user);
    },
    deleteUser() {
      this.editarUsuarioService.deleteUser(this.user);
    },
  },
};
</script>

<style scoped>
.input-style {
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  color: #002233;
  background-color: #fff;
  border: 2px solid #e1e1e1;
  border-radius: 11px;
  outline: 0;
  box-sizing: border-box;
}
.input-style:hover {
  border-color: inherit !important;
  box-shadow: inherit !important;
  border: 3px solid rgba(230, 120, 30, 0.7) !important;
}
.input-style:focus {
  border-color: inherit !important;
  box-shadow: inherit !important;
  border: 3px solid rgba(230, 120, 30, 0.7) !important;
}
.cordefundo {
  background: #f2f4f5;
  color: #002233;
}
.text-style {
  font-family: sans-serif;
}
</style>

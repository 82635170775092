export default {
  urlParams: {
    page: 1,
    perPage: 10,
    idInsumo: "",
    nome: "",
  },
  insumos: {},
  insumosNaoConfigurados: 0,
  insumosNaoConfiguradosFilter: false,
  isLoading: false,
  pagination: {
    total: 0,
    perPage: 10,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
};

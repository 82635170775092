export default {
  getSortingBays(state) {
    return state.sortingBays;
  },
  getIsLoading(state) {
    return state.isLoading;
  },
  getPagination(state) {
    return state.pagination;
  },
  getUrlParams(state) {
    return state.urlParams;
  },
  getIsRegisterBayButtonDisabled(state) {
    return state.isRegisterBayButtonDisabled;
  },
  getIsEditBayModalOpen(state) {
    return state.isEditBayModalOpen;
  },
  getIsRegisterBayModalOpen(state) {
    return state.isRegisterBayModalOpen;
  },
};

import { ErrorNotifier } from "../../../../services/messages/ErrorNotifier";
import { GerenciarPedidoRepository } from "../../repository/GerenciarPedidoRepository";

export default {
  async getPedidoDetails({ commit, dispatch }, numeroPedido) {
    commit("setPedidos", []);
    commit("setVolumes", []);
    commit("setIsLoading", true);
    try {
      const response = await GerenciarPedidoRepository.getPedidoResumo(
        numeroPedido
      );
      if (response.status >= 200 && response.status < 300) {
        if (!response.data.data.pedidos) throw response;

        const { data } = response.data;
        commit("setPedidos", data.pedidos);
        commit("setVolumes", data.total);
        return;
      } else {
        commit("setIsLoading", false);
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async getPedidoResumoEmbarque({ commit, dispatch }, numeroPedido) {
    commit("setIsLoading", true);
    commit("setHistoricoDoPedido", []);
    commit("setDetalhesDoPedido", {});
    commit("setLinhasProducao", []);
    commit("setLinha1", []);
    commit("setLinha2", []);
    commit("setLinha3", []);
    commit("setLinha4", []);
    commit("setLinha5", []);

    try {
      const response = await GerenciarPedidoRepository.pedidoResumoEmbarque(
        numeroPedido
      );
      if (!response.data) throw response;
      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        commit("setHistoricoDoPedido", data.historico);
        commit("setDetalhesDoPedido", data.pedido);
        commit("setLinhasProducao", data.linhasProducao);
        commit(
          "setLinha1",
          data.linhasProducao
            .filter((linha) => linha.linha === 1)
            .map((um) => ({
              insumoDescricao: um.insumoDescricao,
              codigoProdutoMateriaPrima: um.codigoProdutoMateriaPrima,
              insumosQuantidade: um.insumosQuantidade,
              linha: um.linha,
              bipadorCodigo: um.bipadorCodigo,
              bipadorNome: um.bipadorNome,
              dataInicio: um.dataInicio,
              dataFinal: um.dataFinal,
            }))
        );

        commit(
          "setLinha2",
          data.linhasProducao
            .filter((linha) => linha.linha === 2)
            .map((dois) => ({
              insumoDescricao: dois.insumoDescricao,
              codigoProdutoMateriaPrima: dois.codigoProdutoMateriaPrima,
              insumosQuantidade: dois.insumosQuantidade,
              linha: dois.linha,
              bipadorCodigo: dois.bipadorCodigo,
              bipadorNome: dois.bipadorNome,
              dataInicio: dois.dataInicio,
              dataFinal: dois.dataFinal,
            }))
        );

        commit(
          "setLinha3",
          data.linhasProducao
            .filter((linha) => linha.linha === 3)
            .map((tres) => ({
              insumoDescricao: tres.insumoDescricao,
              codigoProdutoMateriaPrima: tres.codigoProdutoMateriaPrima,
              insumosQuantidade: tres.insumosQuantidade,
              linha: tres.linha,
              bipadorCodigo: tres.bipadorCodigo,
              bipadorNome: tres.bipadorNome,
              dataInicio: tres.dataInicio,
              dataFinal: tres.dataFinal,
            }))
        );

        commit(
          "setLinha4",
          data.linhasProducao
            .filter((linha) => linha.linha === 4)
            .map((quatro) => ({
              insumoDescricao: quatro.insumoDescricao,
              codigoProdutoMateriaPrima: quatro.codigoProdutoMateriaPrima,
              insumosQuantidade: quatro.insumosQuantidade,
              linha: quatro.linha,
              bipadorCodigo: quatro.bipadorCodigo,
              bipadorNome: quatro.bipadorNome,
              dataInicio: quatro.dataInicio,
              dataFinal: quatro.dataFinal,
            }))
        );

        commit(
          "setLinha5",
          data.linhasProducao
            .filter((linha) => linha.linha === 5)
            .map((cinco) => ({
              insumoDescricao: cinco.insumoDescricao,
              codigoProdutoMateriaPrima: cinco.codigoProdutoMateriaPrima,
              insumosQuantidade: cinco.insumosQuantidade,
              linha: cinco.linha,
              bipadorCodigo: cinco.bipadorCodigo,
              bipadorNome: cinco.bipadorNome,
              dataInicio: cinco.dataInicio,
              dataFinal: cinco.dataFinal,
            }))
        );
        return;
      } else {
        commit("setIsLoading", false);
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async zerarLinhas({ commit, dispatch }, body) {
    commit("setIsLoadingZerarLinha", true);
    try {
      const response = await GerenciarPedidoRepository.zerarLinhas(
        body.numped,
        body.linha
      );

      if (response.status >= 200 && response.status < 300) {
        commit("setIsLoadingZerarLinha", false);
        let successObj = {
          successStatus: true,
          successMessage: `Linha ${body.linha} zerada com sucesso!`,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return response;
      } else {
        commit("setIsLoadingZerarLinha", false);
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async zerarTotalBipagem({ commit, dispatch }, numped) {
    commit("setIsLoadingZerarLinha", true);
    try {
      const response = await GerenciarPedidoRepository.zerarTotalBipagem(
        numped
      );

      if (response.status >= 200 && response.status < 300) {
        commit("setIsLoadingZerarLinha", false);
        let successObj = {
          successStatus: true,
          successMessage: "Pedido foi zerado com sucesso!",
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return response;
      } else {
        commit("setIsLoadingZerarLinha", false);
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },
};

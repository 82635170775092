import { HttpRestService } from "@/services/http/HttpRestService";

export class ThirdLineRepository {
  static build() {
    return new ThirdLineRepository();
  }

  save(body) {
    return HttpRestService.post(`/api/pedido/save`, body);
  }

  saveVol(body) {
    return HttpRestService.post(`/api/pedido/save-vol`, body);
  }

  saveSeparadorLinha(body) {
    return HttpRestService.post(`/api/valida-separador-linha`, body);
  }
}

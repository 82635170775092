import router from "../../router";
import { HttpRestService } from "../http/HttpRestService";

export class IniciarProducaoService {
  vm;

  constructor() {}

  static build() {
    return new IniciarProducaoService();
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async iniciarProducao() {
    this.vm.loading = true;
    if (!this.validaDados()) return;

    const body = this.getBody();

    await HttpRestService.post(
      // `/api/v2/pedido-web/${this.vm.numeroPedido}/iniciar-bipagem`,
      `/api/v2/pedido-web/${this.vm.numeroPedido}/iniciarProducao`,
      body
    ).then((response) => {
      if (response.status != 200) {
        this.vm.messageError = response.data.message;
        this.vm.$bvModal.show("modal-erro-inicar-producao");
        return;
      }
      this.vm.closeModal();
      router.push({
        name: "pedidoLinhaPaineis",
        params: { numped: this.vm.numeroPedido },
      });
    });
  }

  async iniciarProducaoExpress() {
    this.vm.loading = true;
    if (!this.validaDados()) return;

    const body = this.getBody();

    await HttpRestService.post(
      `/api/v2/pedido-web/${this.vm.numeroPedido}/iniciarProducao`,
      body
    ).then((response) => {
      if (response.status != 200) {
        this.vm.messageError = response.data.message;
        this.vm.$bvModal.show("modal-erro-inicar-producao");
        return;
      }
      this.vm.closeModal();
      router.replace({
        name: "pedidoLinhaPaineisExpress",
        params: { numped: this.vm.numeroPedido },
      });
    });
  }

  validaDados() {
    const data = this.vm.form;

    if (
      !data.localizacaoEstoque.length ||
      data.separadorDois === null ||
      data.separadorUm === null
    ) {
      this.vm.loading = false;
      this.vm.messageError = "Preencha os dados corretamente.";
      this.vm.$bvModal.show("modal-erro-inicar-producao");
      return false;
    }

    if (data.separadorUm === data.separadorDois) {
      this.vm.messageError = "Selecione Dois Separadores diferentes.";
      this.vm.$bvModal.show("modal-erro-inicar-producao");
      this.vm.loading = false;
      return false;
    }
    return true;
  }

  getBody() {
    const data = this.vm.form;
    return {
      volume: data.volume <= 0 ? 1 : data.volume,
      localizacaoEstoque: data.localizacaoEstoque,
      separador1PcEmpId: data.separadorUm,
      separador2PcEmpId: data.separadorDois,
    };
  }
}

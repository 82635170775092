export default {
  urlParams: {
    page: 1,
    perPage: 10,
    nome: "",
    email: "",
  },
  usuarios: {},
  isLoading: false,
  pagination: {
    total: 0,
    perPage: 10,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  usuarioCreate: {
    login: "",
    password: "",
    impressoraId: "",
    pcemprId: "",
    roles: "",
    permission: "",
  },
  impressoras: [],
  roles: [],
  permissions: [],
  employes: [],
};

<template>
  <default-modal v-model="dialogModel">
    <v-card-actions>
      <v-form ref="form">
        <v-radio-group
          v-model="blockId"
          class="mt-0"
          active-class="font-weight-bold"
        >
          <v-radio
            label="Divergência de modelo de painel"
            :value="2"
          ></v-radio>
          <v-select
            ref="select-1"
            :rules="[rules.discrepantPanelModelsRequired]"
            v-model="discrepantPanelModels"
            class="ml-8 mt-2 mb-n10"
            :items="panels"
            item-text="insumoDescricao"
            item-value="tipoInsumoId"
            clearable
            multiple
            outlined
            chips
            deletable-chips
            label="Modelos Divergentes"
            :dense="discrepantPanelModels.length === 0"
            style="font-size: 13px !important"
          >
            <template v-slot:prepend-item>
              <div class="d-flex justify-content-end">
                <v-icon
                  @click="$refs['select-1'].isMenuActive = false"
                  color="primary"
                  class="mr-2 mt-0 mb-1"
                  >mdi-close-circle-outline</v-icon
                >
              </div>
              <v-divider />
            </template>
          </v-select>
        </v-radio-group>
      </v-form>
    </v-card-actions>
    <v-card-actions>
      <default-button
        @click="blockOrder"
        :loading="isLoading"
        >Bloquear Pedido</default-button
      >
    </v-card-actions>
  </default-modal>
</template>

<script>
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultModal from "@/shared/components/vuetify/DefaultModal.vue";
export default {
  name: "BlockOrder",
  components: {
    DefaultButton,
    DefaultModal,
  },
  props: {
    value: {},
    order: {},
    isLoading: {},
    orderId: {},
    panels: {},
  },
  data() {
    return {
      discrepantPanelModels: [],
      blockId: 2,
    };
  },
  methods: {
    blockOrder() {
      if (this.$refs.form.validate()) {
        const orderToBeBlocked = {
          pedidoId: this.orderId,
          motivo: this.blockId,
          paineisDivergentes: this.discrepantPanelModels,
        };
        this.$emit("blockOrder", orderToBeBlocked);
      }
    },
  },
  computed: {
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    rules() {
      return {
        discrepantPanelModelsRequired: (input) =>
          !!input.length > 0 || "Selecione os modelos divergentes",
      };
    },
  },
};
</script>

<style scoped>
::v-deep .v-list-item__title {
  font-size: 13px;
}
</style>

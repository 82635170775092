<template>
  <b-modal data-bv
    id="modal-reportar-problema"
    body-class="rounded"
    size="lg sm"
    hide-footer
    hide-header
    centered
    class="py-2 px-2"
  >
    <div class="mt-2">
      <div class="d-block text-left my-3">
        <h4 class="font-weight-bold">Reportar problema</h4>
      </div>
      <b-form-select
        v-model="motivoErro"
        :options="motivoErrosMapped"
      ></b-form-select>
      <default-button
        :disabled="!motivoErro || loading"
        :name="loading ? 'Confirmando erro...' : 'Confirmar erro'"
        @click="confirmarReporteProblema"
      ></default-button>
    </div>
  </b-modal>
</template>

<script>
import DefaultButton from "../../../../../components/DefaultButton";
import ConfereACRepository from "../../../../../repositories/v2/ConfereACRepository";
export default {
  name: "ModalReportarProblemaConferenciaAc",
  components: { DefaultButton },
  data: () => ({
    motivosErros: ["Separação errada", "Avaria no insumo", "Falta do insumo"],
    motivoErro: "",
    repositories: {
      confereACRepository: ConfereACRepository.build(),
    },
    loading: false,
  }),
  computed: {
    motivoErrosMapped() {
      return this.motivosErros.map((motivoErro) => ({
        text: motivoErro,
        value: motivoErro,
      }));
    },
  },
  props: {
    insumoId: { type: Number, required: true },
    volumeId: { type: Number, required: true },
  },
  methods: {
    async confirmarReporteProblema() {
      const { insumoId, volumeId, motivoErro } = this;
      this.loading = true;
      const response =
        await this.repositories.confereACRepository.reportarProblema({
          motivo: motivoErro,
          insumoId,
          volumeId,
        });
      this.loading = false;
      // response.data.message && alert(response.data.message)
      if (response.status === 200) {
        this.$bvModal.hide("modal-reportar-problema");
        this.$emit("atualizar-reportar-problema", {
          insumoId,
          volumeId,
          motivoErro,
        });
      }
    },
  },
};
</script>

<style scoped>
.cor-background-azul {
  background: #002233;
}
</style>

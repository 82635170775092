<template>
  <div class="mb-4 mt-3">
    <v-row>
      <!-- <b-col
        cols="12"
        sm="3"
        md="3"
        lg="3"
        xl="3"
        class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0 listing-title"
      >
        <h3 class="font-weight-bold">
          {{ statusPedidoDescricao }}
        </h3>
        <span v-if="isManutencao">
          <h3 class="font-weight-bold">
            {{ title }}
          </h3>
        </span>
      </b-col> -->
      <v-col v-if="pesquisarSerial" cols="12" sm="4" md="3">
        <default-button @click="clickPesquisarSerial">
          Pesquisar Serial</default-button
        >
      </v-col>
      <!-- <b-col
        cols="12"
        sm="3"
        md="3"
        lg="2"
        xl="2"
        class="mb-3 mb-sm-3 mb-md-3 mb-lg-0 mb-xl-0"
        v-if="relatorioBtn"
      >
        <default-button
          name="Rel. aguardando produção"
          :color="relatorioColor"
          :disabled="!relatorioEnabled"
          @click="openRelatorioAguardandoProducao"
        />
      </b-col> -->
      <v-col cols="12" sm="3" lg="2" v-if="etiquetaBtn">
        <default-button
          :disabled="!etiquetaEnabled"
          @click="clickEtiqueta"
          v-if="role == 'ADMIN'"
          >Etiqueta</default-button
        >
      </v-col>
      <v-col cols="12" sm="3" lg="2" v-if="uploadBtn">
        <default-button :disabled="!uploadEnabled" @click="clickUpload"
          >Upload</default-button
        >
      </v-col>
      <v-col cols="12" sm="3" lg="2" v-if="agendarBtn">
        <default-button :disabled="!agendarEnabled" @click="clickAgendar"
          >Agendar</default-button
        >
      </v-col>
      <v-col cols="12" sm="4" md="3" v-if="agendarBtn">
        <default-button :disabled="!agendarEnabled" @click="clickAgendarVarios"
          >Agendamento <span class="h3 mb-0 ml-1">+</span></default-button
        >
      </v-col>
      <v-col cols="12" sm="3" lg="2" v-if="selecionarBtn">
        <default-button :disabled="!selecionarEnabled" @click="clickSelecionar"
          >Selecionar Todos</default-button
        >
      </v-col>
    </v-row>
    <v-row dense class="mt-2" justify="center" @keyup.enter="clickFiltrar">
      <v-col cols="12" sm="6" md="3">
        <default-text-field label="Pedido" v-model="numeroPedido" />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <default-text-field
          label="Transportadora"
          v-model="transportadoraNome"
        />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <default-date-picker
          label="Data Inicial"
          :picker-date.sync="dataInicio"
        />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <default-date-picker label="Data Final" :picker-date.sync="dataFinal" />
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <default-filter-button
          :has-filter-all="filtrarAllEnabled"
          :loading="isButtonLoading"
          @filter="clickFiltrar"
          @filterAll="clickFiltrarTodos"
          @cleanFilter="cleanFilter"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export { setUserLogged, getUserLogged } from "../../../services/userLogged";
import DefaultDatePicker from "../../../shared/components/vuetify/DefaultDatePicker.vue";
import DefaultFilterButton from "../../../shared/components/vuetify/DefaultFilterButton.vue";
import DefaultTextField from "../../../shared/components/vuetify/DefaultTextField.vue";
import VisualizacaoRelatorioAguardandoProducao from "../../../services/listagem_pedidos/VisualizacaoRelatorioAguardandoProducao";
import DefaultButton from "../../../shared/components/vuetify/DefaultButton.vue";
export default {
  name: "ListingHeader",
  components: {
    DefaultButton,
    DefaultDatePicker,
    DefaultFilterButton,
    DefaultTextField,
  },
  created() {
    this.visualizacaoRelatorioAguardandoProducao =
      VisualizacaoRelatorioAguardandoProducao.build();
  },
  beforeMount() {
    this.getRoles();
  },
  data() {
    return {
      role: "",
      transportadoraNome: "",
      numeroPedido: "",
      dataInicio: "",
      dataFinal: "",
      visualizacaoRelatorioAguardandoProducao:
        VisualizacaoRelatorioAguardandoProducao.build(),
    };
  },
  computed: {
    statusPedido() {
      return this.$parent.pedidosStatus;
    },
    statusPedidoDescricao() {
      console.log([this.statusPedido]);
      return (
        {
          A: "Aguardando Produção",
          T: "Conferência com problema",
          C: "Cancelados",
          L: "Em linha de produção",
          E: "Aguardando embarque",
          D: "Despachados",
          ac: "Em conferência AC",
          N: "Aguardando conferência",
          F: "Em conferência",
          M: "Agendamento de Produção",
          O: "Pedidos Diversos",
          "": "Adm. de Pedidos",
        }[this.statusPedido] || ""
      );
    },
    permiteFiltro() {
      const { numeroPedido, dataInicio, dataFinal, transportadoraNome } = this;
      const filtroEmAcao = JSON.stringify(this.$parent.filtro);
      const filtroEmEdicao = JSON.stringify({
        numeroPedido,
        dataInicio,
        dataFinal,
        transportadoraNome,
      });
      return filtroEmEdicao !== filtroEmAcao;
    },
  },
  props: {
    isButtonLoading: {
      type: Boolean,
      default: false,
    },
    title: { type: String, required: true },
    isManutencao: { type: Boolean, required: false },
    relatorioBtn: { type: Boolean, default: false },
    relatorioColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    relatorioEnabled: { type: Boolean, default: true },

    etiquetaBtn: { type: Boolean, default: false },
    etiquetaColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    etiquetaEnabled: { type: Boolean, default: true },

    uploadBtn: { type: Boolean, default: false },
    uploadColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    uploadEnabled: { type: Boolean, default: true },

    agendarBtn: { type: Boolean, default: false },
    agendarColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    agendarEnabled: { type: Boolean, default: true },

    selecionarBtn: { type: Boolean, default: false },
    selecionarColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    selecionarEnabled: { type: Boolean, default: true },

    filtrarBtn: { type: Boolean, default: false },
    filtrarColor: {
      type: String,
      default: "orange-color",
      validator: (val) =>
        ~[
          "orange-color",
          "red-color",
          "green-color",
          "blue-color",
          "yellow-color",
          "gray-color",
        ].indexOf(val),
    },
    filtrarAllEnabled: { type: Boolean, default: false },
    filtrarEnabled: { type: Boolean, default: true },
    pesquisarSerial: { type: Boolean, default: false },
  },
  methods: {
    clickPesquisarSerial() {
      this.$bvModal.show("modal-pesquisar-serial");
    },
    getRoles() {
      this.role = window.localStorage.getItem("roles");
    },
    clickEtiqueta() {
      this.$bvModal.show("modal-etiquetas");
    },
    clickUpload() {
      this.$bvModal.show("modal-upload");
    },
    clickAgendar() {
      this.$emit("clickAgendar");
    },
    clickAgendarVarios() {
      this.$emit("clickAgendarVarios");
    },
    clickSelecionar() {
      this.$emit("clickSelecionar");
    },
    clickFiltrarTodos() {
      this.$emit("filterAll", {
        numeroPedido: this.numeroPedido,
        dataInicio: this.dataInicio,
        dataFinal: this.dataFinal,
        transportadoraNome: this.transportadoraNome,
      });
    },
    clickFiltrar() {
      this.$emit("filter", {
        numeroPedido: this.numeroPedido,
        dataInicio: this.dataInicio,
        dataFinal: this.dataFinal,
        transportadoraNome: this.transportadoraNome,
      });
    },
    dataInicioEvent() {
      this.$emit("resetDataInicio", this.dataInicio);
    },
    dataFinalEvent() {
      this.$emit("resetDataFinal", this.dataFinal);
    },
    openRelatorioAguardandoProducao() {
      this.visualizacaoRelatorioAguardandoProducao.abrirRelatorio();
    },
    cleanFilter() {
      this.numeroPedido = "";
      this.transportadoraNome = "";
      this.dataInicio = "";
      this.dataFinal = "";
    },
  },
};
</script>

<style scoped></style>

import PainelBase from "./PainelBase";

export default class PainelJinko335W extends PainelBase {
  getCode = () => 63;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 63 SEGUNDO DADOS DO DIA 2022-01-13 NO WINTHOR
            Segundo a consulta "select numerosseriecontrolados from sspedido where codprodmp = 63",
            todos os 2 seriais desse painel estão nulos no sistema. Portanto, essa amostra será desoconsiderada.
            e quando houver alguma bipagem desse painel novamente, o sistema deixará passar sem validação.
         */
    return true;
  }

  static build() {
    return new PainelJinko335W();
  }
}

<template>
  <b-modal data-bv
    @hidden="onClosingModal"
    @shown="$refs.inputSerial.focus()"
    hide-footer
    centered
    content-class="modal-body"
    id="modal-pesquisar-serial"
    :size="isShown ? 'lg' : 'md'"
  >
    <b-form @submit.prevent>
      <b-row class="mb-3">
        <b-col cols="12" align-self="center">
          <b-form-group
            label="Serial"
            class="font-weight-bold h6"
            required
            :state="inputState"
            :invalid-feedback="invalidFeedback"
          >
            <b-form-input
              @keyup.enter="clickSerial"
              ref="inputSerial"
              v-model="serial"
              class="m-0 py-4 input-serial"
              content-class="input-serial"
              type="text"
              placeholder="Digite o serial"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center" cols="12 mb-2">
          <b-button @click="clickSerial" class="default-button-style px-5">
            <span v-if="!isLoading">Pesquisar</span>
            <div v-else>
              <b-spinner small></b-spinner>
              <span class="ml-2">Pesquisando...</span>
            </div>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div v-if="isShown">
      <div v-if="pedido">
        <b-row class="mt-3 mb-0">
          <b-col cols="12" class="text-center mb-3">
            <span class="h4">{{ serialString }}</span>
          </b-col>
        </b-row>
        <b-card class="mt-1 mb-2 card-serial">
          <b-row>
            <b-col class="text-center">
              <p class="h6 font-weight-bold">Pedido</p>
              <p class="text-uppercase">{{ pedido.numeroPedido }}</p>
            </b-col>
            <b-col class="text-center">
              <p class="h6 font-weight-bold">Data de produção</p>
              <p class="text-uppercase">{{ pedido.dataProducao | dateDMY }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <p class="h6 font-weight-bold">Cliente</p>
              <p class="text-uppercase">{{ pedido.nomeCliente }}</p>
            </b-col>
            <b-col class="text-center">
              <p class="h6 font-weight-bold">Transportadora</p>
              <p class="text-uppercase">{{ pedido.transportadora }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <p class="h6 font-weight-bold">Kit</p>
              <p class="text-uppercase">{{ pedido.kitDescricao }}</p>
            </b-col>
            <b-col class="text-center">
              <p class="h6 font-weight-bold">Status</p>
              <p class="text-uppercase">{{ pedido.statusPedido }}</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <b-button
                class="p-0 mt-2"
                v-b-tooltip.hover
                title="Editar pedido"
                variant="outline-light"
                @click="
                  $router.push(`pedido/${pedido.numeroPedido}/editar-pedido`)
                "
              >
                <b-icon
                  shift-v="8"
                  shift-h="-8"
                  icon="pencil-square"
                  class="icon-editar rounded-circle p-4 text-center"
                  scale="2"
                ></b-icon>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <b-card
        v-else-if="!isLoading"
        class="w-75 mx-auto mt-4 card-serial bg-danger text-center"
      >
        <b-icon
          class="mr-4 d-none d-sm-inline"
          icon="exclamation-circle"
          variant="white"
          scale="2"
        ></b-icon>
        <span class="text-white h6 font-weight-bold"
          >Nenhum pedido encontrado</span
        >
      </b-card>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    pedido: {
      type: null,
      required: true,
    },
    serialString: {
      type: null,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      serial: "",
      isShown: false,
      isValid: true,
    };
  },
  methods: {
    onClosingModal() {
      this.isShown = false;
      this.serial = "";
      this.isValid = true;
    },
    clickSerial() {
      if (this.serial === "") {
        this.isValid = false;
        this.isShown = false;
        return;
      }
      this.$emit("getPedidoPeloSerial", this.serial.trim());
      this.isValid = true;
      this.isShown = true;
    },
  },
  computed: {
    inputState() {
      return this.isValid;
    },
    invalidFeedback() {
      if (this.serial.length < 1) {
        return "Este campo não pode ficar vazio";
      }
      return "";
    },
  },
};
</script>

<style scoped>
.default-button-style {
  text-align: center;
  outline: none;
  border-radius: 1.5rem;
  padding: 0.7rem;
  font-weight: bold !important;
  font-family: inherit;
  font-size: 15px;
  cursor: pointer;
  transition: background-color, padding 0.15s;
  background-color: #cc5d02 !important;
  color: white !important;
  border: none;
}

.default-button-style:hover {
  background-color: #e6781e !important;
  color: white !important;
}

::v-deep .modal-body {
  background-color: #dadfe0;
}

::v-deep .input-serial {
  font-size: 16px;
  background-color: #ffffff;
}

::v-deep .card-serial {
  border-radius: 10px;
  box-shadow: 5px 5px 5px -3px rgb(0 0 0 / 15%);
}

::v-deep .icon-editar {
  color: #dadfe0;
  background-color: #cc5d02;
}

::v-deep .icon-editar:hover {
  background-color: #e6781e;
}

::v-deep .modal-header {
  padding: 0;
}

@media (max-width: 337px) {
  .icon-editar {
    margin-top: 15px;
  }
}
</style>

export class ProximoInsumoService {
  insumos;
  insumo;

  constructor() {}

  static build() {
    return new ProximoInsumoService();
  }

  setInsumos(insumos) {
    this.insumos = insumos;
  }

  avancarParaProximoInput(insumo) {
    const index = this.getNumSeqInput(insumo);
    if (index === null) return;
    const className = "prod-cod-" + index;
    const nextInput = document.getElementsByClassName(className)[0];
    console.log(nextInput);
    nextInput.focus();
  }

  getNumSeqInput(insumo) {
    let { index } = insumo;
    console.log("ak");
    // let numeroSequenciaProximoInput = null;
    // this.insumos.forEach((value) => {
    //   console.log(value.index);
    //   if (
    //     value.index > index &&
    //     numeroSequenciaProximoInput === null &&
    //     value.bipado === false
    //   ) {
    //     numeroSequenciaProximoInput = value.numeroSequencia;
    //   }
    // });
    index++;
    return index;
  }
}

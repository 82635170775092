<template>
  <default-modal
    hide-close-button
    persistent
    v-model="isAlertModalOpen"
    width="550"
  >
    <v-card-title class="justify-center mb-n4"
      ><v-icon size="70" color="pink"
        >mdi-alert-circle-outline</v-icon
      ></v-card-title
    >
    <v-card-title class="justify-center font-weight-bold text-uppercase mb-n3"
      >O painel não possui palete cadastrado
    </v-card-title>
    <div
      class="font-weight-bold text-caption text-center primary--text"
      v-for="name in painelNameList"
      :key="name"
    >
      {{ name }}
    </div>

    <div class="font-weight-light text-caption text-center">
      Entre em contato com o responsável
    </div>
    <v-card-actions class="justify-center pb-5">
      <v-btn color="pink" dark @click="tryAgain()">Verificar Novamente</v-btn>
    </v-card-actions>
  </default-modal>
</template>
<script>
import DefaultModal from "@/shared/components/vuetify/DefaultModal.vue";
export default {
  name: "ModalAlertPainel",
  components: {
    DefaultModal,
  },
  data() {
    return {
      isAlertModalOpen: true,
    };
  },
  props: {
    modalAlert: { type: Boolean, required: true },
    painelNameList: { type: Array, required: true },
  },
  watch: {
    modalAlert: {
      immediate: true,
      handler() {
        this.checkIsAlertModalOpen();
      },
    },
  },
  methods: {
    checkIsAlertModalOpen() {
      this.isAlertModalOpen = this.modalAlert;
    },
    tryAgain() {
      this.isAlertModalOpen = false;
      this.$emit("tryAgain");
    },
    listPainelName() {
      return this.painelNameList;
    },
  },
};
</script>
<style></style>

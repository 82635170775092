import UsuarioIniciadorRepository from "@/repositories/v2/UsuarioIniciadorRepository";

export class UsuarioIniciadorService {
  vm;
  usuarioIniciadorRepository = UsuarioIniciadorRepository.build();

  constructor(usuarioIniciadorRepository) {
    this.usuarioIniciadorRepository = usuarioIniciadorRepository;
  }

  static build() {
    const usuarioIniciadorRepository = UsuarioIniciadorRepository.build();
    return new UsuarioIniciadorService(usuarioIniciadorRepository);
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async usuarioIniciadorLinhaPaineis(numeroPedido) {
    try {
      await this.usuarioIniciadorRepository
        .getUsuarioIniciadorLinhaPaineis(numeroPedido)
        .then((response) => {
          const message = response.data.message;
          if (response.data.statusCode !== 200) {
            this.vm.alertUsuarioIniciador = {
              message: message,
              responseStatus: response.data.statusCode,
              variant: response.data.statusCode === 200 ? "success" : "danger",
            };
            this.vm.$bvModal.show("modal-usuario-iniciador");
          }
        });
    } catch (e) {
      console.error(e);
    }
  }

  async usuarioIniciadorLinhaCabos(numeroPedido) {
    try {
      await this.usuarioIniciadorRepository
        .getUsuarioIniciadorLinhaCabos(numeroPedido)
        .then((response) => {
          const message = response.data.message;
          if (response.data.statusCode !== 200) {
            this.vm.alertUsuarioIniciador = {
              message: message,
              responseStatus: response.data.statusCode,
              variant: response.data.statusCode === 200 ? "success" : "danger",
            };
            this.vm.$bvModal.show("modal-usuario-iniciador");
          }
        });
    } catch (e) {
      console.error(e);
    }
  }

  async usuarioIniciadorLinhaDiversos(numeroPedido) {
    try {
      await this.usuarioIniciadorRepository
        .getUsuarioIniciadorLinhaDiversos(numeroPedido)
        .then((response) => {
          const message = response.data.message;
          if (response.data.statusCode !== 200) {
            this.vm.alertUsuarioIniciador = {
              message: message,
              responseStatus: response.data.statusCode,
              variant: response.data.statusCode === 200 ? "success" : "danger",
            };
            this.vm.$bvModal.show("modal-usuario-iniciador");
          }
        });
    } catch (e) {
      console.error(e);
    }
  }

  async usuarioIniciadorLinhaPerfis(numeroPedido) {
    try {
      await this.usuarioIniciadorRepository
        .getUsuarioIniciadorLinhaPerfis(numeroPedido)
        .then((response) => {
          const message = response.data.message;
          if (response.data.statusCode !== 200) {
            this.vm.alertUsuarioIniciador = {
              message: message,
              responseStatus: response.data.statusCode,
              variant: response.data.statusCode === 200 ? "success" : "danger",
            };
            this.vm.$bvModal.show("modal-usuario-iniciador");
          }
        });
    } catch (e) {
      console.error(e);
    }
  }

  async usuarioIniciadorLinhaInversores(numeroPedido) {
    try {
      await this.usuarioIniciadorRepository
        .getUsuarioIniciadorLinhaInversores(numeroPedido)
        .then((response) => {
          const message = response.data.message;
          if (response.data.statusCode !== 200) {
            this.vm.alertUsuarioIniciador = {
              message: message,
              responseStatus: response.data.statusCode,
              variant: response.data.statusCode === 200 ? "success" : "danger",
            };
            this.vm.$bvModal.show("modal-usuario-iniciador");
          }
        });
    } catch (e) {
      console.error(e);
    }
  }
}

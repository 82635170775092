import { redirectToLoginScreen } from "../services/login/LoginService";
import { UsuarioLogado } from "../services/login/UsuarioLogado";

export default {
  async beforeRouteEnter(to, from, next) {
    if (!UsuarioLogado.isLogged()) {
      await redirectToLoginScreen();
    }
    next();
  },
};

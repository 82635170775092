export default {
  orders: [],
  isLoadingOrders: false,
  orderDetails: {},
  isLoadingOrderDetails: false,
  pagination: {
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    nextPage: 0,
  },
  urlParams: {
    page: 1,
    perPage: 10,
    numeroPedido: "",
    transportadoraNome: "",
    dataInicio: "",
    dataFinal: "",
  },
  defaultParams: {
    web: true,
    ploomes: true,
    express: true,
    fixador: true,
    spt: false,
    bloqueado: true,
  },
};

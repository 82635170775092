import { HttpRestService } from "@/services/http/HttpRestService";

export default class SelecionarPalletRepository {
  static build() {
    return new SelecionarPalletRepository();
  }

  listarPallets(codigoWinthor, qntdInsumo) {
    const queryStrings = {
      codigoWinthor: codigoWinthor,
      qntdInsumo: qntdInsumo,
    };

    const url = `/api/v2/pallet/listar`;

    return HttpRestService.get(url, queryStrings);
  }
}

import { HttpRestService } from "@/services/http/HttpRestService";

export class UsuariosRepository {
  static getUsers(urlParams) {
    let url = `/api/v2/usuario/listagem?page=${urlParams.page}&perPage=${10}`;
    if (urlParams.email) {
      url = url.concat(`&email=${urlParams.email}`);
    }
    if (urlParams.nome) {
      url = url.concat(`&nome=${urlParams.nome}`);
    }
    return HttpRestService.get(url);
  }

  static getUser(usuarioId) {
    return HttpRestService.get(`/api/v2/usuario/listagem/${usuarioId}`);
  }

  static postUser(body) {
    return HttpRestService.post(`/api/v2/usuario/cadastrar`, body);
  }

  static editUser(body) {
    return HttpRestService.post(`/api/v2/usuario/editar`, body);
  }

  static getImpressoras() {
    return HttpRestService.get(`/api/v2/usuario/listagem/impressoras`);
  }

  // deleteUser(id) {
  //   return HttpRestService.delete(`/api/users/${id}`);
  // }
}

<template>
  <b-modal data-bv
    id="modal-inicar-producao"
    body-class="rounded"
    hide-header
    hide-footer
    centered
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">
          {{ loading ? "Iniciando" : "Iniciar" }} Producao do pedido #{{
            numeroPedido
          }}
        </h4>
      </div>
      <hr />
      <div class="row justify-content-center">
        <div v-if="!loading" class="col-8">
          <form-iniciar-producao
            @iniciarProducao="iniciarProducao"
            @closeModal="closeModal"
            :separadores="separadores"
            :localizacoesEstoque="localizacoesEstoque"
          />
        </div>
        <div class="col-12 text-center">
          <b-spinner v-if="loading" style="width: 10rem; height: 10rem" />
        </div>
      </div>
      <modal-erro-iniciar-producao :message="messageError" />
    </div>
  </b-modal>
</template>

<script>
import { IniciarProducaoService } from "../../../services/iniciar_producao/IniciarProducaoService";
import FormIniciarProducao from "./FormIniciarProducao.vue";
import ModalErroIniciarProducao from "./ModalErroIniciarProducao.vue";
export default {
  components: { ModalErroIniciarProducao, FormIniciarProducao },
  name: "ModalIniciarProducao",
  data: () => ({
    messageError: "",
    loading: false,
    form: {},
    iniciarProducaoService: IniciarProducaoService.build(),
  }),
  created() {
    this.iniciarProducaoService = IniciarProducaoService.build().setVm(this);
  },
  props: {
    numeroPedido: { Type: Number, required: true },
    localizacoesEstoque: { Type: Array, required: true, default: [] },
    separadores: { Type: Array, required: true, default: [] },
  },
  methods: {
    async iniciarProducao(form) {
      this.form = form;
      await this.iniciarProducaoService.iniciarProducaoExpress();
    },
    closeModal() {
      this.$bvModal.hide("modal-inicar-producao");
    },
  },
};
</script>

<style scoped>
.btn-success {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}

.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
</style>

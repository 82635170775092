import router from "../../router";
import { UsuarioLogado } from "../login/UsuarioLogado";
import { PedidosRepository } from "@/repositories/v2/PedidosRepository";
import { mapPedidosDetalhesData } from "@/services/pedido_web_producao/maps";
import { PedidoDetalhesModel } from "@/models/PedidoDetalhesModel";

export class EditarSelecaoLinhasService {
  vm;
  pedidos;
  pedidosRepository = PedidosRepository.build();
  pedidoDetalhes = PedidoDetalhesModel.build();

  constructor(pedidosRepository) {
    this.pedidosRepository = pedidosRepository;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  static build() {
    const pedidosRepository = PedidosRepository.build();
    return new EditarSelecaoLinhasService(pedidosRepository);
  }

  async run() {
    await this.requestUserFuncoes();
  }

  async requestUserFuncoes() {
    this.vm.perfilUsuarioAtual = "";
    if (UsuarioLogado.getPerfil().includes("administrador")) {
      this.vm.perfilUsuarioAtual = "ADMIN";
    } else {
      await router.replace({ path: "/dashboard" });
    }
    await this.requestPedidoDetalhes();
  }

  async requestPedidoDetalhes() {
    const numeroPedido = this.vm.$route.params.numped;
    const response = await this.pedidosRepository.getPedidoDetalhes(
      numeroPedido
    );
    // console.log(response);
    const { data } = response.data;
    this.vm.pedidoDetalhes = mapPedidosDetalhesData(data);
    this.pedidoDetalhes = this.vm.pedidoDetalhes;
    // console.log(this.pedidoDetalhes);
    // console.log(this.pedidoDetalhes.statusCodigo);
  }
}

export default {
  getVolumeDetails(state) {
    return state.volumeDetails;
  },
  getIsLoadingVolumeDetails(state) {
    return state.isLoadingVolumeDetails;
  },
  getIsBlockOrderModalOpen(state) {
    return state.isBlockOrderModalOpen;
  },
  getViewKey(state) {
    return state.viewKey;
  },
  getIsInspectionCompletedModalOpen(state) {
    return state.isInspectionCompletedModalOpen;
  },
};

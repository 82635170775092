import { HttpRestService } from "@/services/http/HttpRestService";

export class UsuariosViewRepository {
  static getFuncoes() {
    return HttpRestService.get(`/api/funcs`);
  }

  static getRoles() {
    return HttpRestService.get(`/api/roles`);
  }

  static getPermissions() {
    return HttpRestService.get(`/api/v2/perfil/listar`);
  }
}

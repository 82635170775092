<template>
  <b-modal data-bv
    id="modal-erro-inicar-producao"
    body-class="rounded"
    hide-header
    centered
    hide-footer
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">Atenção!</h4>
      </div>
      <hr />
      <div class="row justify-content-center">
        <div class="col-12">
          <b-alert show variant="danger">{{ message }}</b-alert>
        </div>
        <b-button @click="closeModal()" variant="danger"> OK </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalIniciarProducao",
  data: () => ({}),
  created() {},
  props: {
    message: { Type: String, required: true },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-erro-inicar-producao");
    },
  },
  computed: {},
};
</script>

<style scoped>
.btn-danger {
  color: #fff !important;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.alert-danger {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
}
</style>

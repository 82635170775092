export default class PainelBase {
  serial = "";
  getCode = () => 0;
  setSerial(serial) {
    this.serial = serial;
    return this;
  }

  getPainelDescricao() {
    return (
      {
        19915: "PAINEL CANADIAN MONO 435 W",
        19914: "PAINEL CANADIAN MONO 440 W",
        24387: "PAINEL CANADIAN MONO 445 W",
        19916: "PAINEL CANADIAN MONO 450 W",
        24388: "PAINEL CANADIAN MONO 455 W",
        19917: "PAINEL CANADIAN MONO 535 W",
        19913: "PAINEL CANADIAN POLI 400 W",
        19911: "PAINEL CANADIAN POLI 405 W",
        20307: "PAINEL CANADIAN POLI 420 W",
        15028: "PAINEL DAH MONO 440 W",
        15892: "PAINEL DAH MONO 540 W",
        17429: "PAINEL DAH MONO 545 W",
        3171: "PAINEL DAH POLI HALF CELL 360 W",
        6146: "PAINEL DAH POLI HALF CELL 400 W",
        62: "PAINEL GCL DE 330 W",
        6484: "PAINEL GCL MONO HALF CELL 440 W",
        2468: "PAINEL GCL MONO. 375 W",
        60: "PAINEL GCL 335W",
        8464: "PAINEL GCL 405W MONO HALF CELL (M3/72H)",
        5278: "PAINEL J.A. SOLAR 325W",
        68: "PAINEL JINKO 280 W",
        63: "PAINEL JINKO 335 W",
        25897: "PAINEL LEAPTON 182-MONO-60MH - 460W",
        31606: "PAINEL LEAPTON 210-MONO-66MH - 665W",
        7329: "PAINEL LONGI MONO HALF CELL 440 W",
        25985: "PAINEL OSDA SOLAR MONO 460W HALF CELLS",
        22938: "PAINEL RESUN MONO 545W",
        69: "PAINEL SUNLIGHT 265 W (VIDRO)",
        61: "PAINEL TRINA 330W",
        10950: "PAINEL ULICA MONO HALF-CUT 440 W",
        8915: "PAINEL ULICA MONO HALF-CUT 450 W",
        21632: "PAINEL ULICA MONO HALF-CUT 505 W",
      }[this.getCode()] || ""
    );
  }
}

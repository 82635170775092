import { HttpRestService } from "@/services/http/HttpRestService";

export class FirstLineRepository {
  static build() {
    return new FirstLineRepository();
  }

  getLocalizacaoEstoque() {
    return HttpRestService.get(`/api/local-estoque`);
  }

  getUser() {
    return HttpRestService.get(`/api/user-sep-linha1`);
  }

  getPedidos(numeroPedido) {
    return HttpRestService.get(
      `/api/v2/insumos/pedido/${numeroPedido}/linha/paineis`
    );
  }
}

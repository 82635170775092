import PainelBase from "./PainelBase";

export default class PainelCanadianMono435W extends PainelBase {
  getCode = () => 19915;

  isValid() {
    /* ESTUDO DE PADRÃO DE PAINEL 19915 SEGUNDO DADOS DO DIA 2022-01-12 NO WINTHOR
            Prefixos:
                Consulta executada:
                    select
                        prefix,
                        (select count(*) from sspedido s where s.codprodmp = 19915 and  substr(s.numerosseriecontrolados, 1, 9) = prefix) q_insumos,
                        (select count(distinct s.numped) from sspedido s where s.codprodmp = 19915 and  substr(s.numerosseriecontrolados, 1, 9) = prefix) q_pedidos
                    from (
                        select
                            distinct substr(numerosseriecontrolados, 1, 9) as prefix
                        from SSPEDIDO s
                        where s.codprodmp = 19915
                    ) order by prefix
                Resultado:
                    +---------+------------------+------------------+
                    |PREFIX   |QUANTIDADE_INSUMOS|QUANTIDADE_PEDIDOS|
                    +---------+------------------+------------------+
                    |021500109|1                 |1                 |
                    |021500202|1                 |1                 |
                    |021500206|1                 |1                 |
                    |102150020|5                 |3                 |
                    |121021500|547               |30                |
                    |211510200|2                 |2                 |
                    +---------+------------------+------------------+
                Interpretação:
                    Essa consulta devolve todos os prefixos de 9 dígitos do insumo em questão, mostrando
                    6 resultados. Perceba que a moda dessa amostra é o número 121021500, com 98% de presença.

                    Essa tendência é tal que os 3 primeiros casos que destoam dessa padrão apresentam as
                    iniciais como 021500, que é justamente a mesma string 121021500 a partir do 4º dígito;
                    e as iniciais do 4º caso é a mesma string 121021500, mas a partir do 3º dígito.

                    Nesses 3 primeiros casos, o que aconteceu provavelmente foi que o bipador não conseguiu pegar todos
                    os dígitos do serial, e pegou apenas do 4º dígito para frente, posto que a moda dessa amostra é o nú
                    mero 121021500, que contém justamente os dígitos 021500, que são característicos desses dois casos.

                    Para o último caso, que é a inicial 211510200, temos dois pedidos de julho de 2021 com os
                    seguintes seriais:
                        +-----------------------+
                        |NUMEROSSERIECONTROLADOS|
                        +-----------------------+
                        |211510200048           |
                        |211510200217           |
                        +-----------------------+
                    Perceba que a inicial é exatamente a mesma e a quantidade de caracteres é a mesma, diminuindo muito
                    a possibilidade de erro de bipagem. Esse padrão provavelmente existe. Contudo, o que parece ter acon-
                    tecido é que o operador (que é o mesmo nos dois pedidos) pegou um painel de outra marca e bipou no
                    lugar do que deveria ser um Canadian. Portanto, essa amostragem foi descartada, como as outras.

                    Conclui-se, que as iniciais desse painel sempre devem ser "121021500".
            Tipagem:
                Foi verificado que todos os seriais desse painel são numéricos. Consulta:
                    select
                        s.numerosseriecontrolados
                    from sspedido s
                    where
                        s.codprodmp = 19915
                        and REGEXP_REPLACE(s.NUMEROSSERIECONTROLADOS, '^[0-9]+$', '') is not null

            Tamanho:
                Consulta:
                    select
                       length
                       ,(select count(*) from sspedido s where s.CODPRODMP = 19915 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_insumos
                       ,(select count(distinct s.NUMPED) from sspedido s where s.CODPRODMP = 19915 and length(s.NUMEROSSERIECONTROLADOS) = length) as q_pedidos
                    from (
                      select distinct length(s.NUMEROSSERIECONTROLADOS) as length
                      from SSPEDIDO S
                      where s.CODPRODMP = 19915
                    ) order by length
                Resultado:
                    +------+---------+---------+
                    |LENGTH|Q_INSUMOS|Q_PEDIDOS|
                    +------+---------+---------+
                    |11    |3        |2        |
                    |12    |4        |4        |
                    |13    |1        |1        |
                    |14    |540      |30       |
                    |15    |3        |1        |
                    |16    |5        |3        |
                    |17    |1        |1        |
                    +------+---------+---------+
                Interpretação:
                    Perceba que a quantidade de seriais com 14 dígitos corresponde a 96% do total. Para todos os outros
                    lengths, muito provavelmente ocorreu algum erro de bipagem.

            Em virtude desses dados, concluímos que:

            Esse serial é composto pelas iniciais 121021500 mais 5 dígitos numéricos, totalizando 14 dígitos.

         */
    return !!this.serial.match(/^121021500\d{5}$/);
  }

  static build() {
    return new PainelCanadianMono435W();
  }
}

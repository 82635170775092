import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
// import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#F9820B",
        primaryTransportadora: "#0f2733",
        background: "#eeeeee",
        secondary: "#E5620B",
        tertiary: "#12e07e",
        base: "#ffffff",
        base_dark: "#dadfe0",
        navy: "#0f2733",
        text: "#002233",
        hoverGlobal: "#cfd2d3",
        btnCorColeta: "#f2f4f5",
      },
      dark: {
        primary: "#F9820B",
        primaryTransportadora: "eeeeee",
        secondary: "#E5620B",
        tertiary: "#12e07e",
        background: "#222426",
        base: "#181a1b",
        base_dark: "#2b2f31",
        navy: "#eeeeee",
        text: "#eeeeee",
        hoverGlobal: "#444444",
        btnCorColeta: "#0f2733",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});

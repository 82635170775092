<template>
  <b-modal data-bv
    id="modal-detalhes"
    body-class="rounded"
    size="lg sm"
    hide-footer
    hide-header
    centered
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">
          Detalhes do pedido #{{ pedido.numeroPedido }}
        </h4>
      </div>
      <div class="mt-2">
        <b-table
          hover
          borderless
          fixed
          :sticky-header="false"
          small
          :items="mappedRelacao"
          :fields="fields"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ModalDetalhesPedido",
  data: () => ({
    fields: [
      {
        key: "linha",
        sortable: false,
        label: "LINHA",
      },
      {
        key: "produto",
        label: "PRODUTO",
      },
      {
        key: "numeroNegociacao",
        label: "Nº Negociação",
      },
      {
        key: "quantidade",
        label: "QTD.",
      },
      {
        key: "quantidadeReal",
        label: "QTD. SEP.",
      },
      // {
      //   key: 'separador',
      //   label: 'SEPARADOR'
      // },
    ],
  }),
  props: {
    pedido: { type: Object, required: true },
    relacaoTiposInsumoQuantidade: { type: Array, required: true },
  },
  computed: {
    mappedRelacao() {
      const relacaoWithSpaces = [];
      const linhas = [
        ...new Set(this.relacaoTiposInsumoQuantidade.map((r) => r.linha)),
      ];
      let j = 0;
      for (
        let i = 0;
        i < this.relacaoTiposInsumoQuantidade.length + linhas.length;
        i++
      ) {
        const relacao = this.relacaoTiposInsumoQuantidade[i];
        if (!relacao) continue;
        const isSecondaryRow =
          i === 0 ||
          this.relacaoTiposInsumoQuantidade[i - 1].linha !== relacao.linha;
        if (isSecondaryRow) {
          const insumosPorLinha = this.relacaoTiposInsumoQuantidade.filter(
            (r) => r.linha === relacao.linha
          );
          const quantidadeInsumosPorLinha = insumosPorLinha.reduce(
            (acc, r) => acc + (r.linha != 1 ? r.quantidade : r.quantidadeReal),
            0
          );
          const quantidadeInsumosFinalizadosPorLinha = insumosPorLinha.reduce(
            (acc, r) => acc + r.quantidadeFinalizados,
            0
          );
          const _rowVariant = ((pendentes, finalizados) => {
            if (finalizados === pendentes) return "success";
            return finalizados > 0 ? "warning" : "danger";
          })(quantidadeInsumosPorLinha, quantidadeInsumosFinalizadosPorLinha);
          const quantidadeReal = `${quantidadeInsumosPorLinha}/${quantidadeInsumosFinalizadosPorLinha}`;
          const quantidade = insumosPorLinha.reduce(
            (acc, r) => acc + r.quantidade,
            0
          );
          relacaoWithSpaces.push({
            linha: linhas[j++] + " - " + relacao.separador,
            produto: "",
            numeroNegociacao: "",
            quantidade,
            quantidadeReal,
            _rowVariant,
          });
        }
        // console.log(relacao);
        const tableRow = {
          linha: "",
          produto: relacao.insumoDescricao,
          numeroNegociacao: relacao.NumeroNegociacao,
          quantidade:
            relacao.linha == 1 ? relacao.quantidade : relacao.quantidade,
          quantidadeReal: relacao.quantidadeFinalizados,
          separador: relacao.separador,
        };
        relacaoWithSpaces.push(tableRow);
      }
      return relacaoWithSpaces;
    },
  },
};
</script>

<style scoped></style>

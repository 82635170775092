import { dateTimeInFormatYmdhis } from "../../dateService";
import { LocalStorageService } from "../../LocalStorageService";
import LinhasEnum from "../../enums/LinhasEnum";
import { ProximoInsumoService } from "./ProximoInsumoService";
import { ValidaSerial } from "./validacao_bipagem/ValidaSerial";
import { ThirdLineRepository } from "../../../repositories/v1/ThirdLineRepository";

export class BipagemService {
  insumosBipados = [];
  insumo;
  index;
  vm;
  event;
  input;
  log;
  insumoParaRequest;
  localStorageService;
  proximoInsumoService;
  validaSerial;
  thirdLineRepository;

  constructor(
    localStorageService,
    proximoInsumoService,
    validaSerial,
    thirdLineRepository
  ) {
    this.proximoInsumoService = proximoInsumoService;
    this.localStorageService = localStorageService;
    this.validaSerial = validaSerial;
    this.thirdLineRepository = thirdLineRepository;
  }

  static build() {
    return new BipagemService(
      LocalStorageService.build(),
      ProximoInsumoService.build(),
      ValidaSerial.build(),
      ThirdLineRepository.build()
    );
  }

  getInsumosBipados() {
    return this.insumosBipados;
  }

  setInsumo(insumo) {
    this.insumo = insumo;
    return this;
  }

  setIndex(index) {
    this.index = +index;
    return this;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  setEvent(event) {
    this.event = event;
    return this;
  }

  run() {
    try {
      this.validateBipagem();
    } catch (error) {
      console.error(error.message);
    }
  }

  async validateBipagem() {
    this.setInput();
    this.validateIfEnterWasPressed();
    this.validarSerialBipado();
    await this.enviarConfirmacaoParaBackend();
    this.avaliarAvancoParaProximoInput();
  }

  validarSerialBipado() {
    // this.validaSerial.setInsumo(this.insumo);
    // this.validaSerial.setValorBipado(this.input.value.trim());
    console.log(this.input.value);
    if (!this.input.value.length) {
      alert("Serial bipado está vazio.");
      this.input.value = "";
      throw new Error("Serial Bipado não corresponde ao padrão.");
    }
  }

  async enviarConfirmacaoParaBackend() {
    const response = await this.thirdLineRepository.save(this.getRequestBody());
    const { data } = response;

    // Valida serial Duplicado
    this.validaSerialDuplicado(data.prox_pedido);
    this.vm.insumos.forEach((insumo) => {
      if (insumo.numeroSequencia === this.insumo.numeroSequencia) {
        insumo.bipado = true;
      }
    });
    return true;
  }

  validaSerialDuplicado(prox_pedido) {
    if (prox_pedido === "cod_duplicado") {
      alert("Esse serial já foi bipado.");
      this.input.value = "";
      throw new Error("Esse serial já foi bipado.");
    }
  }

  abrirTelaPreencherVolume() {
    for (const insumo of this.vm.insumos) {
      insumo.bipado = true;
    }
  }

  getRequestBody() {
    return {
      type: this.vm.perfilUsuarioAtual,
      item: this.insumo.numeroSequencia,
      date_begin: dateTimeInFormatYmdhis(),
      numped: this.vm.$route.params.numped,
      linha: LinhasEnum.diversos,
      NUMEROSSERIECONTROLADOS: this.input.value.trim(),
      cod: this.insumo.codigoProdutoMateriaPrima,
    };
  }

  setInput() {
    this.input = document.getElementsByClassName(
      "prod-cod-" + this.insumo.index
    )[0];
  }

  validateIfEnterWasPressed() {
    const enterWasPressed = [0, 13, 229].includes(this.event.keyCode);
    if (!enterWasPressed) {
      throw new Error(
        "O enter não foi pressionado. Portanto, não pode avançar."
      );
    }
  }

  setInsumoParaRequest() {
    const cod = this.input.value.trim();
    const item = this.insumo.numeroSequencia;
    const codProdMp =
      (
        this.insumosBipados.find(
          (insumo) => +insumo.numeroSequencia === +this.insumo.numeroSequencia
        ) || {}
      ).CODPRODMP || -1;
    const dbegin = this.vm.horaInicialBipagem;
    const dend = dateTimeInFormatYmdhis();
    this.insumoParaRequest = { cod, item, codProdMp, dbegin, dend };
    this.storeSerialOnLocalStorage();
  }

  avaliarAvancoParaProximoInput() {
    this.vm.$nextTick(() => {
      this.vm.horaInicialBipagem = dateTimeInFormatYmdhis();
      this.proximoInsumoService.setInsumos(this.vm.insumos);
      console.log("ak");
      this.proximoInsumoService.avancarParaProximoInput(this.insumo);
    });
  }
}

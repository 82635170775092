<template>
  <div>
    <table>
      <tbody v-for="(insumo, x) in insumos" :key="x">
        <tr class="itens" v-if="insumo.bipado">
          <th>
            <div class="mt-2" style="font-size: 20px">
              {{ insumo.insumoDescricao }} -
              {{ `${insumo.index}/${totalInsumos}` }}
            </div>
            <div class="mt-2">
              <input
                type="text"
                :class="
                  'prod-cod item-bipagem insumo-' +
                  insumo.insumoId +
                  (insumo.bipado ? ' insumoBipado' : '') +
                  (insumo.loading ? ' input-loading' : '')
                "
                @keyup.enter="validarBipagem($event, x, insumo)"
                :value="insumo.serial"
                :disabled="
                  insumo.bipado && editandoInsumoIndex !== insumo.index
                "
              />
            </div>
          </th>
          <th>
            <div>
              <b-button
                size="lg"
                variant="transparent"
                class="mt-4 rounded-circle p-2"
                @click="editarInsumo(insumo.index)"
                v-if="editandoInsumoIndex !== insumo.index"
              >
                <b-icon
                  @mouseover="hoverEvent(insumo.index)"
                  @mouseleave="hoverEvent(null)"
                  scale="2"
                  icon="pencil-square"
                  :variant="hover === insumo.index ? 'warning' : 'info'"
                ></b-icon>
              </b-button>
              <span v-if="editandoInsumoIndex === insumo.index">
                <th>
                  <b-button
                    aria-pressed="false"
                    size="lg"
                    variant="transparent"
                    class="mt-4 rounded-circle p-2"
                    @click="limparEditandoInsumoIndex"
                  >
                    <b-icon icon="x-circle" scale="2" variant="danger"></b-icon>
                  </b-button>
                </th>
                <!-- <th>
                  <b-button
                    size="lg"
                    variant="transparent"
                    class="mt-4 rounded-circle p-2"
                    @click="validarBipagem($event, x, insumo)"
                  >
                    <b-icon icon="check-circle" scale="2"></b-icon>
                  </b-button>
                </th> -->
              </span>
            </div>
          </th>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { BipagemService } from "@/services/pedido_web_producao/linha_diversos/BipagemService";
import { UsuarioLogado } from "@/services/login/UsuarioLogado";
import BipagemInsumosRepository from "@/repositories/v2/BipagemInsumoRepository";
export default {
  components: {},
  name: "InsumoCard",
  data: () => ({
    hover: 0,
    isHovered: false,
    bodyMoverInsumo: {},
    bipagemService: BipagemService.build(),
    bipagemRepository: BipagemInsumosRepository.build(),
    editandoInsumoIndex: 0,
  }),
  async created() {
    this.bipagemService = BipagemService.build().setVm(this);
  },
  props: {
    volumes: { type: Array, required: true },
    insumos: { type: Array, required: true },
    indexVol: { type: Number, required: true },
    totalInsumos: { type: Number, required: true },
    detalhes: { type: Boolean, default: false },
  },
  computed: {
    permiteBotaoMoverInsumo() {
      return UsuarioLogado.getPerfil().includes("administrador");
    },
  },
  methods: {
    hoverEvent(index) {
      this.hover = index;
    },
    limparEditandoInsumoIndex() {
      this.editandoInsumoIndex = 0;
    },
    editarInsumo(index) {
      this.editandoInsumoIndex = index;
    },
    async validarBipagem(event, index, insumo) {
      this.insumos[index].serial = event.target.value;
      let bodyValidarBipagem = {
        insumo: insumo,
        event: event,
        index: index,
      };
      await this.$emit("validarBipagem", bodyValidarBipagem);
    },
  },
};
</script>
<style scoped>
.insumoBipado {
  border: 2px solid green;
  border-radius: 10px;
}

.input-loading {
  animation: animate 1s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
    border: 2px solid forestgreen;
  }
}
</style>

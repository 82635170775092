import { LocalStorageService } from "../../LocalStorageService";
import LinhasEnum from "../../enums/LinhasEnum";
import { HttpRequest } from "../../http/HttpRequest";
import router from "../../../router";
import PedidoWebRepository from "../../../repositories/v2/PedidoWebRepository";
import Swal from "sweetalert2";

export class FinalizacaoLinhaPaineisService {
  vm = {};
  loading = false;
  numped = "";
  bodyRequest = {};
  localizacaoEstoque = "";
  numVol = 0;
  response = {};
  localStorageService;
  PedidoWebRepository;

  constructor(localStorageService, pedidoWebRepository) {
    this.localStorageService = localStorageService;
    this.pedidoWebRepository = pedidoWebRepository;
  }

  static build() {
    const pedidoWebRepository = PedidoWebRepository.build();
    const localStorageService = LocalStorageService.build();
    return new FinalizacaoLinhaPaineisService(
      localStorageService,
      pedidoWebRepository
    );
  }

  getLoading() {
    return this.loading;
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  run() {
    try {
      this.tryFinalizarLinhaPaineis();
    } catch (e) {
      console.error(e);
    }
  }

  tryFinalizarLinhaPaineis() {
    this.validaSeTodosOsInsumosEstaoBipados();
    this.setBodyRequest();
    this.loading = true;
    this.enviarRequisicaoSalvar();
  }

  validaSeTodosOsInsumosEstaoBipados() {
    if (!this.todosOsInsumosEstaoBipados()) {
      alert("Bipe todos os volumes");
      throw new Error("Nem todos os insumos foram bipados.");
    }
  }

  todosOsInsumosEstaoBipados() {
    return this.vm.insumosLinhaPaineis.every((insumo) => {
      const className = `prod-cod-${insumo.insumoId}`;
      const input = document.querySelector(className);
      if (!input) {
        return true;
      }
      return !!input.value;
    });
  }

  setBodyRequest() {
    this.setNumped();
    // this.setNumVol();
    this.bodyRequest = {
      type: this.vm.perfilUsuarioAtual,
      linha: LinhasEnum.paineis,
      numped: this.numped,
      prod_linha1: this.getInsumosBipadosMapped(),
    };
  }

  getInsumosBipadosMapped() {
    return this.vm.bipagemService.getInsumosBipados().map((insumo) => {
      const { cod, dbegin, dend, item } = insumo;
      return { cod, dbegin, dend, item };
    });
  }

  setNumped() {
    this.numped = this.vm.$route.params.numped;
  }

  // setNumVol() {
  //     this.numVol = +this.vm.insumos.find(insumo => +insumo.LINHA === LinhasEnum.paineis).NUMVOL;
  // }

  enviarRequisicaoSalvar() {
    this.pedidoWebRepository
      .finalizarLinhaPaineis(this.numped)
      .then(async (response) => {
        console.log({ response });
        this.response = response;
        this.treatResponse();
        this.abrirEtiquetas();
        await router.replace({ name: "pedidosWebAguardandoProducao" });
      })
      .catch((error) => {
        console.error(error);
        this.loading = false;
      });
  }

  treatResponse() {
    if (this.response.status === 200) {
      Swal.fire({
        icon: "sucess",
        text: this.response.data.message,
      });
    } else {
      Swal.fire({
        icon: "error",
        text: this.response.data.message + " Entre em contato com o Suporte.",
      });
    }
  }

  // mostrarFinalizacaoProducao() {
  //     this.localStorageService.removerSeriaisBipados();
  //     alert("O pedido foi finalizado com sucesso! ARMAZENAR A MERCADORIA NA LOCALIZAÇÃO: " + this.localizacaoEstoque);
  // }

  // mostrarFinalizacaoLinhaPaineis() {
  //     this.localStorageService.removerSeriaisBipados();
  //     alert("O pedido foi finalizado com sucesso! ARMAZENAR A MERCADORIA NA LOCALIZAÇÃO: " + this.localizacaoEstoque);
  // }

  // mostrarErroBipagemBackend() {
  //     let errorDescricao = "";
  //     this.response.data.prods.forEach(error => {
  //         errorDescricao += `  - Pedido: ${error.id} - cód.: ${error.cod} - cód. enviado: ${error.cod_enviado}`;
  //     });
  //     const mensagemErro = "Alguns items estão com código de barras cadastrados no sistema, favor entrar em contato com o admin!";
  //     alert(mensagemErro + errorDescricao);
  //     window.location.href = "/pedido/" + this.numped;
  // }

  abrirEtiquetas() {
    const host = HttpRequest.getBaseUrl();
    const { numped, numVol } = this;
    window.location.href = `${host}/pedido/volume-imp/${numped}/${numVol}/1`;
  }
}

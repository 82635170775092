<template>
  <b-modal data-bv
    id="modal-usuarios"
    body-class="rounded"
    hide-footer
    hide-header
    centered
  >
    <div class="mt-2">
      <div class="d-block text-center">
        <h4 class="font-weight-bold">Atenção!</h4>
      </div>
      <div class="col-8 text-center mx-auto">
        <b-alert show :variant="modalAlert.variant">
          {{ modalAlert.message }}
        </b-alert>
        <div
          :class="`btn-${modalAlert.variant}`"
          @click="clickModal"
          class="btn"
        >
          Ok
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import router from "../../router";

export default {
  name: "ModalUsuarios",
  props: {
    modalAlert: { Type: Object, required: true },
  },
  methods: {
    clickModal() {
      if (this.modalAlert.variant == "success") {
        router.replace({
          name: "users",
        });
      } else this.$bvModal.hide("modal-usuarios");
    },
  },
};
</script>

<style scoped>
.alert-danger {
  background-color: #f8d7da !important;
}
.alert-success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
</style>

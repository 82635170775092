export default {
  getDrawer(state) {
    return state.drawer;
  },
  getDarkMode(state) {
    return state.darkMode;
  },
  getEnvironment(state) {
    return state.environment;
  },
};

import { HttpRestService } from "../http/HttpRestService";

export class ParametrosIniciarProducaoPedido {
  vm;

  constructor() {}

  static build() {
    return new ParametrosIniciarProducaoPedido();
  }

  setVm(vm) {
    this.vm = vm;
    return this;
  }

  async run() {
    await this.getLocalizacoesEstoque();
    await this.getSeparadores();
  }

  async getLocalizacoesEstoque() {
    const localizacoesEstoque = await HttpRestService.get("/api/local-estoque");
    this.vm.localizacoesEstoque = this.mapLocalizacoesEstoque(
      localizacoesEstoque.data
    );
  }

  mapLocalizacoesEstoque(localizacoesEstoque) {
    return localizacoesEstoque.map((value) => {
      return {
        value: value.SECTION + value.NR,
        text: value.SECTION + value.NR,
      };
    });
  }

  async getSeparadores() {
    const separadores = await HttpRestService.get("/api/user-sep-linha1");
    this.vm.separadores = this.mapSeparadores(separadores.data);
  }

  mapSeparadores(separadores) {
    return separadores.map((value) => {
      return {
        value: parseInt(value.user.PCEMPR_ID),
        text: value.user.empr.NOME,
      };
    });
  }
}

export default {
  setDrawer(state, value) {
    state.drawer = value;
  },

  setDarkMode(state, value) {
    state.darkMode = value;
  },
  setEnvironment(state, payload) {
    state.environment = payload;
  },
};

<template>
  <div>
    <div>
      <b-modal data-bv
        id="modal-resend-etiqueta"
        body-class="rounded"
        hide-footer
        hide-header
        centered
      >
        <div v-if="!isLoading">
          <b-row align-h="center">
            <b-col sm="9" align-self="center">
              <b-row align-h="center">
                <label class="font-weight-bolder text-style h5"
                  >Deseja reimprimir a etiqueta?</label
                >
              </b-row>
            </b-col>
          </b-row>
          <b-row align-h="center" class="justify-content-center my-4">
            <b-col
              cols="5"
              align-self="center"
              class="d-flex justify-content-center"
            >
              <default-button
                name="Cancelar"
                color="red-color"
                style="padding: 0.5rem !important"
                @click="cancelaImpressao"
              ></default-button>
            </b-col>
            <b-col
              cols="5"
              align-self="center"
              class="d-flex justify-content-center"
            >
              <default-button
                name="Confirmar Impressão"
                style="padding: 0.5rem !important"
                @click="confirmaImpressao"
              ></default-button>
            </b-col>
          </b-row>
        </div>
        <div v-else class="pad my-2" style="text-align: center !important">
          <b-spinner style="width: 10rem; height: 10rem" />
        </div>
      </b-modal>
    </div>
    <div>
      <modal-resend-etiqueta-alert :modalAlert="modalAlert" />
    </div>
  </div>
</template>

<script>
import DefaultButton from "@/components/DefaultButton";
import { EtiquetaService } from "@/services/etiquetas/EtiquetaService";
import ModalResendEtiquetaAlert from "./ModalResendEtiquetaAlert.vue";

export default {
  name: "ModalResendEtiqueta",
  components: { DefaultButton, ModalResendEtiquetaAlert },
  data: () => ({
    impressoras: [],
    modalAlert: {},
    selectImpressora: null,
    isLoading: false,
    etiquetaService: EtiquetaService.build(),
  }),
  mounted() {
    this.etiquetaService = EtiquetaService.build().setVm(this);
  },
  props: {
    numped: { Type: String, required: true },
  },
  methods: {
    cancelaImpressao() {
      this.$bvModal.hide("modal-resend-etiqueta");
    },

    confirmaImpressao() {
      this.etiquetaService.resendEtiqueta(this.numped);
    },
  },
};
</script>

<style scoped>
.input-style {
  font-family: inherit;
  font-size: 14px;
  line-height: 20px;
  color: #002233;
  background-color: #fff;
  border: 2px solid #e1e1e1;
  border-radius: 11px;
  outline: 0;
  box-sizing: border-box;
}
.input-style:hover {
  border-color: inherit !important;
  box-shadow: inherit !important;
  border: 3px solid rgba(230, 120, 30, 0.7) !important;
}
.input-style:focus {
  border-color: inherit !important;
  box-shadow: inherit !important;
  border: 3px solid rgba(230, 120, 30, 0.7) !important;
}
.alert-danger {
  background-color: #f8d7da !important;
}
.alert-success {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
}
</style>
